import React from "react";
import UilStar from "@iconscout/react-unicons/icons/uil-star";

/**
 * Renders a rating with stars. This is not an input, see RatingInput.
 * @param {int} count - The rating to show.
 */
const Rating = ({ count }) => (
  <span className="text-base font-serif mx-1 flex items-center">
    <span className="text-kasmir flex items-center">
      {[...Array(!count ? 0 : count > 5 ? 5 : count)].map((e, i) => (
        <UilStar key={i} size="16" />
      ))}
    </span>
    <span className="text-geyser flex items-center">
      {[...Array(5 - (!count ? 0 : count > 5 ? 5 : count))].map((e, i) => (
        <UilStar key={i} size="16" />
      ))}
    </span>
  </span>
);

export default Rating;
