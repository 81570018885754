import React, { useState } from "react";
import { admin_project_overview as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import TextareaAutosize from "react-textarea-autosize";
import {
  getProjectDescriptionV2,
  patchProjectDescriptionV2,
} from "utils/adminApi";
import useCachedFetch from "hooks/useCachedFetch";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import UilBriefcaseAlt from "@iconscout/react-unicons/icons/uil-briefcase-alt";
import { urlify } from "utils/str";

const Description = ({ project, hubspotInputsDisabled, onDisabledClick }) => {
  const [open, setOpen] = useState(true);
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState("");
  const alert = useAlert();

  const { data, setData } = useCachedFetch(
    "admin_project_description",
    getProjectDescriptionV2,
    project.id
  );

  const onSave = async () => {
    setEditing(false);
    const origVal = data.description;

    setData({ description: value });

    const call = async () => {
      try {
        await patchProjectDescriptionV2(project.id, { description: value });
      } catch (e) {
        setData({ description: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  return (
    <Section id="admin_project_overview" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilBriefcaseAlt className="mr-2" size="18" /> {$.description_title}
          </B2DB>
          <div className="cursor-pointer" onClick={() => setOpen(!open)}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && (
          <div className="px-2 pb-4">
            {editing ? (
              <div className="">
                <TextareaAutosize
                  autoFocus
                  className="px-0 py-0 rounded font-bold text-kasmir text-sm bg-white placeholder-text-kasmir placeholder:font-bold focus:outline-none appearance-none border border-link-water w-full"
                  onChange={(e) => setValue(e.target.value)}
                  value={value}
                />
                <div className="flex items-center justify-end">
                  <button
                    className="text-link text-sm font-bold"
                    onClick={() => setEditing(false)}
                  >
                    {$.cancel_btn}
                  </button>
                  <button
                    onClick={onSave}
                    className="text-link text-sm font-bold ml-2"
                  >
                    {$.save_btn}
                  </button>
                </div>
              </div>
            ) : (
              <div
                className="text-sm text-kasmir cursor-text whitespace-pre-line"
                onClick={(e) => {
                  if (e.target.tagName === 'A') return;
                  if (hubspotInputsDisabled && onDisabledClick) {
                    onDisabledClick();
                    return;
                  }
                  setEditing(true);
                  if (data) {
                    setValue(data.description);
                  }
                }}
              >
                <div className="text-sm font-normal" dangerouslySetInnerHTML={{ __html: data?.description ? urlify(data.description) : $.description_placeholder}} />
              </div>
            )}
          </div>
        )}
      </div>
    </Section>
  );
};

export default Description;
