import React from "react";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Link = ({ to, cached, Icon, title, exact }) => {
  const location = useLocation();

  return (
    <NavLink
      exact={exact}
      to={cached ? (cached !== location.pathname ? cached : to) : to}
      className="rounded inline-flex items-center justify-center w-10 h-10 hover:bg-midnight-dark"
      activeClassName="font-bold admin-nav-link--active"
      title={title}
    >
      <Icon className="text-white" size="22" />
    </NavLink>
  );
};

const Button = ({ Icon, children, ...props }) => (
  <button
    {...props}
    className="inline-flex items-center justify-center w-10 h-10 appearance-none hover:bg-midnight-dark"
  >
    <Icon className="text-white" size="22" />
  </button>
);

const Sidebar = ({ children }) => {
  return (
    <div className="bg-midnight h-full inline-flex flex-col px-2 py-2">
      {children}
    </div>
  );
};

export { Sidebar, Link, Button };
