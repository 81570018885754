import React, { useState } from "react";
import { admin_contact as $ } from "strings";
import { Link } from "react-router-dom";
import { patchAccountContactV2 } from "utils/adminApi";
import { toFormat } from "utils/date";
import Header from "components/ListHeader";
import EditableField from "components/EditableField";
import Indicator from "components/ListIndicator";
import DatePicker from "components/ListDatePicker";
import Select from "components/CompactSelect";
import { RemoveBtn } from "components/Buttons";
import UilCalendarAlt from "@iconscout/react-unicons/icons/uil-calendar-alt";
import UilHeartRate from "@iconscout/react-unicons/icons/uil-heart-rate";
import UilAward from "@iconscout/react-unicons/icons/uil-award";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { getAdminFlags } from "utils/localStorageService";

const boolOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const CompanyRole = ({ roleData, onDelete, onUpdateRole, hubspotInputsDisabled, onDisabledClick }) => {
  const alert = useAlert();
  const [role, setRole] = useState(roleData);
  const [states, setStates] = useState({});
  const flags = getAdminFlags();

  const onChange = async (key, value) => {
    const origVal = role[key];
  
    let toSend = {
      [key]: value,
      user_id: role.user_id
    };
    
    if (key === 'is_active_role' && value === true) {
      toSend.end_date = null;
    }
    setRole({ ...role, ...toSend });
    setStates({ ...states, [key]: "saving" });
    try {
      const response = await patchAccountContactV2(role.id, toSend);
      onUpdateRole(response);
      setStates({ ...states, [key]: "saved" });
    } catch (e) {
      console.error(e);
      setStates({ ...states, [key]: "error" });
      setRole({ ...role, [key]: origVal });
      alert.error(<AlertError error={e} onRetry={() => onChange(key, value)} />);
    }
  }

  return (
    <div 
      id={`company_role--${role.id}`}
      className="bg-white mt-2 mb-2 flex flex-col"
    >
      <div className="w-full flex space-between px-2">
        <div className="items-center w-1/4 mr-1 truncate">
          <div className="text-sm text-midnight font-bold text-ellipsis font-bold mt-1">
            <div className="text-sm text-midnight font-bold text-ellipsis font-bold mt-1">
              {role.company?.name && role.company?.id ?
                <Link
                  id={`companyLink--${role.company.id}`}
                  className="inline-block"
                  to={`/admin/company/${role.company.id}`}
                >
                  {role.company.name}
                </Link> :
              <>{$.unknown_value}</>}
            </div>
          </div>
          <div
            className="text-sm text-midnight font-bold truncate pb-px"
            onClick={() => hubspotInputsDisabled && onDisabledClick()}
          >
            <EditableField
              value={role.role_title}
              displayValue={role.role_title ? role.role_title : $.no_current_title}
              onSave={(v) => {onChange("role_title", v)}}
              disabled={hubspotInputsDisabled}
            />
          </div>
        </div>
        <div className="mt-1 w-1/6" onClick={() => hubspotInputsDisabled && onDisabledClick()}>
          <Header Icon={UilAward} text={$.primary_role_label}>
            <Indicator state={states.is_primary_role} />
          </Header>
          <div className="text-sm text-midnight font-bold text-ellipsis">
           <Select
              className="-ml-1"
              value={boolOptions.find(o => o.value === role.is_primary_role) || null}
              onChange={(v) => onChange("is_primary_role", v.value || false)}
              options={boolOptions}
              isDisabled={hubspotInputsDisabled}
            />
          </div>
        </div>
        <div className="mt-1 w-1/6" onClick={() => hubspotInputsDisabled && onDisabledClick()}>
          <Header Icon={UilHeartRate} text={$.active_role_label}>
            <Indicator state={states.is_active_role} />
          </Header>
          <div className="text-sm text-midnight font-bold text-ellipsis">
            <Select
              className="-ml-1"
              value={boolOptions.find(o => o.value === role.is_active_role) || null}
              onChange={(v) => onChange("is_active_role", v.value || false)}
              options={boolOptions}
              isDisabled={hubspotInputsDisabled}
            />
          </div>
        </div>
        <div className="mt-1 w-1/6" onClick={() => hubspotInputsDisabled && onDisabledClick()}>
          <Header Icon={UilCalendarAlt} text={$.role_started_on_label}>
            <Indicator state={states.start_date} />
          </Header>
          <div className="text-sm text-midnight font-bold text-ellipsis">
            <DatePicker
              wrapperClassName="mt-1"
              placeholderText={$.unknown_value}
              value={
                role.start_date
                ? new Date(`${role.start_date}T12:00:00`)
                : null
              }
              format="MMMM dd, yyyy"
              monthly={false}
              onChange={(v) =>
                onChange(
                  "start_date",
                  toFormat(v, "yyyy-MM-dd")
                )
              }
              disabled={hubspotInputsDisabled}
            />
          </div>
        </div>
        <div className="mt-1 w-1/6" onClick={() => hubspotInputsDisabled && onDisabledClick()}>
          <Header Icon={UilCalendarAlt} text={$.role_ended_on_label}>
            <Indicator state={states.end_date} />
          </Header>
          <div className="text-sm text-midnight font-bold text-ellipsis">
            <DatePicker
              wrapperClassName="mt-1"
              placeholderText={$.unknown_value}
              disabled={!!role.is_active_role || hubspotInputsDisabled}
              value={
                role.end_date
                ? new Date(`${role.end_date}T12:00:00`)
                : null
              }
              format="MMMM dd, yyyy"
              monthly={false}
              onChange={(v) =>
                onChange(
                  "end_date",
                  toFormat(v, "yyyy-MM-dd")
                  )
                }
            />
          </div>
        </div>
        <div className="flex items-center mr-2 ml-auto">
          <RemoveBtn onClick={hubspotInputsDisabled ? onDisabledClick : onDelete} />
        </div>
      </div>
    </div>
  )
};

export default CompanyRole;