import React, { useEffect } from "react";
import { admin_company_contacts as $ } from "strings";
import { B2BLink, B2DB } from "components/Typography";
import { usePageCache } from "contexts/pageCacheContext";
import { useParams } from "react-router-dom";
import { getContacts } from "utils/adminApi";
import Section from "components/Section";
import useTable from "hooks/useTable";
import UilFolder from "@iconscout/react-unicons/icons/uil-folder-open";

const activeOptions = [
  { label: $.active_option, value: "True" },
  { label: $.inactive_option, value: "False" },
];

const Contacts = () => {
  const { set } = usePageCache();
  const { id } = useParams();
  const { Table, tableProps, Filters, filtersProps } = useTable({
    cacheKey: "cache_company_contacts_" + id,
    api: getContacts,
    defaultParams: { company__id: id },
    groupedBy: 'user'
  });

  useEffect(() => {
    set("url_company", `/admin/company/${id}/contacts`);
  }, [set, id]);

  const schema = {
    name: {
      groupKey: "user",
      renderRow: ({ r }) => (
        <B2BLink to={`/admin/contacts/${r.user}`}>
          {r.name}
        </B2BLink>
      ),
    },
    title: {
      renderRow: ({ r }) => (r.role_title),
      disableSort: true
    },
    owner__user__id: {
      renderRow: ({ r }) => (r.staff_poc?.name)
    },
    timestamp: { type: "date", format: "yyyy-MM", rowFormat: "MM/dd/yyyy" },
    last_updated: { type: "date", format: "yyyy-MM", rowFormat: "MM/dd/yyyy" },
    active: {
      type: "select",
      selectProps: { options: activeOptions },
      renderRow: ({ v }) => (v ? $.active_option : $.inactive_option),
    },
  };

  return (
    <Section id="admin_company_contacts">
      <div className="flex">
        <Filters {...{ schema, $, ...filtersProps }} />
        <div className="bg-white px-4 py-6 mb-6 flex-1 min-w-0">
          <div className="flex items-center">
            <B2DB className="flex flex-1 items-center">
              <UilFolder className="mr-2" size="18" /> {$.contacts_title}
            </B2DB>
          </div>
          <div className="my-6 flex">
            <div className="flex-1 min-w-0">
              <Table {...{ schema, $, name: "contacts", ...tableProps }} />
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export const ContactsPreload = () => {
  const { id } = useParams();

  useTable({
    cacheKey: "cache_company_contacts_" + id,
    api: getContacts,
    defaultParams: { company__id: id },
  });

  return null;
};

export default Contacts;
