import UilClock from "@iconscout/react-unicons/icons/uil-clock";
import React from "react";
import FilterDeleteBtn from "./FilterDeleteBtn";
import { Checkbox } from "./Form";

const preventMinus = (e) => {
  if (e.code === "Minus") {
    e.preventDefault();
  }
};

const FilterIntRange = ({
  filter,
  updateSearch,
  setIndex,
  index,
  isSelected,
  removeFilter,
}) => {
  return !isSelected ? (
    <div
      onClick={() => setIndex(index)}
      className="bg-white border flex flex-col items-center border-link rounded h-8 px-1 text-link text-sm font-bold"
    >
      <div className="h-8 flex items-center w-full">
        <div className="flex-1 flex items-center">
          {filter.title}:{" "}
          {filter.data && filter.data.min && filter.data.max && (
            <>
              {"$" + filter.data.min}-{"$" + filter.data.max}
            </>
          )}
          {filter.data && filter.data.min && !filter.data.max && (
            <>{"$" + filter.data.min}+ </>
          )}
          {filter.data && !filter.data.min && filter.data.max && (
            <>{"$" + filter.data.max}- </>
          )}
          {filter.data && filter.data.useFresh && (
            <UilClock className="mx-1" size="14" />
          )}
        </div>
        <FilterDeleteBtn onClick={() => removeFilter(index)} />
      </div>
      {filter.data && parseInt(filter.data.min) > parseInt(filter.data.max) && (
        <span className="text-red text-2xs font-light -mt-2">
          Minimum value should be lower than maximum
        </span>
      )}
    </div>
  ) : (
    <div className="bg-white border items-center border-link rounded text-sm flex flex-col">
      <div className="border-b border-link h-8 w-full flex items-center text-link font-bold px-1">
        {filter.title}
      </div>
      <div className="px-1 flex flex-col">
        <div className="h-8 flex items-center w-full">
          {filter.symbol && (
            <div className="text-link text-sm mr-1">{filter.symbol}</div>
          )}
          <input
            className="focus:outline-none w-8"
            placeholder="min"
            autoFocus
            type="number"
            min="0"
            onKeyPress={preventMinus}
            value={filter.data ? filter.data.min || "" : ""}
            onChange={(e) =>
              updateSearch(index, {
                ...(filter.data || {}),
                min: e.target.value,
              })
            }
          />
          <span className="mx-1 text-sm text-midnight font-thin">to</span>
          {filter.symbol && (
            <div className="text-link text-sm mr-1">{filter.symbol}</div>
          )}
          <input
            className="focus:outline-none w-8"
            placeholder="max"
            type="number"
            min="0"
            onKeyPress={preventMinus}
            value={filter.data ? filter.data.max || "" : ""}
            onChange={(e) =>
              updateSearch(index, {
                ...(filter.data || {}),
                max: e.target.value,
              })
            }
          />
        </div>
        {filter.data &&
          parseInt(filter.data.min) > parseInt(filter.data.max) && (
            <span className="text-red text-2xs font-light -mt-2">
              Minimum value should be lower than maximum
            </span>
          )}
      </div>
      {filter.freshKey && (
        <div className="h-8 px-1 flex items-center border-t border-link w-full">
          <Checkbox
            onChange={(v) =>
              updateSearch(index, { ...(filter.data || {}), useFresh: v })
            }
            value={filter.data && filter.data.useFresh ? true : false}
          >
            <div className="flex items-center">
              <UilClock size="14" className="mr-1" /> Fresh Data
            </div>
          </Checkbox>
        </div>
      )}
    </div>
  );
};

export default FilterIntRange;
