import React, { useEffect, useRef } from "react";
import useScript from "hooks/useScript";
import axios from "axios";

const addressComponents = {
  street_number: "street_number",
  route: "street",
  neighborhood: "neighborhood",
  sublocality_level_1: "locality",
  locality: "city",
  administrative_area_level_1: "state",
  country: "country",
  postal_code: "zipcode",
};
const mapAddress = (place, timezone, full_address) => {
  const address = {
    location_id: place.place_id,
    street_number: "",
    street: "",
    neighborhood: "",
    locality: "",
    city: "",
    state: "",
    state_code: "",
    country: "",
    zipcode: "",
    timezone_id: timezone ? timezone.timeZoneId : "",
    timezone_name: timezone ? timezone.timeZoneName : "",
    full_address: full_address,
    latitude: place.geometry.location.lat(),
    longitude: place.geometry.location.lng(),
    name: place.name,
  };

  place.address_components.forEach((add) => {
    const type = add.types[0];

    if (addressComponents[type]) {
      address[addressComponents[type]] = add.long_name;

      if (addressComponents[type] === "state") {
        address["state_code"] = add.short_name;
      }
    }
  });

  return address;
};

const AddressSearch = ({
  onChange,
  defaultValue,
  defaultFullAddress,
  className,
  error,
  autoFocus=false,
  placeholder="Not Set",
  disabled,
  onKeyDown = () => {},
}) => {
  const defaultLocationId =
    typeof defaultValue === "object" && defaultValue !== null
      ? defaultValue.location_id
      : defaultValue;

  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places`
  );

  const mapLoaded = useRef();
  const inputRef = useRef();

  useEffect(() => {
    if (loaded && autoFocus) {
      inputRef.current.focus();
    }
  }, [loaded, autoFocus]);

  useEffect(() => {
    const handle = async (place) => {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/timezone/json?timestamp=${Math.round(
          new Date() / 1000
        )}&location=${place.geometry.location.lat()},${place.geometry.location.lng()}&key=${
          process.env.REACT_APP_GOOGLE_KEY
        }`
      );
      onChange(mapAddress(place, response.data, inputRef.current ? inputRef.current.value : ''));
    };

    if (loaded && !mapLoaded.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current
      );

      autocomplete.setFields([
        "place_id",
        "address_components",
        "geometry",
        "icon",
        "name",
        "timezone",
      ]);

      autocomplete.setTypes(["geocode"]);

      autocomplete.addListener("place_changed", () => {
        var place = autocomplete.getPlace();
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          return;
        }

        handle(place);
      });

      mapLoaded.current = true;
    }
  }, [loaded, defaultLocationId, onChange]);

  if (!className) {
    className = "rounded text-sm font-bold h-5 w-full text-midnight bg-white focus:outline-none appearance-none border-2 border-white focus:border-link focus:border-2";
  }
  return (
    <input
      maxLength="250"
      error={error}
      ref={inputRef}
      placeholder={placeholder}
      defaultValue={defaultFullAddress || ""}
      className={className}
      disabled={disabled}
      onKeyDown={onKeyDown}
    />
  );
};

export default AddressSearch;
