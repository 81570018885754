import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import { admin_project_stakeholders as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import UilAward from "@iconscout/react-unicons/icons/uil-award";
import { Button, RemoveBtn } from "components/Buttons";
import useCachedFetch from "hooks/useCachedFetch";
import {
  deleteProjectOpsV2,
  getProjectCesV2,
  getProjectOpsV2,
  patchProjectCesV2,
  patchProjectOpsV2,
  patchProjectV2
} from "utils/adminApi";
import LoadPlaceholder from "components/LoadPlaceholder";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { fetchOwners } from "utils/fetch";
import Select from "components/Select";

const rolesOptions = [
  { label: $.owner_primary_option, value: "owner_primary" },
  { label: $.owner_secondary_option, value: "owner_secondary" },
  { label: $.role_ce_option, value: "ce" },
  { label: $.role_ops_option, value: "ops" },
];

const Stakeholders = ({ data, setData, hubspotInputsDisabled, onDisabledClick }) => {
  const { id } = useParams();
  const { set } = usePageCache();
  const [adding, setAdding] = useState();
  const [stakeholder, setStakeholder] = useState();
  const [role, setRole] = useState();
  const alert = useAlert();

  useEffect(() => {
    set("url_project", `/admin/project/${id}/stakeholders`);
  }, [set, id]);

  const project_ces = useCachedFetch(
    "admin_project_ces_v2",
    getProjectCesV2,
    data && data.id
  );

  const ops = useCachedFetch(
    "admin_project_ops_v2",
    getProjectOpsV2,
    data && data.id
  );

  const handleDelete = async (role, id, name) => {
    let api = null;
    let toSend = {};

    if (role === "owner_primary") {
      setData({ ...data, owner_primary: null });
      toSend.owner_primary = null;
      api = patchProjectV2;
    }

    if (role === "owner_secondary") {
      setData({ ...data, owner_secondary: null });
      toSend.owner_secondary = null;
      api = patchProjectV2;
    }

    if (role === "ce") {
      setData({ ...data, project_ces: data.project_ces.filter((ce) => ce !== name) });
      project_ces.setData({  project_ces: project_ces.data.project_ces.filter((ce) => ce.id !== id) });
      api = patchProjectCesV2;
    }

    if (role === "ops") {
      setData({ ...data, ops_owner: null });
      ops.setData({ ops_owner: null });
      api = deleteProjectOpsV2;
    }

    const call = async () => {
      try {
        if (role === "owner_primary" || role === "owner_secondary") {
          await api(data.id, toSend);
        } else if (role === "ce") {
          await api(data.id, {
            project_ces: project_ces.data.project_ces
              .filter((ce) => ce.id !== id)
              .map((ce) => ce.id),
          });
        } else {
          await api(data.id);
        }
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };

    await call();
  };

  const handleSave = async () => {
    let api = null;
    const toSend = {};

    if (role.value === "owner_primary") {
      if (data.owner_primary && data.owner_primary.id === stakeholder.value) {
        setRole(null);
        setStakeholder(null);
        setAdding(false);
        return;
      }
      setData({ ...data, owner_primary: {
        id: stakeholder.value,
        first_name: stakeholder.owner?.first_name,
        last_name: stakeholder.owner?.last_name,
        email: stakeholder.email
      }});
      api = patchProjectV2;
      toSend.owner_primary_id = stakeholder.value;
    }

    if (role.value === "owner_secondary") {
      if (data.owner_secondary && data.owner_secondary.id === stakeholder.value) {
        setRole(null);
        setStakeholder(null);
        setAdding(false);
        return;
      }
      setData({ ...data, owner_secondary: {
        id: stakeholder.value,
        first_name: stakeholder.owner?.first_name,
        last_name: stakeholder.owner?.last_name,
        email: stakeholder.email
      }});
      api = patchProjectV2;
      toSend.owner_secondary_id = stakeholder.value;
    }

    if (role.value === "ce") {
      if (project_ces?.data?.project_ces?.find((o) => o.id === stakeholder.value)) {
        setRole(null);
        setStakeholder(null);
        setAdding(false);
        return;
      }

      setData({ ...data, project_ces: [...data.project_ces, stakeholder.label] });
      project_ces.setData({
        project_ces: [
          ...(project_ces.data.project_ces || []),
          {
            name: stakeholder.label,
            id: stakeholder.value,
            email: stakeholder.email,
          },
        ],
      });
      api = patchProjectCesV2;
      toSend.project_ces = [
        ...(project_ces.data.project_ces ? project_ces.data.project_ces.map((o) => o.id) : []),
        stakeholder.value,
      ];
    }

    if (role.value === "ops") {
      setData({ ...data, ops_owner: stakeholder.label });
      ops.setData({
        ops_owner: {
          name: stakeholder.label,
          id: stakeholder.value,
          email: stakeholder.email,
        },
      });
      toSend.ops_owner = stakeholder.value;
      api = patchProjectOpsV2;
    }

    const call = async () => {
      try {
        await api(data.id, toSend);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }

      setRole(null);
      setStakeholder(null);
      setAdding(false);
    };

    await call();
  };

  if (!data) {
    return (
      <div className="bg-white flex-1 flex flex-col">
        <LoadPlaceholder className="w-60 h-4 mt-10 mb-10" />
        <LoadPlaceholder className="w-60 h-4 mb-2" />
        <LoadPlaceholder className="w-40 h-4 mb-2" />
        <LoadPlaceholder className="w-32 h-4 mb-2" />
      </div>
    );
  }

  return (
    <Section
      id="admin_project_stakeholders"
      className="flex mt-1 h-full flex-col w-2/3"
    >
      {!adding ? (
        <div className="flex mb-1 h-12 items-center">
          <Button onClick={() => hubspotInputsDisabled && onDisabledClick ? onDisabledClick() : setAdding(true)}>{$.add_btn}</Button>
        </div>
      ) : (
        <div className="flex mb-1 items-center h-12">
          <Button
            noMinW
            className="w-auto"
            disabled={!role || !stakeholder}
            onClick={handleSave}
          >
            {$.save_btn}
          </Button>
          <Select
            className="flex-1 mx-2"
            placeholder={$.stakeholder_placeholder}
            onChange={(v) => setStakeholder(v)}
            value={stakeholder}
            async
            loadOptions={fetchOwners}
          />
          <Select
            placeholder={$.role_placeholder}
            options={rolesOptions}
            onChange={(v) => setRole(v)}
            value={role}
          />
        </div>
      )}
      <div className="bg-white flex-1 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilAward className="mr-2 text-link" size="18" /> {$.title}
          </B2DB>
        </div>
        <div className="px-2 py-2">
          <div className="flex items-center text-sm text-kasmir font-bold">
            <div className="w-2/6">{$.name_label}</div>
            <div className="w-3/6">{$.role_label}</div>
            <div className="w-1/6" />
          </div>
          {data.owner_primary && (
            <div key={data.owner_primary.id} className="flex items-start my-2">
              <div className="w-2/6">
                <div className="text-midnight font-bold text-sm">
                  {data.owner_primary.first_name} {data.owner_primary.last_name}
                </div>
                <div className="text-midnight font-bold text-sm">
                  {data.owner_primary.email}
                </div>
              </div>
              <div className="w-3/6 text-kasmir font-bold text-sm">
                {$.role_owner_primary_label}
              </div>
              <div className="w-1/6 text-right pr-4">
                <RemoveBtn
                  colored
                  onClick={() =>
                    hubspotInputsDisabled && onDisabledClick ?
                    onDisabledClick() :
                    handleDelete("owner_primary", data.owner_primary.id, `${data.owner_primary.first_name} ${data.owner_primary.last_name}`)
                  }
                />
              </div>
            </div>
          )}
          {data.owner_secondary && (
            <div key={data.owner_secondary.id} className="flex items-start my-2">
              <div className="w-2/6">
                <div className="text-midnight font-bold text-sm">
                  {data.owner_secondary.first_name} {data.owner_secondary.last_name}
                </div>
                <div className="text-midnight font-bold text-sm">
                  {data.owner_secondary.email}
                </div>
              </div>
              <div className="w-3/6 text-kasmir font-bold text-sm">
                {$.role_owner_secondary_label}
              </div>
              <div className="w-1/6 text-right pr-4">
                <RemoveBtn
                  colored
                  onClick={() =>
                    hubspotInputsDisabled && onDisabledClick ?
                    onDisabledClick() :
                    handleDelete("owner_secondary", data.owner_secondary.id, `${data.owner_secondary.first_name} ${data.owner_secondary.last_name}`)
                  }
                />
              </div>
            </div>
          )}
          {project_ces?.data?.project_ces && 
            Array.isArray(project_ces.data.project_ces) && (
            project_ces.data.project_ces.map((ce) => (
              <div key={ce.id} className="flex items-start my-2">
                <div className="w-2/6">
                  <div className="text-midnight font-bold text-sm">
                    {ce.name}
                  </div>
                  <div className="text-midnight font-bold text-sm">
                    {ce.email}
                  </div>
                </div>
                <div className="w-3/6 text-kasmir font-bold text-sm">
                  {$.role_ce_label}
                </div>
                <div className="w-1/6 text-right pr-4">
                  <RemoveBtn
                    colored
                    onClick={() =>
                      hubspotInputsDisabled && onDisabledClick ?
                      onDisabledClick() :
                      handleDelete("ce", ce.id, ce.name)
                    }
                  />
                </div>
              </div>
            ))
          )}
          {ops?.data?.ops_owner?.id && (
            <div className="flex items-start my-2">
              <div className="w-2/6">
                <div className="text-midnight font-bold text-sm">
                  {ops.data.ops_owner.name}
                </div>
                <div className="text-midnight font-bold text-sm">
                  {ops.data.ops_owner.email}
                </div>
              </div>
              <div className="w-3/6 text-kasmir font-bold text-sm">
                {$.role_ops_label}
              </div>
              <div className="w-1/6 text-right pr-4">
                <RemoveBtn
                  colored
                  onClick={() =>
                    hubspotInputsDisabled && onDisabledClick ?
                    onDisabledClick() :
                    handleDelete("ops", ops.data.ops_owner.id)
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </Section>
  );
};

export default Stakeholders;
