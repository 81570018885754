import React, { useEffect, useCallback } from "react";
import { Card } from "./Card";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import Section from "components/Section";
import useConfirmClose from "hooks/useConfirmClose";
import { Prompt } from "react-router-dom";

/**
 * Renders a modal close button.
 * @param {callback} onClick - Callback to trigger on click.
 */
const CloseBtn = ({ onClick }) => (
  <button onClick={onClick} className="group focus:outline-none w-8 h-8">
    <span
      className="group-focus:ring focus:outline-none rounded text-lg w-8 h-8 text-cove inline-flex items-center justify-center hover:bg-link-water active:bg-geyser"
      tabIndex="-1"
    >
      <UilTimes size="24" />
    </span>
  </button>
);

/**
 * Renders a modal. It includes some features like confirmation prompt on close and a close button. It is used in all forms.
 * @param {component} children - Children to render inside, like a form with all its inputs.
 * @param {callback} onClose - Callback to trigger on close button click.
 * @param {string} id - Identifier to use with the Section component.
 * @param {boolean} fullHeight - Flag to indicate if the modal should take full height.
 * @param {boolean} isDirty - Flag to enable the confirmation feature.
 */
const Modal = ({ children, onClose, id, fullHeight, isDirty }) => {
  const { canClose, confirmMsg } = useConfirmClose(isDirty);

  const onEscape = useCallback(
    (event) => {
      if (event.keyCode === 27 && canClose()) {
        onClose();
      }
    },
    [canClose, onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", onEscape);

    return () => {
      document.removeEventListener("keydown", onEscape);
    };
  }, [onEscape]);

  return (
    <div className="py-12 px-10 z-50 fixed top-0 left-0 w-screen h-screen items-center justify-center flex">
      <Prompt when={isDirty} message={confirmMsg} />
      <div
        onClick={() => {
          if (canClose()) {
            onClose();
          }
        }}
        className="absolute top-0 left-0 w-full h-full z-40"
        style={{ backgroundColor: "rgba(31, 34, 39, 0.5)" }}
      />
      <Section
        id={id}
        className={
          "flex z-50 mx-auto max-h-full w-full lg:w-4/5 xl:w-3/5 2xl:w-2/5" +
          (fullHeight ? " h-full" : "")
        }
      >
        <Card className="rounded flex flex-col flex-nowrap max-h-full h-auto w-full mt-0 mb-0">
          <div className="flex items-center justify-end">
            <CloseBtn
              onClick={() => {
                if (canClose()) {
                  onClose();
                }
              }}
            />
          </div>
          {children}
        </Card>
      </Section>
    </div>
  );
};

export default Modal;
