const regex = new RegExp(`(<em>.*?</em>)`, "gi");

const getFirstMatch = (text) => {
  const match = text.match(regex);

  return match.length ? match[0].replace("<em>", "").replace("</em>", "") : "";
};

const getFullHighlightedText = (text, search) => {
  const parts = text.split(regex);

  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          className={
            part.match(regex) ? "bg-yellow-200 text-midnight" : ""
          }
        >
          {part.match(regex)
            ? part.replace("<em>", "").replace("</em>", "")
            : part}
        </span>
      ))}{" "}
    </span>
  );
};

const getHighlightedText = (text) => {
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(regex);

  return (
    <span>
      {" "}
      {parts.map((part, i) => (
        <span
          key={i}
          className={
            part.match(regex) ? "bg-yellow-200 font-medium text-midnight" : ""
          }
        >
          {part.match(regex)
            ? part.replace("<em>", "").replace("</em>", "")
            : i === 0
            ? "..." + getLastWords(part)
            : i === parts.length - 1
            ? getFirstWords(part) + "..."
            : countWords(part) < 10
            ? part
            : getFirstWords(part) + "..." + getLastWords(part)}
        </span>
      ))}{" "}
    </span>
  );
};

const countWords = (str) => {
  return str.trim().split(/\s+/).length;
};

const getFirstWords = (str) => {
  return str.split(/\s+/).slice(0, 5).join(" ");
};

const getLastWords = (str) => {
  return str.split(/\s+/).slice(-5).join(" ");
};

const removeHtml = (str) => {
  return str.replace(/<(?!em\s*\/?)[^>]+>/g, "");
};

export {
  getHighlightedText,
  getFirstMatch,
  getFullHighlightedText,
  removeHtml,
};
