import React, { useMemo } from "react";

const HubspotToast = ({ type, objectId }) => {
  const HUBSPOT_URL_ROOT =
    process.env.NODE_ENV === 'development' || process.env.REACT_APP_API_URL.includes('staging') ?
    "https://app.hubspot.com/contacts/45416535" :
    "https://app.hubspot.com/contacts/39598951" 

  const url = useMemo(() => {
    if (!type && !objectId) {
      return null;
    }

    let urlSuffix;
    switch (type) {
      case 'contact':
        urlSuffix = `/record/0-1/${objectId}`;
        break;
      case 'company':
        urlSuffix = `/record/0-2/${objectId}`;
        break;
      case 'project':
        urlSuffix = `/record/0-3/${objectId}`;
        break;
      case 'candidate':
        urlSuffix = `/record/0-3/${objectId}`;
        break;
      case 'add-contact':
        urlSuffix = `/objects/0-1/views/my/list`;
        break;
      case 'add-company':
        urlSuffix = `/objects/0-2/views/my/list`;
        break;
      case 'add-project':
        urlSuffix = `/objects/0-3/views/my/list`;
        break;
      default:
        break;
    }
  
    return `${HUBSPOT_URL_ROOT}${urlSuffix}`;
  }, [type, objectId])
  
  return (
    <div className="w-70">
      <div className="flex items-center">
        {type === 'candidate' ?
          <span>Candidates in the Scheduled status can only be edited in HubSpot. <a href={url} className="underline" target="_blank">Go to Hubspot to update candidate status.</a></span> :
          type.includes('add-') ?
            <span>New records can only be added in Hubspot. <a href={url} className="underline" target="_blank">Go to Hubspot to create a new {type.split('-')[1]}.</a></span> :
            <span>This field is only editable in HubSpot. {!url.includes('null') ? <a href={url} className="underline" target="_blank">Go to the record in HubSpot</a> : 'Go to the record in Hubspot'} to make any changes.</span>
        }
      </div>
    </div>
  );
};

export default HubspotToast;
