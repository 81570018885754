import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import { admin_project_clockify as $ } from "strings";
import Section from "components/Section";
import { B2DB } from "components/Typography";
import UilBuilding from "@iconscout/react-unicons/icons/uil-building";
import { Button } from "components/Buttons";
import CreateProjectForm from "./CreateProjectForm";
import { LoadPlaceholderGroup } from "components/LoadPlaceholder";
import InputProjectForm from "./InputProjectForm";
import AddTalentForm from "./AddTalentForm";
import { Input, Label } from "components/Form";
import { getProject, getProjectClokify } from "utils/adminApi";
import useCachedFetch from "hooks/useCachedFetch";
import AddGroupForm from "./AddGroupForm";

const Clockify = () => {
  const { id } = useParams();
  const { set } = usePageCache();
  const [status, setStatus] = useState();

  useEffect(() => {
    set("url_project", `/admin/project/${id}/clockify`);
  }, [set, id]);

  // temporary regression to v1 API to access clockify_id
  const { data, setData } = useCachedFetch(
    "admin_project",
    getProject,
    id
  );

  const clockify = useCachedFetch(
    "admin_project_clockify_project",
    getProjectClokify,
    data ? data.clockify_id : null
  );

  if (!data) {
    return (
      <div className="bg-white px-4 py-6 mb-6">
        <LoadPlaceholderGroup />
      </div>
    );
  }

  return (
    <Section id="admin_project_clockify" className="bg-white px-4 py-6 mb-6">
      <B2DB className="flex items-center mb-4">
        <UilBuilding className="text-link mr-2" size="20" />
        {$.title}
      </B2DB>
      {!data.clockify_id && !status && (
        <div className="flex items-center space-x-4">
          <Button onClick={() => setStatus("input")}>{$.input_btn}</Button>
          <Button onClick={() => setStatus("create")}>{$.create_btn}</Button>
        </div>
      )}
      {status === "create" && (
        <CreateProjectForm
          data={data}
          setData={setData}
          setStatus={setStatus}
        />
      )}
      {status === "input" && (
        <InputProjectForm data={data} setData={setData} setStatus={setStatus} />
      )}
      {data.clockify_id && (
        <div className="w-full md:w-1/2">
          <Label>{$.clockify_id_input}</Label>
          <Input disabled value={data.clockify_id} />
          {clockify &&
            clockify.data &&
            Array.isArray(clockify.data.membership_users) &&
            clockify.data.membership_users.map((t) => (
              <div className="my-3">
                <div className="w-full">
                  <Label>{$.clockify_user_input}</Label>
                  <Input disabled value={t.name} />
                </div>
                <div className="w-full">
                  <Label>{$.pay_rate_input}</Label>
                  <Input disabled value={t.pay_rate} />
                </div>
              </div>
            ))}
          {clockify &&
            clockify.data &&
            Array.isArray(clockify.data.membership_groups) &&
            clockify.data.membership_groups.map((g) => (
              <div className="my-3">
                <div className="w-full">
                  <Label>{$.group_id_input}</Label>
                  <Input disabled value={g.name} />
                </div>
              </div>
            ))}
          {!status && (
            <div className="flex items-center space-x-4 mt-5">
              <Button onClick={() => setStatus("talent")}>
                {$.add_talent_btn}
              </Button>
              <Button onClick={() => setStatus("group")}>
                {$.add_group_btn}
              </Button>
            </div>
          )}
          {status === "talent" && (
            <AddTalentForm
              data={data}
              setData={clockify.setData}
              setStatus={setStatus}
            />
          )}
          {status === "group" && (
            <AddGroupForm
              data={data}
              setData={clockify.setData}
              setStatus={setStatus}
            />
          )}
        </div>
      )}
    </Section>
  );
};

export default Clockify;
