import { useEffect, useCallback } from "react";
import { global as $ } from "strings";

const confirmMsg = $.confirm_close_text;

const useConfirmClose = (isDirty) => {
  useEffect(() => {
    const onUnload = (e) => {
      e.preventDefault();
      e.returnValue = confirmMsg;

      return confirmMsg;
    };

    if (isDirty) {
      window.addEventListener("beforeunload", onUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", onUnload);
    };
  }, [isDirty]);

  const canClose = useCallback(
    () => !isDirty || (isDirty && window.confirm($.confirm_close_text)),
    [isDirty]
  );
  return { canClose, confirmMsg };
};

export default useConfirmClose;
