import React from "react";
import { toFormat } from "utils/date";
import FilterDeleteBtn from "./FilterDeleteBtn";
import DatePicker from "./ListDatePicker";

const FilterDate = ({
  filter,
  updateSearch,
  setIndex,
  index,
  isSelected,
  removeFilter,
}) => {
  return !isSelected ? (
    <div
      onClick={() => setIndex(index)}
      className="bg-white border flex items-center border-link rounded h-8 px-1 text-link text-sm font-bold"
    >
      {filter.title}:{" "}
      {filter.data && (
        <>
          {filter.data.start && toFormat(filter.data.start, "MM/dd/yyyy")} -{" "}
          {filter.data.end && toFormat(filter.data.end, "MM/dd/yyyy")}
        </>
      )}
      <FilterDeleteBtn onClick={() => removeFilter(index)} />
    </div>
  ) : (
    <div className="bg-white border items-center border-link rounded text-sm flex flex-col">
      <div className="border-b border-link h-8 w-full flex items-center text-link font-bold px-1">
        {filter.title}
      </div>
      <div className="h-8 flex items-center border-b border-link">
        <DatePicker
          className="focus:outline-none w-full"
          placeholderText="From"
          autoFocus
          type="number"
          value={filter.data ? filter.data.start : null}
          onChange={(v) =>
            updateSearch(index, { ...(filter.data || {}), start: v })
          }
        />
      </div>
      <div className="h-8 flex items-center">
        <DatePicker
          className="focus:outline-none w-full"
          placeholderText="To"
          autoFocus
          type="number"
          value={filter.data ? filter.data.end : null}
          onChange={(v) =>
            updateSearch(index, { ...(filter.data || {}), end: v })
          }
        />
      </div>
    </div>
  );
};

export default FilterDate;
