import React, { useState } from "react";
import { Label, Clear } from "components/Form";
import Select from "components/Select";
import NewFilterForm from "components/NewFilterForm";
import RenameFilterForm from "components/RenameFilterForm";
import DeleteFilterForm from "components/DeleteFilterForm";
import DatePicker from "components/DatePicker";
import {
  toFormat,
  firstDayInMonth,
  lastDayInMonth,
  moveDays,
} from "utils/date";
import DebouncedInput from "./DebouncedInput";
import RatingInput from "./RatingInput";
import { RemoveBtn } from "./Buttons";
import { B2DB } from "./Typography";
import FilterSection from "./FilterSection";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus-circle";
import { global as $$ } from "strings";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import useCachedFetch from "hooks/useCachedFetch";
import { getAdminUserId } from "utils/localStorageService";

// Small utility to remove an index from an array, pass the array and the index to remove.
const arrayRemove = (a, i) => a.filter((e, j) => j !== i);

const getDate = (d) => {
  if (typeof d === "string") {
    return new Date(d);
  }

  return d;
};

/**
 * Renders all filters specified in a schema object. Props object is generated by useTable hook.
 * @param {object} schema - Defines the filters. Each should have a type. If no type it will not render a filter for it.
 * @param {object} $ - Strings object keys.
 * @param {object} filters - Filters state, this is the state of the components.
 * @param {callback} setFilters - Used to set filters state, in a component state level (example: date filter will save a Date object).
 * @param {object} params - Params state, this is what is sent to the API.
 * @param {callback} setParams - Used to set params state, in a api payload state level (example: date filter will save a a date as string formatted as the api requires).
 * @param {boolean} showFilters - Flag to render or not the component.
 * @param {object} sections - Sections state, tells what section should be displayed.
 * @param {callback} setSections - Used to set sections state.
 */
const FiltersRenderer = ({
  schema,
  $,
  filters,
  setFilters,
  params,
  setParams,
  showFilters,
  sections,
  setSections,
  manager,
  cacheKey,
}) => {
  const [modal, setModal] = useState();
  const [customFilter, setCustomFilter] = useState();
  const alert = useAlert();
  const user = getAdminUserId();

  const customFilters = useCachedFetch(
    cacheKey + "_customfilters",
    manager ? manager.getApi : null,
    user,
    { page_size: 9999999 }
  );

  if (!showFilters) {
    return null;
  }

  const handleCustomFilter = async (f) => {
    if (f.filter && f.filter.snapshot) {
      try {
        const snapshot = JSON.parse(f.filter.snapshot);
        const newSnap = manager.versioning(snapshot);
        if (newSnap.filters && newSnap.params) {
          setFilters(newSnap.filters);
          setParams(newSnap.params);
        }
        if (newSnap.needsUpdate) {
          await manager.patchApi(customFilter.value, {
            snapshot: JSON.stringify({
              filters: newSnap.filters,
              params: newSnap.params,
            }),
          });
        }
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    }

    setCustomFilter(f);
  };

  const onSaveFilter = async () => {
    try {
      await manager.patchApi(customFilter.value, {
        snapshot: JSON.stringify({ filters, params }),
      });
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} />);
    }
  };

  /**
   * This removes a filter and its param, it takes care of considering __lte or __gte for some range filters.
   * @param {string} k - The key to remove.
   */
  const remove = (k) => {
    const {
      [k]: z,
      [k + "__lte"]: le,
      [k + "__gte"]: ge,
      [k + "__lt"]: l,
      [k + "__gt"]: g,
      ...newFilters
    } = filters;
    const {
      [k]: x,
      [k + "__lte"]: lte,
      [k + "__gte"]: gte,
      [k + "__lt"]: lt,
      [k + "__gt"]: gt,
      ...newParams
    } = params;
    setFilters(newFilters);
    setParams(newParams);
  };

  /**
   * This generates the qualification filter param given a qualification filter state. In most cases ignores empty values in the filter to avoid sent it to the api.
   * @param {object} newState - The qualification filter state to calculate from.
   */
  const getQualificationsParam = (newState) => {
    const param = {};

    newState.forEach((s) => {
      if (s.qualification) {
        param[s.qualification.value] = s.rating === undefined ? 0 : s.rating;
      }
    });

    return Object.keys(param).length === 0 ? null : param;
  };

  /**
   * This adds a new entry in the qualification filter if the filter is initializated, but if it is new sets a new array (depending if is from the add qualification button or by another mean, it creates the array with one element, the default one, and other one if needed by the add button.).
   * @param {string} k - The key of the filter.
   * @param {boolean} fromUpdate - Flag to check if the action is comming from the add button or not.
   */
  const addQualificationFilter = (k, fromUpdate = false) => {
    if (!filters[k]) {
      setFilters({
        ...filters,
        [k]: fromUpdate ? [{ rating: 0 }] : [{ rating: 0 }, { rating: 0 }],
      });
    } else {
      const newFilters = { ...filters, [k]: [...filters[k], { rating: 0 }] };
      setFilters(newFilters);
      setParams({ ...params, [k]: getQualificationsParam(newFilters[k]) });
    }
  };

  /**
   * This clears the filters and params states.
   */
  const clearFilters = () => {
    setFilters({});
    setParams({});
    setCustomFilter(null);
  };

  /**
   * Updates a qualification filter state, if it new, it creates the filter entry, if the value is null, removes the entry.
   * @param {string} k - The key of the filter.
   * @param {int} i - Index of the filter entry.
   * @param {object} v - Value to set to the filter entry.
   */
  const setQualificationFilter = (k, i, v) => {
    if (!filters[k] && v) {
      addQualificationFilter(k, true);
    }

    if (!filters[k] && !v) {
      setFilters({ ...filters, [k]: [] });
    }

    if (filters[k] && !v) {
      const newFilters = arrayRemove(filters[k], i);
      setFilters({ ...filters, [k]: newFilters });
      setParams({ ...params, [k]: getQualificationsParam(newFilters) });
    } else if (filters[k]) {
      const newFilters = filters[k].map((w, j) => {
        return j === i ? v : w;
      });
      setFilters({ ...filters, [k]: newFilters });
      setParams({ ...params, [k]: getQualificationsParam(newFilters) });
    } else if (!filters[k] && v) {
      const newFilters = [{ rating: 0 }].map((w, j) => {
        return j === i ? v : w;
      });
      setFilters({ ...filters, [k]: newFilters });
      setParams({ ...params, [k]: getQualificationsParam(newFilters) });
    }
  };

  /**
   * Sets a value to a specific filter. It handles the entry to the param and the filter states, consifering the filter type from the schema.
   * @param {string} k - The key of the filter.
   * @param {object} v - Value to set to the filter.
   */
  const setFilter = (k, v) => {
    if (!v) {
      remove(k);
    } else {
      let val = v;
      const info =
        schema[k] ||
        schema[
          k
            .replace("__gte", "")
            .replace("__lte", "")
            .replace("__gt", "")
            .replace("__lt", "")
        ] ||
        {};

      if (info.type === "select") {
        val = v.value;
      } else if (info.type === "multiselect") {
        val = v.map((j) => j.value).join(",");
      } else if (info.type === "date" && info.format) {
        val = toFormat(v, info.format);
      } else if (info.type === "range_date" && k.includes("__gt")) {
        val =
          info.monthly === false
            ? toFormat(moveDays(v, -1), "yyyy-MM-dd")
            : toFormat(moveDays(firstDayInMonth(v), -1), "yyyy-MM-dd");
      } else if (info.type === "range_date" && k.includes("__lt")) {
        val =
          info.monthly === false
            ? toFormat(moveDays(v, 1), "yyyy-MM-dd")
            : toFormat(moveDays(lastDayInMonth(v), 1), "yyyy-MM-dd");
      }
      setFilters({ ...filters, [k]: v });
      setParams({ ...params, [k]: val });
    }
  };

  /**
   * Toggles a section.
   * @param {string} k - The key of the filter.
   */
  const toggleSection = (k) => {
    setSections((s) => ({ ...s, [k]: !s[k] }));
  };

  return (
    <div className="bg-white px-4 py-6 mr-6 flex flex-col mb-6">
      <div className="mb-6 flex items-center">
        <B2DB className="flex-1">{$.filters_header}</B2DB>
        {Object.keys(params).length > 0 && (
          <button onClick={clearFilters} className="group focus:outline-none">
            <span
              className="group-focus:ring focus:outline-none rounded text-sm font-bold p-1 text-white inline-flex items-center justify-center bg-link hover:bg-link-dark active:bg-link-dark"
              tabIndex="-1"
            >
              {$$.clear_all_button}
            </span>
          </button>
        )}
      </div>
      {Object.entries(schema).map(
        ([k, s]) =>
          s.type && !s.disableFilter && (
            <div key={k} className="w-66 my-2">
              {s.type !== "range_int" &&
                s.type !== "range_date" &&
                s.type !== "custom" &&
                s.type !== "qualifications" && (
                  <Label>
                    {$[`${k}_label`]}
                    {filters[k] && <Clear onClick={() => setFilter(k, "")} />}
                  </Label>
                )}
              {s.type === "custom" &&
                s.renderFilter({
                  setParams,
                  setFilters,
                  remove,
                  setFilter,
                  filters,
                  params,
                  k,
                })}
              {s.type === "select" && (
                <Select
                  placeholder={$.select_option}
                  onChange={(v) => setFilter(k, v)}
                  value={filters[k] || []}
                  {...s.selectProps}
                />
              )}
              {s.type === "multiselect" && (
                <Select
                  placeholder={$.select_option}
                  onChange={(v) => setFilter(k, v)}
                  value={filters[k] || []}
                  isMulti
                  {...s.selectProps}
                />
              )}
              {s.type === "qualifications" && (
                <FilterSection
                  active={sections[k] !== false}
                  label={$[`${k}_label`]}
                  onToggle={() => {
                    toggleSection(k);
                  }}
                >
                  {(filters[k] || [{}]).map((q, i) => (
                    <div key={i} className="flex items-center my-2">
                      <div className="flex-1 mr-2">
                        <Label>{$$.qualification_name_filter}</Label>
                        <Select
                          placeholder={$.select_option}
                          onChange={(v) =>
                            setQualificationFilter(k, i, {
                              ...q,
                              qualification: v,
                            })
                          }
                          value={q.qualification || []}
                          {...s.selectProps}
                        />
                      </div>
                      <div className="flex-1">
                        <Label>{$$.qualification_rating_filter}</Label>
                        <div className="flex items-center h-10 border border-transparent my-1">
                          <RatingInput
                            onChange={(v) =>
                              setQualificationFilter(k, i, { ...q, rating: v })
                            }
                            value={q.rating || 0}
                          />
                        </div>
                      </div>
                      <RemoveBtn
                        colored
                        onClick={() => setQualificationFilter(k, i)}
                      />
                    </div>
                  ))}
                  <button
                    type="button"
                    className="mt-1 text-link text-sm font-bold appearance-none focus:outline-none outline-none inline-flex items-center"
                    onClick={() => addQualificationFilter(k)}
                  >
                    <UilPlus size="18" className="mr-1" />
                    {$.add_qualification_button}
                  </button>
                </FilterSection>
              )}
              {s.type === "date" && (
                <DatePicker
                  placeholderText={$.select_date_placeholder}
                  onChange={(v) => setFilter(k, v)}
                  value={getDate(filters[k])}
                  monthly={s.monthly !== false}
                />
              )}
              {s.type === "range_date" && (
                <FilterSection
                  active={sections[k]}
                  label={$[`${k}_label`]}
                  onToggle={() => {
                    toggleSection(k);
                  }}
                >
                  <div className="flex items-center">
                    <div className="flex-1 mr-2">
                      <Label>
                        {$$.date_range_start_filter}
                        {filters[k + "__"] && (
                          <Clear onClick={() => setFilter(k + "__gt")} />
                        )}
                      </Label>
                      <DatePicker
                        placeholderText={$.select_date_placeholder}
                        onChange={(v) => setFilter(k + "__gt", v)}
                        value={getDate(filters[k + "__gt"])}
                        maxDate={getDate(filters[k + "__lt"])}
                        monthly={s.monthly !== false}
                      />
                    </div>
                    <div className="flex-1">
                      <Label>
                        {$$.date_range_end_filter}
                        {filters[k + "__lt"] && (
                          <Clear onClick={() => setFilter(k + "__lt")} />
                        )}
                      </Label>
                      <DatePicker
                        placeholderText={$.select_date_placeholder}
                        onChange={(v) => setFilter(k + "__lt", v)}
                        value={getDate(filters[k + "__lt"])}
                        minDate={getDate(filters[k + "__gt"])}
                        monthly={s.monthly !== false}
                      />
                    </div>
                  </div>
                </FilterSection>
              )}
              {s.type === "int" && (
                <DebouncedInput
                  onChange={(v) => setFilter(k, v)}
                  value={filters[k]}
                  resetValue={!filters[k]}
                  placeholder={$[`${k}_placeholder`]}
                  type="number"
                  step="1"
                  min="1"
                  maxLength="10"
                  className="w-full px-3 rounded h-10 w-60 flex items-center font-bold text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-medium focus:outline-none appearance-none border border-geyser focus:border-link focus:border-2"
                />
              )}
              {s.type === "range_int" && (
                <FilterSection
                  active={sections[k]}
                  label={$[`${k}_label`]}
                  onToggle={() => {
                    toggleSection(k);
                  }}
                >
                  <div className="flex items-center">
                    {["__gte", "__lte"].map((i) => {
                      const key =
                        "__gte" === i ? s.min_param || k : s.max_param || k;

                      return (
                        <div key={k + i} className="flex-1 first:mr-2">
                          <Label>
                            {"__gte" === i
                              ? s.range_minimum_label || $$.range_minimum_label
                              : s.range_maximum_label || $$.range_maximum_label}
                            {filters[key + i] && (
                              <Clear onClick={() => setFilter(key + i, "")} />
                            )}
                          </Label>
                          <DebouncedInput
                            onChange={(v) => setFilter(key + i, v)}
                            value={filters[key + i]}
                            resetValue={!filters[key + i]}
                            placeholder={
                              "__gte" === i
                                ? s.range_minimum_placeholder ||
                                  $$.range_minimum_placeholder
                                : s.range_maximum_placeholder ||
                                  $$.range_maximum_placeholder
                            }
                            type="number"
                            step="1"
                            min="1"
                            maxLength="9999999"
                            className="w-full px-3 rounded h-10 w-60 flex items-center font-bold text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-medium focus:outline-none appearance-none border border-geyser focus:border-link focus:border-2"
                          />
                        </div>
                      );
                    })}
                  </div>
                </FilterSection>
              )}
              {s.type === "text" && (
                <DebouncedInput
                  onChange={(v) => setFilter(k, v)}
                  value={filters[k]}
                  resetValue={!filters[k]}
                  type="text"
                  placeholder={$[`${k}_placeholder`]}
                  maxLength="255"
                  className="w-full px-3 rounded h-10 w-60 flex items-center font-bold text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-medium focus:outline-none appearance-none border border-geyser focus:border-link focus:border-2"
                />
              )}
            </div>
          )
      )}
      {manager && (
        <div className="w-66 my-6">
          <div className="mb-2">
            <Label>{$$.load_filters_label}</Label>
            <Select
              placeholder={$$.select_filter}
              onChange={(v) => {
                handleCustomFilter(v);
              }}
              value={customFilter}
              options={
                customFilters &&
                customFilters.data &&
                Array.isArray(customFilters.data.results)
                  ? customFilters.data.results.map((c) => ({
                      filter: c,
                      value: c.id,
                      label: c.name,
                    }))
                  : []
              }
            />
          </div>
          <div className="flex items-center justify-end">
            {Object.keys(params).length > 0 && !customFilter && (
              <button
                onClick={() => setModal("create_filter")}
                className="group focus:outline-none"
              >
                <span
                  className="group-focus:ring focus:outline-none rounded text-sm font-bold py-1 px-3 text-white inline-flex items-center justify-center bg-link hover:bg-link-dark active:bg-link-dark"
                  tabIndex="-1"
                >
                  {$$.create_filter_button}
                </span>
              </button>
            )}
            {customFilter && (
              <>
                <button
                  onClick={() => onSaveFilter()}
                  className="group focus:outline-none"
                >
                  <span
                    className="ml-4 group-focus:ring focus:outline-none rounded text-sm font-bold py-1 px-2 text-white inline-flex items-center justify-center bg-link hover:bg-link-dark active:bg-link-dark"
                    tabIndex="-1"
                  >
                    {$$.save_filter_button}
                  </span>
                </button>
                <button
                  onClick={() => setModal("rename_filter")}
                  className="group focus:outline-none"
                >
                  <span
                    className="ml-2 group-focus:ring focus:outline-none rounded text-sm font-bold py-1 px-2 text-white inline-flex items-center justify-center bg-link hover:bg-link-dark active:bg-link-dark"
                    tabIndex="-1"
                  >
                    {$$.rename_filter_button}
                  </span>
                </button>
                <button
                  onClick={() => setModal("delete_filter")}
                  className="group focus:outline-none"
                >
                  <span
                    className="ml-2 group-focus:ring focus:outline-none rounded text-sm font-bold py-1 px-2 text-white inline-flex items-center justify-center bg-link hover:bg-link-dark active:bg-link-dark"
                    tabIndex="-1"
                  >
                    {$$.delete_filter_button}
                  </span>
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {modal === "create_filter" && (
        <NewFilterForm
          snapshot={JSON.stringify({ filters, params })}
          postApi={manager.postApi}
          onClose={() => setModal()}
          setCustomFilter={setCustomFilter}
          reload={customFilters.reload}
        />
      )}
      {modal === "rename_filter" && (
        <RenameFilterForm
          filter={customFilter.filter}
          patchApi={manager.patchApi}
          onClose={() => setModal()}
          setCustomFilter={setCustomFilter}
          reload={customFilters.reload}
        />
      )}
      {modal === "delete_filter" && (
        <DeleteFilterForm
          filter={customFilter.filter}
          deleteApi={manager.deleteApi}
          onClose={() => setModal()}
          setCustomFilter={setCustomFilter}
          reload={customFilters.reload}
        />
      )}
    </div>
  );
};

export default FiltersRenderer;
