import React from "react";
import { admin_contact as $ } from "strings";
import { Header, Footer } from "components/Card";
import { Button } from "components/Buttons";
import Modal from "components/Modal";

const DeletePersonalEmailForm = ({ onClose, onDelete, toDelete }) => {
  const insertStr = `${toDelete?.email_type.replace('_', ' ')} ${toDelete?.email}`;
  return (
    <Modal isDirty={false} onClose={onClose} id="admin_contact_company_email_delete_form">
      <div className="flex flex-col flex-nowrap flex-1 min-h-0" noValidate>
        <Header title={$.confirm_title} />
        <div className="flex flex-wrap -mr-1 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="text-midnight text-sm font-bold">
            {$.personal_email_confirm_delete_msg.replaceAll("%", insertStr)}
          </div>
        </div>
        <Footer>
          <Button secondary onClick={onClose} className="mr-4">
            {$.cancel_button}
          </Button>
          <Button danger onClick={onDelete} type="submit">
            {$.confirm_button}
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default DeletePersonalEmailForm;
