const DBConfig = {
  name: "RightSideUp",
  version: 1,
  objectStoresMeta: [
    {
      store: "cache",
      storeConfig: { keyPath: "key" },
      storeSchema: [
        { name: "value", keypath: "value", options: { unique: false } },
        { name: "timestamp", keypath: "timestamp", options: { unique: false } },
      ],
    },
  ],
};

export default DBConfig;
