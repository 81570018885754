import React, { useState, useEffect } from "react";
import { admin_talent_overview as $ } from "strings";
import { toFormat } from "utils/date";
import {
  deleteTalentNoteV2,
  deleteStatusNote,
  patchTalentNoteV2,
  patchStatusNote
} from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import EditNoteForm from "./EditNoteForm";
import { urlify } from "utils/str";
import { B2BLink } from "components/Typography";
import iconFlagged from "../../../../../assets/icon_flagged.svg";

const Note = ({ note, editable, updateNote, removeNote, measureRef, className, highlightedNote, highlightedKeyword }) => {
  const alert = useAlert();
  const [editing, setEditing] = useState();
  const [highlightedNoteBody, setHighlightedNoteBody] = useState();

  useEffect(() => {
    if (!highlightedNote || !highlightedKeyword || highlightedNote.id !== note.id) return;
    let highlighted = note.note_body.replace(highlightedKeyword, `<span class="bg-yellow-200">${highlightedKeyword}</span>`);
    setHighlightedNoteBody(highlighted)
  }, [highlightedNote]);

  const onDelete = async () => {
    const call = async () => {
      removeNote(note.id);

      try {
        if (note.type === "status_history") {
          await deleteStatusNote(note.id);
        } else {
          await deleteTalentNoteV2(note.talent_ref, note.type, note.id);
        }
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    };

    await call();
  };

  const onSetFlag = async newVal => {
    const call = async () => {
      updateNote(note.id, {
        ...note,
        flag: newVal,
      });
      try {
        if (note.type === "status_history") {
          await patchStatusNote(note.id, { flag: newVal });
        } else {
          await patchTalentNoteV2(note.talent_ref, note.type, note.id, { flag: newVal });
        }
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    };

    await call();
  };

  return (
    <div id={note.id} ref={measureRef} className={"py-1 " + className}>
      {!editing && (
        <div>
          <div className="text-sm font-bold text-midnight flex items-center justify-between">
            <div className="flex-1">
              {note.created_by_name === "Crelate_Populate_Talents_Script"
                ? $.crelate_note_label
                : `${note.created_by_name || note.updated_by_name || $.unknown_author_label}`
              }
              {note.project_ref && note.project_name ?
                <>
                  {$.project_added_by_label}
                  <B2BLink to={`/admin/project/${note.project_ref}`}>
                    {note.project_name}
                  </B2BLink>:
                </> :
                <>
                  {note.created_by_name !== "Crelate_Populate_Talents_Script" && (' ' + (note.flag ? ($.flag_added_by_label) : $.added_by_label))}
                  {note.flag && (<>&nbsp;<img className="h-5" src={iconFlagged} alt="Flagged" /></>)}
                </>
              }
            </div>
          </div>
          <div className="relative text-sm font-normal text-midnight whitespace-pre-line rich-content min-h-0">
            {
              highlightedNoteBody ?
                <div dangerouslySetInnerHTML={{ __html: urlify(highlightedNoteBody) }} /> :
                <div dangerouslySetInnerHTML={{ __html: urlify(note.note_body) }} />
            }
          </div>
          <div className="text-xs text-kasmir flex items-center justify-start">
            <div className="group relative flex items-center">
              {note.created_timestamp && (
                <div className="">
                  {toFormat(
                    new Date(note.created_timestamp),
                    "MMMM dd, yyyy 'at' h:mm a"
                  )}
                </div>
              )}
              {note.updated_at && (
                <div className="bottom-full w-content bg-cove text-white rounded px-1 py-1 text-center absolute z-30 font-bold text-xs hidden group-hover:inline">
                  {$.last_update_label}
                  <br />
                  {toFormat(
                    new Date(note.updated_at),
                    "MMMM dd, yyyy 'at' h:mm a"
                  )}
                </div>
              )}
              {note.created_timestamp &&
                (editable ? <span className="mx-1">|</span> : "")}
            </div>
            {editable && (
              <>
                <button
                  className="focus:outline-none text-xs text-kasmir p-0 outline-none"
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  {$.edit_btn}
                </button>
                <span className="mx-1">|</span>
                <button
                  className="focus:outline-none text-xs text-kasmir p-0 outline-none"
                  onClick={onDelete}
                >
                  {$.delete_btn}
                </button>
                <span className="mx-1">|</span>
                {note.flag ? (
                <button
                  className="focus:outline-none text-xs text-kasmir p-0 outline-none"
                  onClick={() => onSetFlag(false)}
                >
                  {$.remove_flag_btn}
                </button>
                ) : (
                <button
                  className="focus:outline-none text-xs text-kasmir p-0 outline-none"
                  onClick={() => onSetFlag(true)}
                >
                  {$.flag_btn}
                </button>
                )}
              </>
            )}
          </div>
        </div>
      )}
      {editing && (
        <div>
          <div className="text-sm font-bold text-midnight flex">
            {note.created_by_name === "Crelate_Populate_Talents_Script"
              ? "Crelate Tags"
              : note.created_by_name || $.no_author_label}{" "}
            {
              note.flag ?
              // $.flag_added_by_label :
              (<>{$.flag_added_by_label}<img className="h-5" src={iconFlagged} alt="Flagged" /></>) :
              $.added_by_label
            }
          </div>
          <EditNoteForm
            note={note}
            updateNote={updateNote}
            setEditing={setEditing}
          />
        </div>
      )}
    </div>
  );
};

export default Note;
