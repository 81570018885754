import React, { useState } from "react";
import clsx from 'clsx';
import { EditBtn, CancelBtn, SaveBtn } from "./Buttons";

const EditableField = ({
  value,
  onSave,
  copiable,
  required,
  onCopyClick,
  onToggleEdit,
  displayValue = "",
  disabled
}) => {
  const [editable, setEditable] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  return !editable ? (
    <div className="flex items-center">
      <span
        onClick={copiable ? () => onCopyClick() : null}
        className={clsx("text-sm h-5 ", value?.length ? "text-midnight font-bold " : "text-kasmir font-medium ", {"cursor-pointer": copiable})}
      >
        {displayValue || value}
      </span>
      <EditBtn
        onClick={() => {
          if (disabled) return;
          setInputValue(value);
          setEditable(true);
        }}
      />
    </div>
  ) : (
    <div className="flex items-center flex-1">
      <input
        autoFocus
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
        className={
          "px-0 py-0 h-5 rounded text-left font-medium text-sm bg-white placeholder-text-kasmir placeholder:font-medium focus:outline-none appearance-none border border-link-water w-56"
        }
      />
      <SaveBtn
        className={required && !inputValue?.length && "cursor-not-allowed"}
        onClick={() => {
          setEditable(false);
          onSave(inputValue);
        }}
        disabled={required && !inputValue?.length}
      />
      <CancelBtn onClick={() => setEditable(false)} />
    </div>
  );
};

export default EditableField;
