import React from "react";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";

const FilterDeleteBtn = ({ onClick }) => (
  <div
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
    className="h-4 w-4 items-center text-link justify-center ml-1"
  >
    <UilTimes size="16" />
  </div>
);

export default FilterDeleteBtn;
