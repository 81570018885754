import React, { useState, useMemo } from "react";
import { admin_project_overview as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import UilGlobe from "@iconscout/react-unicons/icons/uil-globe";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import Header from "components/ListHeader";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope";
import UilLink from "@iconscout/react-unicons/icons/uil-link";
import UilMapMarker from "@iconscout/react-unicons/icons/uil-map-marker";
import useCachedFetch from "hooks/useCachedFetch";
import {
  getProjectCompanyV2,
  patchProjectCompanyV2,
  getContactsForCompany
} from "utils/adminApi";
import UilLinkedin from "@iconscout/react-unicons/icons/uil-linkedin";
import UilTwitter from "@iconscout/react-unicons/icons/uil-twitter";
import UilFacebook from "@iconscout/react-unicons/icons/uil-facebook";
import UilInstagram from "@iconscout/react-unicons/icons/uil-instagram";
import UilGithub from "@iconscout/react-unicons/icons/uil-github";
import { withHttp } from "utils/str";
import Select from "components/CompactSelect";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import AddressSearch from "components/AddressSearch";
import { Link } from "react-router-dom";
import ContactForm from "./ContactForm";
import { getAdminFlags } from "utils/localStorageService";
import { getFetchCompanyContacts } from "utils/fetch";

const social = {
  linkedin: UilLinkedin,
  twitter: UilTwitter,
  facebook: UilFacebook,
  instagram: UilInstagram,
  github: UilGithub,
};

const Company = ({ project, hubspotInputsDisabled, onDisabledClick }) => {
  const [open, setOpen] = useState(true);
  const [modal, setModal] = useState(false);
  const alert = useAlert();
  const flags = getAdminFlags();

  const { data, setData } = useCachedFetch(
    "admin_project_company",
    getProjectCompanyV2,
    project.id
  );

  const fetchCompanyContacts = useMemo(() => {
    if (data) {
      return getFetchCompanyContacts(data.id);
    }
  }, [data]);

  const onContactChange = async (value) => {
    const origVal = {
      contact_id: data.contact_id,
      contact_name: data.contact_name,
    };

    const newVal = { contact_id: value.value, contact_name: value.label };

    setData({ ...data, ...newVal });

    const call = async () => {
      try {
        await patchProjectCompanyV2(project.id, {
          contact_id: value.value,
          contact_name: value.label
        });
      } catch (e) {
        setData({ ...data, ...origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const onAddressChange = async (values) => {
    delete values.latitude;
    delete values.longitude;
    delete values.name;
    const origVal = data.address;
    const newVal = values.full_address;

    setData({ ...data, address: newVal });

    const call = async () => {
      try {
        await patchProjectCompanyV2(project.id, values);
      } catch (e) {
        setData({ ...data, address: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    if (!project) return;
    await call();
  };

  return (
    <Section id="admin_project_overview" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilGlobe className="mr-2" size="18" /> {$.company_title}
          </B2DB>
          <div className="cursor-pointer" onClick={() => setOpen(!open)}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && !!data && (
          <div className="px-2 pb-4">
            <div className="w-full flex">
              <div className="w-1/2">
                <Header Icon={UilEnvelope} text={$.company_name_title} />
                {data && 
                  <Link
                    to={`/admin/company/${data.id}`}
                    className="text-sm text-midnight font-bold"
                  >
                    {data.name}
                  </Link>
                }
              </div>
              <div className="w-1/2">
                <Header Icon={UilLink} text={$.links_title} />
                <div className="text-link text-sm font-bold">
                  <div className="mr-4">
                    <a
                      className="flex items-center"
                      href={withHttp(data?.website)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <UilLink size="14" className="mr-1" />
                      {withHttp(data?.website)}
                    </a>
                  </div>
                  {data.links &&
                    Array.isArray(data.links) &&
                    data.links.map(({ url }) => {
                      if (!url) {
                        return null;
                      }
                      const name = url.match(
                        /(linkedin|facebook|twitter|instagram|github)/g
                      );

                      const Icon =
                        Array.isArray(name) && name[0]
                          ? social[name[0].toLowerCase()] || UilLink
                          : UilLink;
                      return (
                        <div className="mr-4" key={url}>
                          <a
                            className="flex items-center"
                            href={withHttp(url)}
                            title={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Icon size="14" className="mr-1 flex-shrink-0" />
                            {withHttp(url)}
                          </a>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            <div
              className="w-full flex mt-2"
              onClick={() => {
                if (hubspotInputsDisabled && onDisabledClick) {
                  onDisabledClick()
                }
              }
            }>
              {!flags.multiple_project_contacts_enabled && <div className="w-1/2">
                <Header Icon={UilEnvelope} text={$.project_contact_title} />
                <div className="w-full">
                  <Select
                    className="w-full"
                    onChange={(v) => onContactChange(v)}
                    value={
                      data.contact_id
                      ? {
                        label: data.contact_name,
                        value: data.contact_id,
                      }
                      : null
                    }
                    placeholder={$.select_option}
                    footerText={$.add_new_contact}
                    footerOnClick={() => setModal('add_contact')}
                    async
                    loadOptions={fetchCompanyContacts}
                    isDisabled={hubspotInputsDisabled}
                  />
                </div>
              </div>}
              <div className="w-1/2">
                <Header Icon={UilMapMarker} text={$.location_title} />
                <div className="text-sm text-midnight font-bold">
                  <AddressSearch
                    onChange={(v) => onAddressChange(v)}
                    defaultFullAddress={data.address}
                    disabled={hubspotInputsDisabled}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {modal === 'add_contact' && <ContactForm {...{ company: data, setModal, onContactChange, onClose: () => setModal() }}/>}
    </Section>
  );
};

export default Company;
