import React, { useEffect, useState } from "react";
import $ from "strings";
import UilText from "@iconscout/react-unicons/icons/uil-left-indent";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";

/**
 * Renders a wrapper that provides access to the strings keys. To enable this feature set REACT_APP_ENABLE_STRINGS_FINDER environment variable to true.
 * To use it click CTRL + ALT or CMD + ALT.
 * @param {component} children - The children to render.
 * @param {string} id - Identifier of the section, to link it with the string file.
 * @param {object} props - Extra props to attach to the wrapper.
 */
const Section =
  process.env.REACT_APP_ENABLE_STRINGS_FINDER === "true"
    ? ({ children, id, style = {}, ...props }) => {
        const [active, setActive] = useState(false);
        const [show, setShow] = useState(false);
        const strings = $[id];

        useEffect(() => {
          const handle = (e) => {
            if (e.ctrlKey && e.altKey) {
              setActive((a) => !a);
            }
          };

          document.addEventListener("keydown", handle);
          return () => {
            document.removeEventListener("keydown", handle);
          };
        });

        const sectionStyle = active
          ? { border: "inset #9f7aea", position: "relative" }
          : {};

        return (
          <div {...props} style={{ ...style, ...sectionStyle }}>
            {active && show && (
              <div className="absolute top-0 right-0 bg-purple-500 z-50 rounded m-2">
                <div className="text-base font-mono bg-purple-900 text-purple-100 mb-4 flex items-center justify-between">
                  {id}
                  <UilTimes
                    onClick={() => {
                      setShow(false);
                    }}
                    className="ml-2 cursor-pointer"
                    size="20"
                  />
                </div>
                <div className="mb-4 px-6 overflow-y-auto max-h-section">
                  {Object.entries(strings).map(([k, v]) => (
                    <div className="py-2">
                      <div className="text-xs font-mono bg-purple-900 text-purple-100">
                        {k}
                      </div>
                      <div className="text-sm bg-purple-100 text-purple-900 px-1">
                        {v}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            {active && !show && (
              <div
                onClick={() => {
                  setShow(true);
                }}
                className="absolute top-0 right-0 bg-purple-600 z-40 w-8 h-8 flex items-center justify-center rounded m-2 text-purple-100 cursor-pointer"
              >
                <UilText size="20" />
              </div>
            )}
            {children}
          </div>
        );
      }
    : ({ children, ...props }) => <div {...props}>{children}</div>;

export default Section;
