import React from "react";
import { H2B, B1 } from "./Typography";

/**
 * Renders a special card header with an icon, and text below. Used in talent pages.
 * @param {string} title - Header text.
 * @param {component} Icon - Icon component to render.
 * @param {string} text - Text to render below the header.
 * @param {component} children - Children to render, used to render an extra button if needed.
 * @param {string} className - Classes to append to the default classes.
 */
const IconHeader = ({ title, Icon, text, children, className = "" }) => (
  <div className={"border-b border-link-water pt-2 pb-4 " + className}>
    <div className="flex items-center mb-2">
      <div className="flex-1 flex items-center">
        <Icon size="28" className="text-link mr-2" />
        <H2B>{title}</H2B>
      </div>
      {children}
    </div>
    <B1>{text}</B1>
  </div>
);

/**
 * Renders a card header. Used in talent pages.
 * @param {string} title - Header text.
 * @param {component} children - Children to render, used to render an extra button if needed.
 * @param {string} className - Classes to append to the default classes.
 */
const Header = ({ title, children, className = "" }) => {
  return (
    <div
      className={
        "border-b border-link-water py-2 flex items-center justify-between " +
        className
      }
    >
      <H2B>{title}</H2B>
      {children}
    </div>
  );
};

/**
 * Renders a card footer.
 * @param {component} children - Children to render; buttons, text, links.
 */
const Footer = ({ children }) => {
  return (
    <div className="border-t border-link-water pb-2 pt-4 flex items-center justify-end">
      {children}
    </div>
  );
};

/**
 * Wrappers a component/children with a padding and border.
 * @param {component} children - Children to render.
 * @param {string} className - Classes to append to the default classes.
 */
const Separator = ({ children, className = "" }) => (
  <div className={"border-b border-link-water last:border-0 py-4 " + className}>
    {children}
  </div>
);

/**
 * Renders a card. Used in modals and sections in most pages.
 * @param {component} children - Children to render.
 * @param {string} className - Classes to append to the default classes.
 */
const Card = ({ children, className = "" }) => {
  return (
    <div className={"bg-white px-8 py-4 my-4 " + className}>{children}</div>
  );
};

export { Card, Header, Footer, Separator, IconHeader };
