import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { admin_talent_excluded_form as $ } from "strings";
import { Header, Footer } from "components/Card";
import { Label, Error, Textarea } from "components/Form";
import Select from "components/Select";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { excludedReasonOptions } from './constants';
import { patchTalentOnboardingV2, postTalentNote } from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import AlertSuccess from "../../../../../components/AlertSuccess";
import { getAdminUser } from "utils/localStorageService";

const ExcludedForm = ({
  onClose,
  onSuccess,
  talentData,
  user
}) => {
  const alert = useAlert();
  const adminUser = getAdminUser();
  const [disableOther, setDisableOther] = useState();

  const { handleSubmit, register, errors, formState, control, watch } = useForm({
    defaultValues: { excluded_reason: "", excluded_details: "" },
    mode: "onBlur",
    reValidateMode: "onBlur"
  });
  
  const watchReason = watch('excluded_reason');
  const watchDetails = watch('excluded_details');

  useEffect(() => {
    (watchReason.value === 'other' && watchDetails === '') ?
      setDisableOther(true) :
      setDisableOther(false)
  }, [watchReason, watchDetails])

  const onSubmit = async (values) => {
    const toSend = {
      excluded: true
    };
    if (!values.excluded_reason) return;
    toSend.excluded_reason = values.excluded_reason.value;

    let note = `Excluded Reason: ${values.excluded_reason.label}`
    if (values.excluded_details) {
      note += `; ${values.excluded_details}`
    }

    try {
      const response = await patchTalentOnboardingV2(talentData.id, toSend);
      await postTalentNote({
        body: note,
        talent: user,
        note_tag: 'excluded',
        created_by_id: adminUser.id,
        created_by: adminUser.first_name + " " + adminUser.last_name,
      });
      let msg = 'Talent excluded successfully.'
      alert.success(<AlertSuccess message={msg} />, {timeout: 2000});
      onSuccess(response);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={() => onSubmit(values)} />);
    }
  };

  return (
    <Modal
      isDirty={formState.isDirty}
      onClose={onClose}
      id="admin_talent_excluded_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Header title={$.excluded_form_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full pr-8 mb-3 mt-2">
            <Label>{$.excluded_reason_label}</Label>
            <Controller
              control={control}
              rules={{ required: $.validation_required }}
              name="excluded_reason"
              as={<Select />}
              error={errors.excluded_reason}
              placeholder={$.select_option}
              options={excludedReasonOptions}
              ref={register('excluded_reason', { required: true })}
            />
            {errors.excluded_reason && <Error>{errors.excluded_reason.message}</Error>}
          </div>
          <div className="w-full pr-8 mb-3 mt-2">
            <Label>{$.excluded_details_label}</Label>
            <Controller
              control={control}
              name="excluded_details"
              as={<Textarea />}
              error={errors.excluded_details}
              placeholder={$.excluded_details_placeholder}
              ref={register('excluded_details')}
            />
            {errors.excluded_details && <Error>{errors.excluded_details.message}</Error>}
          </div>
        </div>
        <Footer>
          <Button
            secondary
            onClick={() => {
              onClose();
            }}
            className="mr-4"
          >
            {$.cancel_button}
          </Button>
          <Button
            type="submit"
            loading={formState.isSubmitting}
            disabled={
              formState.isSubmitting ||
              !formState.isDirty ||
              disableOther
            }>
            {$.save_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default ExcludedForm;
