import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import { admin_project_requirements as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import { RemoveBtn } from "components/Buttons";
import LoadPlaceholder from "components/LoadPlaceholder";
import useCachedFetch from "hooks/useCachedFetch";
import {
  deleteProjectQualification,
  getProjectQualifications,
  patchProjectQualification,
  postProjectQualification,
} from "utils/adminApi";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import Select from "components/ListSelect";
import UilAward from "@iconscout/react-unicons/icons/uil-award";
import useQualifications from "hooks/useQualifications";
import RatingInput from "components/RatingInput";
import { components } from "react-select";
import Preferences from "./Preferences";

const importanceOptions = [
  { value: 0, label: $.importance_empty_option },
  { value: 2, label: $.importance_nice_option },
  { value: 1, label: $.importance_required_option },
];

const Requirements = ({ project, setData, hubspotInputsDisabled, onDisabledClick }) => {
  const { id } = useParams();
  const { set } = usePageCache();
  const { qualifications } = useQualifications(true);
  const { qualificationsByValue, qualificationsOptions } = qualifications;
  const alert = useAlert();
  const projectQualifications = useCachedFetch(
    "admin_project_qualifications",
    getProjectQualifications,
    project && project.id
  );

  useEffect(() => {
    set("url_project", `/admin/project/${id}/requirements`);
  }, [set, id]);

  const onAddSuccess = (requirement) => {
    if (project.top_requirements?.length >= 5) return;
    setData({
      ...(project || []),
      top_requirements: [...(project.top_requirements || []), requirement],
    });
  }

  const onDeleteSuccess = (requirement) => {
    setData({
      ...(project || []),
      top_requirements: (project.top_requirements || []).filter(
        (r) => r.name !== requirement.name
      ),
    });
  }
  
  const onUpdateSuccess = (requirement, rating) => {
    const i = project.top_requirements.findIndex(
      (r) => r.name === requirement.name
    );
    if (i === -1) return;
    const topRequirements = project.top_requirements;
    topRequirements[i].rating = rating;
    setData({ ...project, top_requirements: topRequirements });
  }

  const onAdd = async (id) => {
    const call = async () => {
      try {
        const res = [...projectQualifications.data.results];

        const response = await postProjectQualification({
          project: project.id,
          qualification: id,
          rating: 0,
        });
        res.push(response);

        projectQualifications.setData((d) => ({ ...d, results: res }));
        
        onAddSuccess(qualificationsByValue[response.qualification]);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };

    if (!projectQualifications.data?.results?.some((q) => q.qualification === id)) {
      await call();
    }
  };

  const onRatingDelete = async (id) => {
    const call = async () => {
      try {
        const res = [...projectQualifications.data.results].filter((q) => q.id !== id);
        const deleted = [...projectQualifications.data.results].find((q) => q.id === id);

        projectQualifications.setData((d) => ({ ...d, results: res }));

        await deleteProjectQualification(id);
        onDeleteSuccess(qualificationsByValue[deleted.qualification]);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
        projectQualifications.setData((d) => ({
          ...d,
          results: projectQualifications.data.results,
        }));
      }
    };

    await call();
  };

  const onRatingChange = async (id, rating) => {
    const call = async () => {
      try {
        const res = [...projectQualifications.data.results];

        const i = res.findIndex((q) => q.id === id);
        res[i].rating = rating;

        projectQualifications.setData((d) => ({ ...d, results: res }));

        await patchProjectQualification(id, { rating });
        onUpdateSuccess(qualificationsByValue[res[i].qualification], rating);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
        projectQualifications.setData((d) => ({
          ...d,
          results: projectQualifications.data.results,
        }));
      }
    };

    await call();
  };

  const onRequiredChange = async (id, required) => {
    const call = async () => {
      try {
        const res = [...projectQualifications.data.results];

        const i = res.findIndex((q) => q.id === id);
        res[i].required = required;

        projectQualifications.setData((d) => ({ ...d, results: res }));

        await patchProjectQualification(id, { required });
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
        projectQualifications.setData((d) => ({
          ...d,
          results: projectQualifications.data.results,
        }));
      }
    };

    await call();
  };

  const onInputClick = () => {
    if (hubspotInputsDisabled && onDisabledClick) {
      onDisabledClick();
    }
  }

  if (!project || !projectQualifications || !qualificationsByValue) {
    return (
      <div className="bg-white flex-1 flex flex-col px-2 py-4">
        <LoadPlaceholder className="w-60 h-4 mt-10 mb-10" />
        <LoadPlaceholder className="w-60 h-4 mb-2" />
        <LoadPlaceholder className="w-40 h-4 mb-2" />
        <LoadPlaceholder className="w-32 h-4 mb-2" />
      </div>
    );
  }

  return (
    <Section id="admin_project_requirements" className="flex mt-1 h-full">
      <div className="bg-white flex-1 mr-2 h-full flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilAward className="mr-2 text-link" size="18" /> {$.title}
          </B2DB>
        </div>
        <div className="w-full my-3 relative px-4" onClick={onInputClick}>
          <Select
            secondary
            placeholder={$.select_placeholder}
            components={{
              DropdownIndicator: () => null,
              Option: ({ data: d, children, ...rest }) => (
                <components.Option {...rest}>
                  <div className="flex items-center justify-between">
                    {children}
                    {projectQualifications?.data?.results.some((q) => q.qualification === d.value) ? (
                      <div className="text-kasmir">{$.added_label}</div>
                    ) : (
                      <div className="text-link">{$.add_label}</div>
                    )}
                  </div>
                </components.Option>
              ),
            }}
            options={qualificationsOptions}
            value={null}
            onChange={(v) => onAdd(v.value)}
            isDisabled={hubspotInputsDisabled}
          />
        </div>
        <div className="px-2 py-2 h-full overflow-scroll">
          <div className="flex items-center text-sm text-kasmir font-bold">
            <div className="flex items-center flex-1">
              <div className="w-4/12">{$.name_label}</div>
              <div className="w-1/4">{$.grouping_label}</div>
              <div className="w-2/12">{$.type_label}</div>
              <div className="w-2/12">{$.required_label}</div>
              <div className="w-3/12">{$.rating_label}</div>
            </div>
            <div className="w-1/12" />
          </div>
          {projectQualifications?.data?.results && Array.isArray(projectQualifications.data.results) &&
            projectQualifications.data.results.map((q) => (
              <div
                key={q.id}
                className="flex items-center text-sm text-midnight"
              >
                <div className="flex items-center flex-1">
                  <div className="w-4/12 font-bold">
                    {qualificationsByValue[q.qualification].name}
                  </div>
                  <div className="w-1/4 capitalize">
                    {qualificationsByValue[q.qualification].grouping.replace(
                      "_",
                      " "
                    )}
                  </div>
                  <div className="w-2/12 capitalize">
                    {qualificationsByValue[q.qualification].type}
                  </div>
                  <div className="w-2/12" onClick={onInputClick}>
                    <Select
                      options={importanceOptions}
                      value={importanceOptions.find(
                        (o) => o.value === q.required
                      )}
                      onChange={(v) => onRequiredChange(q.id, v.value)}
                      placeholder={$.required_placeholder}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                  <div className="w-3/12" onClick={onInputClick}>
                    <RatingInput
                      value={q.rating}
                      onChange={(v) => onRatingChange(q.id, v)}
                      disabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
                <div className="w-1/12 flex items-center justify-end">
                  <RemoveBtn colored onClick={() => hubspotInputsDisabled && onDisabledClick ? onDisabledClick() : onRatingDelete(q.id)} />
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="w-1/3">
        {projectQualifications && <Preferences projectId={project.id} hubspotInputsDisabled={hubspotInputsDisabled} onDisabledClick={onDisabledClick} />}
      </div>
    </Section>
  );
};

export default Requirements;
