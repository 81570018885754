import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { useParams } from "react-router-dom";
import { postCandidateV2 } from "utils/adminApi";
import { admin_talent_project as $ } from "strings";
import AlertError from "components/AlertError";
import { Button, OutlinedButton } from "components/Buttons";
import { Textarea, Checkbox } from "components/Form";
import { useAlert } from "react-alert";
import { getAlgoliaResults } from "@algolia/autocomplete-js";
import algoliasearch from "algoliasearch";
import AlgoliaAutocomplete from "../../../../admin/AlgoliaAutocomplete";
import { stageOptions } from "./constants";
import UilTimesCircle from "@iconscout/react-unicons/icons/uil-times-circle";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_KEY
);

const ProjectListActions = ({ projects, onSuccess }) => {
  const alert = useAlert();
  const { id } = useParams();
  const [selectedProject, setSelectedProject] = useState();
  const [searchbarOpen, setSearchbarOpen] = useState(false);
  const [description, setDescription] = useState("");
  const [projectIds, setProjectIds] = useState([]); // already added
  const [showAll, setShowAll] = useState(false);

  useEffect(() => {
    const ids = projects.map(p => p.project_id);
    setProjectIds([...ids]);
  }, [projects]);

  const onAddProject = async () => {
    try {
      await postCandidateV2({
        candidate: id,
        project: selectedProject.objectID,
        status: "new",
        applied: new Date(),
        description
      });
      resetValues();
      onSuccess();
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onAddProject} />);
    }
  };

  const resetValues = () => {
    setSearchbarOpen(false);
    setDescription(null);
    setSelectedProject(null);
  }

  const SearchItem = ({ item }) => (
    <div
      className="flex items-center w-full mb-2 border border-transparent border-1 rounded-sm bg-white p-2"
    >
      <div className="text-midnight w-1/3 font-bold text-sm">
        {item.name}
      </div>
      <div className="text-kasmir w-2/3 flex items-center font-bold text-sm">
        <div className="w-1/2 truncate">
          {`${$.stage_label} ${stageOptions.find(o => o.value === item.stage)?.label || $.unknown_value}`}
        </div>
        <div className="w-1/4">
          {`${$.pay_rate_label} $${item.ideal_rate}`}
        </div>
        <div className="w-1/4">
          {$.hours_label} {item.availability_hours ? `${item.availability_hours}/wk` : $.unknown_value}
        </div>
        <UilTimesCircle className="cursor-pointer" onClick={() => setSelectedProject(null)} />
      </div>
    </div>
  )

  return (
    <div className="candidate_list_actions flex mb-1 items-start py-2">
      {searchbarOpen ? (
        <div className="flex w-3/4 items-start">
          <div className="flex items-start justify-start mr-2">
            <div className="group relative">
              <Button
                noMinW
                disabled={!selectedProject || projectIds.indexOf(selectedProject.objectID) > -1}
                onClick={onAddProject}
              >
                {$.save_button}
              </Button>
              {(selectedProject && projectIds.indexOf(selectedProject.objectID) > -1) && 
                <div className="bottom-full left-1/4 trans-left-1/4 mb-1 w-content bg-cove text-white rounded px-2 py-1 text-center absolute z-30 font-bold text-xs hidden group-hover:inline">
                  {$.project_already_added_label}
                </div>
              }
            </div>
            <OutlinedButton noMinW className="ml-1 h-10" onClick={resetValues}>
              {$.cancel_button}
            </OutlinedButton>
          </div>
          <div className="flex flex-1 items-start justify-start flex-col">
            {selectedProject ?
              <SearchItem item={selectedProject} /> : <div className="flex items-center w-full">
              <AlgoliaAutocomplete
                customStyle="w-3/4"
                classNames={{
                  root: "talent-project-aa-root",
                  form: "talent-project-aa-form"
                }}
                openOnFocus={true}
                placeholder={$.search_placeholder}
                getSources={({ query, setContext }) => [
                  {
                    sourceId: "project",
                    getItems() {
                      return getAlgoliaResults({
                        searchClient,
                        queries: [
                          {
                            indexName: "project_index",
                            query,
                            params: { hitsPerPage: 10 },
                            filters: showAll ? "" : "stage:matching_support"
                          },
                        ],
                        transformResponse({ results, hits }) {
                          setContext({
                            nbProjectHits: results[0].nbHits,
                          });

                          return hits;
                        },
                      });
                    },
                    templates: {
                      item: ({item}) => (
                        <div className="flex items-center w-full font-lato" onClick={() => setSelectedProject(item)}>
                          <div className={clsx("text-midnight font-bold text-sm", projectIds.indexOf(item.objectID) > -1 ? 'w-1/2' : 'w-1/3')}>
                            {item.name}
                          </div>
                          <div className={clsx("text-kasmir flex items-center font-bold text-sm", projectIds.indexOf(item.objectID) > -1 ? 'w-1/2' : 'w-2/3')}>
                            {projectIds.indexOf(item.objectID) > -1 ?
                            <>
                              <div className="w-full text-right italic pr-2">
                                {$.project_already_added_label}
                              </div>
                            </> :
                            <>
                              <div className="w-1/2 truncate">
                                {`${$.stage_label} ${stageOptions.find(o => o.value === item.stage)?.label || $.unknown_value}`}
                              </div>
                              <div className="w-1/4">
                                {`${$.pay_rate_label} $${item.ideal_rate}`}
                              </div>
                              <div className="w-1/4">
                                {$.hours_label} {item.availability_hours ? `${item.availability_hours}/wk` : $.unknown_value}
                              </div>
                            </>
                            }
                          </div>
                        </div>
                      ),
                    },
                  }
                ]}
              />
              {!selectedProject &&
              <div className="w-1/4 ml-2">
                <Checkbox
                  onChange={() => setShowAll(!showAll)}
                  value={showAll}
                >
                  {$.show_all_projects_label}
                </Checkbox>
                </div>
              }
            </div>
            }
            {selectedProject && (
              <div className="w-full">
                <Textarea
                  small
                  placeholder={$.description_placeholder}
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <Button noMinW onClick={() => setSearchbarOpen(true)}>
            {$.add_project_title}
          </Button>
        </>
      )}
    </div>
  );
};

export default ProjectListActions;
