import React, { useEffect, useState } from "react";
import { admin_talent_roles as $ } from "strings";
import { B2DB, B1, B2B } from "components/Typography";
import { EditBtn, RemoveBtn, Button } from "components/Buttons";
import { Media, MediaBody, MediaLeft } from "components/Layout";
import { usePageCache } from "contexts/pageCacheContext";
import { useParams } from "react-router-dom";
import Section from "components/Section";
import UilUser from "@iconscout/react-unicons/icons/uil-user-square";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus-circle";
import { deleteTalentOwner, getTalentOnboardingV2 } from "utils/adminApi";
import LoadPlaceholder from "components/LoadPlaceholder";
import userImg from "assets/user.svg";
import useCachedFetch from "hooks/useCachedFetch";
import { patchTalentOnboardingV2 } from "utils/adminApi";
import RolesForm from "./RolesForm";
import LeadSourceForm from "./LeadSourceForm";

const Roles = ({ data = {}, setData, owners }) => {
  const { set } = usePageCache();
  const { id } = useParams();
  const [modal, setModal] = useState();

  const { data: onboardingData, setData: setOnboardingData }  = useCachedFetch(
    "admin_talent_onboarding",
    getTalentOnboardingV2,
    data?.id
  )

  useEffect(() => {
    set("url_talent", `/admin/talent/${id}/roles`);
  }, [set, id]);

  if (!data) {
    return (
      <div className="bg-white px-4 py-6 mb-6">
        <LoadPlaceholder className="w-60 h-4 mt-10 mb-10" />
        <LoadPlaceholder className="w-60 h-4 mb-2" />
        <LoadPlaceholder className="w-40 h-4 mb-2" />
        <LoadPlaceholder className="w-32 h-4 mb-2" />
      </div>
    );
  }

  const handleDelete = async (role, entityId) => {
    try {
      if (role === "lead_source") {
        setData({ ...data, [role]: {} });
        setOnboardingData({ ...onboardingData, lead_source_name: '', lead_source_id: null })
        await patchTalentOnboardingV2(data.id, { ...onboardingData, lead_source_name: '', lead_source_id: null });
      } else if (role === "owner") {
        owners.setData({
          ...owners.data,
          results: owners.data.results.filter((o) => o.id !== entityId),
        });

        await deleteTalentOwner(entityId);
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Section className="bg-white px-4 py-6 mb-6" id="admin_talent_roles">
      <div className="flex items-center">
        <B2DB className="flex flex-1 items-center">
          <UilUser className="mr-2" size="18" /> {$.all_roles_title}
        </B2DB>
        <Button onClick={() => setModal("roles")}>
          <UilPlus size="18" className="mr-1" /> {$.add_user_button}
        </Button>
      </div>
      {(!data.lead_source || !data.lead_source.id) &&
        (!owners.data ||
          (Array.isArray(owners.data.results) &&
            owners.data.results.length === 0)) && (
          <div className="text-center py-12 text-kasmir">{$.no_roles_text}</div>
        )}
      {owners.data &&
        Array.isArray(owners.data.results) &&
        owners.data.results.length > 0 &&
        owners.data.results.map((o) =>
          o.owner ? (
            <div
              key={o.owner.id}
              className="mt-6 p-4 w-85 rounded border border-link-water"
            >
              <div className="flex items-center mb-4">
                <B2DB className="mr-2 flex-1">{$.owner_label}</B2DB>
                {!!o.id && (
                  <RemoveBtn
                    colored
                    onClick={() => handleDelete("owner", o.id)}
                  />
                )}
                <EditBtn colored onClick={() => setModal("roles")} />
              </div>
              <Media>
                <MediaLeft>
                  <img
                    className="rounded h-16 w-16 bg-geyser"
                    src={o.owner.photo || userImg}
                    alt={o.owner.first_name}
                  />
                </MediaLeft>
                <MediaBody>
                  <B2B className="mb-1">
                    {o.owner.first_name} {o.owner.last_name}
                  </B2B>
                  <B1>{o.owner.email}</B1>
                </MediaBody>
              </Media>
            </div>
          ) : null
        )}
      {data.lead_source && data.lead_source.name && onboardingData && (
        <div className="mt-6 p-4 w-85 rounded border border-link-water">
          <div className="flex items-center mb-4">
            <B2DB className="mr-2 flex-1">{$.lead_source_label}</B2DB>
            <RemoveBtn colored onClick={() => handleDelete("lead_source")} />
            <EditBtn colored onClick={() => setModal("roles.lead_source")} />
          </div>
          <Media>
            <MediaLeft>
              <img
                className="rounded h-16 w-16 bg-geyser"
                src={userImg}
                alt={onboardingData.lead_source_name}
              />
            </MediaLeft>
            <MediaBody>
              <B2B className="mb-1">{onboardingData.lead_source_name}</B2B>
            </MediaBody>
          </Media>
        </div>
      )}
      {modal && modal.includes("roles") && (
        <RolesForm
          {...{
            defaultRole: modal.split(".")[1],
            data,
            setData,
            setModal,
            owners,
            onboardingData,
            setOnboardingData,
            onClose: () => setModal(),
          }}
        />
      )}
      {modal === "add_lead_source" && (
        <LeadSourceForm {...{ setModal, onClose: () => setModal() }} />
      )}
    </Section>
  );
};

export default Roles;