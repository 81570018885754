import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import { admin_contact as $ } from "strings";
import { getProjectsV2 } from "utils/adminApi";
import LoadPlaceholder from "components/LoadPlaceholder";
import UilAngleDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilAngleUp from "@iconscout/react-unicons/icons/uil-angle-up";
import Project from './Project';

const StakeholderProjects = ({ data }) => {
  const { id } = useParams();
  const { set } = usePageCache();
  const [loading, setLoading] = useState(true);
  const [stakeholderProjects, setStakeholderProjects] = useState([]);

  useEffect(() => {
    set("url_contact", `/admin/contacts/${id}/stakeholder_projects`);
  }, [set]);

  useEffect(() => {
    if (!id) return;
    getStakeholderProjects(id);
  }, [id])

  const getStakeholderProjects = async (id) => {
    const params = { contact__user_id: id };
    const response = await getProjectsV2(params);
    const results = response.results.map(project => ({
      id: project.id,
      company_name: project.company_name,
      project_name: project.name.includes(project.company_name) ? project.name.split(' - ')[1] : project.name,
      project_stage: project.stage,
      created_at: project.timestamp,
      last_updated: project.last_updated
    }));
    setStakeholderProjects(results);
    setLoading(false);
  }

  const ProjectGroup = ({ group, projects }) => {
    const [open, setOpen] = useState(true);
    return (
      <div id={`stakeholder_project_${group.split(' ')[0]}`} className="m-1">
        <div className="flex items-center text-sm text-midnight font-bold font-bold">
          {group}
          {open ? 
            <UilAngleDown className="cursor-pointer" onClick={() => setOpen(!open)} /> :
            <UilAngleUp className="cursor-pointer" onClick={() => setOpen(!open)} />
          }
        </div>
        {open && <div>
          {projects?.length ? 
            projects.map((project) =>
              <Project
                key={project.id}
                project={project}
                clickable={true}
              />
            ) :
            <NoProjects text={`No ${group}`} />
          }
        </div>}
      </div>
    )
  }

  const NoProjects = ({ text }) => (
    <div className="flex items-center">
      <div className="border-t border-geyser flex-1"></div>
      <div className="mx-2 text-sm font-normal italic text-midnight">{text}</div>
      <div className="border-t border-geyser flex-1 flex-no-shrink"></div>
    </div>
  );

  return (
    <div className="roles_list pb-10">
      {(loading || !stakeholderProjects) ?
        <div className="flex-1 px-6 pt-6">
          <LoadPlaceholder className="w-60 h-4 my-4" />
          <LoadPlaceholder className="w-60 h-4 mb-4" />
          <LoadPlaceholder className="w-40 h-4 mb-2" />
        </div> :
          <>
            {stakeholderProjects.length ?
              <>
                <ProjectGroup group='Potential Projects' projects={
                  stakeholderProjects.filter(p => 
                    p.project_stage !== 'lost' && 
                    p.project_stage !== 'passive' &&
                    p.project_stage !== 'completed' &&
                    p.project_stage !== 'filled'
                  )}
                />
                <ProjectGroup group='Active Projects' projects={
                  stakeholderProjects.filter(p => 
                    p.project_stage === 'filled'
                  )}
                />
                <ProjectGroup group='Completed Projects' projects={
                  stakeholderProjects.filter(p => 
                    p.project_stage === 'completed'
                  )}
                />      
                <ProjectGroup group='Lost/Passive Projects' projects={
                  stakeholderProjects.filter(p => 
                    p.project_stage === 'lost' || p.project_stage === 'passive'
                  )}
                />
              </> : 
              <div className="flex py-8">
                <div className="mx-2 text-sm font-normal text-midnight">{$.no_stakeholder_projects_label.replace('%', `${data?.first_name} ${data?.last_name}`)}</div>
              </div>
            }
          </>
        }
    </div>
  );
};

export default StakeholderProjects;