import React, { useEffect, useState } from "react";
import { admin_project_overview as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import UilGlobe from "@iconscout/react-unicons/icons/uil-globe";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import Header from "components/ListHeader";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope";
import UilUserCircle from "@iconscout/react-unicons/icons/uil-user-circle";
import UilSuitcase from "@iconscout/react-unicons/icons/uil-suitcase";
import UilFileAlt from "@iconscout/react-unicons/icons/uil-file-alt";

import {
  getContactsForCompany,
  getProjectCompanyV2,
  patchLeadSource,
  patchProject,
  postLeadSource,
} from "utils/adminApi";
import Select from "components/CompactSelect";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import {
  fetchAccounts,
  fetchCompanies,
  fetchOwners,
  fetchTalentContacts,
  fetchCompanyContacts,
} from "utils/fetch";
import Input from "components/ListEditable";
import useFetch from "hooks/useFetch";

const sourceOptions = [
  { value: "Social", label: "Social" },
  { value: "Search", label: "Search" },
  { value: "Referral", label: "Referral" },
  { value: "Events", label: "Events" },
  { value: "Client Expansion", label: "Client Expansion" },
  { value: "Email", label: "Email" },
  { value: "Slack", label: "Slack" },
  { value: "Passive Lead Return", label: "Passive Lead Return" },
  { value: "Other", label: "Other" },
];

const socialChannelOptions = [
  { value: "Facebook", label: "Facebook" },
  { value: "Instagram", label: "Instagram" },
  { value: "LinkedIn", label: "LinkedIn" },
];

const searchChannelOptions = [
  { value: "SEO", label: "SEO" },
  { value: "SEM", label: "SEM" },
  { value: "Blog", label: "Blog" },
];

const campaignOptions = [
  { value: "VC Newsletter", label: "VC Newsletter" },
  { value: "Talent Newsletter", label: "Talent Newsletter" },
  { value: "Company Newsletter", label: "Company Newsletter" },
  { value: "Other", label: "Other" },
];

const eventOptions = [
  { value: "In Person", label: "In Person" },
  { value: "Online", label: "Online" },
];

const expansionOptions = [
  { value: "Existing", label: "Existing" },
  { value: "Past", label: "Past" },
];

const referralOptions = [
  { value: "Talent", label: "Talent" },
  { value: "Client", label: "Client" },
  { value: "Internal", label: "Internal" },
  { value: "VC/PE", label: "VC/PE" },
  { value: "Other", label: "Other" },
];

// used to clear dependent upstream fields
const emptyLeadSourceObj = {
  campaign_name: null,
  campaign_other_note: null,
  event_type: null,
  event_name: null,
  event_contact: null,
  expansion_contact: null,
  expansion_company: null,
  expansion_name: null,
  other: null,
  passive_lead_company: null,
  passive_lead_contact: null,
  referral_contact: null,
  referral_other: null,
  referral_staff: null,
  referral_talent: null,
  referral_type: null,
  search_channel: null,
  slack_talent: null,
  social_channel: null,
}

const LeadSource = ({ project, hubspotInputsDisabled, onDisabledClick }) => {
  const [open, setOpen] = useState(true);
  const [expansionContacts, setExpansionContacts] = useState([]);
  const [passiveLeadContacts, setPassiveLeadContacts] = useState([]);
  const alert = useAlert();

  const { data, setData } = useFetch(getProjectCompanyV2, project.id);


  useEffect(() => {
    if (!data || !data.lead_source) return;
    if (data.lead_source.expansion_company) {
      getExpansionContacts();
    }
    if (data.lead_source.passive_lead_company) {
      getPassiveLeadContacts();
    }
  }, [data]);

  const getExpansionContacts = async () => {
    let expansionCompanyId = data?.lead_source?.expansion_company?.id;
    if (!expansionCompanyId) return;
    const response = await getContactsForCompany(expansionCompanyId);
    const contacts = response.map((c) => (
      {
        contact: c,
        value: c.id,
        label: c.name,
      }
    ))
    setExpansionContacts(contacts);
  }

  const getPassiveLeadContacts = async () => {
    let passiveLeadCompanyId = data?.lead_source?.passive_lead_company?.id;
    if (!passiveLeadCompanyId) return;
    const response = await getContactsForCompany(passiveLeadCompanyId);
    const contacts = response.map((c) => (
      {
        contact: c,
        value: c.id,
        label: c.name,
      }
    ))
    setPassiveLeadContacts(contacts);
  }

  const lsId = data?.lead_source?.id;
  useEffect(() => {
    if (lsId) {
      setData((d) => ({
        ...d,
        lead_source: {
          ...d.lead_source,
          passive_lead_company: d.lead_source.passive_lead_company?.id
            ? {
                id: d.lead_source.passive_lead_company.id,
                name: d.lead_source.passive_lead_company.name,
              }
            : null,
          passive_lead_contact: d.lead_source.passive_lead_contact?.id
            ? {
                id: d.lead_source.passive_lead_contact.id,
                name:
                  d.lead_source.passive_lead_contact.name
              }
            : null,
          referral_contact: d.lead_source.referral_contact?.id
            ? {
                id: d.lead_source.referral_contact.id,
                company_name: d.lead_source.referral_contact.company?.name,
                name: d.lead_source.referral_contact.name
              }
            : null,
          referral_staff: d.lead_source.referral_staff?.id
            ? {
                id: d.lead_source.referral_staff.id,
                name:
                  d.lead_source.referral_staff.first_name +
                  " " +
                  d.lead_source.referral_staff.last_name,
              }
            : null,
          referral_talent: d.lead_source.referral_talent?.user?.id
            ? {
                id: d.lead_source.referral_talent.user.id,
                company_name: d.lead_source.referral_talent.current_role,
                name:
                  d.lead_source.referral_talent.user.first_name +
                  " " +
                  d.lead_source.referral_talent.user.last_name,
              }
            : null,
          slack_talent: d.lead_source.slack_talent?.user?.id
            ? {
                id: d.lead_source.slack_talent.user.id,
                name:
                  d.lead_source.slack_talent.user.first_name +
                  " " +
                  d.lead_source.slack_talent.user.last_name,
              }
            : null,
          event_contact: d.lead_source.event_contact?.id
            ? {
                id: d.lead_source.event_contact.id,
                name: d.lead_source.event_contact.name
              }
            : null,
          expansion_contact: d.lead_source.expansion_contact?.id
            ? {
                id: d.lead_source.expansion_contact.id,
                name: d.lead_source.expansion_contact.name
              }
            : null,
        },
      }));
    }
  }, [lsId]);

  const onChange = async (key, value, isRelation, company_name, clear) => {
    const origVal = data.lead_source;

    const val = isRelation
      ? { id: value.value, name: value.label, company_name }
      : value;

    const newVal = clear
      ? { ...(data.lead_source || {}), [key]: val, [clear]: null }
      : { ...(data.lead_source || {}), [key]: val };

    setData({ ...data, lead_source: newVal });

    const call = async () => {
      try {
        if (key === "source" && (!data.lead_source || !data.lead_source.id)) {
          const leadSourceResponse = await postLeadSource({ source: value });
          await patchProject(project.id, {
            new_lead_source: leadSourceResponse.id,
          });
          setData({
            ...data,
            lead_source: { ...newVal, id: leadSourceResponse.id },
          });
        } else if (key === "source" || key === "referral_type" && (data.lead_source || data.lead_source.id)) {
          const toSend = {...emptyLeadSourceObj, [key]: value };
          const newData = key === 'referral_type' ?
          // if referral type is changed, preserve source in state and clear other dependent fields
            {
              ...data,
              lead_source: {
                ...emptyLeadSourceObj,
                [key]: value,
                'source': origVal.source,
                'id': origVal.id
              }
            } :
            // otherwise, clear all fields from state except for source and id
            {
              ...data,
              lead_source: {
                ...emptyLeadSourceObj,
                'id': origVal.id,
                [key]: value 
              }
            };
          setData({ ...newData });
          await patchLeadSource(data.lead_source.id, toSend);
        } else {
          const toSend = clear
            ? { [key]: isRelation ? value.value : value, [clear]: null }
            : { [key]: isRelation ? value.value : value };
          await patchLeadSource(data.lead_source.id, toSend);
        }
      } catch (e) {
        setData({ ...data, lead_source: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const type = data?.lead_source?.source;

  const onInputClick = () => {
    if (hubspotInputsDisabled && onDisabledClick) {
      onDisabledClick();
    }
  }

  return (
    <Section id="admin_project_overview" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilGlobe className="mr-2" size="18" /> {$.lead_source_title}
          </B2DB>
          <div className="cursor-pointer" onClick={() => setOpen(!open)}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && !!data && (
          <div className="px-2 pb-4">
            <div className="w-full flex">
              <div className="w-1/2" onClick={onInputClick}>
                <Header Icon={UilEnvelope} text={$.source_title} />
                <div className="w-full">
                  <Select
                    className="w-full"
                    onChange={(v) => onChange("source", v.value)}
                    value={
                      data.lead_source
                        ? {
                            label: data.lead_source.source,
                            value: data.lead_source.id,
                          }
                        : null
                    }
                    placeholder={$.source_placeholder}
                    options={sourceOptions}
                    isDisabled={hubspotInputsDisabled}
                  />
                </div>
              </div>
              {type === "Referral" && (
                <div className="w-1/2" onClick={onInputClick}>
                  <Header Icon={UilFileAlt} text={$.referral_type_title} />
                  <div className="w-full">
                    <Select
                      className="w-full"
                      onChange={(v) => onChange("referral_type", v.value)}
                      value={
                        data.lead_source?.referral_type
                          ? referralOptions.find(
                              (o) => o.value === data.lead_source?.referral_type
                            )
                          : null
                      }
                      placeholder={$.referral_type_placeholder}
                      options={referralOptions}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
              )}
              {type === "Social" && (
                <div className="w-1/2" onClick={onInputClick}>
                  <Header Icon={UilFileAlt} text={$.channel_title} />
                  <div className="w-full">
                    <Select
                      className="w-full"
                      onChange={(v) => onChange("social_channel", v.value)}
                      value={
                        data.lead_source?.social_channel
                          ? socialChannelOptions.find(
                              (o) => o.value === data.lead_source?.social_channel
                            )
                          : null
                      }
                      placeholder={$.channel_placeholder}
                      options={socialChannelOptions}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
              )}
              {type === "Search" && (
                <div className="w-1/2" onClick={onInputClick}>
                  <Header Icon={UilFileAlt} text={$.channel_title} />
                  <div className="w-full">
                    <Select
                      className="w-full"
                      onChange={(v) => onChange("search_channel", v.value)}
                      value={
                        data.lead_source?.search_channel
                          ? searchChannelOptions.find(
                              (o) => o.value === data.lead_source?.search_channel
                            )
                          : null
                      }
                      placeholder={$.channel_placeholder}
                      options={searchChannelOptions}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
              )}
              {type === "Slack" && (
                <div className="w-1/2" onClick={onInputClick}>
                  <Header Icon={UilUserCircle} text={$.slack_talent_title} />
                  <div className="w-full">
                    <Select
                      className="w-full"
                      onChange={(v) => onChange("slack_talent", v, true)}
                      value={
                        data.lead_source?.slack_talent
                          ? {
                              label: data.lead_source.slack_talent.name,
                              value: data.lead_source.slack_talent.id,
                            }
                          : null
                      }
                      placeholder={$.slack_talent_placeholder}
                      async
                      loadOptions={fetchAccounts}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
              )}
              {type === "Other" && (
                <div className="w-1/2" onClick={onInputClick}>
                  <Header Icon={UilEnvelope} text={$.other_title} />
                  <div className="w-full">
                    <Input
                      type="text"
                      text={data.lead_source?.other || $.other_placeholder}
                      defaultValue={data.lead_source?.other || ""}
                      onChange={(v) => onChange("other", v)}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
              )}
              {type === "Email" && (
                <div className="w-1/2" onClick={onInputClick}>
                  <Header Icon={UilFileAlt} text={$.campaign_name_title} />
                  <div className="w-full">
                    <Select
                      className="w-full"
                      onChange={(v) => onChange("campaign_name", v.value)}
                      value={
                        data.lead_source?.campaign_name
                          ? campaignOptions.find(
                              (o) => o.value === data.lead_source?.campaign_name
                            )
                          : null
                      }
                      placeholder={$.campaign_name_placeholder}
                      options={campaignOptions}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
              )}
              {type === "Events" && (
                <div className="w-1/2" onClick={onInputClick}>
                  <Header Icon={UilFileAlt} text={$.event_type_title} />
                  <div className="w-full">
                    <Select
                      className="w-full"
                      onChange={(v) => onChange("event_type", v.value)}
                      value={
                        data.lead_source?.event_type
                          ? eventOptions.find(
                              (o) => o.value === data.lead_source?.event_type
                            )
                          : null
                      }
                      placeholder={$.event_type_placeholder}
                      options={eventOptions}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
              )}
              {type === "Client Expansion" && (
                <div className="w-1/2" onClick={onInputClick}>
                  <Header Icon={UilFileAlt} text={$.expansion_type_title} />
                  <div className="w-full">
                    <Select
                      className="w-full"
                      onChange={(v) => onChange("expansion_type", v.value)}
                      value={
                        data.lead_source?.expansion_type
                          ? expansionOptions.find(
                              (o) =>
                                o.value === data.lead_source?.expansion_type
                            )
                          : null
                      }
                      placeholder={$.expansion_type_placeholder}
                      options={expansionOptions}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
              )}
            </div>
            {type === "Email" && data.lead_source?.campaign_name === "Other" && (
              <div className="w-full flex mt-1">
                <div className="w-1/2" onClick={onInputClick}>
                  <Header
                    Icon={UilEnvelope}
                    text={$.campaign_other_note_title}
                  />
                  <div className="w-full">
                    <Input
                      type="text"
                      text={
                        data.lead_source?.campaign_other_note ||
                        $.campaign_other_note_placeholder
                      }
                      defaultValue={data.lead_source?.campaign_other_note || ""}
                      onChange={(v) => onChange("campaign_other_note", v)}
                      disabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
              </div>
            )}
            {type === "Events" && (
              <div className="w-full flex mt-1">
                <div className="w-1/2" onClick={onInputClick}>
                  <Header Icon={UilFileAlt} text={$.event_name_title} />
                  <div className="w-full">
                    <Input
                      type="text"
                      text={
                        data.lead_source?.event_name || $.event_name_placeholder
                      }
                      defaultValue={data.lead_source?.event_name || ""}
                      onChange={(v) => onChange("event_name", v)}
                      disabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
                <div className="w-1/2" onClick={onInputClick}>
                  <Header Icon={UilUserCircle} text={$.event_contact_title} />
                  <div className="w-full">
                    <Select
                      className="w-full"
                      onChange={(v) => onChange("event_contact", v, true)}
                      value={
                        data.lead_source?.event_contact
                          ? {
                              label: data.lead_source.event_contact.name,
                              value: data.lead_source.event_contact.id,
                            }
                          : null
                      }
                      placeholder={$.event_contact_placeholder}
                      async
                      loadOptions={fetchCompanyContacts}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
              </div>
            )}
            {type === "Passive Lead Return" && (
              <div className="w-full flex mt-1">
                <div className="w-1/2" onClick={onInputClick}>
                  <Header
                    Icon={UilSuitcase}
                    text={$.passive_lead_company_title}
                  />
                  <div className="w-full">
                    <Select
                      className="w-full"
                      onChange={(v) =>
                        onChange(
                          "passive_lead_company",
                          v,
                          true,
                          null,
                          "passive_lead_contact"
                        )
                      }
                      value={
                        data.lead_source?.passive_lead_company
                          ? {
                              label: data.lead_source.passive_lead_company.name,
                              value: data.lead_source.passive_lead_company.id,
                            }
                          : null
                      }
                      placeholder={$.passive_lead_company_placeholder}
                      async
                      loadOptions={fetchCompanies}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
                {data.lead_source?.passive_lead_company?.id && (
                  <div className="w-1/2" onClick={onInputClick}>
                    <Header
                      Icon={UilUserCircle}
                      text={$.passive_lead_contact_title}
                    />
                    <div className="w-full">
                      <Select
                        className="w-full"
                        onChange={(v) =>
                          onChange("passive_lead_contact", v, true)
                        }
                        value={
                          data.lead_source?.passive_lead_contact
                            ? {
                                label:
                                  data.lead_source.passive_lead_contact.name,
                                value: data.lead_source.passive_lead_contact.id,
                              }
                            : null
                        }
                        placeholder={$.passive_lead_contact_placeholder}
                        options={passiveLeadContacts}
                        isDisabled={hubspotInputsDisabled}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            {type === "Client Expansion" && (
              <div className="w-full flex mt-1">
                <div className="w-1/2" onClick={onInputClick}>
                  <Header Icon={UilSuitcase} text={$.expansion_company_title} />
                  <div className="w-full">
                    <Select
                      className="w-full"
                      onChange={(v) =>
                        onChange(
                          "expansion_company",
                          v,
                          true,
                          null,
                          "expansion_contact"
                        )
                      }
                      value={
                        data.lead_source?.expansion_company
                          ? {
                              label: data.lead_source.expansion_company.name,
                              value: data.lead_source.expansion_company.id,
                            }
                          : null
                      }
                      placeholder={$.expansion_company_placeholder}
                      async
                      loadOptions={fetchCompanies}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
                <div className="w-1/2" onClick={onInputClick}>
                  <Header Icon={UilUserCircle} text={$.expansion_contact_title} />
                  <div className="w-full">
                    <Select
                      className="w-full"
                      onChange={(v) => onChange("expansion_contact", v, true)}
                      value={
                        data.lead_source?.expansion_contact
                          ? {
                              label: data.lead_source.expansion_contact.name,
                              value: data.lead_source.expansion_contact.id,
                            }
                          : null
                      }
                      placeholder={$.expansion_contact_placeholder}
                      options={expansionContacts}
                      isDisabled={hubspotInputsDisabled}
                    />
                  </div>
                </div>
              </div>
            )}
            {type === "Referral" &&
              (data.lead_source?.referral_type === "Other" || data.lead_source?.referral_other) && (
                <div className="w-full flex mt-1">
                  <div className="w-1/2" onClick={onInputClick}>
                    <Header Icon={UilEnvelope} text={$.referral_other_title} />
                    <div className="w-full">
                      <Input
                        type="text"
                        text={
                          data.lead_source?.referral_other ||
                          $.referral_other_placeholder
                        }
                        defaultValue={data.lead_source?.referral_other || ""}
                        onChange={(v) => onChange("referral_other", v)}
                        disabled={hubspotInputsDisabled}
                      />
                    </div>
                  </div>
                </div>
              )}
            {type === "Referral" &&
              (data.lead_source?.referral_type === "Talent" || data.lead_source?.referral_talent) && (
                <div className="w-full flex mt-1">
                  <div className="w-1/2" onClick={onInputClick}>
                    <Header Icon={UilSuitcase} text={$.referral_talent_title} />
                    <div className="w-full">
                      <Select
                        className="w-full"
                        onChange={(v) =>
                          onChange(
                            "referral_talent",
                            v,
                            true,
                            v?.account?.current_role
                          )
                        }
                        value={
                          data.lead_source?.referral_talent
                            ? {
                                label: data.lead_source.referral_talent.name,
                                value: data.lead_source.referral_talent.id,
                              }
                            : null
                        }
                        placeholder={$.referral_talent_placeholder}
                        async
                        loadOptions={fetchTalentContacts}
                        isDisabled={hubspotInputsDisabled}
                      />
                    </div>
                  </div>
                  {data.lead_source?.referral_talent && (
                    <div className="w-1/2" onClick={onInputClick}>
                      <Header Icon={UilSuitcase} text={$.current_role_title} />
                      {data.lead_source.referral_talent.company_name ? (
                        <div className="w-full text-sm text-midnight font-bold">
                          {data.lead_source.referral_talent.company_name}
                        </div>
                      ) : (
                        <div className="w-full text-sm text-kasmir font-bold">
                         Unknown
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            {type === "Referral" &&
              (data.lead_source?.referral_type === "Client") && (
                <div className="w-full flex mt-1">
                  <div className="w-1/2" onClick={onInputClick}>
                    <Header
                      Icon={UilUserCircle}
                      text={$.referral_contact_title}
                    />
                    <div className="w-full">
                      <Select
                        className="w-full"
                        onChange={(v) =>
                          onChange(
                            "referral_contact",
                            v,
                            true,
                            v?.company?.name
                          )
                        }
                        value={
                          data.lead_source?.referral_contact
                            ? {
                                label: data.lead_source.referral_contact.name,
                                value: data.lead_source.referral_contact.id,
                              }
                            : null
                        }
                        placeholder={$.referral_contact_placeholder}
                        async
                        loadOptions={fetchCompanyContacts}
                        isDisabled={hubspotInputsDisabled}
                      />
                    </div>
                  </div>
                  {data.lead_source?.referral_contact && (
                    <div className="w-1/2" onClick={onInputClick}>
                      <Header Icon={UilSuitcase} text={$.primary_company_title} />
                      {data.lead_source.referral_contact.company_name ? (
                        <div className="w-full text-sm text-midnight font-bold">
                          {data.lead_source.referral_contact.company_name}
                        </div>
                      ) : (
                        <div className="w-full text-sm text-kasmir font-bold">
                          Unknown Company
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            {type === "Referral" &&
              (data.lead_source?.referral_type === "VC/PE") && (
                <div className="w-full flex mt-1">
                  <div className="w-1/2" onClick={onInputClick}>
                    <Header
                      Icon={UilUserCircle}
                      text={$.vcreferral_contact_title}
                    />
                    <div className="w-full">
                      <Select
                        className="w-full"
                        onChange={(v) =>
                          onChange(
                            "referral_contact",
                            v,
                            true,
                            v?.company?.name
                          )
                        }
                        value={
                          data.lead_source?.referral_contact
                            ? {
                                label: data.lead_source.referral_contact.name,
                                value: data.lead_source.referral_contact.id,
                              }
                            : null
                        }
                        placeholder={$.vcreferral_contact_placeholder}
                        async
                        loadOptions={fetchCompanyContacts}
                        isDisabled={hubspotInputsDisabled}
                      />
                    </div>
                  </div>
                  {data.lead_source?.referral_contact && (
                    <div className="w-1/2" onClick={onInputClick}>
                      <Header Icon={UilSuitcase} text={$.primary_company_title} />
                      {data.lead_source.referral_contact.company_name ? (
                        <div className="w-full text-sm text-midnight font-bold">
                          {data.lead_source.referral_contact.company_name}
                        </div>
                      ) : (
                        <div className="w-full text-sm text-kasmir font-bold">
                          Unknown Company
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            {type === "Referral" &&
              (data.lead_source?.referral_type === "Internal" || data.lead_source?.referral_staff) && (
                <div className="w-full flex mt-1">
                  <div className="w-1/2" onClick={onInputClick}>
                    <Header Icon={UilUserCircle} text={$.referral_staff_title} />
                    <div className="w-full">
                      <Select
                        className="w-full"
                        onChange={(v) => onChange("referral_staff", v, true)}
                        value={
                          data.lead_source?.referral_staff
                            ? {
                                label: data.lead_source.referral_staff.name,
                                value: data.lead_source.referral_staff.id,
                              }
                            : null
                        }
                        placeholder={$.referral_staff_placeholder}
                        async
                        loadOptions={fetchOwners}
                        isDisabled={hubspotInputsDisabled}
                      />
                    </div>
                  </div>
                </div>
              )}
          </div>
        )}
      </div>
    </Section>
  );
};

export default LeadSource;
