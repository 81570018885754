import React, { useState, useEffect } from "react";
import LoadPlaceholder from "components/LoadPlaceholder";
import { Checkbox } from "components/Form";
import CandidateListActions from './CandidateListActions';
import CandidateListItem from './CandidateListItem';
import DeleteCandidateForm from './DeleteCandidateForm';
import CandidateStatusForm from "./CandidateStatusForm";
import { statusOptions } from './constants';
import { groupBy, orderBy } from "lodash";
import MoveDuplicateForm from "./MoveDuplicateForm";

const CandidateList = ({ candidates, project, onOpenCandidateDetail, onUpdate, loading }) => {
  const [modal, setModal] = useState(false);
  const [ungroupedCandidates, setUngroupedCandidates] = useState([]);
  const [groupedCandidates, setGroupedCandidates] = useState({});
  const [selectedSort, setSelectedSort] = useState();
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  useEffect(() => {
    setUngroupedCandidates(candidates);
    setGroupedCandidates(groupBy(candidates, 'status'));
  }, [candidates]);

  useEffect(() => {
    if (!selectedCandidates) return;
    if (!selectedCandidates.length) {
      setSelectedStatuses([]);
      return;
    }
    selectedStatuses.forEach((status) => {
      let matches = selectedCandidates.filter(c => c.status === status);
      if (!matches.length) {
        let statuses = [...selectedStatuses].filter(s => s !== status);
        setSelectedStatuses(statuses);
      }
    })
  }, [selectedCandidates]);

  const onDeleteSuccess = () => {
    setModal(false);
    setSelectedCandidates([]);
    setSelectedStatuses([]);
    onUpdate();
  }

  const onDeleteClose = () => {
    setModal(false);
  }

  const onChangeStatusSuccess = () => {
    setModal(false);
    setSelectedCandidates([]);
    setSelectedStatuses([]);
    onUpdate();
  }

  const onSort = (sort) => {
    const sorted = {};
    for (const [group, candidateList] of Object.entries(groupedCandidates)) {
      // default sorts null values to bottom
      let withValues = [];
      let withoutValues = [];
      let sortedValues = [];
      candidateList.forEach((c) => {
        c[sort.value] === null ? withoutValues.push(c) : withValues.push(c);
      })
      sortedValues = orderBy(withValues, sort.value, sort.order);
      sorted[group] = sortedValues.concat(withoutValues);
    };

    setGroupedCandidates(sorted);
  }

  const onGroupCheckboxClick = (status) => {
    let groupedCandidateIds = groupedCandidates[status].map(c => c.id);
    let alreadySelected = selectedCandidates.map(c => c.id);
    if (selectedStatuses.indexOf(status) > -1) {
      const s = [...selectedStatuses].filter(s => s !== status);
      const c = [...selectedCandidates].filter(c => groupedCandidateIds.indexOf(c.id) < 0);
      setSelectedStatuses(s);
      setSelectedCandidates(c);
    } else {
      let candidatesToAdd = groupedCandidates[status].filter(c => alreadySelected.indexOf(c.id) < 0);
      const s = [...selectedStatuses, status];
      const c = [...selectedCandidates, ...candidatesToAdd]
      setSelectedStatuses(s);
      setSelectedCandidates(c);
    }
  }

  const CandidateGroup = ({ status }) => {
    return (
      <div id={`candidate_group_${status.value}`} className="m-1">
        <div className="flex gap-2">
          <Checkbox
            onChange={() => onGroupCheckboxClick(status.value)}
            value={selectedStatuses.indexOf(status.value) > -1 ? true : false}
            disabled={!groupedCandidates[status.value]?.length}
          />
          <div className="text-sm text-midnight font-bold font-bold">{status.label}</div>
        </div>
        <div>
          {groupedCandidates[status.value]?.length ?
            groupedCandidates[status.value].map((candidate) =>
              <CandidateListItem
                key={candidate.id}
                candidate={candidate}
                project={project}
                openCandidateDetail={onOpenCandidateDetail}
                onUpdate={onUpdate}
                selectedCandidates={selectedCandidates}
                setSelectedCandidates={setSelectedCandidates}
              />
            ) :
            <NoCandidates text={`No ${status.label} candidates`} />
          }
        </div>
      </div>
    )
  }

  const NoCandidates = ({ text }) => (
    <div className="flex items-center">
      <div className="border-t border-geyser flex-1"></div>
      <div className="mx-2 text-sm font-bold italic text-midnight">{text}</div>
      <div className="border-t border-geyser flex-1 flex-no-shrink"></div>
    </div>
  );

  return (
    <div className="candidate_list pb-10 mr-2">
      <CandidateListActions
        candidates={candidates}
        project={project}
        onSuccess={onUpdate}
        onSort={onSort}
        selectedSort={selectedSort}
        setSelectedSort={setSelectedSort}
        selectedCandidates={selectedCandidates}
        setSelectedCandidates={setSelectedCandidates}
        selectedStatuses={selectedStatuses}
        setSelectedStatuses={setSelectedStatuses}
        setModal={setModal}
      />
      {loading ?
        <div className="flex-1 px-6 pt-6">
          <LoadPlaceholder className="w-60 h-4 my-4" />
          <LoadPlaceholder className="w-60 h-4 mb-4" />
          <LoadPlaceholder className="w-40 h-4 mb-2" />
        </div> :
        <>
          {statusOptions.map((status, i) => 
          <CandidateGroup status={status} key={i} count={groupedCandidates[status.value]?.length || 0} /> 
        )}
        </>
      }
      {modal === "deleteCandidate" &&
        <DeleteCandidateForm
          selectedCandidates={selectedCandidates}
          onClose={onDeleteClose}
          onSuccess={onDeleteSuccess}
        />
      }
      {modal === "batchChangeStatus" &&
        <CandidateStatusForm
          project={project}
          selectedCandidates={selectedCandidates}
          onClose={setModal}
          onSuccess={onChangeStatusSuccess}
        />
      }
      {modal === "batchMove" &&
        <MoveDuplicateForm
          selectedCandidates={selectedCandidates}
          action="move"
          onClose={setModal}
          onSuccess={onChangeStatusSuccess}
        />
      }
      {modal === "batchDuplicate" &&
        <MoveDuplicateForm
          selectedCandidates={selectedCandidates}
          action="duplicate"
          onClose={setModal}
          onSuccess={onChangeStatusSuccess}
        />
      }
    </div>
  );
};

export default CandidateList;