import React, { useState, useEffect } from "react";
import { useIndexedDB } from "react-indexed-db";

const TIME_LIMIT = 30; // in minutes

const PageCacheContext = React.createContext();

function usePageCache() {
  const context = React.useContext(PageCacheContext);
  if (!context) {
    throw new Error(`usePageCahe must be used within a PageCacheProvider`);
  }

  return context;
}

function PageCacheProvider({ children }) {
  const { update, getAll } = useIndexedDB("cache");

  const [cache, setCache] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (!getAll) {
      setLoaded(true);
      return;
    }
    getAll().then((allCache) => {
      const cached = {};
      allCache.forEach((c) => {
        if ((new Date() - new Date(c.timestamp)) / 1000 / 60 < TIME_LIMIT) {
          cached[c.key] = c.value;
        }
      });
      setCache(cached);
      setLoaded(true);
    });
  }, []);

  const set = React.useCallback(
    (k, v) => {
      setCache((u) => ({ ...u, [k]: v }));
      update({ key: k, value: v, timestamp: Date.now() }).then(
        () => {},
        (e) => {
          console.error(e);
        }
      );
    },
    [setCache]
  );

  return (
    <PageCacheContext.Provider value={{ set, cache }}>
      {children(loaded)}
    </PageCacheContext.Provider>
  );
}

export { PageCacheProvider, usePageCache };
