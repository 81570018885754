import React from "react";
import UilLeft from "@iconscout/react-unicons/icons/uil-angle-left-b";
import { admin_talent_project as $ } from "strings";
import { Button, OutlinedButton, RemoveBtn } from "components/Buttons";

const ProjectDetailActions = ({ onBack, onChangeStatus, onDelete }) => {
  return (
    <div className="project_detail_actions flex mb-1 h-12 items-center">
      <OutlinedButton className="h-10" onClick={onBack}>
        <UilLeft size="18" />
      </OutlinedButton>
      <Button noMinW className="ml-1" onClick={onChangeStatus}>
        {$.change_status_button}
      </Button>
      <RemoveBtn className="h-10 w-10" danger={true} onClick={onDelete} />
  </div>
  );
};

export default ProjectDetailActions;