import UilTrashAlt from "@iconscout/react-unicons/icons/uil-trash-alt";
import React from "react";

const Button = ({ children, ...props }) => (
  <button {...props} type="button" className="group focus:outline-none h-5">
    <span
      className="group-focus:ring focus:outline-none text-sm font-bold rounded px-1 h-5 inline-flex items-center justify-center text-red-dark bg-white hover:bg-red-dark active:bg-red-darker hover:text-white"
      tabIndex="-1"
    >
      <UilTrashAlt size="20" className="mr-1" />
      {children}
    </span>
  </button>
);

export default Button;
