import React, { useEffect, useState, useMemo } from "react";
import { admin_company_dataimport as $ } from "strings";
import { Search, Clear, Label, Checkbox } from "components/Form";
import DatePicker from "components/DatePicker";
import { B2B, B2DB, B2G } from "components/Typography";
import { useParams } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import Section from "components/Section";
import UilFile from "@iconscout/react-unicons/icons/uil-file-alt";
import LoadPlaceholder from "components/LoadPlaceholder";
import NoteForm from "./NoteForm";
import useCachedFetch from "hooks/useCachedFetch";
import { getCompanyNotes } from "utils/adminApi";
import Note from "./Note";
import { getAdminUserId } from "utils/localStorageService";
import { removeHtml } from "utils/highlightText";

const DataImport = ({ hubspotEnabled }) => {
  const userId = getAdminUserId();
  const { set, cache } = usePageCache();
  const c = cache.cache_company_dataimport || {};
  const { id } = useParams();
  const [maxDate, setMaxDate] = useState(c.maxDate || "");
  const [minDate, setMinDate] = useState(c.minDate || "");
  const [search, setSearch] = useState(c.search || "");
  const [sort] = useState(c.sort || "date");
  const [showCrelate, setShowCrelate] = useState(c.showCrelate || true);

  const { data, setData } = useCachedFetch(
    "admin_company_dataimport",
    getCompanyNotes,
    id
  );

  useEffect(() => {
    set("url_company", `/admin/company/${id}/dataimport`);
  }, [set, id]);

  useEffect(() => {
    return () => {
      set("cache_company_dataimport", {
        search,
        sort,
        minDate,
        maxDate,
        showCrelate,
      });
    };
  }, [search, sort, minDate, maxDate, showCrelate, set]);

  const notes = useMemo(() => {
    if (!data) {
      return [];
    }

    let notes = [...(data.results || [])];

    notes = notes.filter((n) => !!n.body);

    if (!showCrelate) {
      notes = notes.filter((n) => !n.crelate_id);
    }

    const term = search.trim();
    if (term) {
      notes = notes.filter((n) =>
        removeHtml(n.body).toLowerCase().includes(term.toLowerCase())
      );
    }

    if (minDate) {
      notes = notes.filter((n) => new Date(n.created_on) >= minDate);
    }

    if (maxDate) {
      const max = new Date(maxDate);
      notes = notes.filter(
        (n) => new Date(n.created_on) <= max.setDate(maxDate.getDate() + 1)
      );
    }

    if (sort === "date") {
      notes.sort((a, b) => {
        const aDate = new Date(a.created_on);
        const bDate = new Date(b.created_on);

        return aDate > bDate ? -1 : aDate < bDate ? 1 : 0;
      });
    }

    if (sort === "staff") {
      notes.sort((a, b) => {
        if (a.created_by) {
          return b.created_by ? a.created_by.localeCompare(b.created_by) : -1;
        }

        if (b.created_by) {
          return a.created_by ? b.created_by.localeCompare(a.created_by) : 1;
        }

        return 0;
      });
    }

    return notes;
  }, [data, sort, search, maxDate, minDate, showCrelate]);

  if (!data) {
    return (
      <div className="bg-white px-4 py-6 mb-6">
        <LoadPlaceholder className="w-32 h-4 my-5" />
        <LoadPlaceholder className="w-48 h-4 my-4" />
        <LoadPlaceholder className="w-40 h-4 my-4" />
      </div>
    );
  }

  return (
    <>
      {!hubspotEnabled && <NoteForm setData={setData} />}
      <Section
        className="bg-white px-4 py-6 mb-6"
        id="admin_company_dataimport"
      >
        <div className="pr-4 flex-1">
          <div className="flex items-center">
            <B2DB className="flex flex-1 items-center">
              <UilFile className="mr-2" size="18" /> {$.data_import_title}
            </B2DB>
          </div>
          <div className="my-6 flex">
            <div className="flex-1 flex items-center">
              <div className="w-64 mr-4">
                <Search
                  debounced
                  placeholder={$.search_placeholder}
                  value={search}
                  onChange={setSearch}
                />
              </div>
            </div>
            <div className="flex items-center">
              <div className="mr-4">
                <Checkbox
                  onChange={(v) => setShowCrelate(v)}
                  value={showCrelate}
                >
                  {$.view_crelate_label}
                </Checkbox>
              </div>
              <div className="text-midnight mr-2 font-bold text-sm">
                {$.date_range_label}
              </div>
              <div className="-mt-4">
                <Label className={minDate ? "" : "invisible"}>
                  <Clear onClick={() => setMinDate()} />
                </Label>
                <DatePicker
                  monthly={false}
                  value={minDate}
                  onChange={(date) => setMinDate(date)}
                  selectsStart
                  startDate={minDate}
                  endDate={maxDate}
                  maxDate={maxDate}
                />
              </div>
              <span className="mx-2 text-caded">-</span>
              <div className="-mt-4">
                <Label className={maxDate ? "" : "invisible"}>
                  <Clear onClick={() => setMaxDate()} />
                </Label>
                <DatePicker
                  monthly={false}
                  value={maxDate}
                  onChange={(date) => setMaxDate(date)}
                  selectsEnd
                  startDate={minDate}
                  endDate={maxDate}
                  minDate={minDate}
                />
              </div>
            </div>
          </div>
        </div>
        {!notes.length && (
          <div className="w-full mt-16 flex justify-center items-center py-6">
            <B2G>{$.no_notes_text}</B2G>
          </div>
        )}
        {!!notes.length && (
          <div className="mt-16">
            <B2B>{$.activity_title}</B2B>
            <div className="mt-6 w-full pr-4 overflow-hidden">
              {notes.map((n) => (
                <Note
                  key={n.id}
                  note={n}
                  editable={n.created_by_id === userId}
                  setData={setData}
                />
              ))}
            </div>
          </div>
        )}
      </Section>
    </>
  );
};

export const DataImportPreload = () => {
  const { id } = useParams();

  useCachedFetch("admin_company_dataimport", getCompanyNotes, id);

  return null;
};

export default DataImport;
