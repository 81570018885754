import AlertError from "components/AlertError";
import { Button } from "components/Buttons";
import { Error, Input, Label } from "components/Form";
import Select from "components/Select";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { Controller, useForm } from "react-hook-form";
import { admin_project_clockify as $ } from "strings";
import { global as $$ } from "strings";
import { attachTalentToProjectClockify } from "utils/adminApi";
import { fetchClockifyTalents } from "utils/fetch";
import AlertSuccess from "../../../../../components/AlertSuccess";

const AddTalentForm = ({ data, setData, setStatus }) => {
  const alert = useAlert();
  const [saving, setSaving] = useState(false);

  const { handleSubmit, register, errors, control, watch } = useForm();

  const allVal = watch();

  const onSubmit = async (values) => {
    setSaving(true);
    const toSend = {
      ...values,
      talent_id: values.talent_id && values.talent_id.value,
      clockify_project_id: data.clockify_id,
    };

    const call = async () => {
      try {
        await attachTalentToProjectClockify(data.id, toSend);
        alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
        setData((d) => ({
          ...d,
          membership_users: [
            ...(d.membership_users || []),
            {
              pay_rate: values.pay_rate,
              name: values.talent_id && values.talent_id.label,
            },
          ],
        }));
        setStatus(null);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
      setSaving(false);
    };

    await call();
  };

  return (
    <div className="w-full">
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="flex flex-wrap flex-1 min-h-0 py-4">
          <div className="w-full">
            <div className="w-full md:w-1/2">
              <div className="w-full mb-3 mt-2">
                <Label>{$.talent_id_input}</Label>
                <Controller
                  control={control}
                  name="talent_id"
                  as={<Select />}
                  error={errors.talent_id}
                  placeholder={$.talent_id_placeholder}
                  async
                  loadOptions={fetchClockifyTalents}
                  rules={{ required: $.validation_required }}
                  required
                />
                {errors.talent_id && <Error>{errors.talent_id.message}</Error>}
              </div>
            </div>
            <div className="w-full mb-3 mt-2">
              <Label>{$.pay_rate_input}</Label>
              <Input
                placeholder="$"
                name="pay_rate"
                maxLength="150"
                ref={register({ required: $.validation_required })}
                error={errors.pay_rate}
              />
              {errors.pay_rate && <Error>{errors.pay_rate.message}</Error>}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Button
            type="submit"
            loading={saving}
            disabled={!(allVal.pay_rate && allVal.talent_id) || saving}
          >
            {saving ? $.saving_btn : $.save_btn}
          </Button>
          <Button secondary onClick={() => setStatus(null)} className="mr-4">
            {$.cancel_btn}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddTalentForm;
