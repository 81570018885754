import React from "react";
import { useForm } from "react-hook-form";
import { admin_company_project_form as $ } from "strings";
import { Header, Footer } from "components/Card";
import { Label, Error, Input } from "components/Form";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { postProjectV2 } from "utils/adminApi";
import { getAdminUserId } from "utils/localStorageService";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import AlertSuccess from "../../../components/AlertSuccess";

const NewProjectForm = ({ id, onClose, onSuccess, company }) => {
  const alert = useAlert();
  const userId = getAdminUserId();
  const { handleSubmit, register, errors, formState } = useForm();

  const onSubmit = async (values) => {
    const call = async () => {
      try {
        await postProjectV2({
          name: company.name + " - " + values.name,
          company: id,
          stage: "pitching",
          owner_primary_id: userId
        });
        alert.success(<AlertSuccess message={$.success_message} />, {timeout: 2000});
        onSuccess();
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };

    await call();
    onClose();
  };

  return (
    <Modal
      isDirty={formState.isDirty}
      onClose={onClose}
      id="admin_company_project_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Header title={$.new_project_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full pr-8 mb-3 mt-2">
            <Label>{$.project_name_input}</Label>
            <Input
              name="name"
              maxLength="150"
              ref={register({ required: $.validation_required })}
              error={errors.name}
            />
            {errors.name && <Error>{errors.name.message}</Error>}
          </div>
        </div>
        <Footer>
          <Button secondary onClick={onClose} className="mr-4">
            {$.cancel_button}
          </Button>
          <Button type="submit" loading={formState.isSubmitting} disabled={formState.isSubmitting}>
            {$.create_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default NewProjectForm;
