import React, { useState } from "react";
import { admin_talent_overview as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import Indicator from "components/ListIndicator";
import UilFolderOpen from "@iconscout/react-unicons/icons/uil-folder-open";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import UilNotes from "@iconscout/react-unicons/icons/uil-notes";
import UilSearchMinus from "@iconscout/react-unicons/icons/uil-search-minus";
import UilUsersAlt from "@iconscout/react-unicons/icons/uil-users-alt";
import UilUserCheck from "@iconscout/react-unicons/icons/uil-user-check";
import UilUserCircle from "@iconscout/react-unicons/icons/uil-user-circle";
import UilUserSquare from "@iconscout/react-unicons/icons/uil-user-square";
import Header from "components/ListHeader";
import useCachedFetch from "hooks/useCachedFetch";
import {
  getTalentOnboardingV2,
  patchTalentOnboardingV2,
  patchAccountV2
} from "utils/adminApi";
import { fetchTalentLeadSources } from "utils/fetch";
import ExcludedForm from './ExcludedForm';
import AvailabilityLastUpdate from "./AvailabilityLastUpdate";
import Select from "components/CompactSelect";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";

const boolOptions = [
  { value: true, label: $.yes_option },
  { value: false, label: $.no_option },
];

const fte1099Options = [
  { value: "fte", label: "FTE" },
  { value: "1099", label: "1099" },
]

const Onboarding = ({ user, talentData, setTalentData }) => {
  const [open, setOpen] = useState(true);
  const [states, setStates] = useState({});
  const [modal, setModal] = useState();
  const alert = useAlert();

  const { data, setData } = useCachedFetch(
    "admin_talent_onboarding",
    getTalentOnboardingV2,
    talentData?.id
  );

  const onLeadSourceChange = async (value) => {
    const origVal = {
      'lead_source_id': data.lead_source_id,
      'lead_source_name': data.lead_source_name
    }

    const dataValues = {
      'lead_source_id': value.value,
      'lead_source_name': value.label
    }

    setData({ ...data, ...dataValues });
    setStates({ ...states, 'lead_source': "saving" });

    const call = async () => {
      try {
        const response = await patchTalentOnboardingV2(talentData.id, { ...dataValues });
        const { onboard_last_updated_at, onboard_last_updated_by } = response;
        setData({ ...data, ...dataValues, onboard_last_updated_at, onboard_last_updated_by });
        setStates({ ...states, 'lead_source': "saved" });
      } catch (e) {
        setStates({ ...states, 'lead_source': "error" });
        setData({ ...data, 'lead_source': origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  }

  const onChange = async (key, value) => {
    const origVal = data[key];

    setData({ ...data, [key]: value });
    setStates({ ...states, [key]: "saving" });

    const call = async () => {
      try {
        const response = await patchTalentOnboardingV2(talentData.id, { [key]: value });
        const { onboard_last_updated_at, onboard_last_updated_by } = response;
        setData({ ...data, [key]: value, onboard_last_updated_at, onboard_last_updated_by });
        setStates({ ...states, [key]: "saved" });

        if (key === 'excluded') {
          setTalentData({ ...talentData, ['excluded']: value });
        }
      } catch (e) {
        setStates({ ...states, [key]: "error" });
        setData({ ...data, [key]: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  }

  const onOnboardedChange = async (value) => {
    const origVal = talentData.onboarded;

    setTalentData({ ...talentData, ['onboarded']: value });
    setStates({ ...states, ['onboarded']: "saving" });

    const call = async () => {
      try {
        await patchAccountV2(user, { 'onboarded': value });
        setStates({ ...states, ['onboarded']: "saved" });
      } catch (e) {
        setStates({ ...states, ['onboarded']: "error" });
        setTalentData({ ...data, ['onboarded']: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  }

  const onToggleExcluded = async (value) => {
    if (value === true) {
      setModal("changeExcluded");
      return;
    } else {
      const origVal = talentData.excluded;
      const toSend = {
        excluded: false,
        excluded_reason: null,
        excluded_details: null
      }

      setTalentData({ ...talentData, ['excluded']: value });
      setStates({ ...states, ['excluded']: "saving" });
  
      const call = async () => {
        try {
          const response = await patchTalentOnboardingV2(talentData.id, toSend);
          const { onboard_last_updated_at, onboard_last_updated_by } = response;
          setData({ ...data, excluded: toSend.excluded, onboard_last_updated_at, onboard_last_updated_by });
          setStates({ ...states, ['excluded']: "saved" });
        } catch (e) {
          setStates({ ...states, ['excluded']: "error" });
          setTalentData({ ...data, ['excluded']: origVal });
          console.error(e);
          alert.error(<AlertError error={e} onRetry={call} />);
        }
      };
      await call();
    }
  }

  const onExcludeSuccess = () => {
    setData({ ...data, ['excluded']: true });
    setTalentData({ ...talentData, ['excluded']: true });
    setModal();
  }

  return (
    <Section id="admin_talent_onboarding" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilFolderOpen className="mr-2" size="18" /> {$.onboarding_title}
          </B2DB>
          <div className="cursor-pointer" onClick={() => setOpen(!open)}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && !!data && (<>
          <div className="px-2">
            <div className="w-full flex">
              <div className="w-1/2">
                <Header Icon={UilUserCheck} text={$.background_check_title}>
                  <Indicator state={states.background_check_passed} />
                </Header>
                <Select
                  className="w-full"
                  onChange={(v) => onChange("background_check_passed", v.value)}
                  value={
                    boolOptions.find((i) => i.value === data.background_check_passed) ||
                    null
                  }
                  placeholder={$.select_placeholder}
                  options={boolOptions}
                />
              </div>
              <div className="w-1/2">
                <Header Icon={UilNotes} text={$.fte_1099_title}>
                  <Indicator state={states.fte_1099} />
                </Header>
                <Select
                  className="w-full"
                  onChange={(v) => onChange("fte_1099", v.value)}
                  value={
                    fte1099Options.find((i) => i.value === data.fte_1099) ||
                    null
                  }
                  placeholder={$.select_placeholder}
                  options={fte1099Options}
                />
              </div>
            </div>
            <div className="w-full flex mt-2">
              <div className="w-1/2">
                <Header Icon={UilUsersAlt} text={$.community_title}>
                  <Indicator state={states.community} />
                </Header>
                <Select
                  className="w-full"
                  onChange={(v) => onChange("community", v.value)}
                  value={
                    boolOptions.find((i) => i.value === data.community) ||
                    null
                  }
                  placeholder={$.select_placeholder}
                  options={boolOptions}
                />
              </div>
              <div className="w-1/2">
                <Header Icon={UilUserSquare} text={$.lead_source_title}>
                  <Indicator state={states.lead_source} />
                </Header>
                <Select
                  placeholder={$.select_placeholder}
                  value={
                    data.lead_source_id && data.lead_source_name ?
                    {
                      label: data.lead_source_name,
                      value: data.lead_source_id
                    } : null
                  }
                  onChange={(v) => onLeadSourceChange(v)}
                  async
                  loadOptions={fetchTalentLeadSources}
                />
              </div>
            </div>
            <div className="w-full flex mt-2">
              <div className="w-1/2">
              <Header Icon={UilSearchMinus} text={$.excluded_title}>
                  <Indicator state={states.excluded} />
                </Header>
                <Select
                  className="w-full"
                  onChange={(v) => onToggleExcluded(v.value)}
                  value={
                    boolOptions.find((i) => i.value === talentData.excluded) ||
                    null
                  }
                  placeholder={$.select_placeholder}
                  options={boolOptions}
                />
              </div>
              <div className="w-1/2">
                <Header Icon={UilUserCircle} text={$.onboarded_title}>
                  <Indicator state={states.onboarded} />
                </Header>
                <Select
                  className="w-full"
                  onChange={(v) => onOnboardedChange(v.value)}
                  value={
                    boolOptions.find((i) => i.value === talentData.onboarded) ||
                    null
                  }
                  placeholder={$.select_placeholder}
                  options={boolOptions}
                />
              </div>
            </div>
          </div>
          <div className="ml-2 py-1">
            <AvailabilityLastUpdate
              updatedBy={data.onboard_last_updated_by}
              updatedAt={data.onboard_last_updated_at}
            />
          </div> 
        </>)}
      </div>
      {modal === "changeExcluded" && (
        <ExcludedForm
          talentData={talentData}
          user={user}
          onClose={setModal}
          onSuccess={onExcludeSuccess}
        />
      )}
    </Section>
  );
};

export default Onboarding;
