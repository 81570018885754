import React, { useState } from "react";
import { admin_candidate_delete_form as $ } from "strings";
import { global as $$ } from "strings";
import { Header, Footer } from "components/Card";
import { Input } from "components/Form";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { deleteCandidatesV2 } from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { B2DB } from "components/Typography";
import AlertSuccess from "../../../../../components/AlertSuccess";

const DeleteCandidateForm = ({ selectedCandidates, onClose, onSuccess }) => {
  const alert = useAlert();
  const [name, setName] = useState("");
  const [number, setNumber] = useState();
  const [loading, setLoading] = useState(false);

  const onDelete = async () => {
    try {
      setLoading(true);
      let ids = selectedCandidates.map(c => c.id);
      const msg = `Successfully deleted ${ids.length} candidate${ids.length === 1 ? '': 's'} from this project`;
      await deleteCandidatesV2({ candidate_ids: ids.join(',') });
      alert.success(
        <AlertSuccess message={msg} />,
        {timeout: 2000}
      );
      onSuccess();
      setLoading(false);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onDelete} />);
    }
  };

  return (
    <Modal isDirty={false} onClose={onClose} id="admin_candidate_delete_form">
      <div className="flex flex-col flex-nowrap flex-1 min-h-0" noValidate>
        <Header title={`${$.confirm_title}${selectedCandidates.length > 1 ? 's' : ''}`} />
        <div className="flex flex-wrap flex-1 min-h-0 overflow-y-auto py-4">
          {selectedCandidates.length > 1 ?
            <div className="flex flex-col gap-2">
              <B2DB>{$.sure_batch_message_1.replace("%", selectedCandidates.length)}</B2DB>
              <div style={{maxHeight: '200px', overflowY: 'scroll'}}>
                {selectedCandidates.map(c => 
                  <B2DB>{c.name}</B2DB>
                )}
              </div>
              <B2DB>{$.sure_batch_message_2}</B2DB>
              <div className="w-full md:w-1/2 pr-8 mb-3 mt-4">
                <Input onChange={(e) => setNumber(e.target.value)} />
              </div>
            </div> :
            <>
              <B2DB>{$.sure_message.replace("%", selectedCandidates[0].name)}</B2DB>
              <div className="w-full md:w-1/2 pr-8 mb-3 mt-4">
                <Input onChange={(e) => setName(e.target.value)} />
              </div>
            </>
          }
        </div>
        <Footer>
          <Button secondary onClick={onClose} className="mr-4">
            {$.cancel_button}
          </Button>
          {(
            (selectedCandidates.length === 1 && name === selectedCandidates[0].name) ||
            (Number(number) === selectedCandidates.length)
          ) ? (
            <Button danger onClick={onDelete} type="submit">
              {$.confirm_button}
            </Button>
          ) : (
            <Button disabled type="submit">
              {$.confirm_button}
            </Button>
          )}
        </Footer>
      </div>
    </Modal>
  );
};

export default DeleteCandidateForm;
