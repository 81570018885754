import React, { useState } from "react";
import { admin_contact as $ } from "strings";
import { patchAccountContactV2 } from "utils/adminApi";
import Header from "components/ListHeader";
import EditableField from "components/EditableField";
import Select from "components/CompactSelect";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope";
import UilUsersAlt from "@iconscout/react-unicons/icons/uil-users-alt";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";

const boolOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const rsuTeamOptions = [
  { label: 'Talent', value: 'Talent' },
  { label: 'Project Strategy', value: 'Project Strategy' },
  { label: 'Client Engagement', value: 'Client Engagement' },
  { label: 'Operations', value: 'Operations' },
  { label: 'Finance', value: 'finance' },
  { label: 'Other', value: 'other' }
]

const StaffRole = ({ roleData }) => {
  const alert = useAlert();
  const [role, setRole] = useState(roleData);
  const [states, setStates] = useState({});
  
  const onChange = async (key, value) => {
    const origVal = role[key];
    setRole({ ...role, [key]: value });
    setStates({ ...states, [key]: "saving" });
    try {
      await patchAccountContactV2(role.id, { [key]: value });
      setStates({ ...states, [key]: "saved" });
    } catch (e) {
      console.error(e);
      setStates({ ...states, [key]: "error" });
      setRole({ ...role, [key]: origVal });
      alert.error(<AlertError error={e} onRetry={() => onChange(key, value)} />);
    }
  }
   
  return (
    <>
      {role && 
        <div 
          id={`staff_role--${role?.id}`}
          className="bg-white flex-1 mt-2 mb-2 flex flex-col"
        >
          <div className="w-full flex space-between px-2">
            <div className="w-1/4 my-1">
              <Header Icon={UilEnvelope} text={$.rsu_email_label} />
              <div className="w-full text-sm text-midnight font-bold text-ellipsis">
              <div className="text-sm text-midnight font-bold text-ellipsis">{role.email ? role.email : $.unknown_value}</div>
                {/* <EditableField
                  disabled
                  value={role.email}
                  displayValue={role.email ? role.email : $.unknown_value}
                  onSave={(v) => {onChange("email", v)}}
                /> */}
              </div>
            </div>
            {/* <div className="w-1/6 mt-1">
              <Header Icon={UilCheckCircle} text={$.emails_enabled_label} />
              <div className="text-sm text-midnight font-bold text-ellipsis">
                <Select
                  className="-ml-1"
                  value={emails_enabled
                    ? boolOptions.find(o => o.value === emails_enabled)?.label
                    : { label: 'No', value: false }
                  }
                  onChange={(v) => onChange("emails_enabled", v.value || false)}
                  options={boolOptions}
                />
              </div>
            </div> */}
            <div className="w-1/6 mt-1">
              <Header Icon={UilUsersAlt} text={$.rsu_team_label} />
              <div className="text-sm text-midnight font-bold text-ellipsis">
                {$.unknown_value}
              </div>
              {/* <Select
                className="-ml-1"
                value={role.rsu_team
                  ? rsuTeamOptions.find((o) => o.value === role.rsu_team)
                  : { label: `${$.unknown_value}`, value: null }
                }
                onChange={(v) => onChange("rsu_team", v.value || null)}
                options={rsuTeamOptions}
              /> */}
            </div>
          </div>
        </div>
      }
    </>
  )
};

export default StaffRole;