import React from "react";
import { Link as RouteLink } from "react-router-dom";
import Logo from "components/Logo";

const Photo = ({ to, src, alt }) => (
  <RouteLink to={to} className="inline ml-4">
    <img src={src} alt={alt} className="rounded-full h-8 w-8" />
  </RouteLink>
);

const Button = ({ Icon }) => (
  <button className="text-kasmir inline-flex items-center justify-center w-8 h-8">
    <Icon size="24" />
  </button>
);

const Topbar = ({ children }) => {
  return (
    <div className="bg-white border-b border-geyser flex h-14 items-center">
      <div className="px-2 flex items-center">
        <div className="w-10 flex items-center justify-start flex-shrink-0">
          <Logo />
        </div>
      </div>
      <div className="flex-1 px-5 h-full min-w-0">{children}</div>
    </div>
  );
};

export { Topbar, Photo, Button };
