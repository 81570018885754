import React, { useState, useEffect } from "react";
import { admin_contact as $ } from "strings";
import { toFormat } from "utils/date";
import { NavLink, Switch, Route, useParams } from "react-router-dom";
import { BackLinkBtn, HubspotButton } from "components/Buttons";
import UilTrashAlt from "@iconscout/react-unicons/icons/uil-trash-alt";
import UilLink from "@iconscout/react-unicons/icons/uil-link";
import { usePageCache } from "contexts/pageCacheContext";
import {
  getUserLinks,
  getAccountContactsV2,
  getContactOverviewV2,
  getAccountV2,
  getUser,
} from "utils/adminApi";
import Section from "components/Section";
import useCachedFetch from "hooks/useCachedFetch";
import { LoadPlaceholderGroup } from "components/LoadPlaceholder";
import AlertError from "components/AlertError";
import HubspotToast from "components/HubspotToast";
import { useAlert } from "react-alert";
import DeleteForm from "../DeleteForm";
import ContactHeader from "./Header";
import Roles from "./Roles";
import Notes from "./Notes";
import StakeholderProjects from "./StakeholderProjects";
import ReferralProjects from "./ReferralProjects";
import ContactPreferences from "./ContactPreferences";
import Stakeholders from "./Stakeholders";
import { getAdminFlags } from "utils/localStorageService";
import { withHttp } from "utils/str";

const HUBSPOT_BASE_URL =
  process.env.NODE_ENV === 'development' || process.env.REACT_APP_API_URL.includes('staging') ?
  "https://app.hubspot.com/contacts/45416535/record/0-1" :
  "https://app.hubspot.com/contacts/39598951/record/0-1"

const NO_USER_ERROR = "Select a valid choice. That choice is not one of the available choices.";

const Page = () => {
  const [modal, setModal] = useState();
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [talentRole, setTalentRole] = useState(false);
  const [staffRole, setStaffRole] = useState(false);
  const [contactLinks, setContactLinks] = useState([]);
  const [defaultContact, setDefaultContact] = useState();
  const alert = useAlert();
  const { id } = useParams();
  const { cache } = usePageCache();
  const flags = getAdminFlags();

  const { data, setData, pageState } = useCachedFetch("admin_contact_overview", getContactOverviewV2, id);
  const { data: user } = useCachedFetch("admin_contact_user", getUser, id);

  useEffect(() => {
    if (!id) return;
    getContactsForUser(id);
    getContactLinks(id);
  }, [id]);

  useEffect(() => {
    if (!user) return;
    if (user.is_staff) {
      setStaffRole(user);
    }
    // TODO: remove this
    if (user.is_talent) {
      getTalentRoleData();
    }
  }, [user]);

  const getTalentRoleData = async () => {
    try {
      const response = await getAccountV2(id);
      setTalentRole(response);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={() => getContactsForUser(id)} />);
    }
  }

  const getContactsForUser = async (id) => {
    try {
      const response = await getAccountContactsV2({ user_id: id });
      setContacts(response?.results);
      setDefaultContact(response?.results.find(c => c.is_default === true));
      setLoading(false);
    } catch (e) {
      console.error(e);
      const msg = e.response?.data?.user_id?.[0];
      if (msg === NO_USER_ERROR) {
        alert.error(<AlertError message={'This contact does not exist.'} />);
      } else {
        alert.error(<AlertError error={e} onRetry={() => getContactsForUser(id)} />);
      }
    }
  }

  const getContactLinks = async (id) => {
    try {
      const response = await getUserLinks(id);
      setContactLinks(response?.data?.results);
    } catch (e) {
      console.error(e);
      const msg = e.response?.data?.user_id?.[0];
      if (msg !== NO_USER_ERROR) {  // handled above
        alert.error(<AlertError error={e} onRetry={() => getContactLinks(id)}/>);
      }
    }
  }

  const onEditClick = () => {
    alert.success(<HubspotToast type='contact' objectId={data.hubspot_contact_id} />, {timeout: 10000});
  }

  return (
    <Section id="admin_contact" className="h-full flex flex-col">
      <div className="flex items-center justify-between -mt-1 p-2">
        <BackLinkBtn to="/admin/contacts" text={$.back_contact} />
        {!!user && (
          <div className="text-kasmir font-light text-xs text-right">
            {$.created_at_label}{" "}
            <span className="lowercase">
              {!!user.date_joined &&
                toFormat(new Date(user.date_joined), "MM/dd/yyyy h:mma")}
            </span>
            {/* fixme - requires a backend change */}
            {/*<br />*/}
            {/*{$.updated_at_label}{" "}*/}
            {/*<span className="lowercase">*/}
            {/*  {!!defaultContact.last_updated &&*/}
            {/*    toFormat(new Date(defaultContact.last_updated), "MM/dd/yyyy h:mma")}*/}
            {/*</span>*/}
          </div>
        )}
      </div>
      <div className="bg-white flex flex-col">
        {(!contacts || !data || loading || pageState === 'loading') ? (
          <div className="p-2">
            <LoadPlaceholderGroup />
          </div>
        ) : (<>
              {data &&
                <ContactHeader
                  data={data}
                  setData={setData}
                  hubspotInputsDisabled={flags.hubspot_features_enabled}
                  onDisabledClick={onEditClick}
                />
              }
              <div className="flex w-full bg-link-water">
                <div className="w-full flex flex-row flex-shrink-0 border-t border-b border-link-ligher">
                  <div className="flex flex-1">
                    <NavLink
                      to={cache["url_contact_preferences"] || `/admin/contacts/${id}/contact_info`}
                      className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
                      activeClassName="font-bold"
                    >
                      {$.contact_info_link}
                    </NavLink>
                    <NavLink
                      exact
                      to={cache["url_contact_roles"] || `/admin/contacts/${id}/roles`}
                      className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
                      activeClassName="font-bold"
                    >
                      {$.roles_link}
                    </NavLink>
                    <NavLink
                      to={cache["url_contact_notes"] || `/admin/contacts/${id}/notes`}
                      className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
                      activeClassName="font-bold"
                    >
                      {$.notes_link}
                    </NavLink>
                    <NavLink
                      to={`/admin/contacts/${id}/stakeholder_projects`}
                      className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
                      activeClassName="font-bold"
                    >
                      {$.stakeholder_projects_link}
                    </NavLink>
                    <NavLink
                      to={`/admin/contacts/${id}/referral_projects`}
                      className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-caded"
                      activeClassName="font-bold"
                    >
                      {$.referral_projects_link}
                    </NavLink>
                    <NavLink
                      to={`/admin/contacts/${id}/stakeholders`}
                      className="text-sm px-2 font-medium h-5 flex items-center text-kasmir border-l border-r border-caded"
                      activeClassName="font-bold"
                    >
                      {$.stakeholders_link}
                    </NavLink>
                  </div>
                  <div className="flex items-center gap-2">
                    {(flags.hubspot_features_enabled && data?.hubspot_contact_id) &&
                      <HubspotButton to={{pathname: withHttp(`${HUBSPOT_BASE_URL}/${data?.hubspot_contact_id}`)}}>
                        <UilLink size="16" className="mr-1" />
                        {$.view_in_hubspot_btn}
                      </HubspotButton>
                    }
                    {staffRole ? <></> : <button type="button" className="group focus:outline-none h-5" onClick={() => flags.hubspot_features_enabled ? onEditClick() : setModal("delete")}>
                      <span
                        className="group-focus:ring focus:outline-none text-sm font-bold rounded px-1 h-5 inline-flex items-center justify-center text-red-dark bg-white hover:bg-red-dark active:bg-red-darker hover:text-white"
                        tabIndex="-1"
                      >
                        <UilTrashAlt size="20" className="mr-1" />
                        {$.delete_contact_button}
                      </span>
                    </button>}
                  </div>
                </div>
              </div>
            </>)}
      </div>
      <div className="flex w-full h-full mt-0 min-h-0">
        <div className="flex-1 h-full">
          <Switch>
            <Route exact path="/admin/contacts/:id">
              <ContactPreferences
                data={data}
                setData={setData}
                contacts={contacts}
                setContacts={setContacts}
                contactLinks={contactLinks}
                setContactLinks={setContactLinks}
                talentRole={talentRole}
                hubspotInputsDisabled={flags.hubspot_features_enabled}
                onDisabledClick={onEditClick}
              />
            </Route>
            <Route exact path="/admin/contacts/:id/contact_info">
              <ContactPreferences
                data={data}
                setData={setData}
                contacts={contacts}
                setContacts={setContacts}
                contactLinks={contactLinks}
                setContactLinks={setContactLinks}
                talentRole={talentRole}
                hubspotInputsDisabled={flags.hubspot_features_enabled}
                onDisabledClick={onEditClick}
              />
            </Route>
            <Route exact path="/admin/contacts/:id/roles">
              <Roles
                data={data}
                setData={setData}
                contacts={contacts}
                setContacts={setContacts}
                userId={id}
                staffRole={staffRole}
                talentRole={talentRole}
                setTalentRole={setTalentRole}
                hubspotInputsDisabled={flags.hubspot_features_enabled}
                onDisabledClick={onEditClick}
              />
            </Route>
            <Route exact path="/admin/contacts/:id/notes">
              <Notes
                data={data}
                defaultContactId={defaultContact?.id}
                hubspotInputsDisabled={flags.hubspot_features_enabled}
                onDisabledClick={onEditClick}
              />
            </Route>
            <Route exact path="/admin/contacts/:id/stakeholder_projects">
              <StakeholderProjects data={data} />
            </Route>
            <Route exact path="/admin/contacts/:id/referral_projects">
              <ReferralProjects data={data} />
            </Route>
            <Route exact path="/admin/contacts/:id/stakeholders">
              <Stakeholders
                data={data}
                setData={setData}
                defaultContactId={defaultContact?.id}
                hubspotInputsDisabled={flags.hubspot_features_enabled}
                onDisabledClick={onEditClick}
              />
            </Route>
          </Switch>
        </div>
      </div>
      {modal === "delete" && <DeleteForm data={defaultContact} onClose={setModal} />}
    </Section>
  );
};

export default Page;
