import React, { useEffect, useState, useMemo } from "react";
import { admin_talent_overview as $ } from "strings";
import { B2DB, B2G, D1 } from "components/Typography";
import RatingInput from "components/ListRatingInput";
import Section from "components/Section";
import CompactSelect from "components/CompactSelect";
import DebouncedInput from "components/DebouncedInput";
import { OutlinedButton, RemoveBtn } from "components/Buttons";
import { Checkbox } from "components/Form";
import {
  patchTalentQualification,
  deleteTalentQualificationV2,
  postTalentQualification,
} from "utils/adminApi";
import { components } from "react-select";
import UilAward from "@iconscout/react-unicons/icons/uil-award";
import UilPlusCircle from "@iconscout/react-unicons/icons/uil-plus-circle";
import UilDirection from "@iconscout/react-unicons/icons/uil-direction";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import AlertError from "components/AlertError";

const sortOptions = [
  { value: "name", label: $.name_label },
  { value: "rsu_rating", label: $.rsu_rating_label },
  { value: "self_rating", label: $.self_rating_label },
];

const Qualifications = ({ talentQualifications, qualificationsOptions, user }) => {
  const [qualifications, setQualifications] = useState({
    items: [],
    names: [],
    counts: { all: 0, skill: 0, tool: 0, industry: 0, leadership: 0 },
  });
  const [searchResults, setSearchResults] = useState({
    items: [],
    counts: { all: 0, skill: 0, tool: 0, industry: 0, leadership: 0 },
  });
  const [type, setType] = useState("all");
  const [dirty, setDirty] = useState(false);
  const [showSelfRatings, setShowSelfRatings] = useState(true);
  const [sort, setSort] = useState(sortOptions[0]);
  const [sortDirection, setSortDirection] = useState();
  const [searchString, setSearchString] = useState('');
  const [isFirstUpdate, setIsFirstUpdate] = useState(true);
  const [qualificationIsSaving, setQualificationIsSaving] = useState(false);

  const updateQualifications = () => {
    const res = {
      names: [],
      items: [],
      counts: { all: 0, skill: 0, tool: 0, industry: 0, leadership: 0 },
    };

    if (
      talentQualifications.data &&
      Array.isArray(talentQualifications.data.results)
      ) {
      talentQualifications.data.results
      .filter((q) =>
        (q.name.toLowerCase()).includes(searchString.toLowerCase()) && (showSelfRatings || q.rsu_rating))
      .forEach((q) => {
        let t = q.type;
        t = t ? t.toLowerCase() : "skill";
        if (t === "strategy") {
          t = "skill";
        }

        res.counts.all++;
        res.counts[t]++;

        res.names.push(q.name);

        if (type === t || type === "all") {
          res.items.push(q);
        }
      });
    }

    if (sort.value === "rsu_rating") {
      res.items.sort((a, b) =>
        a.rsu_rating < b.rsu_rating
          ? 1
          : b.rsu_rating < a.rsu_rating
          ? -1
          : a.name.localeCompare(b.name)
      );

      if (sortDirection === 'asc') res.items = res.items.reverse();
    } else if (sort.value === "self_rating") {
      res.items.sort((a, b) =>
        a.talent_rating < b.talent_rating
          ? 1
          : b.talent_rating < a.talent_rating
          ? -1
          : a.name.localeCompare(b.name)
      );

      if (sortDirection === 'asc') res.items = res.items.reverse();
    } else if (sort.value === "name") {
      res.items.sort((a, b) =>
        a.name.localeCompare(b.name)
      );

      if (sortDirection === 'asc') res.items = res.items.reverse();
    }

    setQualifications(res);
  };

  useMemo(() => {
    updateQualifications();
  }, [talentQualifications.data]);

  useEffect(() => {
    if (!isFirstUpdate) {
      updateQualifications();
    } else {
      setIsFirstUpdate(false);
    }
  }, [showSelfRatings, sort, type, sortDirection, searchString, dirty]);

  useEffect(() => {
    setSortDirection();
  }, [sort]);

  const onAdd = async (toAdd) => {
    setQualificationIsSaving(true);

    const call = async () => {
      try {
        setDirty(true);
        const results = [...talentQualifications.data.results];

        const response = await postTalentQualification({
          user: user,
          qualification: toAdd.id
        });

        const formattedResponse = {
          id: response.id,
          group: response.qualification.grouping,
          name: response.qualification.name,
          rsu_rating: response.rsu_rating,
          talent_rating: response.talent_rating,
          type: response.qualification.type
        }

        const items = [...qualifications.items];
        items.push(formattedResponse);

        const type = toAdd.type.toLowerCase();
        const counts = {
          ...qualifications.counts,
          [type]: qualifications.counts[type] + 1
        };

        const names = [...qualifications.names];
        names.push(toAdd.name);

        setQualificationIsSaving(false);
        setQualifications((q) => ({...q, items, counts, names}));

        results.push(formattedResponse);
        talentQualifications.setData((d) => ({
          ...d,
          results
        }));

        setSearchResults({
          counts: {
            ...searchResults.counts,
            [type]: searchResults.counts[type] - 1
          },
          items: [...searchResults.items].filter(q => q.name !== formattedResponse.name)
        })

      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
        setQualificationIsSaving(false);
      }
    };

    if (!qualifications.names.includes(toAdd.name)) {
      await call();
    }
  };

  const onDelete = async (toDelete) => {
    setQualificationIsSaving(true);
    const call = async () => {
      try {
        const results = [...talentQualifications.data.results].filter(
          (q) => q.name !== toDelete.name
        );

        talentQualifications.setData((d) => ({
          ...d,
          results
        }));

        await deleteTalentQualificationV2(toDelete.id);
        setQualificationIsSaving(false);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
        talentQualifications.setData((d) => ({
          ...d,
          results: talentQualifications.data.results,
        }));
        setQualificationIsSaving(false);
      }
    };

    await call();
  };

  const onRatingChange = async (id, rating_obj) => {
    const rsu_rating = rating_obj.rsu_rating;
    const talent_rating = rating_obj.talent_rating;
    const call = async () => {
      try {
        const items = [...qualifications.items];

        const i = items.findIndex((q) => q.id === id);
        if (rsu_rating) {
          items[i].rsu_rating = rsu_rating;
        }
        if (talent_rating) {
          items[i].talent_rating = talent_rating;
        }

        setQualifications((q) => ({...q, items }));

        await patchTalentQualification(id, rating_obj);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
        talentQualifications.setData((d) => ({
          ...d,
          results: talentQualifications.data.results,
        }));
      }
    };

    await call();
  };

  const Qualification = ({q, selected, showSelfRatings}) => {
    return (
      <div className="w-full p-2 flex items-start">
        <div className="flex-1 flex flex-col">
          <B2DB className="flex-1">{q.name}</B2DB>
        </div>
        {selected &&
          <div className="flex-1 text-kasmir flex flex-wrap items-center">
            <div className="flex items-center">
              <D1>{$.rsu_rating_label}</D1>:{" "}
              <RatingInput
                value={q.rsu_rating}
                onChange={(v) =>
                  onRatingChange(q.id, {rsu_rating: v}, talentQualifications.setState)
                }
              />
            </div>
            {showSelfRatings && <>/
              <div className="flex items-center">
                <D1>{$.self_rating_label}</D1>:{" "}
                <RatingInput
                  value={q.talent_rating}
                  onChange={(v) =>
                    onRatingChange(q.id, {talent_rating: v}, talentQualifications.setState)
                  }
                />
              </div>
            </>}
          </div>
        }
        <div className="mr-2 ml-auto">
          {selected ?
            <RemoveBtn disabled={qualificationIsSaving}
                       onClick={() => onDelete(q)}/> :
            <OutlinedButton disabled={qualificationIsSaving}
                            onClick={() => onAdd(q)}>
              <UilPlusCircle size="16" className="mr-1"/>
              Add
            </OutlinedButton>
          }
        </div>
      </div>
    )
  }

  const SortButton = () => (
    <button
      onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')}
      className="group focus:outline-none w-6 h-6 ml-1"
    >
      <span
        className={
          "group-focus:ring focus:outline-none appearance-none active:bg-geyser w-6 h-6 rounded flex items-center justify-center " +
          "text-link hover:text-link-dark"
        }
        tabIndex="-1"
      >
        {!sortDirection && <UilDirection size="22" />}
        {sortDirection === "asc" && <UilUp size="22" />}
        {sortDirection === "desc" && <UilDown size="22" />}
      </span>
    </button>
  )

  const updateSearchResults = (searchString) => {
    const res = {
      items: [],
      counts: { all: 0, skill: 0, tool: 0, industry: 0, leadership: 0 },
    };

    const matches = qualificationsOptions.filter(q =>
      q.name?.toLowerCase().includes(searchString.toLowerCase()) &&
      !qualifications.names.includes(q.name)
    );

    if (matches.length) {
      matches.forEach((q) => {
        let t = q.type;
        t = t ? t.toLowerCase() : "skill";
        if (t === "strategy") {
          t = "skill";
        }

        res.counts.all++;
        res.counts[t]++;

        if (type === t || type === "all") {
          res.items.push(q);
        }
      });
    }
    setSearchResults(res);
  };


  useEffect(() => {
    if (searchString.length && qualificationsOptions) {
      updateSearchResults(searchString);
    } else {
      setDirty(false);
      setSearchResults({
        items: [],
        counts: { all: 0, skill: 0, tool: 0, industry: 0, leadership: 0 },
      });
    }
  }, [searchString, type]);

  return (
    <Section id="admin_talent_qualifications" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilAward className="mr-2" size="18" /> {$.qualifications_title}
          </B2DB>
        </div>
        <div className="px-2">
          <div className="w-full relative">
            <DebouncedInput
              onChange={(v) =>setSearchString(v)}
              value={searchString}
              resetValue={''}
              type="text"
              placeholder={$.search_placeholder}
              maxLength="255"
              className="w-full px-3 rounded h-10 w-60 flex items-center font-bold text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-bold focus:outline-none appearance-none border border-geyser focus:border-link focus:border-2"
            />
          </div>
          <div className="flex w-full border-b border-geyser my-1">
            {["all", "skill", "tool", "industry", "leadership"].map((t) => (
              <button
                onClick={() => setType(t)}
                key={t}
                className={
                  "-mb-px text-sm font-bold h-10 flex-1 flex items-center justify-start border-b-2 appearance-none focus:outline-none " +
                  (type === t
                    ? "text-link border-link"
                    : "text-midnight border-transparent")
                }
              >
                {$[`${t}_title`]}
                {!!qualifications.counts[t] && <> ({qualifications.counts[t]})</>}
              </button>
            ))}
          </div>
          <div className="overflow-y-auto h-80">
            {/* Talent Qualifications */}
            {!!qualifications.items.length ?
              qualifications.items.map((q) => (
                <Qualification key={q.id} q={q} selected={true} showSelfRatings={showSelfRatings} />
              )) : (!qualifications.items.length && !searchResults) ?
              <div id="items" className="text-sm text-kasmir my-8 text-center">
                {$.no_items_label}
              </div>
            : <></>}
            {/* Search Options */}
            {!!searchResults?.items?.length && (
              <div className={"bg-link-water pt-2 " + (qualifications.items.length && "border-t-2 border-kasmir")}>
                <div className="w-full px-2 py-1">
                  <B2G>Qualifications Not Added</B2G>
                </div>
                {searchResults?.items.map((q) => (
                  <Qualification key={q.id} q={q} selected={false} showSelfRatings={showSelfRatings} />
                ))}
              </div>
            )}
            {(searchString.length > 0 && !searchResults.items?.length && !dirty) && (
              <div id="search" className="text-sm text-kasmir my-8 text-center">
                {$.no_results_label}
              </div>
            )}
          </div>
          <div className="flex items-center justify-between w-full pt-2 border-t border-geyser">
            <div className="flex items-center">
              <CompactSelect
                placeholder={$.sort_placeholder}
                onChange={(v) => setSort(v)}
                value={sort}
                options={sortOptions}
                components={{
                  SingleValue: ({ data, children, ...rest }) => (
                    <components.SingleValue {...rest}>
                      <span className="font-bold text-kasmir">{$.sort_placeholder}:</span> {children}
                    </components.SingleValue>
                  )
                }}
              />
              <SortButton />
            </div>
            <Checkbox
              onChange={() => setShowSelfRatings(!showSelfRatings)}
              value={showSelfRatings}
            >
              {$.show_self_ratings_label}
            </Checkbox>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default Qualifications;
