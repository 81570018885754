import React, { useEffect, useRef, useMemo } from "react";
import { admin_project_overview as $ } from "strings";
import useCachedFetch from "hooks/useCachedFetch";
import Input from "components/ListEditableNote";
import Select from "components/CompactSelectNote";
import {
  getProject,
  patchCompany,
  patchProjectV2,
  getSenioritiesList,
  postProjectSeniorityV2,
  deleteProjectSeniorityV2,
  patchProjectTalentDescriptionV2
} from "utils/adminApi";
import { Textarea } from "components/Form";
import useFetch from "hooks/useFetch";

const moonlighterOptions = [
  { value: 0, label: $.no_option },
  { value: 1, label: $.yes_option },
  { value: 2, label: $.maybe_option },
];

const onUpdateSeniorities = async (projectId, selectedSeniorities, originalSeniorities) => {
  const oldSeniorityIds = originalSeniorities.map(s => s.seniority);
  const newSeniorityIds = selectedSeniorities ? selectedSeniorities.map(s => s.value) : [];

  const toDelete = oldSeniorityIds.filter((q) => !newSeniorityIds.includes(q));
  const toCreate = newSeniorityIds.filter((q) => !oldSeniorityIds.includes(q));

  let senioritiesData = [...originalSeniorities];
  const call = async () => {
    try {
      for (let s of toDelete) {
        const projectSeniorityId = originalSeniorities.find(val => s === val.seniority).id;
        if (!projectSeniorityId) continue;
        await deleteProjectSeniorityV2(projectSeniorityId);
        senioritiesData = senioritiesData.filter(s => s.id !== projectSeniorityId);
      }

      for (let s of toCreate) {
        if (!s) continue;
        const newSeniority = await postProjectSeniorityV2({
          seniority: s,
          project: projectId
        });
        senioritiesData.push(newSeniority);
      }

    } catch (e) {
      console.error(e);
    }
  };
  await call();
  return senioritiesData;
}

export const onShadowPitchedSave = async (
  {
    availability_hours,
    availability_hours_maximum,
    reflections,
    ideal_rate,
    ideal_rate_maximum,
    maximum_rate,
    minimum_rate,
    seniorities,
    talent_paragraph,
    ...values
  },
  data,
  projectId,
  originalSeniorities
) => {
  const toSendCompany = { reflections };

  const toSendProject = {
    minimum_rate: minimum_rate || 0,
    maximum_rate: maximum_rate || 0,
    ideal_rate: ideal_rate || 0,
    ideal_rate_maximum: ideal_rate_maximum || 0,
    moonlighter: values.moonlighter && values.moonlighter.value,
    availability_hours: availability_hours || null,
    availability_hours_maximum: availability_hours_maximum || null,
    talent_paragraph: talent_paragraph || "",
    stage: 'shadow_pitched'
  };

  if (talent_paragraph?.length) {
    const toSendDescription = { talent_description: talent_paragraph };
    await patchProjectTalentDescriptionV2(projectId, toSendDescription);
  }

  await onUpdateSeniorities(projectId, seniorities, originalSeniorities);
  await patchCompany(data.company.id, toSendCompany);
  const updatedProjectData = await patchProjectV2(projectId, toSendProject);
  return updatedProjectData;
};

export const getShadowPitchedNote = (values) => [
  {
    type: "paragraph",
    children: [
      { text: "Reflections Paragraph: ", bold: true },
      { text: values.reflections || "Not Set" },
    ],
  },
  {
    type: "paragraph",
    children: [
      { text: "Talent Profile: ", bold: true },
      { text: values.talent_paragraph || "Not Set" },
    ],
  },
  {
    type: "table",
    children: [
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Availability Min: ", bold: true },
              {
                text: Number.isInteger(Number(values.availability_hours))
                  ? Number(values.availability_hours) + " hours per week"
                  : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Minimum Bill Rate: ", bold: true },
              {
                text: Number.isInteger(Number(values.minimum_rate))
                  ? "$" + Number(values.minimum_rate) + "/hr"
                  : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Minimum Pay Rate: ", bold: true },
              {
                text: Number.isInteger(Number(values.ideal_rate))
                  ? "$" + Number(values.ideal_rate) + "/hr"
                  : "Not Set",
              },

            ],
          },
        ],
      },
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Availability Max: ", bold: true },
              {
                text: Number.isInteger(
                  Number(values.availability_hours_maximum)
                )
                  ? Number(values.availability_hours_maximum) +
                    " hours per week"
                  : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Maximum Bill Rate: ", bold: true },
              {
                text: Number.isInteger(Number(values.maximum_rate))
                  ? "$" + Number(values.maximum_rate) + "/hr"
                  : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Maximum Pay Rate: ", bold: true },
              {
                text: Number.isInteger(Number(values.ideal_rate_maximum))
                  ? "$" + Number(values.ideal_rate_maximum) + "/hr"
                  : "Not Set",
              }
            ],
          }
        ],
      },
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Moonlighter: ", bold: true },
              {
                text: values.moonlighter ? values.moonlighter.label : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Proposal Document: ", bold: true },
              {
                text: values.proposal_document || "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Seniority: ", bold: true },
              {
                text:
                  values.seniorities && values.seniorities.length
                    ? values.seniorities.map((s) => s.label).join(", ")
                    : "Not Set",
              },
            ],
          },
        ],
      },
    ],
  },
];

const ShadowPitchedForm = ({
  values,
  updateVal,
  projectId,
  setValues,
  setContext
}) => {
  const setupDone = useRef(false);

  const { data, pageState } = useFetch(getProject, projectId);

  const seniorities = useCachedFetch(
    "admin_seniority_options",
    getSenioritiesList,
    projectId
  );

  const seniorityOptions = useMemo(() => {
    const options = [];
    if (seniorities.data && Array.isArray(seniorities.data.results)) {
      seniorities.data.results.forEach((s) => {
        options.push({
          label: s.name,
          value: s.id
        })
      });
    }

    return options;
  }, [seniorities]);

  useEffect(() => {
    if (!setupDone.current && pageState === "loaded") {
      setupDone.current = true;

      setContext(data);
      setValues({
        ...{
          reflections: data.company.reflections,
          talent_paragraph: data.talent_paragraph,
          proposal_document: data.proposal_document,
          availability_hours: data.availability_hours,
          availability_hours_maximum: data.availability_hours_maximum,
          ideal_rate: data.ideal_rate,
          ideal_rate_maximum: data.ideal_rate_maximum,
          maximum_rate: data.maximum_rate,
          minimum_rate: data.minimum_rate,
          seniorities: Array.isArray(data.seniorities)
            ? data.seniorities.map((s) =>
                seniorityOptions.find((o) => o.value === s)
              )
            : typeof data.seniorities === "string"
            ? data.seniorities
                .split("|")
                .map((s) => seniorityOptions.find((o) => o.value === s))
            : null,
          moonlighter:
            moonlighterOptions.find((i) => i.value === data.moonlighter) ||
            null,
        },
        ...values,
      });
    }
  }, [data, pageState]);

  if (pageState !== "loaded") {
    return null;
  }

  return (
    <div className="stage-change-form-middle flex flex-col border-l border-r border-link px-2">
      <div className="flex items-center w-full border-t border-link-water">
        <div className="w-full">
          <span className="text-sm font-bold text-kasmir">
            {$.reflections_label}
          </span>
          <div>
            <Textarea
              smallest
              borderless
              className="px-0 py-0 rounded font-normal text-kasmir text-sm bg-white placeholder-text-kasmir placeholder:font-normal focus:outline-none appearance-none border border-link-water w-full"
              placeholder={$.reflections_placeholder}
              value={values.reflections}
              onChange={(e) => updateVal("reflections", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center w-full border-t border-b mb-2 border-link-water">
        <div className="w-full">
          <span className="text-sm font-bold text-kasmir">
            {$.talent_paragraph_label}
          </span>
          <div>
            <Textarea
              smallest
              borderless
              className="px-0 py-0 rounded font-normal text-kasmir text-sm bg-white placeholder-text-kasmir placeholder:font-normal focus:outline-none appearance-none border border-link-water w-full"
              placeholder={$.talent_paragraph_placeholder}
              value={values.talent_paragraph}
              onChange={(e) => updateVal("talent_paragraph", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center w-full">
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.availability_hours_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              text={
                values.availability_hours
                  ? values.availability_hours + " hours per week"
                  : $.availability_hours_placeholder
              }
              afterText="per week"
              defaultValue={values.availability_hours}
              onChange={(v) => updateVal("availability_hours", Number(v))}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.ideal_rate_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ".", " "].includes(evt.key) &&
                evt.preventDefault()
              }
              text={
                Number.isInteger(Number(values.minimum_rate))
                  ? "$" + values.minimum_rate + "/hr"
                  : $.minimum_rate_placeholder
              }
              beforeText="$"
              afterText="/hr"
              defaultValue={values.minimum_rate}
              onChange={(v) => updateVal("minimum_rate", Number(v))}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.minimum_rate_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ".", " "].includes(evt.key) &&
                evt.preventDefault()
              }
              text={
                Number.isInteger(Number(values.ideal_rate))
                  ? "$" + values.ideal_rate + "/hr"
                  : $.ideal_rate_placeholder
              }
              beforeText="$"
              afterText="/hr"
              defaultValue={values.ideal_rate}
              onChange={(v) => updateVal("ideal_rate", Number(v))}
            />
          </div>
        </div>
      </div>
      <div className="flex items-start w-full">
        <div className="w-1/3 flex items-start">
          <span className="text-sm font-bold text-kasmir">
            {$.availability_hours_maximum_label}
          </span>
          <Input
            type="number"
            text={
              values.availability_hours_maximum
                ? values.availability_hours_maximum + " hours per week"
                : $.availability_hours_maximum_placeholder
            }
            afterText="per week"
            defaultValue={values.availability_hours_maximum}
            onChange={(v) => updateVal("availability_hours_maximum", Number(v))}
          />
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.ideal_rate_maximum_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ".", " "].includes(evt.key) &&
                evt.preventDefault()
              }
              text={
                Number.isInteger(Number(values.maximum_rate))
                  ? "$" + values.maximum_rate + "/hr"
                  : $.maximum_rate_placeholder
              }
              beforeText="$"
              afterText="/hr"
              defaultValue={values.maximum_rate}
              onChange={(v) => updateVal("maximum_rate", Number(v))}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.maximum_rate_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ".", " "].includes(evt.key) &&
                evt.preventDefault()
              }
              text={
                Number.isInteger(Number(values.ideal_rate_maximum))
                  ? "$" + values.ideal_rate_maximum + "/hr"
                  : $.ideal_rate_maximum_placeholder
              }
              beforeText="$"
              afterText="/hr"
              defaultValue={values.ideal_rate_maximum}
              onChange={(v) => updateVal("ideal_rate_maximum", Number(v))}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center w-full">
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.moonlighter_label}
          </span>
          <div className="flex-1 ml-1">
            <Select
              placeholder={$.moonlighter_placeholder}
              value={values.moonlighter}
              onChange={(v) => updateVal("moonlighter", v)}
              options={moonlighterOptions}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.proposal_document_label}
          </span>
          <div className="flex-1 ml-1 truncate">
            <Input
              type="url"
              textClass="truncate"
              text={values.proposal_document || $.proposal_document_placeholder}
              defaultValue={values.proposal_document}
              onChange={(v) => updateVal("proposal_document", v)}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.seniority_label}
          </span>
          <div className="flex-1 ml-1">
            <Select
              placeholder={$.seniority_placeholder}
              value={values.seniorities}
              onChange={(v) => updateVal("seniorities", v)}
              options={seniorityOptions}
              isMulti
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShadowPitchedForm;
