import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { admin_company_note_form as $ } from "strings";
import { Label, Error } from "components/Form";
import { Button } from "components/Buttons";
import { B2DB } from "components/Typography";
import { postCompanyNote } from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { useParams } from "react-router-dom";
import Section from "components/Section";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus-circle";
import useConfirmClose from "hooks/useConfirmClose";
import { Prompt } from "react-router-dom";
import { getAdminUser } from "utils/localStorageService";
import { v4 as uuidv4 } from "uuid";
import EmptyResponseError from "errors/EmptyResponseError";
import RichTextEditor, { isBodyEmpty } from "components/RichTextEditor";
import { serialize } from "utils/htmlSerializer";

const NoteForm = ({ setData }) => {
  const alert = useAlert();
  const [isOpen, setIsOpen] = useState(false);
  const { id } = useParams();
  const user = getAdminUser();

  const { handleSubmit, errors, watch, reset, formState, control } = useForm();
  const { canClose, confirmMsg } = useConfirmClose(formState.isDirty);

  const bodyVal = watch("body");

  const onSubmit = async (values) => {
    setIsOpen(false);
    reset({
      body: [{ type: "paragraph", children: [{ text: "" }] }],
    });

    const toSend = {
      ...values,
      body: serialize({ children: values.body }),
      company: id,
      created_by_id: user.id,
      created_by: user.first_name + " " + user.last_name,
    };

    const tmpId = uuidv4();
    setData((data) => ({
      ...data,
      results: [
        ...data.results,
        {
          ...toSend,
          id: tmpId,
          created_on: new Date().toISOString(),
          isTmp: true,
        },
      ],
    }));

    const call = async () => {
      try {
        const response = await postCompanyNote(toSend);
        if (response && response.id) {
          setData((data) => ({
            ...data,
            results: [...data.results.filter((n) => n.id !== tmpId), response],
          }));
        } else {
          throw new EmptyResponseError(
            `Unexpected response while saving the note. Response: ${JSON.stringify(
              response
            )}. Request: ${JSON.stringify(toSend)}`
          );
        }
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    };

    await call();
  };

  const isEmpty = isBodyEmpty(bodyVal);

  return (
    <Section className="bg-white px-4 py-6 mb-6" id="admin_company_note_form">
      <div className="pr-4 flex-1">
        <div className="flex items-center">
          <B2DB className="flex flex-1 items-center">
            <UilPlus className="mr-2" size="18" /> {$.note_form_title}
          </B2DB>
        </div>
        <div className="">
          <form
            className="flex flex-col flex-nowrap flex-1 min-h-0"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <Prompt when={formState.isDirty} message={confirmMsg} />
            <div className="-mr-8 flex-1 min-h-0 overflow-y-auto py-4">
              <div className="flex flex-wrap">
                <div className="w-full pr-8">
                  <Label>{$.body_input}</Label>
                  <Controller
                    defaultValue={[
                      { type: "paragraph", children: [{ text: "" }] },
                    ]}
                    control={control}
                    rules={{ required: $.validation_required }}
                    as={<RichTextEditor />}
                    onFocusChange={(v) => setIsOpen(v)}
                    tabIndex={0}
                    placeholder={$.body_placeholder}
                    name="body"
                    error={errors.body}
                  />
                  {errors.body && <Error>{errors.body.message}</Error>}
                </div>
              </div>
            </div>
            {isOpen && (
              <div className="flex items-center justify-end">
                <div className="flex items-center">
                  <Button
                    secondary
                    onClick={() => {
                      if (canClose()) {
                        reset({
                          body: [
                            { type: "paragraph", children: [{ text: "" }] },
                          ],
                        });
                        setIsOpen(false);
                      }
                    }}
                    className="mr-4"
                  >
                    {$.cancel_button}
                  </Button>
                  <Button type="submit" disabled={isEmpty || formState.isSubmitting}>
                    {$.save_button}
                  </Button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </Section>
  );
};

export default NoteForm;
