import React, { useEffect, useRef } from "react";
import { Search } from "components/Form";
import { global as $ } from "strings";
import useScript from "hooks/useScript";
import axios from "axios";

const addressComponents = {
  street_number: "street_number",
  route: "street",
  neighborhood: "neighborhood",
  sublocality_level_1: "locality",
  locality: "city",
  administrative_area_level_1: "state",
  country: "country",
  postal_code: "zipcode",
};
const mapAddress = (place, timezone, full_address) => {
  const address = {
    location_id: place.place_id,
    street_number: "",
    street: "",
    neighborhood: "",
    locality: "",
    city: "",
    state: "",
    state_code: "",
    country: "",
    zipcode: "",
    timezone_id: timezone ? timezone.timeZoneId : "",
    timezone_name: timezone ? timezone.timeZoneName : "",
    full_address,
  };

  place.address_components.forEach((add) => {
    const type = add.types[0];

    if (addressComponents[type]) {
      address[addressComponents[type]] = add.long_name;

      if (addressComponents[type] === "state") {
        address["state_code"] = add.short_name;
      }
    }
  });

  return address;
};

const MapSearch = ({ onChange, defaultValue, defaultFullAddress, error }) => {
  const defaultLocationId =
    typeof defaultValue === "object" && defaultValue !== null
      ? defaultValue.location_id
      : defaultValue;

  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}&libraries=places`
  );

  const mapLoaded = useRef();

  const mapRef = useRef();
  const wrapperRef = useRef();
  const inputRef = useRef();
  const markerRef = useRef();

  useEffect(() => {
    const handle = async (place) => {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/timezone/json?timestamp=${Math.round(
          new Date() / 1000
        )}&location=${place.geometry.location.lat()},${place.geometry.location.lng()}&key=${
          process.env.REACT_APP_GOOGLE_KEY
        }`
      );
      onChange(mapAddress(place, response.data, inputRef.current?.value));
    };

    if (loaded && !mapLoaded.current) {
      const map = new window.google.maps.Map(mapRef.current, {
        center: { lat: 37.775528330508756, lng: -122.42052546044923 },
        zoom: 12,
        options: {
          streetViewControl: false,
          scaleControl: false,
          mapTypeControl: false,
          panControl: false,
          zoomControl: false,
          rotateControl: false,
          fullscreenControl: false,
        },
      });

      map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(
        wrapperRef.current
      );

      const autocomplete = new window.google.maps.places.Autocomplete(
        inputRef.current
      );

      autocomplete.bindTo("bounds", map);

      autocomplete.setFields([
        "place_id",
        "address_components",
        "geometry",
        "icon",
        "name",
        "timezone",
      ]);
      autocomplete.setTypes(["geocode"]);

      markerRef.current = new window.google.maps.Marker({
        map: map,
        anchorPoint: new window.google.maps.Point(0, -29),
      });

      autocomplete.addListener("place_changed", () => {
        markerRef.current.setVisible(false);
        var place = autocomplete.getPlace();
        if (!place.geometry) {
          // User entered the name of a Place that was not suggested and
          // pressed the Enter key, or the Place Details request failed.
          return;
        }

        // If the place has a geometry, then present it on a map.
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }
        markerRef.current.setPosition(place.geometry.location);
        markerRef.current.setVisible(true);

        handle(place);
      });

      if (defaultLocationId) {
        const service = new window.google.maps.places.PlacesService(map);
        service.getDetails({ placeId: defaultLocationId }, (result, status) => {
          if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
            return;
          }
          markerRef.current.setVisible(false);
          if (!result.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            return;
          }

          if (result.geometry.viewport) {
            map.fitBounds(result.geometry.viewport);
          } else {
            map.setCenter(result.geometry.location);
            map.setZoom(17);
          }
          markerRef.current.setPosition(result.geometry.location);
          markerRef.current.setVisible(true);
        });
      }

      mapLoaded.current = true;
    }
  }, [loaded, defaultLocationId, onChange]);

  return (
    <>
      <div className="w-full h-full rounded bg-geyser" ref={mapRef}>
        <div ref={wrapperRef} className={"m-2 w-64"}>
          <Search
            maxLength="250"
            error={error}
            ref={inputRef}
            placeholder={$.search_location_placeholder}
            defaultValue={defaultFullAddress || ""}
          />
        </div>
      </div>
    </>
  );
};

export default MapSearch;
