import AlertError from "components/AlertError";
import { Button } from "components/Buttons";
import { Error, Label } from "components/Form";
import Select from "components/Select";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { Controller, useForm } from "react-hook-form";
import { admin_project_clockify as $ } from "strings";
import { global as $$ } from "strings";
import { attachGroupToProjectClockify } from "utils/adminApi";
import { fetchClockifyGroups } from "utils/fetch";
import AlertSuccess from "../../../../../components/AlertSuccess";

const AddGroupForm = ({ data, setData, setStatus }) => {
  const alert = useAlert();
  const [saving, setSaving] = useState(false);

  const { handleSubmit, errors, control, watch } = useForm();

  const allVal = watch();

  const onSubmit = async (values) => {
    setSaving(true);
    const toSend = {
      group_id: values.group_id && values.group_id.value,
      clockify_project_id: data.clockify_id,
    };

    const call = async () => {
      try {
        await attachGroupToProjectClockify(data.id, toSend);
        alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
        setData((d) => ({
          ...d,
          membership_groups: [
            ...(d.membership_groups || []),
            { name: values.group_id && values.group_id.label },
          ],
        }));
        setStatus(null);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
      setSaving(false);
    };

    await call();
  };

  return (
    <div className="w-full">
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="flex flex-wrap flex-1 min-h-0 py-4">
          <div className="w-full">
            <div className="w-full md:w-1/2">
              <div className="w-full mb-3 mt-2">
                <Label>{$.group_id_input}</Label>
                <Controller
                  control={control}
                  name="group_id"
                  defaultValue={null}
                  as={<Select />}
                  error={errors.group_id}
                  placeholder={$.group_id_placeholder}
                  async
                  loadOptions={fetchClockifyGroups}
                  rules={{ required: $.validation_required }}
                  required
                />
                {errors.group_id && <Error>{errors.group_id.message}</Error>}
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Button type="submit" loading={saving} disabled={!allVal.group_id || saving}>
            {saving ? $.saving_btn : $.save_btn}
          </Button>
          <Button secondary onClick={() => setStatus(null)} className="mr-4">
            {$.cancel_btn}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AddGroupForm;
