import React, { useEffect, useState, useMemo } from "react";
import { admin_company_information as $ } from "strings";
import { B1, B2DB, B3B } from "components/Typography";
import { usePageCache } from "contexts/pageCacheContext";
import { useParams } from "react-router-dom";
import UilLinkedIn from "@iconscout/react-unicons/icons/uil-linkedin";
import UilTwitter from "@iconscout/react-unicons/icons/uil-twitter";
import UilFacebook from "@iconscout/react-unicons/icons/uil-facebook";
import UilInstagram from "@iconscout/react-unicons/icons/uil-instagram";
import UilGithub from "@iconscout/react-unicons/icons/uil-github";
import UilExternal from "@iconscout/react-unicons/icons/uil-external-link-alt";
import { EditBtn } from "components/Buttons";
import Section from "components/Section";
import { withHttp } from "utils/str";
import { LoadPlaceholderGroup } from "components/LoadPlaceholder";
import UilBuilding from "@iconscout/react-unicons/icons/uil-building";
import InfoRow from "components/InfoRow";
import UilMarker from "@iconscout/react-unicons/icons/uil-map-marker";
import UilUserCircle from "@iconscout/react-unicons/icons/uil-user-circle";
import InformationForm from "./InformationForm";
import useCachedFetch from "hooks/useCachedFetch";
import { getCompanyLinks } from "utils/adminApi";

const social = {
  linkedin: UilLinkedIn,
  twitter: UilTwitter,
  facebook: UilFacebook,
  instagram: UilInstagram,
  github: UilGithub,
};

const Information = ({ data = {}, setData, hubspotEnabled, onEditClick }) => {
  const [modal, setModal] = useState();
  const { set } = usePageCache();
  const { id } = useParams();

  useEffect(() => {
    set("url_company", `/admin/company/${id}`);
  }, [set, id]);

  const links = useCachedFetch("admin_company_links", getCompanyLinks, id);

  const companyLinks = useMemo(() => {
    const ordered = {};

    if (links.data && Array.isArray(links.data.results)) {
      links.data.results.forEach((l) => {
        ordered[l.name.toLowerCase()] = l.url;
      });
    }

    return ordered;
  }, [links.data]);

  if (!data) {
    return (
      <div className="bg-white px-4 py-6 mb-6">
        <LoadPlaceholderGroup />
      </div>
    );
  }

  return (
    <Section id="admin_company_information">
      <div className="w-full flex flex-col xl:flex-row">
        <div className="w-full">
          <div className="bg-white px-4 py-6 mb-6">
            <div className="mb-4">
              <div className="flex items-center mb-2">
                <B2DB className="flex flex-1 items-center">
                  <UilBuilding size="18" className="mr-1" />
                  {$.overview_title}
                </B2DB>
                <EditBtn colored onClick={() => hubspotEnabled ? onEditClick() : setModal("information")} />
              </div>
            </div>
            {!!data.description && (
              <div className="text-midnight text-sm font-bold relative flex items-center">
                {data.description}
              </div>
            )}
            {!!data.reflections && (
              <div className="text-midnight text-sm font-bold relative flex items-center whitespace-pre-line mt-4">
                {data.reflections}
              </div>
            )}
            <div className="mt-4 mb-4 flex items-center">
              <B3B className="mr-4">
                <a
                  className="flex items-center"
                  href={withHttp(data.website)}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <UilExternal size="18" className="mr-1" />
                  {withHttp(data.website)}
                </a>
              </B3B>
              {links.data &&
                Array.isArray(links.data.results) &&
                links.data.results.map(({ name, url }) => {
                  if (!url) {
                    return null;
                  }
                  const Icon = social[name.toLowerCase()] || UilExternal;
                  return (
                    <B3B className="mr-4" key={name}>
                      <a
                        className="flex items-center"
                        href={withHttp(url)}
                        title={name}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Icon size="18" className="mr-1" />
                        {withHttp(url)}
                      </a>
                    </B3B>
                  );
                })}
            </div>
            <InfoRow title={$.owner_title} Icon={UilUserCircle}>
              <B3B>
                {data.owner &&
                  data.owner.first_name + " " + data.owner.last_name}
              </B3B>
            </InfoRow>
            <InfoRow title={$.client_engagement_title} Icon={UilUserCircle}>
              <B3B>
                {data.client_engagement &&
                  data.client_engagement &&
                  data.client_engagement.first_name +
                    " " +
                    data.client_engagement.last_name}
              </B3B>
            </InfoRow>
            <InfoRow title={$.location_title} Icon={UilMarker}>
              <B1>{data.full_address}</B1>
            </InfoRow>
          </div>
        </div>
      </div>
      {modal === "information" && (
        <InformationForm
          {...{
            data,
            companyLinks,
            linksData: links,
            setModal,
            setData,
            onClose: () => setModal(),
          }}
        />
      )}
    </Section>
  );
};

export default Information;
