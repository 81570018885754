import { useState, useEffect, useCallback } from "react";
import { usePageCache } from "contexts/pageCacheContext";
import { getNext } from "../utils/adminApi";

const ignoreIfNotFound = [
  'admin_talent_agreements'
]

const useCachedFetch = (cacheKey, api, id = "", defaultParams = {}) => {
  const key = cacheKey + "_" + id + "_fetch";
  const { set, cache } = usePageCache();
  const hasCache = !!cache[key];
  const [pageState, setPageState] = useState(hasCache ? "loaded" : "loading");
  const [data, setData] = useState(cache[key] || null);

  useEffect(() => {
    if (id && data) {
      set(key, data);
    }

    return () => {
      if (id && data) {
        set(key, data);
      }
    };
  }, [key, data, id, set]);

  const handleNext = async (response) => {
    try {
      const next = await getNext(response.next);
      setData((d) => ({
        ...d,
        results: d.results.concat(next.results)
      }));
      next.next ? await handleNext(next) : setPageState("loaded");
    } catch (e) {
      setPageState("error");
      console.error(e);
    }
  }

  const fetch = useCallback(() => {
    const load = async () => {
      try {
        const response = await api(id, defaultParams);
        setData(response);
        if (response?.next) {
          await handleNext(response);
        } else {
          setPageState("loaded");
        }
      } catch (e) {
        if (e.response?.status === 404 && ignoreIfNotFound.indexOf(cacheKey) > -1) {
          return;
        } else {
          setPageState("error");
          console.error(e);
        }
      }
    };

    if (!id || !api) {
      return;
    }

    load();
  }, [id, api]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { data, setData, pageState, setPageState, reload: fetch };
};

export default useCachedFetch;
