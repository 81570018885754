import React, { useRef, useState, useEffect } from "react";
import AutosizeInput from "react-input-autosize";
import debounce from "lodash.debounce";

const INPUT_DEBOUNCE_TIME = 250;

const InnerInput = ({
  onChange,
  value,
  index,
  setIndex,
  setCaretPos,
  isLast,
  removeFilter,
  caretPos,
  nextCaretPos,
  currentIndex,
  setNextCaretPos,
  isGlobalAble,
  setUseGlobal,
  setGlobalConfirm,
  globalConfirm,
}) => {
  const [inputValue, setValue] = useState(value || "");
  const debouncedOnChange = useRef();
  const el = useRef();

  useEffect(() => {
    debouncedOnChange.current = debounce(
      (newValue) => onChange(index, newValue),
      INPUT_DEBOUNCE_TIME
    );
  }, [index]);

  useEffect(() => {
    setValue(value);
  }, [value]);

  useEffect(() => {
    if (nextCaretPos !== null && currentIndex === index && el.current) {
      setTimeout(() => {
        el.current?.input.setSelectionRange(nextCaretPos, nextCaretPos);
      }, 50);
      el.current?.input.focus();
      setNextCaretPos(null);
    }
  }, [nextCaretPos, currentIndex]);

  const handleChange = (e) => {
    const text = e.target.value;
    setValue(text);
    debouncedOnChange.current(text);
  };

  const handleKeyUp = (e) => {
    const key = e.keyCode || e.charCode;
    const newCaretPos = e.target.selectionStart;

    if (isGlobalAble && !globalConfirm && key === 40) {
      setGlobalConfirm(true);
    }

    if (isGlobalAble && globalConfirm && key === 13) {
      setGlobalConfirm(false);
      setUseGlobal(true);
    }

    if (
      caretPos === 0 &&
      newCaretPos === 0 &&
      index > 0 &&
      (key === 8 || key === 46)
    ) {
      removeFilter(index - 1);
    } else {
      setCaretPos(newCaretPos);
    }
  };

  return (
    <AutosizeInput
      ref={el}
      onFocus={() => {
        setIndex(index);
      }}
      autoComplete="off"
      onMouseUp={(e) => setCaretPos(e.target.selectionEnd)}
      className={"mx-1 " + (isLast ? " flex-1" : "")}
      name={"search_" + index}
      onChange={handleChange}
      value={inputValue}
      onKeyUp={handleKeyUp}
      inputStyle={{
        minWidth: isLast ? "100%" : ".4rem",
        backgroundColor: "transparent",
        outline: "none",
        paddingLeft: isLast ? ".3rem" : "",
        paddingRight: isLast ? ".3rem" : "",
        height: "2rem",
      }}
    />
  );
};

export default InnerInput;
