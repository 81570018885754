import React, { useEffect } from "react";
import { admin_settings as $ } from "strings";
import { H1B } from "components/Typography";
import { NavLink, Switch, Route } from "react-router-dom";
import Section from "components/Section";
import Email from "./Email";

const Page = () => {
  useEffect(() => {
    document.title = "Pipeline - Settings";
  }, []);

  return (
    <Section id="admin_settings">
      <div className="bg-white pt-4 pb-4 px-4 mb-6 mt-4">
        <H1B className="text-center md:text-left">{$.title}</H1B>
      </div>
      <div className="flex flex-col lg:flex-row w-full mt-4">
        <div className="bg-white p-2 md:mr-6 mb-6 flex flex-row lg:flex-col overflow-x-auto flex-shrink-0">
          <NavLink
            to="/admin/settings/email"
            className="-mb-px rounded text-sm px-2 font-bold h-10 min-w-32 flex items-center justify-start text-midnight border-b-2 border-transparent"
            activeClassName="bg-link-lighter text-link"
          >
            {$.email_link}
          </NavLink>
        </div>
        <div className="flex-1 overflow-x-auto">
          <Switch>
            <Route path="/admin/settings/email">
              <Email />
            </Route>
          </Switch>
        </div>
      </div>
    </Section>
  );
};

export default Page;
