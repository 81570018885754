import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { admin_project_contract_details as $ } from "strings";
import { global as $$ } from "strings";
import { Header, Footer } from "components/Card";
import { Input, Label, Error } from "components/Form";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { postPaymentDeadline, postInvoiceFrequency } from "utils/adminApi";
import AlertSuccess from "../../../../../components/AlertSuccess";

const apiList = {
  payment_deadline: postPaymentDeadline,
  invoice_frequency: postInvoiceFrequency,
};

const ContractDetailsOptionForm = ({ onClose, id, onSave }) => {
  const alert = useAlert();
  const [sending, setSending] = useState(false);

  const { handleSubmit, register, errors, formState } = useForm();

  const onSubmit = async (values) => {
    setSending(true);

    const call = async () => {
      try {
        const response = await apiList[id](values);
        alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
        onSave(id, {
          [id]: response,
          value: response.id,
          label: response.name,
        });
        onClose(true);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
        setSending(false);
      }
    };

    await call();
  };

  return (
    <div className="z-max absolute">
      <Modal
        isDirty={formState.isDirty}
        onClose={onClose}
        id="admin_project_contract_details_option"
      >
        <form
          className="flex flex-col flex-nowrap flex-1 min-h-0"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <Header title={$[id + "_title"]} />
          <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
            <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
              <Label>{$.name_input}</Label>
              {id !== "payment_deadline" && (
                <Input
                  name="name"
                  maxLength="150"
                  ref={register({ required: $.validation_required })}
                  error={errors.name}
                />
              )}
              {id === "payment_deadline" && (
                <Input
                  name="name"
                  type="number"
                  step="1"
                  min="1"
                  max="120"
                  maxLength="3"
                  ref={register({
                    required: $.validation_required,
                    pattern: {
                      value: /^[0-9]+$/i,
                      message: $.validation_int,
                    },
                    min: { value: 1, message: $.validation_min },
                    max: { value: 120, message: $.validation_max_120 },
                  })}
                  error={errors.name}
                />
              )}
              {errors.name && <Error>{errors.name.message}</Error>}
            </div>
          </div>
          <Footer>
            <Button secondary onClick={onClose} className="mr-4">
              {$.cancel_button}
            </Button>
            <Button type="submit" loading={sending} disabled={sending}>
              {sending ? $.sending_button : $.save_button}
            </Button>
          </Footer>
        </form>
      </Modal>
    </div>
  );
};

export default ContractDetailsOptionForm;
