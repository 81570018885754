import React, { useState, useEffect } from "react";
import InputMask from "comigo-tech-react-input-mask";

const PhoneEditable = ({
  text,
  defaultValue,
  onChange,
  inputClass = "",
  inputProps,
  type,
  style,
  disabled,
  ...props
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleKeyDown = (e) => {
    const { key } = e;
    const keys = ["Escape", "Tab", "Enter"];

    if (keys.indexOf(key) > -1) {
      setIsEditing(false);

      if (key !== "Escape") {
       handleSave();
      }
    }
  };

  const handleChange = (e) => {
    const unmaskedPhone = e.target.value.replace(/[^\d]/g, '');
    setValue(unmaskedPhone);
  }

  const handleSave = () => {
    if (value && value.length < 10) {
      setError("10-digit number required");
      return;
    } else if (!value) {
      setIsEditing(false);
      setError(null);
      return;
    } else {
      onChange(value);
      setIsEditing(false);
      setError(null);
    }
  }

  return (
    <div className="flex flex-col" {...props}>
      {isEditing ? (
        <div
          onBlur={() => {
            handleSave();
          }}
          onKeyDown={(e) => handleKeyDown(e)}
        >
          <InputMask
            className="px-0 py-0 h-5 rounded text-left font-medium text-sm bg-white placeholder-text-kasmir placeholder:font-medium focus:outline-none appearance-none border border-link-water"
            mask="(999)-999-9999"
            placeholder="(###) ###-####"
            onChange={(e) => {
              handleChange(e)
            }}
            value={value}
          />
          {error && (
            <div className="flex items-center text-xs text-red">
              {error}
            </div>
          )}
        </div>
      ) : (
        <div
          onClick={() => {
            if (disabled) return;
            setIsEditing(true);
            setValue(defaultValue);
          }}
          className={
            "text-sm font-bold h-5 cursor-pointer " + style +
            (!defaultValue
              ? "text-kasmir"
              : "text-midnight")
          }
        >
          {text || `(###) ###-####`}
        </div>
      )}
    </div>
  );
};

export default PhoneEditable;
