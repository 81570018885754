import React, { useState } from "react";
import { admin_project_overview as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import UilPlusCircle from "@iconscout/react-unicons/icons/uil-plus-circle";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import Header from "components/ListHeader";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope";
import UilCalender from "@iconscout/react-unicons/icons/uil-calender";
import UilBill from "@iconscout/react-unicons/icons/uil-bill";
import UilClock from "@iconscout/react-unicons/icons/uil-clock";
import LoadPlaceholder from "components/LoadPlaceholder";
import { getAccount, getProjectContract } from "utils/adminApi";
import useCachedFetch from "hooks/useCachedFetch";
import { toFormat } from "utils/date";
import { Link } from "react-router-dom";

const ActiveTalent = ({ data }) => {
  const [open, setOpen] = useState(true);

  const contract = useCachedFetch(
    "admin_project_contract",
    getProjectContract,
    data.id
  );

  let candidateId = null;
  let contractData = null;
  if (contract.pageState === "loaded" && contract.data.results.length > 0) {
    contractData = contract.data.results[0];
    candidateId = contractData.candidate;
  }
  const candidate = useCachedFetch(
    "admin_project_contract_candidate",
    getAccount,
    candidateId
  );

  if (!candidateId || !contractData) {
    return null;
  }

  return (
    <Section id="admin_project_overview" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilPlusCircle className="mr-2" size="18" /> {$.active_talent_title}
          </B2DB>
          <div className="cursor-pointer" onClick={() => setOpen(!open)}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && (
          <div className="px-2 pb-4">
            {candidate.data && candidateId && contract.pageState === "loading" && (
              <div className="flex-1 border border-link-water rounded px-6 pt-6">
                <LoadPlaceholder className="w-40 h-4 my-4" />
                <LoadPlaceholder className="w-40 h-4 mb-4" />
                <LoadPlaceholder className="w-20 h-4 mb-2" />
              </div>
            )}
            {contractData && (
              <>
                <div className="w-full flex">
                  <div className="w-1/2">
                    <Header Icon={UilEnvelope} text={$.talent_name_title} />
                    <div className="text-sm text-midnight font-bold">
                      {candidate.data && candidate.data.user &&
                        <Link
                          to={`/admin/talent/${candidate.data.user.id}/overview`}
                          className="text-sm text-midnight font-bold"
                        >
                          {candidate.data.user.first_name} {candidate.data.user.last_name}
                        </Link>
                      }
                    </div>
                  </div>
                  <div className="w-1/2">
                    <Header Icon={UilCalender} text={$.start_date_title} />
                    <div className="text-sm text-midnight font-bold">
                      {contractData.start_date ? (
                        toFormat(
                          new Date(contractData.start_date),
                          "MMM dd, yyyy"
                        )
                      ) : (
                        <>Not specified</>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full flex mt-2">
                  <div className="w-1/2">
                    <Header Icon={UilBill} text={$.pay_rate_title} />

                    {contractData.pay_rate && (
                      <div className="text-sm text-midnight font-bold">
                        ${contractData.pay_rate} per hour
                      </div>
                    )}
                  </div>
                  <div className="w-1/2">
                    <Header Icon={UilClock} text={$.clockify_hours_title} />
                    <div className="text-sm text-midnight font-bold"></div>
                  </div>
                </div>
                <div className="w-full mt-2">
                  <Header Icon={UilBill} text={$.bill_rate_title} />
                  {contractData.rate && (
                    <div className="text-sm text-midnight font-bold">
                      ${contractData.rate} per hour
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </Section>
  );
};

export default ActiveTalent;
