import React, {useEffect, useState} from "react";
import { global as $ } from "strings";
import * as Sentry from "@sentry/react";

/**
 * Special content to render an exception.
 * @param {string} message - The message title of the alert.
 * @param {callback} onRetry - If the error should display a retry button provide a callback.
 * @param {string} error - The exception object.
 */
const AlertError = ({ message, onRetry, error }) => {
  const [details, setDetails] = useState(false);
  const [errorInfo, setErrorInfo] = useState(message || $.default_error_message);

  useEffect(() => {
    // fixme: temporary so that we can track most common errors so that we can improve the error messages
    let msg;
    if (error?.response) {
      msg = `(${error.response.status}) ${error.response.config.url} [${error.response.config.method}]
${JSON.stringify(error.response.data)}`;
    } else {
      msg = `ERROR: ${JSON.stringify(error)}`;
    }
    Sentry.captureMessage(msg);
  }, [error]);

  useEffect(() => {
    if (!message && error && error.response && error.response.data) {
      if (typeof error.response.data === "string") {
        setErrorInfo(error.response.data);
      } else if (typeof error.response.data === "object") {
        if (typeof error.response.data[0] === "string") {
          setErrorInfo(error.response.data[0]);
        } else if (error.response.data.message) {
          setErrorInfo(error.response.data.message);
        } else if (error.response.data.status) {
          setErrorInfo(error.response.data.status);
        } else if (error.response.data.detail) {
          setErrorInfo(error.response.data.detail);
        } else if (error.response.data[0]) {
          setErrorInfo(JSON.stringify(error.response.data[0]));
        } else {
          setErrorInfo(JSON.stringify(error.response.data));
        }
      }
    }
  }, [error, message]);

  return (
    <div className="w-70">
      <div className="flex items-center">
        {errorInfo}
        {onRetry && (
          <button
            className="font-bold mx-2 rounded border border-red hover:bg-red hover:text-white px-2 py-1"
            onClick={onRetry}
          >
            {$.error_retry_button}
          </button>
        )}
      </div>
      {error && (<>
        <button
          className="mx-2 text-red mb-2 outline-none focus:outline-none text-sm"
          onClick={() => setDetails(!details)}
        >
          {details ? $.error_less_button : $.error_more_button}
        </button>
        {details && (
          <div className="p-1 bg-pink-100 font-mono text-xs font-medium overflow-auto max-h-alert w-70">
            {error.message}
            <br />
            <br />
            {error.stack}
            <br />
            <br />
            {errorInfo}
          </div>
        )}
      </>)}
    </div>
  );
};

export default AlertError;
