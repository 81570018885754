import React, { useState } from "react";
import { admin_project_requirements_preferences as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import Header from "components/ListHeader";
import UilClock from "@iconscout/react-unicons/icons/uil-clock";
import UilStopwatch from "@iconscout/react-unicons/icons/uil-stopwatch";
import UilPin from "@iconscout/react-unicons/icons/uil-map-pin";
import UilUserSquare from "@iconscout/react-unicons/icons/uil-user-square";
import UilCheck from "@iconscout/react-unicons/icons/uil-check";
import {
  getProjectPreferencesV2,
  patchProjectPreferencesV2,
} from "utils/adminApi";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import Indicator from "components/ListIndicator";
import Select from "components/CompactSelect";
import useCachedFetch from "hooks/useCachedFetch";
import UilInfoCircle from "@iconscout/react-unicons/icons/uil-info-circle";

const boolOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const moonlighterOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
  { value: 2, label: "Maybe" },
];

const workWindowsOptions = [
  { value: "morning", label: $.morning_option },
  { value: "afternoon", label: $.afternoon_option },
  { value: "night", label: $.night_option },
  { value: "weekend", label: $.weekend_option },
  { value: "anytime", label: $.anytime_option },
];

const Preferences = ({ projectId, hubspotInputsDisabled, onDisabledClick }) => {
  const [open, setOpen] = useState(false);
  const alert = useAlert();
  const [states, setStates] = useState({});

  const { data, setData } = useCachedFetch(
    "admin_project_preferences_v2",
    getProjectPreferencesV2,
    projectId
  );

  const onChange = async (key, value) => {
    const origVal = data[key];

    setData({ ...data, [key]: value });

    const call = async () => {
      try {
        await patchProjectPreferencesV2(projectId, { ...data, [key]: value });
        setStates({ ...states, [key]: "saved" });
      } catch (e) {
        setStates({ ...states, [key]: "error" });
        setData({ ...data, [key]: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const onInputClick = () => {
    if (hubspotInputsDisabled && onDisabledClick) {
      onDisabledClick();
    }
  }

  return (
    <Section id="admin_project_requirements_preferences" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilInfoCircle className="mr-2" size="18" /> {$.title}
          </B2DB>
          <div className="cursor-pointer" onClick={() => setOpen(!open)}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && (
          <div className="px-2 pb-4">
            <div className="w-full flex">
              <div className="w-1/2" onClick={onInputClick}>
                <Header Icon={UilStopwatch} text={$.work_windows_title}>
                  <Indicator state={states.onsite_projects} />
                </Header>
                <Select
                  value={
                    Array.isArray(data.work_windows)
                      ? data.work_windows.map((w) =>
                          workWindowsOptions.find(
                            (o) => o.value === w.work_window
                          )
                        )
                      : { label: "None Set", value: null }
                  }
                  isMulti
                  onChange={(v) =>
                    onChange(
                      "work_windows",
                      v ? v.map((val) => ({ work_window: val.value })) : []
                    )
                  }
                  options={workWindowsOptions}
                  isDisabled={hubspotInputsDisabled}
                />
              </div>
              <div className="w-1/2" onClick={onInputClick}>
                <Header Icon={UilPin} text={$.onsite_title}>
                  <Indicator state={states.onsite_projects} />
                </Header>
                <Select
                  value={
                    data.onsite_projects || data.onsite_projects === false
                      ? {
                          label: data.onsite_projects ? "Yes" : "No",
                          value: data.onsite_projects,
                        }
                      : { label: "-", value: null }
                  }
                  onChange={(v) =>
                    onChange("onsite_projects", v ? v.value : null)
                  }
                  options={boolOptions}
                  isDisabled={hubspotInputsDisabled}
                />
              </div>
            </div>
            <div className="w-full flex mt-2">
              <div className="w-1/2" onClick={onInputClick}>
                <Header Icon={UilClock} text={$.full_time_title}>
                  <Indicator state={states.fulltime_interest} />
                </Header>
                <Select
                  value={
                    data.fulltime_interest || data.fulltime_interest === false
                      ? {
                          label: data.fulltime_interest ? "Yes" : "No",
                          value: data.fulltime_interest,
                        }
                      : { label: "-", value: null }
                  }
                  onChange={(v) =>
                    onChange("fulltime_interest", v ? v.value : null)
                  }
                  options={boolOptions}
                  isDisabled={hubspotInputsDisabled}
                />
              </div>
              <div className="w-1/2" onClick={onInputClick}>
                <Header Icon={UilUserSquare} text={$.moonlighter_title}>
                  <Indicator state={states.moonlighter} />
                </Header>
                <Select
                  value={
                    moonlighterOptions.find(
                      (o) => o.value === data.moonlighter
                    ) || { label: "-", value: null }
                  }
                  onChange={(v) => onChange("moonlighter", v ? v.value : null)}
                  options={moonlighterOptions}
                  isDisabled={hubspotInputsDisabled}
                />
              </div>
            </div>
            <div className="w-full flex mt-2">
              <div className="w-full">
                <Header
                  Icon={UilCheck}
                  text={$.background_check_required_title}
                >
                  <Indicator state={states.background_check_required} />
                </Header>
                <Select
                  value={
                    data.background_check_required ||
                    data.background_check_required === false
                      ? {
                          label: data.background_check_required ? "Yes" : "No",
                          value: data.background_check_required,
                        }
                      : { label: "-", value: null }
                  }
                  onChange={(v) =>
                    onChange("background_check_required", v ? v.value : null)
                  }
                  options={boolOptions}
                  isDisabled={hubspotInputsDisabled}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </Section>
  );
};

export default Preferences;
