import React, { useState, useEffect } from "react";
import { admin_talent_project as $ } from "strings";
import LoadPlaceholder from "components/LoadPlaceholder";
import ProjectListItem from './ProjectListItem';
import ProjectListActions from "./ProjectListActions";
import { orderBy } from "lodash";
import UilAngleDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilAngleUp from "@iconscout/react-unicons/icons/uil-angle-up";

const projectGroupings = [
  { label: $.active_projects_label, value: "active" },
  { label: $.candidate_projects_label, value: "candidate" },
  { label: $.completed_projects_label, value: "completed" },
  { label: $.rejected_projects_label, value: "rejected" },
  { label: $.inactive_projects_label, value: "inactive" }
];

const ProjectList = ({ projects, onSelectProject, onUpdate, loading }) => {
  const [groupedProjects, setGroupedProjects] = useState();

  const groupProjects = (projects) => {
    const grouped = {
      'candidate': [],
      'active': [],
      'completed': [],
      'rejected': [],
      'inactive': [],
    };

    const ordered = orderBy(projects, 'project_name', 'asc');

    ordered.forEach((p) => {
      const cpDisallowedProjectStages = ['passive', 'filled', 'lost', 'completed'];
      const cpDisallowedCandidateStatuses = ['placed', 'pass'];
      if (p.status === 'pass') {
        grouped['rejected'].push(p);
      } else if (p.status === 'placed' && p.project_stage === 'filled') {
        grouped['active'].push(p);
      } else if (p.status === 'placed' && p.project_stage === 'completed') {
        grouped['completed'].push(p);
      } else if (
        !cpDisallowedProjectStages.includes(p.project_stage) &&
        !cpDisallowedCandidateStatuses.includes(p.status)
      ) {
        grouped['candidate'].push(p);
      } else {
        grouped['inactive'].push(p);
      }
    })

    setGroupedProjects(grouped);
  }

  useEffect(() => {
    groupProjects(projects);
  }, [projects]);

  const ProjectGroup = ({ status }) => {
    const [open, setOpen] = useState(status.value === 'rejected' || status.value === 'inactive' ? false : true);
  
    return (
      <div id={`project_group_${status.value}`} className="m-1">
        <div className="flex items-center text-sm text-midnight font-bold font-bold">
          {status.label}
          {open ? 
            <UilAngleDown className="cursor-pointer" onClick={() => setOpen(!open)} /> :
            <UilAngleUp className="cursor-pointer" onClick={() => setOpen(!open)} />
          }
        </div>
        {open && <div>
          {groupedProjects[status.value]?.length ?
            groupedProjects[status.value].map((project) =>
              <ProjectListItem
                key={project.id}
                project={project}
                onSelectProject={onSelectProject}
                clickable={true}
              />
            ) :
            <NoProjects text={`No ${status.label}`} />
          }
        </div>}
      </div>
    )
  }

  const NoProjects = ({ text }) => (
    <div className="flex items-center">
      <div className="border-t border-geyser flex-1"></div>
      <div className="mx-2 text-sm font-normal italic text-midnight">{text}</div>
      <div className="border-t border-geyser flex-1 flex-no-shrink"></div>
    </div>
  );

  return (
    <div className="candidate_list pb-10">
      <ProjectListActions projects={projects} onSuccess={onUpdate} />
      {(loading || !groupedProjects) ?
        <div className="flex-1 px-6 pt-6">
          <LoadPlaceholder className="w-60 h-4 my-4" />
          <LoadPlaceholder className="w-60 h-4 mb-4" />
          <LoadPlaceholder className="w-40 h-4 mb-2" />
        </div> :
          <>
            {projectGroupings.map((status, i) => 
              <ProjectGroup status={status} key={i} /> 
            )}
          </>
        }
    </div>
  );
};

export default ProjectList;