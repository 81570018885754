import React from "react";
import Select from "./ListSearchSelect";
import FilterDeleteBtn from "./FilterDeleteBtn";

const FilterSelect = ({
  filter,
  updateSearch,
  setIndex,
  index,
  isSelected,
  removeFilter,
}) => {
  return !isSelected ? (
    <div
      onClick={() => setIndex(index)}
      className="bg-white border flex items-center border-link rounded h-8 px-1 text-link text-sm font-bold"
    >
      {filter.title}:{" "}
      {filter.data
        ? !filter.isMulti
          ? filter.data.label
          : Array.isArray(filter.data) && filter.data.length > 0
          ? filter.data.map((v) => v.label).join(", ")
          : ""
        : ""}
      <FilterDeleteBtn onClick={() => removeFilter(index)} />
    </div>
  ) : (
    <div className="bg-white border items-center border-link rounded-t text-sm flex flex-col">
      <div className="h-8 flex items-center w-full min-w-40">
        <Select
          autoFocus
          menuIsOpen
          placeholder={filter.placeholder}
          onChange={(v) => {
            updateSearch(index, v);
            setIndex(0, false);
          }}
          value={filter.data || []}
          {...filter.selectProps}
          isMulti={filter.isMulti}
        />
      </div>
    </div>
  );
};

export default FilterSelect;
