import AlertError from "components/AlertError";
import { Button } from "components/Buttons";
import { Error, Input, Label } from "components/Form";
import Select from "components/Select";
import React, { useState, useRef } from "react";
import { useAlert } from "react-alert";
import { Controller, useForm } from "react-hook-form";
import { admin_project_clockify as $ } from "strings";
import { global as $$ } from "strings";
import { createProjectClockify, postClockifyClient } from "utils/adminApi";
import { fetchClockifyClients } from "utils/fetch";
import AlertSuccess from "../../../../../components/AlertSuccess";

const typeOptions = [
  { value: "Offline", label: $.offline_option },
  { value: "Amazon", label: $.amazon_option },
  { value: "RightPercent", label: $.right_percent_option },
  { value: "Digital", label: $.digital_option },
  { value: "Creative", label: $.creative_option },
  { value: "Internal", label: $.internal_option },
  { value: "Affiliate", label: $.affiliate_option },
];

const CreateProjectForm = ({ data, setData, setStatus }) => {
  const alert = useAlert();
  const lastClient = useRef();
  const [checking, setChecking] = useState(false);
  const [saving, setSaving] = useState(false);
  const [isAddingClient, setIsAddingClient] = useState(false);

  const { handleSubmit, register, errors, control, watch } = useForm();

  const clientNameVal = watch("client_name");
  const allVal = watch();

  const onSubmit = async (values) => {
    if (isAddingClient) {
      setSaving(true);
      const toSend = {
        name: values.client_name,
        id: data?.company?.id,
        project_id: data?.id
      };

      const call = async () => {
        try {
          const response = await postClockifyClient(toSend);
          alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
          lastClient.current = {
            value: response.clockify_id,
            label: values.client_name,
            client: {
              id: response.clockify_id,
              name: values.client_name,
            },
          };
          setIsAddingClient(false);
        } catch (e) {
          console.error(e);
          alert.error(<AlertError error={e} onRetry={call} />);
        }
        setSaving(false);
      };

      await call();
    } else {
      setChecking(true);
      const toSend = {
        name: values.name,
        clientId: values.clientId && values.clientId.value,
        project_type: values.project_type && values.project_type.value,
        billable: true,
      };

      const call = async () => {
        try {
          const response = await createProjectClockify(data.id, toSend);
          setData({ ...data, clockify_id: response.clockify_project_id });
          setStatus(null);
        } catch (e) {
          console.error(e);
          alert.error(<AlertError error={e} onRetry={call} />);
        }
        setChecking(false);
      };

      await call();
    }
  };

  return (
    <div className="w-full">
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="flex flex-wrap flex-1 min-h-0 py-4">
          <div className="w-full">
            <div className="w-full md:w-1/2 mb-3 mt-2">
              <Label>{$.name_input}</Label>
              <Input
                name="name"
                maxLength="150"
                ref={register({
                  required: !isAddingClient ? $.validation_required : false,
                })}
                error={errors.name}
              />
              {errors.name && <Error>{errors.name.message}</Error>}
            </div>
          </div>
          {isAddingClient && (
            <div className="w-full block md:flex md:space-x-4 items-start">
              <div className="w-full md:w-1/2 mb-3 mt-2">
                <Label>{$.client_name_input}</Label>
                <Input
                  name="client_name"
                  maxLength="150"
                  defaultValue={data.company?.name}
                  ref={register({ required: $.validation_required })}
                  error={errors.client_name}
                />
                {errors.client_name && (
                  <Error>{errors.client_name.message}</Error>
                )}
              </div>
              <div className="flex w-full md:w-1/2 items-center space-x-4 mt-7">
                <Button
                  type="submit"
                  loading={saving}
                  disabled={!clientNameVal || saving}
                >
                  {saving ? $.saving_btn : $.save_btn}
                </Button>
                <Button
                  secondary
                  onClick={() => setIsAddingClient(false)}
                  className="mr-4"
                >
                  {$.cancel_btn}
                </Button>
              </div>
            </div>
          )}
          <div className="w-full md:w-1/2">
            <div className="w-full md:w-1/2">
              {!isAddingClient && (
                <div className="w-full mb-3 mt-2">
                  <Label>{$.client_input}</Label>
                  <Controller
                    control={control}
                    name="clientId"
                    defaultValue={
                      lastClient && lastClient.current
                        ? lastClient.current
                        : null
                    }
                    as={<Select />}
                    error={errors.clientId}
                    placeholder={$.client_placeholder}
                    async
                    loadOptions={fetchClockifyClients}
                    footerText={$.add_client_btn}
                    footerOnClick={() => {
                      lastClient.current = allVal.clientId;
                      setIsAddingClient(true);
                    }}
                    rules={{
                      required: !isAddingClient ? $.validation_required : false,
                    }}
                    required={!isAddingClient}
                  />
                  {errors.clientId && <Error>{errors.clientId.message}</Error>}
                </div>
              )}
              <div className="w-full mb-3 mt-2">
                <Label>{$.type_input}</Label>
                <Controller
                  control={control}
                  name="project_type"
                  defaultValue={null}
                  as={<Select />}
                  error={errors.project_type}
                  placeholder={$.type_placeholder}
                  options={typeOptions}
                  rules={{
                    required: !isAddingClient ? $.validation_required : false,
                  }}
                  required={!isAddingClient}
                />
                {errors.project_type && (
                  <Error>{errors.project_type.message}</Error>
                )}
              </div>
              <div className="w-full mb-3 mt-2">
                <Label>{$.billable_input}</Label>
                <span className="text-sm font-bold text-midnight">
                  {$.yes_option}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Button
            type="submit"
            loading={checking}
            disabled={
              isAddingClient ||
              !(allVal.clientId && allVal.name && allVal.project_type) ||
              checking
            }
          >
            {checking ? $.checking_btn : $.save_btn}
          </Button>
          <Button secondary onClick={() => setStatus(null)} className="mr-4">
            {$.cancel_btn}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateProjectForm;
