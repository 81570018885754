import React from "react";
import Section from "components/Section";
import { error as $ } from "strings";
import logoSm from "assets/logo_sm.svg";

const Error = () => (
  <Section
    id="error"
    className="bg-white min-h-full flex items-center justify-center p-6"
  >
    <div className="absolute top-0 left-0 h-16 w-16 ml-12 mt-10">
      <img className="max-w-full max-h-full" src={logoSm} alt="Right Side Up" />
    </div>
    <div className="text-midnight text-3xl font-bold">{$.error_message}</div>
  </Section>
);

export default Error;
