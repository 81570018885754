import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { global as $$ } from "strings";
import { Table, Body, Head, H, D, Row, Footer } from "./Table";
import { Search } from "components/Form";
import { Toggler } from "components/Buttons";
import InfiniteScroll from "react-infinite-scroll-component";
import { toFormat } from "utils/date";
import LoadPlaceholder from "./LoadPlaceholder";
import { usePageCache } from "contexts/pageCacheContext";
import EditColumnsForm from "components/EditColumnsForm";
import useCachedFetch from "hooks/useCachedFetch";
import { getAdminUserId } from "utils/localStorageService";
import { groupBy } from "lodash";

const TableRenderer = ({
  children,
  data,
  schema,
  fetch,
  hasMore,
  $,
  params,
  total,
  name,
  nameKey,
  pageState,
  page,
  showColumns,
  showAll = true,
  ordering,
  direction,
  showFilters,
  search,
  setOrdering,
  setDirection,
  setShowFilters,
  setSearch,
  setNeedsIdSort,
  groupedBy,
  dragBy,
  onRowDrag,
  manager,
}) => {
  const id = "table_" + (nameKey || name);

  const hasFirst = useRef(false);
  const scrollableRef = useRef();
  const user = getAdminUserId();
  const [modal, setModal] = useState();
  const { set, cache } = usePageCache();
  const [dragOver, setDragOver] = useState("");
  const [rowDragOver, setRowDragOver] = useState("");
  const schemaCols = Object.keys(schema);
  const localCols = [
    schemaCols[0],
    ...(localStorage.getItem(id + "_cols") || "")
      .split(",")
      .filter((c) => c !== schemaCols[0]),
  ];

  const customColumns = useCachedFetch(
    id + "_customcolumns",
    manager ? manager.getColumnApi : null,
    user
  );

  let snapshot = [];
  if (customColumns && customColumns.data && customColumns.data.snapshot) {
    try {
      snapshot = JSON.parse(customColumns.data.snapshot);
    } catch (e) {
      console.error(e);
    }
  }

  const [isEdited, setIsEdited] = useState(!!localCols.length);
  const [cols, setCols] = useState(
    localCols
      .filter((i) => schemaCols.indexOf(i) >= 0)
      .concat(schemaCols.filter((i) => localCols.indexOf(i) < 0))
  );

  const initialScroll = cache[id + "_scroll"];

  useLayoutEffect(() => {
    const r = scrollableRef.current;
    if (r) {
      r.scrollTop = initialScroll;
    }

    return () => {
      if (r) {
        set(id + "_scroll", r.scrollTop);
      }
    };
  }, [set, id, initialScroll]);

  useEffect(() => {
    if (isEdited) {
      localStorage.setItem(id + "_cols", cols.join(","));
    }
  }, [cols, id, isEdited]);

  useEffect(() => {
    if (scrollableRef.current && hasFirst.current) {
      scrollableRef.current.scrollTop = 0;
    }
    hasFirst.current = true;
  }, [params, ordering, direction]);

  if (!data) {
    return (
      <>
        <div className="flex px-8 py-6 mb-4">
          <LoadPlaceholder className="w-64 h-4 mr-8" />
          <LoadPlaceholder className="w-40 h-4" />
        </div>
        <div className="flex px-8 py-2 justify-between mb-4">
          <LoadPlaceholder className="w-40 h-4" />
          <LoadPlaceholder className="w-40 h-4" />
          <LoadPlaceholder className="w-40 h-4" />
          <LoadPlaceholder className="w-40 h-4" />
        </div>
        <div className="flex px-8 py-2 justify-between mb-2">
          <LoadPlaceholder className="w-40 h-4" />
          <LoadPlaceholder className="w-40 h-4" />
          <LoadPlaceholder className="w-40 h-4" />
          <LoadPlaceholder className="w-40 h-4" />
        </div>
        <div className="flex px-8 py-2 justify-between">
          <LoadPlaceholder className="w-40 h-4" />
          <LoadPlaceholder className="w-40 h-4" />
          <LoadPlaceholder className="w-40 h-4" />
          <LoadPlaceholder className="w-40 h-4" />
        </div>
        <div className="flex px-8 py-2 justify-between">
          <LoadPlaceholder className="w-40 h-4" />
          <LoadPlaceholder className="w-40 h-4" />
          <LoadPlaceholder className="w-40 h-4" />
          <LoadPlaceholder className="w-40 h-4" />
        </div>
      </>
    );
  }

  const handleSort = (v, needsIdSort) => {
    setNeedsIdSort(needsIdSort);

    if (v === ordering) {
      if (direction === "") {
        setDirection("-");
      } else {
        setOrdering();
        setDirection("");
      }
    } else {
      setOrdering(v);
      setDirection("");
    }
  };

  const canShow = {};
  Object.entries(schema).forEach(([k, s]) => {
    const key = k
      .replace("__gte", "")
      .replace("__lte", "")
      .replace("__gt", "")
      .replace("__lt", "");

    if (customColumns && customColumns.data && customColumns.data.snapshot) {
      if (
        snapshot.includes(key) ||
        snapshot.includes(k) ||
        showColumns.includes(key) ||
        showColumns.includes(k) ||
        (s.type === "range_int" &&
          ((s.min_param && showColumns.includes(s.min_param)) ||
            (s.max_param && showColumns.includes(s.max_param))))
      ) {
        canShow[key] = true;
      }
    } else {
      if (
        showAll ||
        s.show ||
        snapshot.includes(key) ||
        showColumns.includes(key) ||
        (s.type === "range_int" &&
          ((s.min_param && showColumns.includes(s.min_param)) ||
            (s.max_param && showColumns.includes(s.max_param))))
      ) {
        canShow[key] = true;
      }
    }
  });

  const handleDragStart = (e) => {
    const { id } = e.currentTarget;
    if (!id) {
      return;
    }
    e.dataTransfer.setData("colIdx", id);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragEnd = (e) => {
    setDragOver("");
  };

  const handleDragEnter = (e) => {
    const { id } = e.currentTarget;
    if (!id) {
      return;
    }

    setDragOver(id);
  };

  const handleOnDrop = (e) => {
    const { id } = e.currentTarget;
    if (!id) {
      return;
    }
    const draggedId = e.dataTransfer.getData("colIdx");
    const droppedColIdx = cols.indexOf(id);
    const draggedColIdx = cols.indexOf(draggedId);
    const tempCols = [...cols];

    tempCols.splice(droppedColIdx, 0, tempCols.splice(draggedColIdx, 1)[0]);
    setCols(tempCols);
    setIsEdited(true);
    setDragOver("");
  };

  const handleRowDragStart = (e) => {
    const { id } = e.currentTarget;
    if (!id) {
      return;
    }
    e.dataTransfer.setData("rowIdx", id);
  };

  const handleRowDragOver = (e) => {
    e.preventDefault();
  };

  const handleRowDragEnd = (e) => {
    setRowDragOver("");
  };

  const handleRowDragEnter = (e) => {
    const { id } = e.currentTarget;
    if (!id) {
      return;
    }

    setRowDragOver(id);
  };

  const handleRowOnDrop = (e) => {
    const { id } = e.currentTarget;
    if (!id) {
      return;
    }
    const draggedId = e.dataTransfer.getData("rowIdx");
    onRowDrag(id, draggedId);
    setRowDragOver("");
  };

  let groups = [];
  if (groupedBy) {
    groups = groupBy(data, groupedBy);
  }

  return (
    <>
      <div className="mb-8 flex flex-wrap gap-y-4">
        <div className="flex-1 flex flex-wrap gap-y-4">
          <div className="w-64 mr-4">
            <Search
              debounced
              placeholder={$.search_placeholder}
              value={search}
              onChange={(v) => setSearch(v)}
            />
          </div>
          <Toggler
            className="uppercase"
            active={showFilters}
            onClick={() => setShowFilters(!showFilters)}
          >
            {$.filter_button}
          </Toggler>
          {manager && (
            <button
              type="button"
              className="group focus:outline-none h-10 ml-2"
              onClick={() => setModal("edit_columns")}
            >
              <span
                className="group-focus:ring hover:bg-link-water focus:outline-none text-sm font-bold rounded h-10 inline-flex items-center text-midnight justify-center px-4 bg-transparent"
                tabIndex="-1"
              >
                {$$.edit_columns_button}
              </span>
            </button>
          )}
        </div>
        {children}
      </div>
      <InfiniteScroll
        dataLength={data.length}
        next={() => fetch(page + 1, true, null, true, true)}
        hasMore={hasMore}
        scrollableTarget={id}
        style={{ overflow: "hidden" }}
      >
        <div className="flex flex-col rounded-t overflow-hidden -mb-px">
          <div
            id={id}
            ref={scrollableRef}
            className="h-auto max-h-table relative flex-grow overflow-y-auto"
          >
            <Table>
              <Head>
                {cols.map((k, i) => {
                  const s = schema[k];
                  const key = k
                    .replace("__gte", "")
                    .replace("__lte", "")
                    .replace("__gt", "")
                    .replace("__lt", "");

                  return (
                    (canShow[key] || i === 0) &&
                    (i === 0 ? (
                      <H
                        id={k}
                        key={key}
                        className="left-0 shadow-r z-40"
                        sorted={ordering === (s.sortKey || key)}
                        direction={direction}
                        disableSort={s.disableSort}
                        onSort={() =>
                          handleSort(s.sortKey || key, s.needsIdSort)
                        }
                      >
                        {$[`${k}_label`]}
                      </H>
                    ) : (
                      <H
                        id={k}
                        key={key}
                        className="z-30"
                        sorted={ordering === (s.sortKey || key)}
                        direction={direction}
                        disableSort={s.disableSort}
                        onSort={() =>
                          handleSort(s.sortKey || key, s.needsIdSort)
                        }
                        draggable
                        onDragStart={handleDragStart}
                        onDragOver={handleDragOver}
                        onDragEnd={handleDragEnd}
                        onDrop={handleOnDrop}
                        onDragEnter={handleDragEnter}
                        dragOver={k === dragOver}
                      >
                        {$[`${k}_label`]}
                      </H>
                    ))
                  );
                })}
              </Head>
              <Body>
                {!groupedBy &&
                  data.map((r) => (
                    <Row key={r.id}>
                      {cols.map((k, i) => {
                        const s = schema[k];
                        const key = k
                          .replace("__gte", "")
                          .replace("__lte", "")
                          .replace("__gt", "")
                          .replace("__lt", "");

                        return (
                          (canShow[key] || i === 0) && (
                            <D
                              key={key}
                              className={
                                i === 0
                                  ? "sticky shadow bg-white left-0 z-20"
                                  : ""
                              }
                            >
                              {s.renderRow
                                ? s.renderRow({
                                    r,
                                    v: r[key],
                                    f: params[k],
                                  })
                                : s.type === "date" || s.type === "range_date"
                                ? r[key]
                                  ? toFormat(new Date(r[key]), s.rowFormat)
                                  : ""
                                : r[key]}
                            </D>
                          )
                        );
                      })}
                    </Row>
                  ))}
                {groupedBy && groups &&
                  Object.keys(groups).map((groupKey) => 
                    <Row key={groupKey}>
                      {cols.map((k, i) => {
                        const s = schema[k];
                        const key = k
                          .replace("__gte", "")
                          .replace("__lte", "")
                          .replace("__gt", "")
                          .replace("__lt", "");

                        return (
                          (canShow[key] || i === 0) && (
                            <D
                              key={key}
                              className={
                                i === 0
                                  ? "sticky shadow bg-white left-0 z-20"
                                  : ""
                              }
                            >
                              {groups[groupKey].map((r, i) => {
                                if (key === groupedBy || s.groupKey && s.groupKey === groupedBy) {
                                  if (i === 0) {
                                    return s.renderRow({
                                      r,
                                      v: r[key],
                                      f: params[k],
                                    })
                                  }
                                } else {
                                  return (
                                    <div className="py-px">
                                      {s.renderRow
                                        ? s.renderRow({
                                            r,
                                            v: r[key],
                                            f: params[k],
                                          })
                                        : s.type === "date" ||
                                          s.type === "range_date"
                                        ? r[key]
                                          ? toFormat(
                                              new Date(r[key]),
                                              s.rowFormat
                                            )
                                          : ""
                                        : r[key]
                                      }
                                    </div>
                                  )
                                }
                              })}
                            </D>
                          )
                        );
                      })}
                    </Row>
                  )
                }
              </Body>
            </Table>
          </div>
        </div>
      </InfiniteScroll>
      <Footer>
        {pageState === "fetching"
          ? $.loading_label
          : `Showing ${data.length} of ${total || 0} ${name}`}
      </Footer>
      {modal === "edit_columns" && (
        <EditColumnsForm
          postApi={manager.postColumnApi}
          patchApi={manager.patchColumnApi}
          onClose={() => setModal()}
          schema={schema}
          columns={customColumns}
          $={$}
        />
      )}
    </>
  );
};

export default TableRenderer;
