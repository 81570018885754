import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { admin_project_candidate_status_form as $ } from "strings";
import { Header, Footer } from "components/Card";
import { Label, Error, Textarea } from "components/Form";
import Select from "components/Select";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { allowedStatusOptions } from './constants';
import MoveDuplicateToast from "./MoveDuplicateToast";
import { bulkMoveCandidatesV2 } from "utils/adminApi";
import { fetchActiveProjects } from "utils/fetch";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { rejectionReasonOptions } from "./constants";
import UilWarning from "@iconscout/react-unicons/icons/uil-exclamation-triangle";

const MoveDuplicateForm = ({
  onClose,
  onSuccess,
  selectedCandidates,
  selectedStatus,
  setSelectedStatus,
  action
}) => {
  const keepCurrentStatusOption = {label: 'Keep Current Status', value: ''};
  const alert = useAlert();

  const { handleSubmit, register, errors, formState, control, watch } = useForm({
    defaultValues: { description: "" },
  });

  const watchStatus = watch('status');

  useEffect(() => {
    if (!watchStatus || !selectedStatus) return;
    if (watchStatus.value !== selectedStatus && setSelectedStatus) {
      setSelectedStatus(watchStatus.value);
    }
  }, [watchStatus, selectedStatus])

  const onSubmit = async (values) => {
    const toSend = {};
    toSend.project_id = values.project.value;
    toSend.status = values.status?.value?.length ? values.status?.value : null;
    toSend.action = action;
    if (values.primary_rejection_reason?.value) {
      toSend.primary_rejection_reason = values.primary_rejection_reason.value;
      if (values.rejection_reason?.length) {
        toSend.rejection_reason = values.rejection_reason;
      };
      toSend.description = `Rejection Reason: ${values.primary_rejection_reason.label} `
      if (values.rejection_reason?.length) {
        toSend.description += `Note: ${values.rejection_reason}`;
      };
    }

    if (!values.primary_rejection_reason && values.description?.length) {
      toSend.description = values.description;
    };

    try {
      const ids = selectedCandidates.map(c => c.id);
      const response = await bulkMoveCandidatesV2(ids, toSend);
      alert.success(
        <MoveDuplicateToast
          updatedCandidates={response.updated_candidates_count}
          skippedCandidates={response.skipped_candidates_count}
          project={values.project.project}
          action={action}
        />, {timeout: 8000});
      onSuccess();
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={() => onSubmit(values)} />);
    }
  };

  return (
    <Modal
      isDirty={formState.isDirty}
      onClose={onClose}
      id="admin_project_candidate_status_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Header title={`${action === 'move' ? 'Move' : 'Duplicate'} to Another Project`} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          {selectedCandidates?.length > 1 &&
            <div className="flex items-center gap-2 p-2 rounded bg-orange text-sm font-medium">
              <UilWarning size="16" />
              <span>You're {action === 'move' ? 'moving' : 'duplicating'} {selectedCandidates?.length} candidates.</span>
            </div>
          } 
          <div className="w-full pr-8 mb-3 mt-2">
            <Label>{$.project_input}</Label>
            <Controller
              control={control}
              rules={{ required: $.validation_required }}
              name="project"
              as={<Select />}
              error={errors.project}
              placeholder={$.select_option}
              required
              loadOptions={fetchActiveProjects}
              async
              ref={register()}
            />
            {errors.project && <Error>{errors.project.message}</Error>}
          </div>
          <div className="w-full pr-8 mb-3 mt-2">
            <Label>{$.status_input}</Label>
            <Controller
              control={control}
              rules={{ required: $.validation_required }}
              name="status"
              defaultValue={keepCurrentStatusOption}
              as={<Select />}
              error={errors.status}
              placeholder={$.select_option}
              required
              options={[keepCurrentStatusOption, ...allowedStatusOptions]}
              ref={register()}
            />
            {errors.status && <Error>{errors.status.message}</Error>}
          </div>
          {(selectedStatus === 'pass' || watchStatus?.value === 'pass') && (
            <div className="w-full pr-8 mb-3 mt-2">
              <Label>{$.rejection_reason_input}</Label>
              <Controller
                control={control}
                rules={{ required: $.validation_required }}
                name="primary_rejection_reason"
                as={<Select />}
                error={errors.status}
                placeholder={$.select_option}
                required
                options={rejectionReasonOptions}
              />
              {errors.primary_rejection_reason && <Error>{errors.primary_rejection_reason.message}</Error>}
            </div>)
          }
          {(selectedStatus === 'pass' || watchStatus?.value === 'pass') ? 
            <div className="w-full pr-8 mb-3 mt-2">
              <Label>{$.rejection_note_input}</Label>
              <Textarea
                name="rejection_reason"
                ref={register()}
                error={errors.rejection_reason}
              />
              {errors.rejection_reason && <Error>{errors.rejection_reason.message}</Error>}
            </div> :
            <div className="w-full pr-8 mb-3 mt-2">
              <Label>{$.note_description_input}</Label>
              <Textarea
                name="description"
                ref={register()}
                error={errors.description}
              />
              {errors.description && <Error>{errors.description.message}</Error>}
            </div>
          }
        </div>
        <Footer>
          <Button
            secondary
            onClick={() => {
              onClose();
            }}
            className="mr-4"
          >
            {$.cancel_button}
          </Button>
          <Button
            type="submit"
            loading={formState.isSubmitting}
            disabled={formState.isSubmitting}
          >
            {$.save_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default MoveDuplicateForm;
