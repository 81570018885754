import React from "react";
import { admin_project_candidate_detail as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import CandidateProject from './CandidateProject';
import ActiveProject from './ActiveProject';
import CompletedProject from './CompletedProject';
import { capitalize } from "lodash";
import { Sun, Sunrise, Sunset } from 'react-feather';

const CandidateProjects = ({ type, projects, open, onTogglePanel }) => {
  return (
    <Section id="candidate_projects" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            {type === 'active' && <><Sun className="mr-2" size="18" />{$.active_projects_title}</>}
            {type === 'candidate' && <><Sunrise className="mr-2" size="18" />{$.candidate_projects_title}</>}
            {type === 'completed' && <><Sunset className="mr-2" size="18" />{$.completed_projects_title}</>}
          </B2DB>
          <div className="cursor-pointer" onClick={onTogglePanel}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && (
          <div className="px-2 pb-4">
            {!projects.length && (
              <B2DB>
                This candidate has no {capitalize(type)} projects.
              </B2DB>
            )}
            {projects && (projects.map((project, i) => 
              <div key={project.project_id}>
                {type === 'active' && <ActiveProject project={project} />}
                {type === 'candidate' && <CandidateProject project={project} />}
                {type === 'completed' && <CompletedProject project={project} />}
                {i !== projects.length - 1 && <div className="border-t border-geyser flex-1 mt-2" />}
              </div>
            ))}
          </div>
        )}
      </div>
    </Section>
  )
};

export default CandidateProjects;