import React from "react";
import Date from "react-datepicker";
import UilCalendar from "@iconscout/react-unicons/icons/uil-calender";

/**
 * Renders an input, used internally for the DatePicker component, it is not meant to be used alone.
 */
const Input = React.forwardRef(
  ({ value, onClick, error, disabled, placeholder, onClearClick }, ref) => (
    <div className="relative">
      <input
        placeholder={placeholder}
        ref={ref}
        disabled={disabled}
        type="text"
        onClick={onClick}
        value={value}
        readOnly
        className={
          "w-full my-1 pl-8 pr-3 rounded h-10 flex items-center font-bold text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-medium focus:outline-none appearance-none " +
          (error
            ? "border-red border-2"
            : disabled
            ? "bg-link-water cursor-not-allowed"
            : "border border-geyser focus:border-link focus:border-2")
        }
      />
      <button
        type="button"
        onClick={onClick}
        className={
          "absolute left-0 top-0 bottom-0 text-lg flex items-center ml-3 appearance-none cursor-pointer focus:outline-none " +
          (disabled ? "text-geyser" : "text-kasmir")
        }
      >
        <UilCalendar size="16" />
      </button>
    </div>
  )
);

/**
 * Renders an input with a calendar picker.
 * It wrappers react-datepicker (https://reactdatepicker.com/). Can add more props for that library, but this component is customize to support monthly and daily, and some default styles.
 * @param {boolean} error - Flag to indicate error on this input.
 * @param {callback} onChange - Callback to catch new selected values.
 * @param {date} value - Input value, a date.
 * @param {boolean} monthly - Flag to indicate if is a monthly or daily input, by default monthly.
 * @param {object} props - Extra props to pass to the Date component.
 */
const DatePicker = ({ error, onChange, value, monthly = true, ...props }) => {
  return (
    <div className="relative block">
      <Date
        {...props}
        selected={value}
        onChange={onChange}
        showMonthYearPicker={monthly}
        showFullMonthYearPicker={monthly}
        isClearable
        clearButtonClassName="uil uil-times absolute w-4 h-4 right-0 top-0 bottom-0 text-lg flex items-center mr-1 mt-2 appearance-none cursor-pointer focus:outline-none"
        clearButtonTitle="Clear"
        customInput={<Input error={error} disabled={props.disabled} />}
        dateFormat={monthly ? "MMM, yyyy" : "MM/dd/yyyy"}
        popperClassName="z-40 bg-white text-midnight shadow overflow-hidden rounded text-sm"
      />
    </div>
  );
};

export default DatePicker;
