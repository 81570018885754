import React, { useState, useEffect } from "react";
import useFetch from "hooks/useFetch";
import { getProjectV2 } from "utils/adminApi";
import Section from "components/Section";
import ProjectListItem from './ProjectListItem';
import CandidateActivity from './CandidateActivity';
import ProjectInfo from './ProjectInfo';
import CompanyInfo from './CompanyInfo';
import Stakeholders from "./Stakeholders";
import ProjectDetailActions from "./ProjectDetailActions";
import DeleteCandidateForm from "./DeleteCandidateForm";
import ChangeStatusForm from "./ChangeStatusForm";

const ProjectDetail = ({ project, talent, onBack, onUpdate }) => {
  const [modal, setModal] = useState(false);
  const [openPanel, setOpenPanel] = useState('project');
  const [refreshActivity, setRefreshActivity] = useState(0);
  
  const { data, setData } = useFetch(getProjectV2, project.project_id);

  const onTogglePanel = (panel) => {
    let currentPanel = openPanel;
    currentPanel === panel ? setOpenPanel(null) : setOpenPanel(panel);
  }

  const onChangeStatus = () => {
    setModal('changeStatus');
  }

  const onChangeStatusSuccess = () => {
    setModal(false);
    setRefreshActivity(refreshActivity + 1);
    onUpdate();
  }

  const onDelete = () => {
    setModal('deleteCandidate');
  }

  const onDeleteSuccess = () => {
    setModal(false);
    onUpdate();
    onBack();
  }

  return (
    <div className="candidate_detail flex flex-col mt-1 h-full">
      <div className="flex mt-1 w-full">
        <ProjectDetailActions onBack={onBack} onChangeStatus={onChangeStatus} onDelete={onDelete} />
      </div>
      <div className="flex mt-1 w-full">
        <ProjectListItem project={project} />
      </div>
      <Section className="flex mt-1 h-full overflow-y-auto">
        <CandidateActivity project={project} refresh={refreshActivity} />
        <div className="w-1/3">
          <ProjectInfo data={data} open={openPanel === 'project'} onTogglePanel={() => onTogglePanel('project')} />
          <Stakeholders projectId={project.project_id} open={openPanel === 'stakeholders'} onTogglePanel={() => onTogglePanel('stakeholders')} />
          <CompanyInfo projectId={project.project_id} open={openPanel === 'company'} onTogglePanel={() => onTogglePanel('company')} />
        </div>
      </Section>
      {modal === "deleteCandidate" && <DeleteCandidateForm talent={talent} project={project} onClose={setModal} onSuccess={onDeleteSuccess} />}
      {modal === "changeStatus" && (
        <ChangeStatusForm
          data={project}
          onClose={setModal}
          onSuccess={onChangeStatusSuccess}
        />
      )}
    </div>
  );
};

export default ProjectDetail;