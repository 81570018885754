import UilClock from "@iconscout/react-unicons/icons/uil-clock";
import React from "react";
import FilterDeleteBtn from "./FilterDeleteBtn";
import { Checkbox } from "./Form";

const FilterInt = ({
  filter,
  updateSearch,
  setIndex,
  index,
  isSelected,
  removeFilter,
}) => {
  return !isSelected ? (
    <div
      onClick={() => setIndex(index)}
      className="bg-white border flex items-center border-link rounded h-8 px-1 text-link text-sm font-bold"
    >
      {filter.title}: {filter.data ? filter.data.value : ""}
      {filter.data && filter.data.useFresh && (
        <UilClock className="mx-1" size="14" />
      )}
      <FilterDeleteBtn onClick={() => removeFilter(index)} />
    </div>
  ) : (
    <div className="bg-white border items-center border-link rounded text-sm flex flex-col">
      <div className="border-b border-link h-8 w-full flex items-center text-link font-bold px-1">
        {filter.title}
      </div>
      <div className="h-8 px-1 flex items-center">
        {filter.symbol && (
          <div className="text-link text-sm mr-1">{filter.symbol}</div>
        )}
        <input
          className="focus:outline-none w-full"
          placeholder={filter.placeholder}
          autoFocus
          type="number"
          value={filter.data ? filter.data.value || "" : ""}
          onChange={(e) =>
            updateSearch(index, {
              ...(filter.data || {}),
              value: e.target.value,
            })
          }
        />
      </div>
      {filter.freshKey && (
        <div className="h-8 px-1 flex items-center border-t border-link w-full">
          <Checkbox
            onChange={(v) =>
              updateSearch(index, { ...(filter.data || {}), useFresh: v })
            }
            value={filter.data && filter.data.useFresh ? true : false}
          >
            <div className="flex items-center">
              <UilClock size="14" className="mr-1" /> Fresh Data
            </div>
          </Checkbox>
        </div>
      )}
    </div>
  );
};

export default FilterInt;
