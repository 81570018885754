import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { admin_company_information_form as $ } from "strings";
import { Header, Footer } from "components/Card";
import { Input, Label, Error, Textarea } from "components/Form";
import Select from "components/Select";
import { Button, RemoveBtn } from "components/Buttons";
import { B2DB, B1, B3B } from "components/Typography";
import Modal from "components/Modal";
import {
  patchCompany,
  patchCompanyLink,
  postCompanyLink,
  deleteCompanyLink,
  getCompanyLinks,
} from "utils/adminApi";
import MapSearch from "components/MapSearch";
import { fetchOwners, fetchPrimaryClientEngagement } from "utils/fetch";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus-circle";
import { withHttp } from "utils/str";
import { toFormat } from "utils/date";
import { getAdminFlags } from "utils/localStorageService";
import AlertSuccess from "../../../components/AlertSuccess";

const InformationForm = ({
  onClose,
  setData,
  data = {},
  companyLinks = {},
  linksData,
}) => {
  const alert = useAlert();
  const filteredLinks = Object.entries(companyLinks).filter(
    ([n]) => n !== "linkedin"
  );
  const flags = getAdminFlags();

  const [links, setLinks] = useState(filteredLinks.map(() => true));
  const defaultValues = {
    description: data.description,
    reflections: data.reflections,
    website: data.website,
    linkedin: companyLinks["linkedin"],
  };
  filteredLinks.forEach(([n, u], i) => {
    defaultValues[`link_name_${i}`] = n;
    defaultValues[`link_url_${i}`] = u;
  });
  const { handleSubmit, register, errors, formState, control } = useForm({
    defaultValues,
  });

  const onSubmit = async ({ location_id, ...values }) => {
    let sendData = {
      ...values,
      description: values.description,
      website: values.website,
    };
    if (!location_id || typeof location_id === "string") {
      sendData = { ...sendData, location_id };
    } else {
      sendData = {
        ...sendData,
        ...location_id,
        location_id: location_id.location_id,
      };
    }

    const toSave = {
      ...sendData,
      owner: values.owner && values.owner.owner,
      client_engagement:
        values.client_engagement && values.client_engagement.owner,
    };

    const linksValues = [
      {
        name: "LinkedIn",
        url: values.linkedin ? withHttp(values.linkedin) : "",
      },
      ...Object.keys(values)
        .filter((k) => k.includes("link_name"))
        .map((k) => {
          const i = k.replace("link_name_", "");

          return { name: values[k], url: withHttp(values[`link_url_${i}`]) };
        }),
    ];

    const toUpdate = [];
    const toCreate = [];
    const toDelete = [];

    linksValues.forEach((l) => {
      const existing = linksData.data.results.find((d) => d.name === l.name);
      if (existing) {
        l.id = existing.id;
        if (l.url !== existing.url) {
          toUpdate.push(l);
        }
      } else {
        toCreate.push(l);
      }
    });

    linksData.data.results.forEach((l) => {
      if (!linksValues.find((d) => d.name === l.name)) {
        toDelete.push(l);
      }
    });

    linksData.setData((d) => ({ ...d, results: linksValues }));
    setData({ ...data, ...toSave });

    const call = async () => {
      try {
        const response = await patchCompany(data.id, {
          ...sendData,
          owner: values.owner && values.owner.value,
          client_engagement:
            values.client_engagement && values.client_engagement.value,
        });
        setData(response);

        if (toDelete.length) {
          for (const l of toDelete) {
            await deleteCompanyLink(l.id);
          }
        }

        if (toCreate.length) {
          for (const l of toCreate) {
            await postCompanyLink({ ...l, company: data.id });
          }
        }

        if (toUpdate.length) {
          for (const l of toUpdate) {
            await patchCompanyLink(l.id, l);
          }
        }

        const linksRes = await getCompanyLinks(data.id);
        alert.success(<AlertSuccess message={$.success_message} />, {timeout: 2000});
        linksData.setData(linksRes);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };

    await call();
    onClose();
  };

  return (
    <Modal
      isDirty={formState.isDirty}
      onClose={() => {
        onClose();
      }}
      id="admin_company_information_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Header title={$.company_information_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.owner_input}</Label>
            <Controller
              control={control}
              name="owner"
              defaultValue={
                data.owner
                  ? {
                      value: data.owner.id,
                      label:
                        data.owner.first_name +
                        " " +
                        data.owner.last_name,
                      owner: data.owner,
                    }
                  : null
              }
              as={<Select />}
              error={errors.owner}
              placeholder={$.select_option}
              async
              loadOptions={fetchOwners}
            />
            {errors.owner && <Error>{errors.owner.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.client_engagement_input}</Label>
            <Controller
              control={control}
              name="client_engagement"
              defaultValue={
                data.client_engagement
                  ? {
                      value: data.client_engagement.id,
                      label:
                        data.client_engagement.first_name +
                        " " +
                        data.client_engagement.last_name,
                      owner: data.client_engagement,
                    }
                  : null
              }
              as={<Select />}
              error={errors.client_engagement}
              placeholder={$.select_option}
              async
              loadOptions={fetchPrimaryClientEngagement}
            />
            {errors.client_engagement && (
              <Error>{errors.client_engagement.message}</Error>
            )}
          </div>
          <div className="w-full">
            <B2DB>{$.location_title}</B2DB>
          </div>
          <div className="mb-5 h-32 w-full pr-8 mt-4">
            <Controller
              control={control}
              name="location_id"
              defaultValue={data.location_id}
              as={<MapSearch defaultValue={data.location_id} />}
              error={errors.location_id}
              defaultFullAddress={data.full_address}
            />
            {errors.location_ids && <Error>{errors.location_id.message}</Error>}
          </div>
          <div className="w-full pr-8 mb-3 mt-2">
            <Label>{$.description_input}</Label>
            <Textarea
              name="description"
              ref={register}
              error={errors.description}
            />
            {errors.description && <Error>{errors.description.message}</Error>}
          </div>
          {(flags.proposal_submitted_form || flags.matching_support_form) && (
            <div className="w-full pr-8 mb-3 mt-2">
              <Label>{$.reflections_input}</Label>
              <Textarea
                name="reflections"
                placeholder={$.reflections_placeholder}
                error={errors.reflections}
                ref={register}
              />
              {errors.reflections && (
                <Error>{errors.reflections.message}</Error>
              )}
              {data.reflections_updated_at && data.reflections_updated_by_name && (
                <div className="flex items-center">
                  <B1 className="mr-1">
                    {$.updated_label}{" "}
                    {toFormat(
                      new Date(data.reflections_updated_at),
                      "MM/dd/yyyy, hh:mm a"
                    )}
                  </B1>
                  {data.reflections_updated_by_name && (
                    <>
                      <B1 className="mr-1">by</B1>
                      <B3B>{data.reflections_updated_by_name}</B3B>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.linkedin_input}</Label>
            <Input
              name="linkedin"
              type="url"
              maxLength="250"
              ref={register({
                pattern: {
                  value:
                    /^(https?:\/\/)?(www\.)?linkedin.com\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i,
                  message: $.validation_linkedin_url,
                },
              })}
              error={errors.linkedin}
            />
            {errors.linkedin && <Error>{errors.linkedin.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.website_input}</Label>
            <Input
              name="website"
              type="url"
              maxLength="250"
              ref={register({
                pattern: {
                  value:
                    /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i,
                  message: $.validation_url,
                },
              })}
              error={errors.website}
            />
            {errors.website && <Error>{errors.website.message}</Error>}
          </div>
          <div className="mt-4 w-full pr-8">
            <button
              type="button"
              className="text-link text-sm font-bold appearance-none focus:outline-none outline-none inline-flex items-center"
              onClick={() => setLinks([...links, true])}
            >
              <UilPlus size="18" className="mr-1" />
              {$.add_link_button}
            </button>
            {links.map((v, i) =>
              !v ? (
                false
              ) : (
                <div key={i} className="flex items-end my-3 w-full">
                  <div className="flex-1 pr-4">
                    <Label>{$.link_name_input}</Label>
                    <Input
                      name={`link_name_${i}`}
                      maxLength="200"
                      ref={register({ required: $.validation_required })}
                      error={errors[`link_name_${i}`]}
                    />
                    {errors[`link_name_${i}`] && (
                      <Error>{errors[`link_name_${i}`].message}</Error>
                    )}
                  </div>
                  <div className="flex-1 pr-4">
                    <Label>{$.link_url_input}</Label>
                    <Input
                      name={`link_url_${i}`}
                      type="url"
                      maxLength="250"
                      ref={register({
                        required: $.validation_required,
                        pattern: {
                          value:
                            /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i,
                          message: $.validation_url,
                        },
                      })}
                      error={errors[`link_url_${i}`]}
                    />
                    {errors[`link_url_${i}`] && (
                      <Error>{errors[`link_url_${i}`].message}</Error>
                    )}
                  </div>
                  <div className="mb-1">
                    <RemoveBtn
                      onClick={() => {
                        const newLinks = [...links];
                        newLinks[i] = false;
                        setLinks(newLinks);
                      }}
                    />
                  </div>
                </div>
              )
            )}
          </div>
        </div>
        <Footer>
          <Button
            secondary
            onClick={() => {
              onClose();
            }}
            className="mr-4"
          >
            {$.cancel_button}
          </Button>
          <Button type="submit" loading={formState.isSubmitting} disabled={formState.isSubmitting}>
            {$.save_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default InformationForm;
