import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { admin_new_filter_form as $ } from "strings";
import { Header, Footer } from "components/Card";
import { Input, Label, Error } from "components/Form";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { getAdminUserId } from "utils/localStorageService";

const NewFilterForm = ({ postApi, snapshot, onClose, setCustomFilter, reload }) => {
  const alert = useAlert();
  const [sending, setSending] = useState(false);
  const user = getAdminUserId();

  const { handleSubmit, register, errors, formState } = useForm();

  const onSubmit = async (values) => {
    setSending(true);

    const call = async () => {
      try {
        const response = await postApi({ ...values, user, snapshot });
        setCustomFilter({
          filter: response,
          value: response.id,
          label: response.name,
        });
        onClose();
        reload();
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
        setSending(false);
      }
    };

    await call();
  };

  return (
    <Modal
      isDirty={formState.isDirty}
      onClose={onClose}
      id="admin_new_filter_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Header title={$.title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.name_input}</Label>
            <Input
              name="name"
              maxLength="150"
              ref={register({ required: $.validation_required })}
              error={errors.name}
            />
            {errors.name && <Error>{errors.name.message}</Error>}
          </div>
        </div>
        <Footer>
          <Button secondary onClick={onClose} className="mr-4">
            {$.cancel_button}
          </Button>
          <Button type="submit" loading={sending} disabled={sending}>
            {sending ? $.sending_button : $.save_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default NewFilterForm;
