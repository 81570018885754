const TMP_DOMAIN = "@tmp.rightsideup.co";

const withHttp = (url) => (!/^https?:\/\//i.test(url) ? `http://${url}` : url);

const formatPhone = (phone) => {
  let cleaned = "" + phone;
  // handle 1, +1 prefixes
  if (cleaned.length > 10) {
    if (cleaned.charAt(0) === '1') {
      cleaned = cleaned.slice(1);
    } else if (cleaned.includes('+1')) {
      cleaned = cleaned.slice(2);
    }
  }
  const match = cleaned.match(/^(\d{3})(\d{3})(\d+)$/);
  if (match) {
    return match[1] + "-" + match[2] + "-" + match[3];
  }

  return phone;
};

const generateEmail = () =>
  Math.random().toString(36).substring(2, 11) + TMP_DOMAIN;

const isTemporalEmail = (email) => email?.includes(TMP_DOMAIN);

const urlify = (text) => {
  var urlRegex =
    /(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
  return text.replaceAll(urlRegex, function (url) {
    return (
      '<a target="_blank" rel="noopener noreferrer" href="' +
      withHttp(url) +
      '">' +
      url +
      "</a>"
    );
  });
};

export { withHttp, formatPhone, generateEmail, isTemporalEmail, urlify };
