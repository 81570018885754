import React from "react";

const MoveDuplicateToast = ({
  action,
  updatedCandidates,
  skippedCandidates,
  project
}) => {
  const RSU_URL_ROOT =
    process.env.NODE_ENV === 'development' || process.env.REACT_APP_API_URL.includes('staging') ?
    "https://staging.writepercent.com/admin/project/" :
    "https://app.rightsideup.co/admin/project/" 

  return (
    <div className="w-70">
      <div className="flex flex-col items-start">
          <div>{updatedCandidates} candidate{updatedCandidates === 1 ? '': 's'} {updatedCandidates === 1 ? 'has' : 'have'} been {action}d to <a href={`${RSU_URL_ROOT}${project.id}/candidates`} className="underline" target="_blank">{project.name}.</a></div>
          {skippedCandidates ?
            <div>{skippedCandidates} candidate{skippedCandidates === 1 ? '': 's'} aleady exist{skippedCandidates === 1 ? 's': ''} on <a href={`${RSU_URL_ROOT}${project.id}/candidates`} className="underline" target="_blank">{project.name}.</a></div> :
            <></>
          }
      </div>
    </div>
  );
};

export default MoveDuplicateToast;