import React, { useState } from "react";
import { B2DB, B1 } from "./Typography";
import UilCopy from "@iconscout/react-unicons/icons/uil-copy";
import { global as $ } from "strings";

/**
 * Renders an information box. Used in some talent and project pages.
 * @param {component} children - The children to render, the information to show.
 * @param {string} title - Header text of the information.
 * @param {component} Icon - Icon component to render.
 * @param {string} className - Classes to append to the box default classes.
 */
const InfoRow = ({
  children,
  title,
  Icon,
  className,
  contentClass = "",
  clipboard,
}) => {
  const [hasClip, setHasClip] = useState(false);

  const handleClick = () => {
    if (!clipboard) {
      return;
    }

    navigator.clipboard.writeText(clipboard);
    setHasClip(true);
    setTimeout(() => {
      setHasClip(false);
    }, 1000);
  };

  return (
    <div
      className={
        "px-2 py-4 w-full max-w-full rounded my-4 border border-link-water bg-white flex items-center group " +
        className
      }
      onClick={handleClick}
    >
      <B2DB className="flex items-center mr-4">
        <Icon size="18" className="mr-2 flex-shrink-0" />
        {title}
      </B2DB>
      <B1 className={contentClass}>{children}</B1>
      {!!clipboard && !hasClip && (
        <div
          className="text-kasmir opacity-80 hidden group-hover:block"
          title={$.copy_clipboard}
        >
          <UilCopy size="20" />
        </div>
      )}
      {hasClip && (
        <div className="text-kasmir text-xs font-medium">
          {$.copied_clipboard}
        </div>
      )}
    </div>
  );
};

export default InfoRow;
