import React, { useEffect, useRef, useMemo } from "react";
import clsx from "clsx";
import { admin_project_overview as $ } from "strings";
import Input from "components/ListEditableNote";
import Select from "components/CompactSelectNote";
import useQualifications from "hooks/useQualifications";
import DatePicker from "components/NoteDatePicker";
import { toFormat } from "utils/date";
import {
  deleteContractScope,
  getCandidates,
  getProjectContract,
  patchCandidate,
  patchContract,
  postCandidate,
  postContract,
  postContractScope,
  patchProjectV2
} from "utils/adminApi";
import { getFetchContractTalentsAndTeams } from "utils/fetch";
import { getAccount } from "utils/adminApi";
import useFetch from "hooks/useFetch";

const backgroundCheckOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const teamOptions = [
  { value: 0, label: "Offline", type: "team" },
  { value: 1, label: "Amazon", type: "team" },
  { value: 2, label: "Right Percent", type: "team" },
  { value: 3, label: "Affiliate", type: "team" },
  { value: 4, label: "Creative", type: "team" },
  { value: 5, label: "Other", type: "team" },
];

export const onFilledSave = async (
  {
    scope,
    background_check,
    team_person,
    est_hours,
    bill_rate,
    body,
    ...values
  },
  data,
  projectId
) => {
  values.rate = bill_rate;
  values.availability = est_hours;
  values.background_check_required = background_check
    ? background_check.value
    : null;

  if (team_person) {
    const type = team_person.type === "talent" ? "candidate" : "team";
    const emptytype = team_person.type === "talent" ? "team" : "candidate";
    values[type] = team_person.value;
    values[emptytype] = null;
  } else {
    values.candidate = null;
    values.team = null;
  }

  const olds = data && Array.isArray(data.scope) ? data.scope : [];
  const news = scope ? scope.map((s) => s.value) : [];
  const oldsKeys = olds.map((s) => s.scope);

  const toDelete = olds.filter((q) => !news.includes(q.scope));

  const toCreate = news.filter((q) => !oldsKeys.includes(q));

  if (data) {
    for (let q of toDelete) {
      await deleteContractScope(q.id);
    }

    for (let q of toCreate) {
      await postContractScope({ contract: data.id, scope: q });
    }

    await patchContract(data.id, values);
  } else {
    const response = await postContract({ ...values, project: projectId });
    for (let q of toCreate) {
      await postContractScope({ contract: response.id, scope: q });
    }
  }

  if (values.candidate && (!data || values.candidate !== data.candidate)) {
    const candRes = await getCandidates({
      candidate__user__id: values.candidate,
      project__id: projectId,
    });

    if (candRes && Array.isArray(candRes.results) && candRes.results[0]) {
      const cand = candRes.results[0];

      if (cand.status !== "placed") {
        await patchCandidate(cand.id, {
          status: "placed",
        });
      }
    } else {
      await postCandidate({
        status: "placed",
        candidate: values.candidate,
        project: projectId,
        applied: new Date(),
      });
    }
  }

  const toSend = {
    stage: 'filled'
  }
  if (values.start_date) {
    toSend.estimated_start_date = values.start_date
  }
  if (values.end_date) {
    toSend.estimated_end_date = values.end_date
  }
  const updatedProjectData = await patchProjectV2(projectId, toSend);
  return updatedProjectData;
};

export const getFilledNote = (values) => [
  {
    type: "table",
    children: [
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Person: ", bold: true },
              {
                text: values.team_person ? values.team_person.label : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Bill Rate: ", bold: true },
              {
                text: Number.isInteger(Number(values.bill_rate))
                  ? "$" + Number(values.bill_rate) + "/hr"
                  : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Start Date: ", bold: true },
              {
                text:
                  values && values.start_date
                    ? toFormat(new Date(values.start_date), "MM/dd/yyyy")
                    : "Not Set",
              },
            ],
          },
        ],
      },
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Scope: ", bold: true },
              {
                text: values.scope?.length
                  ? values.scope.map((v) => v.label).join(", ")
                  : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Pay Rate: ", bold: true },
              {
                text: Number.isInteger(Number(values.pay_rate))
                  ? "$" + Number(values.pay_rate) + "/hr"
                  : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "End Date: ", bold: true },
              {
                text:
                  values && values.end_date
                    ? toFormat(new Date(values.end_date), "MM/dd/yyyy")
                    : "Not Set",
              },
            ],
          },
        ],
      },
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Background Check Required: ", bold: true },
              {
                text: values.background_check
                  ? values.background_check.label
                  : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Est. Hours: ", bold: true },
              {
                text: Number.isInteger(Number(values.est_hours))
                  ? Number(values.est_hours) + " hours per week"
                  : "Not Set",
              },
            ],
          },
          { type: "table-cell", children: [{ text: "" }] },
        ],
      },
    ],
  },
];

const StageFilledForm = ({
  values,
  updateVal,
  projectId,
  setValues,
  setContext,
  isNoteForm,
  onReady
}) => {
  const { qualifications } = useQualifications(true);
  const setupDone = useRef(false);
  const fetchTalents = useMemo(
    () => getFetchContractTalentsAndTeams(projectId),
    [projectId]
  );

  const contract = useFetch(getProjectContract, projectId);

  let candidateId = null;
  let contractData = null;
  if (contract.pageState === "loaded" && contract.data.results?.length > 0) {
    contractData = contract.data.results[0];
    candidateId = contractData.candidate;
  }
  const candidate = useFetch(getAccount, candidateId);

  useEffect(() => {
    if (
      !setupDone.current &&
      qualifications.qualificationsByValue &&
      contractData &&
      (!candidateId || candidate.pageState === "loaded")
    ) {
      setupDone.current = true;
      if (onReady) onReady();

      setContext(contractData);
      setValues({
        ...{
          scope:
            Array.isArray(contractData.scope) &&
            qualifications.qualificationsByValue
              ? contractData.scope.map((s) => ({
                  scope: s,
                  label: qualifications.qualificationsByValue[s.scope].name,
                  value: s.scope,
                }))
              : [],
          est_hours: contractData.availability,
          start_date: contractData.start_date,
          end_date: contractData.end_date,
          background_check: backgroundCheckOptions.find(
            (o) => o.value === contractData.background_check_required
          ),
          team_person: contractData.team
            ? teamOptions.find((o) => o.value === contractData.team)
            : contractData.candidate
            ? {
                value: contractData.candidate,
                label:
                  candidate.data.user.first_name +
                  " " +
                  candidate.data.user.last_name,
                type: "talent",
              }
            : null,
          pay_rate: contractData.pay_rate,
          bill_rate: contractData.rate,
        },
        ...values,
      });
    }
  }, [contractData, candidate, qualifications.qualificationsByValue]);

  if (contract.pageState !== "loaded") {
    return null;
  }

  return (
    <div className={clsx("flex flex-col px-2", isNoteForm && "border-l border-r border-link stage-change-form-middle")}>
      <div className="flex items-center w-full">
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.team_person_label}
          </span>
          <div className="flex-1 ml-1">
            <Select
              placeholder={$.team_person_placeholder}
              value={values.team_person}
              onChange={(v) => updateVal("team_person", v)}
              async
              loadOptions={fetchTalents}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.bill_rate_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ".", " "].includes(evt.key) &&
                evt.preventDefault()
              }
              text={
                Number.isInteger(Number(values.bill_rate))
                  ? "$" + values.bill_rate + "/hr"
                  : $.bill_rate_placeholder
              }
              beforeText="$"
              afterText="/hr"
              defaultValue={values.bill_rate}
              onChange={(v) => updateVal("bill_rate", Number(v))}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.start_date_label}
          </span>
          <div className="flex-1 ml-1">
            <DatePicker
              placeholderText="mm/dd/yyyy"
              value={
                values && values.start_date ? new Date(values.start_date) : null
              }
              format="MM/dd/yyyy"
              monthly={false}
              onChange={(v) => updateVal("start_date", v)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center w-full">
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.scope_label}
          </span>
          {qualifications && qualifications.qualificationsOptions && (
            <div className="flex-1 ml-1">
              <Select
                placeholder={$.scope_placeholder}
                value={values.scope || []}
                isMulti
                onChange={(v) => updateVal("scope", v)}
                options={qualifications.qualificationsOptions}
              />
            </div>
          )}
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.pay_rate_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ".", " "].includes(evt.key) &&
                evt.preventDefault()
              }
              text={
                Number.isInteger(Number(values.pay_rate))
                  ? "$" + values.pay_rate + "/hr"
                  : $.pay_rate_placeholder
              }
              beforeText="$"
              afterText="/hr"
              defaultValue={values.pay_rate}
              onChange={(v) => updateVal("pay_rate", Number(v))}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.end_date_label}
          </span>
          <div className="flex-1 ml-1">
            <DatePicker
              placeholderText="mm/dd/yyyy"
              value={
                values && values.end_date ? new Date(values.end_date) : null
              }
              format="MM/dd/yyyy"
              monthly={false}
              onChange={(v) => updateVal("end_date", v)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center w-full">
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.background_check_label}
          </span>
          <div className="flex-1 ml-1">
            <Select
              placeholder={$.background_check_placeholder}
              value={values.background_check}
              onChange={(v) => updateVal("background_check", v)}
              options={backgroundCheckOptions}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.est_hours_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              text={
                Number.isInteger(Number(values.est_hours))
                  ? values.est_hours + " hours per week"
                  : $.est_hours_placeholder
              }
              afterText=" hours per week"
              defaultValue={values.est_hours}
              onChange={(v) => updateVal("est_hours", Number(v))}
            />
          </div>
        </div>
        <div className="w-1/3"></div>
      </div>
    </div>
  );
};

export default StageFilledForm;
