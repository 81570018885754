import React from "react";
import { Link } from "react-router-dom";
import { admin_project_candidate_detail as $ } from "strings";
import { getTimestamp, getDaysText } from "utils/date";
import userImg from "assets/user.svg";
import Header from "components/ListHeader";
import UilUser from "@iconscout/react-unicons/icons/uil-user";
import UilUserCircle from "@iconscout/react-unicons/icons/uil-user-circle";
import UilClock from "@iconscout/react-unicons/icons/uil-clock";
import UilMoney from "@iconscout/react-unicons/icons/uil-usd-circle";
import UilAward from "@iconscout/react-unicons/icons/uil-award";

const Candidate = ({ candidate }) => {
  const grades = ["A+", "A", "A-", "B+", "B", "B-", "C"];
  const gradeOptions = grades.map((g, i) => ({
    label: g,
    value: 8 - i,
  }));

  const { 
    availability_capacity,
    availability_utilization,
    availability_updated_at,
    candidate_user_id,
    creator,
    created_at,
    current_title,
    minimum_rate,
    name,
    profile_photo,
    talent_grade,
    talent_grade_updated_by,
    top_qualifications
  } = candidate;  

  return (
    <div 
      id="candidate"
      className="bg-white flex-1 mt-2 mb-2 flex flex-col cursor-default"
    >
      <div className="w-full flex">
        <div className="flex items-center w-3/12">
          <img
            src={profile_photo || userImg}
            className="rounded w-12 h-12 mr-1"
            alt={name}
          />
          <div className="truncate">
            <div className="text-sm text-midnight font-bold text-ellipsis font-bold mt-1">
              <Link
                id={`talentLink--${candidate_user_id}`}
                className="inline-block"
                to={`/admin/talent/${candidate_user_id}/overview`}
              >{name}
              </Link>
            </div>
            <div className="text-sm text-midnight font-bold truncate">
              {current_title || $.no_current_title}
            </div>
          </div>
        </div>
        <div className="mt-1 w-1/6">
          <Header Icon={UilUserCircle} text={$.added_by_title} />
          <div className="text-sm text-midnight font-bold text-ellipsis">
            {creator || $.unknown_value} <span className="text-xs">({getDaysText(getTimestamp(created_at))})</span>
          </div>
        </div>
        <div className="mt-1 w-1/6">
          <Header Icon={UilClock} text={$.availability_title} />
          <div className="text-sm text-midnight font-bold text-ellipsis">
            {availability_capacity && availability_utilization ? availability_capacity - availability_utilization : availability_capacity ? availability_capacity : 0} {$.hours_per_week} <span className="text-xs">({getDaysText(getTimestamp(availability_updated_at))})</span>
          </div>
        </div>
        <div className="mt-1 w-1/12">
          <Header Icon={UilMoney} text={$.min_rate_title} />
          <div className="text-sm text-midnight font-bold text-ellipsis">
            ${minimum_rate}{$.per_hour}
          </div>
        </div>
        <div className="mt-1 w-1/12">
          <Header Icon={UilUser} text={$.talent_grade_title} />
          <div className="text-sm text-midnight font-bold text-ellipsis">
            {talent_grade > 0 ? gradeOptions.find(g => g.value === talent_grade).label : $.no_talent_grade} {talent_grade_updated_by && `(${talent_grade_updated_by})`}
          </div>
        </div>
        <div className="mt-1 w-3/12">
          <Header Icon={UilAward} text={$.top_qualifications_title} />
          <div className="text-sm text-midnight font-bold truncate">
            {top_qualifications ? top_qualifications.map((qual, i) => 
              <span key={i}>{qual.name}{` (${qual.rating})`}{i !== top_qualifications.length - 1 && ', '}
            </span>) : <span>{$.unknown_value}</span>}
          </div>
        </div>
      </div>
    </div>
  )
};

export default Candidate;