import React from "react";
import { admin_talent_project as $ } from "strings";
import { B2DB } from "components/Typography";
import { Link } from "react-router-dom";
import Section from "components/Section";
import Header from "components/ListHeader";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import UilUserCircle from "@iconscout/react-unicons/icons/uil-user-circle";
import UilSuitcase from "@iconscout/react-unicons/icons/uil-suitcase";
import UilPin from "@iconscout/react-unicons/icons/uil-map-pin";
import UilBuilding from "@iconscout/react-unicons/icons/uil-building";
import LoadPlaceholder from "components/LoadPlaceholder";
import UilInfoCircle from "@iconscout/react-unicons/icons/uil-info-circle";
import UilBill from "@iconscout/react-unicons/icons/uil-bill";
import UilClock from "@iconscout/react-unicons/icons/uil-clock";

const ProjectInfo = ({ data, open, onTogglePanel }) => {
  return (
    <Section id="project_company_info" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilInfoCircle className="mr-2" size="18" />{$.project_info_title}
          </B2DB>
          <div className="cursor-pointer" onClick={onTogglePanel}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && (
          <div className="px-2 pb-4">
            {!data && (
              <div className="flex-1 border border-link-water rounded px-6 pt-6">
                <LoadPlaceholder className="w-40 h-4 my-4" />
                <LoadPlaceholder className="w-40 h-4 mb-4" />
                <LoadPlaceholder className="w-20 h-4 mb-2" />
              </div>
            )}
            {data && (
              <>
                <div className="w-full flex mb-2">
                  <div className="w-1/2">
                    <Header Icon={UilSuitcase} text={$.project_name_title} />
                    <div className="text-sm text-midnight font-bold text-ellipsis">
                      <Link
                        className="inline-block cursor-pointer"
                        to={`/admin/projects/${data.id}`}
                      >
                        <B2DB>
                          {data.name}
                        </B2DB>
                      </Link>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <Header Icon={UilBuilding} text={$.company_name_title} />
                    <div className="text-sm text-midnight font-bold truncate">
                      {data.company_name || $.unknown_value}
                    </div>
                  </div>
                </div>
                <div className="w-full flex mb-2">
                  <div className="w-1/2">
                    <Header Icon={UilPin} text={$.location_title} />
                    <div className="text-sm text-midnight font-bold truncate">
                      {$.unknown_value}
                    </div>
                  </div>
                  <div className="w-1/2">
                    <Header Icon={UilClock} text={$.min_hours_title} />
                    <div className="text-sm text-midnight font-bold truncate">
                      {data.availability_hours || $.unknown_value}
                    </div>
                  </div>
                </div>
                <div className="w-full flex mb-2">
                  <div className="w-1/2">
                    <Header Icon={UilBill} text={$.pay_rate_title} />
                    <div className="text-sm text-midnight font-bold truncate">
                      {`$${data.ideal_rate || 0} - $${data.ideal_rate_maximum || 0}`}
                    </div>
                  </div>
                  <div className="w-1/2">
                    <Header Icon={UilBill} text={$.bill_rate_title} />
                    <div className="text-sm text-midnight font-bold truncate">
                      {`$${data.minimum_rate || 0} - $${data.maximum_rate || 0}`}
                    </div>
                  </div>
                </div>
              </>
            )}
            </div>
          )}
      </div>
    </Section>
  )
};

export default ProjectInfo;