import React, {useState} from "react";
import {useForm} from "react-hook-form";
import {admin_talent_form as $} from "strings";
import {Footer, Header} from "components/Card";
import {Error, Input, Label} from "components/Form";
import {Button} from "components/Buttons";
import Modal from "components/Modal";
import {
  postTalentOwner,
  postUser,
  postUserLink
} from "utils/adminApi";
import {generateEmail} from "utils/str";
import {useAlert} from "react-alert";
import AlertError from "components/AlertError";
import {useHistory} from "react-router-dom";
import {getAdminUserId} from "utils/localStorageService";
import AlertSuccess from "../../../components/AlertSuccess";

const NewTalentForm = ({ onClose }) => {
  const history = useHistory();
  const alert = useAlert();
  const { handleSubmit, register, errors, formState } = useForm();
  const [sending, setSending] = useState(false);
  const userId = getAdminUserId();

  const onSubmit = async (values) => {
    setSending(true);
    const params = {
      password1: "",
      password2: "",
      first_name: values.firstname,
      last_name: values.lastname,
      primary_talent_email: values.email || generateEmail(),
      type: 'talent'
    };

    const call = async () => {
      try {
        const response = await postUser(params);
        if (values.linkedin.length) {
          await postUserLink({
            name: "linkedin",
            url: values.linkedin,
            updated_by: userId,
            user: response.id,
          });
        }
        await postTalentOwner({ talent: response.id, owner: userId });
        alert.success(<AlertSuccess message={$.success_message} />, {timeout: 2000});
        history.push(`/admin/talent/${response.id}/overview`);
        setSending(false);
      } catch (e) {
        setSending(false);
        let talentExists = false;
        let duplicatedId = null;
        if (e.response && e.response.data && e.response.data.users) {
          for (const user of e.response.data.users) {
            duplicatedId = user?.id || null;
            if (user?.is_talent === "True" || user?.is_talent === "true" || user?.is_talent === true) {
              talentExists = true;
              duplicatedId = user.id;
              break;
            }
          }
        }
        if (talentExists) {
          const talentPageUrl = `/admin/talent/${duplicatedId}/overview`;
          alert.info(
            <AlertSuccess>
              <div>
                A Talent with this email already exists. <a href={talentPageUrl} className="underline">Click here to view the Talent</a>.
              </div>
            </AlertSuccess>);
        } else if (duplicatedId) {  // user exists but is not a talent
          const contactPageUrl = `/admin/contacts/${duplicatedId}/roles?type=talent`;
          alert.info(
            <AlertSuccess>
              <div>
                A Contact with this email already exists. <a href={contactPageUrl} className="underline">Click here to add a Talent Role</a> from the Contact page.
              </div>
            </AlertSuccess>);
        } else {
          console.error(e);
          alert.error(<AlertError error={e} />);
        }
      }
    };

    await call();
  };

  return (
    <Modal
      isDirty={!sending && formState.isDirty}
      onClose={onClose}
      id="admin_talent_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Header title={$.create_talent_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.firstname_input}</Label>
            <Input
              name="firstname"
              maxLength="254"
              ref={register({ required: $.validation_required })}
              error={errors.firstname}
            />
            {errors.firstname && <Error>{errors.firstname.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.lastname_input}</Label>
            <Input
              name="lastname"
              maxLength="254"
              ref={register({ required: $.validation_required })}
              error={errors.lastname}
            />
            {errors.lastname && <Error>{errors.lastname.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.email_input}</Label>
            <Input
              name="email"
              type="email"
              maxLength="254"
              ref={register({
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                  message: $.validation_email,
                },
              })}
              error={errors.email}
            />
            {errors.email && <Error>{errors.email.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.linkedin_input}</Label>
            <Input
              name="linkedin"
              type="url"
              maxLength="250"
              ref={register({
                pattern: {
                  value: /^(https?:\/\/)?(www\.)?linkedin.com\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i,
                  message: $.validation_linkedin_url,
                },
              })}
              error={errors.linkedin}
            />
            {errors.linkedin && <Error>{errors.linkedin.message}</Error>}
          </div>
        </div>
        <Footer>
          <Button secondary onClick={onClose} className="mr-4">
            {$.cancel_button}
          </Button>
          <Button type="submit" loading={sending} disabled={sending}>
            {sending ? $.sending_button : $.create_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default NewTalentForm;
