import React, { useRef, useState } from "react";
import InnerInput from "./InnerInput";
import FilterInt from "./FilterInt";
import FilterIntRange from "./FilterIntRange";
import useClickOutside from "hooks/useClickOutside";
import FilterSelect from "./FilterSelect";
import FilterBoolean from "./FilterBoolean";
import FilterDate from "./FilterDate";
import FilterQualification from "./FilterQualification";
import UilSearch from "@iconscout/react-unicons/icons/uil-search";
import FilterLocation from "./FilterLocation";

const components = {
  int: FilterInt,
  int_range: FilterIntRange,
  select: FilterSelect,
  location: FilterLocation,
  boolean: FilterBoolean,
  date: FilterDate,
  qualification: FilterQualification,
};

const SearchRenderer = ({
  search,
  updateSearch,
  setIndex,
  setCaretPos,
  currentIndex,
  removeFilter,
  total,
  caretPos,
  nextCaretPos,
  setNextCaretPos,
  filters,
  setUseGlobal,
  placeholder
}) => {
  const [globalConfirm, setGlobalConfirm] = useState(false);
  const ref = useRef();
  useClickOutside(ref, () => {
    if (
      search[currentIndex] &&
      typeof search[currentIndex] !== "string" &&
      filters[search[currentIndex].id].type !== "boolean" &&
      !search[currentIndex].data
    ) {
      removeFilter(currentIndex);
    }
    setIndex(null, false);
  });

  return (
    <div className="h-full w-full py-1 flex">
      <div
        className={
          "z-50 h-full min-w-full md:min-w-3/4 lg:min-w-1/2 xl:min-w-1/3 2xl:min-w-1/4 max-w-full flex items-start rounded py-2 border relative " +
          (currentIndex !== null
            ? "border-link bg-white"
            : "bg-link-water border-link-water")
        }
        ref={ref}
      >
        <div className="text-kasmir h-full ml-3 mr-1 flex items-center justify-center">
          <UilSearch size="20" />
        </div>
        <div className="flex-1 flex overflow-x-auto">
          {search.map((s, i) => {
            if (typeof s === "string") {
              return (
                <InnerInput
                  isGlobalAble={search.length === 1 && currentIndex === 0}
                  setUseGlobal={setUseGlobal}
                  setGlobalConfirm={setGlobalConfirm}
                  globalConfirm={globalConfirm}
                  setIndex={setIndex}
                  setCaretPos={setCaretPos}
                  key={i}
                  index={i}
                  value={s}
                  onChange={updateSearch}
                  isLast={i + 1 === search.length}
                  removeFilter={removeFilter}
                  caretPos={caretPos}
                  nextCaretPos={nextCaretPos}
                  setNextCaretPos={setNextCaretPos}
                  currentIndex={currentIndex}
                />
              );
            } else {
              const Filter = components[filters[s.id]?.type] || null;

              return (
                <div key={i} className="flex-shrink-0">
                  {Filter && <Filter
                    setIndex={setIndex}
                    removeFilter={removeFilter}
                    updateSearch={updateSearch}
                    index={i}
                    filter={{ ...filters[s.id], ...s }}
                    isSelected={i === currentIndex}
                  />}
                </div>
              );
            }
          })}
        </div>
        <div className="text-kasmir h-full ml-3 mr-1 flex items-center justify-center text-xs">
          {total} results
        </div>
        {search.length === 1 && currentIndex === 0 && !search[0] && (
          <div
            className={
              "absolute top-0 left-0 mt-10 px-2 py-1 border border-link rounded-b text-sm text-kasmir -ml-px " +
              (globalConfirm ? "bg-link-water" : "bg-white")
            }
            style={{ width: "calc(100% + 2px)" }}
          >
            {placeholder ? placeholder : 'Press Down to Search Across Projects, Companies and Talent'}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchRenderer;
