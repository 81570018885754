import React, { useEffect, useRef } from "react";
import { admin_project_overview as $ } from "strings";
import Select from "components/CompactSelectNote";
import DatePicker from "components/ListDatePicker";
import { toFormat } from "utils/date";
import {
  getProject,
  patchProjectV2
} from "utils/adminApi";
import useFetch from "hooks/useFetch";

const terminatedByOptions = [
  { value: "client", label: $.client_option },
  { value: "talent", label: $.talent_option },
];
const clientSentimentOptions = [
  { value: "positive", label: $.positive_option },
  { value: "negative", label: $.negative_option },
  { value: "neutral", label: $.neutral_option },
  { value: "unknown", label: $.unknown_option },
];
const clientTerminationReasonOptions = [
  { value: "end_of_need_reason", label: $.end_of_need_reason },
  { value: "budgets_reason", label: $.budgets_reason },
  { value: "talent_performance_reason", label: $.talent_performance_reason },
  { value: "hired_non_rsu_fte_reason", label: $.hired_non_rsu_fte_reason },
  { value: "hired_rsu_fte_reason", label: $.hired_rsu_fte_reason },
  { value: "other_reason", label: $.other_reason },
];
const talentTerminationReasonOptions = [
  { value: "talent_took_other_fte_reason", label: $.talent_took_other_fte_reason },
  { value: "talent_unhappy_reason", label: $.talent_unhappy_reason },
  { value: "other_reason", label: $.other_reason },
];
const backfillingOptions = [
  { value: true, label: $.yes_option },
  { value: false, label: $.no_option },
];
export const onCompletedSave = async (
  {
    terminated_by,
    client_sentiment,
    termination_reason,
    estimated_end_date,
    backfilling,
    body,
    ...values
  },
  data,
  projectId
) => {
  const toSend = {
    terminated_by: terminated_by && terminated_by.value || null,
    client_sentiment: client_sentiment && client_sentiment.value || null,
    termination_reason: termination_reason && termination_reason.value || null,
    backfilling: backfilling && backfilling.value,
    stage: 'completed'
  };

  if (estimated_end_date) {
    toSend.estimated_end_date = estimated_end_date
  };

  const updatedProjectData = await patchProjectV2(projectId, toSend);
  return updatedProjectData;
};

export const getCompletedNote = (values) => [
  {
    type: "table",
    children: [
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Terminated By: ", bold: true },
              {
                text: values.terminated_by ? values.terminated_by.label : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Client Sentiment: ", bold: true },
              {
                text: values.client_sentiment ? values.client_sentiment.label : "Not Set",
              },
            ],
          },
        ]
      },
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Termination Reason: ", bold: true },
              {
                text: values.termination_reason ? values.termination_reason.label : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Estimated End Date: ", bold: true },
              {
                text:
                  values.estimated_end_date
                    ? toFormat(new Date(values.estimated_end_date), "MM/dd/yyyy")
                    : "Not Set",
              }
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Backfilling: ", bold: true },
              {
                text: values.backfilling ? values.backfilling.label : "Not Set",
              },
            ],
          },
        ]
      },
    ],
  },
];

const StageCompletedForm = ({
  values,
  updateVal,
  projectId,
  setValues,
  setContext,
}) => {
  const setupDone = useRef(false);
  const { data, pageState } = useFetch(getProject, projectId);
  useEffect(() => {
    if (!setupDone.current && pageState === "loaded") {
      setupDone.current = true;
      setContext(data);
      const newVals = {
        ...{
          terminated_by: data.terminated_by,
          client_sentiment: data.client_sentiment,
          termination_reason: data.termination_reason,
          estimated_end_date: data.estimated_end_date,
          backfilling: data.backfilling,
        },
        ...values,
      };

      if (!newVals.estimated_end_date) {
        newVals.estimated_end_date = new Date();
      }

      setValues(newVals);
    }
  }, [data, pageState]);

  if (pageState !== "loaded") {
    return null;
  }
  return (
    <div className="stage-change-form-middle flex flex-col border-l border-r border-link px-2">
      <div className="flex items-center w-full">
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.terminated_by_label}
          </span>
          <div className="flex-1 ml-1">
            <Select
              placeholder={$.terminated_by_placeholder}
              value={values.terminated_by}
              onChange={(v) => updateVal("terminated_by", v)}
              options={terminatedByOptions}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.client_sentiment_label}
          </span>
          <div className="flex-1 ml-1">
            <Select
              placeholder={$.client_sentiment_placeholder}
              value={values.client_sentiment}
              onChange={(v) => updateVal("client_sentiment", v)}
              options={clientSentimentOptions}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center w-full">
        {!values.terminated_by &&
          <div className="flex items-center">
            <span className="text-sm font-bold text-kasmir cursor-not-allowed">
              {$.termination_reason_label}
            </span>
          </div>
        } 
        {values.terminated_by?.value === 'client' &&
          <div className="flex w-full items-center">
            <span className="text-sm font-bold text-kasmir">
              {$.client_termination_reason_label}
            </span>
            <div className="flex-1 ml-1">
              <Select
                placeholder={$.termination_reason_placeholder}
                value={values.termination_reason}
                onChange={(v) => updateVal("termination_reason", v)}
                options={clientTerminationReasonOptions}
              />
            </div>
          </div>
        }
        {values.terminated_by?.value === 'talent' &&
          <div className="flex items-center w-full">
          <span className="text-sm font-bold text-kasmir">
            {$.talent_termination_reason_label}
          </span>
          <div className="flex-1 ml-1">
            <Select
              placeholder={$.termination_reason_placeholder}
              value={values.termination_reason}
              onChange={(v) => updateVal("termination_reason", v)}
              options={talentTerminationReasonOptions}
            />
          </div>
         </div>
        }
      </div>
      <div className="flex items-center w-full">
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.expected_end_date_label}
          </span>
          <div className="flex-1 ml-1">
            <DatePicker
              placeholderText={$.expected_end_date_placeholder}
              value={values.estimated_end_date ? new Date(values.estimated_end_date) : null}
              format="MMMM dd, yyyy"
              monthly={false}
              onChange={(v) => updateVal("estimated_end_date", v)}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.backfilling_label}
          </span>
          <div className="flex-1 ml-1">
            <Select
              placeholder={$.backfilling_placeholder}
              value={values.backfilling}
              onChange={(v) => updateVal("backfilling", v)}
              options={backfillingOptions}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default StageCompletedForm;