import AlertError from "components/AlertError";
import { Button } from "components/Buttons";
import { Error, Input, Label } from "components/Form";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { admin_company_clockify as $ } from "strings";
import { assignCompanyClockify } from "utils/adminApi";
import AlertSuccess from "../../../../components/AlertSuccess";

const InputCompanyForm = ({ data, setData, setStatus }) => {
  const alert = useAlert();
  const [saving, setSaving] = useState(false);

  const { handleSubmit, register, errors } = useForm();

  const onSubmit = async (values) => {
    setSaving(true);

    const call = async () => {
      try {
        await assignCompanyClockify(data.id, values);
        setData({ ...data, clockify_id: values.clockify_id });
        setStatus(null);
        alert.success(<AlertSuccess message={$.input_success_message} />, {timeout: 2000});
      } catch (e) {
        console.error(e);
        if (e.response?.data?.code === 501) {
          alert.error(<AlertError message={$.input_error_message} error={e} onRetry={call} />);
        } else {
          alert.error(<AlertError error={e} onRetry={call} />);
        }
      }
      setSaving(false);
    };

    await call();
  };

  return (
    <div className="w-full md:w-1/2">
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="flex flex-wrap flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full mb-3 mt-2">
            <Label>{$.clockify_id_input}</Label>
            <Input
              name="clockify_id"
              maxLength="150"
              placeholder={$.clockify_id_placeholder}
              ref={register({ required: $.validation_required })}
              error={errors.clockify_id}
            />
            {errors.clockify_id && <Error>{errors.clockify_id.message}</Error>}
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Button type="submit" loading={saving} disabled={saving}>
            {saving ? $.saving_btn : $.save_btn}
          </Button>
          <Button secondary onClick={() => setStatus(null)} className="mr-4">
            {$.cancel_btn}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default InputCompanyForm;
