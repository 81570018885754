import React, { useState } from "react";
import { admin_project_candidate_detail as $ } from "strings";
import { B2DB } from "components/Typography";
import { Link } from "react-router-dom";
import Section from "components/Section";
import Header from "components/ListHeader";
import { withHttp } from "utils/str";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import UilEnvelope from "@iconscout/react-unicons/icons/uil-envelope";
import UilLinkedIn from "@iconscout/react-unicons/icons/uil-linkedin";
import UilPhone from "@iconscout/react-unicons/icons/uil-phone";
import UilMarker from "@iconscout/react-unicons/icons/uil-map-marker";
import UilUserCircle from "@iconscout/react-unicons/icons/uil-user-circle";
import UilSuitcase from "@iconscout/react-unicons/icons/uil-suitcase";
import UilCopy from "@iconscout/react-unicons/icons/uil-copy";
import LoadPlaceholder from "components/LoadPlaceholder";
import UilInfoCircle from "@iconscout/react-unicons/icons/uil-info-circle";
import UilCalendarAlt from "@iconscout/react-unicons/icons/uil-calendar-alt";

const TalentInfo = ({ talentInfo, open, onTogglePanel }) => {
  const [hasClip, setHasClip] = useState(false);
  const { candidateId, currentTitle, name, phone, email, linkedin, location, calendar_link } = talentInfo;

  const copyEmailToClipboard = () => {
    if (!email) return;
    navigator.clipboard.writeText(email);

    setHasClip(true);
    setTimeout(() => {
      setHasClip(false);
    }, 1000);
  }

  return (
    <Section id="candidate_talent_info" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilInfoCircle className="mr-2" size="18" />{$.talent_info_title}
          </B2DB>
          <div className="cursor-pointer" onClick={onTogglePanel}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && (
          <div className="px-2 pb-4">
            {!talentInfo && (
              <div className="flex-1 border border-link-water rounded px-6 pt-6">
                <LoadPlaceholder className="w-40 h-4 my-4" />
                <LoadPlaceholder className="w-40 h-4 mb-4" />
                <LoadPlaceholder className="w-20 h-4 mb-2" />
              </div>
            )}
            {talentInfo && (
              <>
                <div className="w-full flex">
                  <div className="w-1/2">
                    <Header Icon={UilUserCircle} text={$.name_title} />
                    <div className="text-sm text-midnight font-bold text-ellipsis">
                    <Link
                      className="inline-block"
                      to={`/admin/talent/${candidateId}/overview`}
                    >
                      <B2DB>
                        {name}
                      </B2DB>
                    </Link>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <Header Icon={UilSuitcase} text={$.current_title_title} />
                    <div className="text-sm text-midnight font-bold truncate">
                      {currentTitle || $.empty_value}
                    </div>
                  </div>
                </div>
                <div className="w-full flex mt-2">
                  <div className="w-1/2" onClick={copyEmailToClipboard}>
                    <Header Icon={UilEnvelope} text={$.email_address_title}>
                      {hasClip ?
                        <span className="text-kasmir text-xs font-normal ml-1 italic">
                          {$.copied_clipboard}
                        </span> :
                        <span className="ml-1"><UilCopy size="16" /></span>
                      }
                    </Header>
                    {email && (
                      <div className="text-sm text-midnight font-bold truncate">
                        {email || $.empty_value}
                      </div>
                    )}
                  </div>
                    <div className="w-1/2">
                      <Header Icon={UilLinkedIn} text={$.linkedin_url_title} />
                      <div className="text-sm text-midnight font-bold truncate">
                        {linkedin ?
                          <a href={linkedin} target="_blank" rel="noreferrer">
                            <B2DB>{linkedin}</B2DB>
                          </a> : <span>{$.empty_value}</span>
                        }
                      </div>
                    </div>
                </div>
                <div className="w-full flex mt-2">
                  <div className="w-1/2">
                    <Header Icon={UilPhone} text={$.phone_number_title} />
                    <div className="text-sm text-midnight font-bold text-ellipsis">
                      {phone || $.empty_value}
                    </div>
                  </div>
                  <div className="w-1/2">
                    <Header Icon={UilMarker} text={$.location_title} />
                    <div className="text-sm text-midnight font-bold text-ellipsis">
                      {location || $.empty_value}
                    </div>
                  </div>
                </div>
                <div className="w-full flex mt-2">
                  <div className="w-full">
                    <Header Icon={UilCalendarAlt} text={$.calendar_link_title} />
                    <div className="text-sm text-midnight font-bold text-ellipsis">
                      {calendar_link ?
                        <a href={withHttp(calendar_link)} target="_blank" rel="noreferrer">
                          <B2DB>{calendar_link}</B2DB>
                        </a> : <span>{$.empty_value}</span>
                      }
                    </div>
                    </div>
                </div>
              </>
            )}
            </div>
          )}
      </div>
    </Section>
  )
};

export default TalentInfo;