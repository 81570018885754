import { useState, useEffect, useCallback } from "react";

const useFetch = (api, id = "", defaultParams = {}) => {
  const [pageState, setPageState] = useState("loading");
  const [data, setData] = useState(null);

  const fetch = useCallback(() => {
    const load = async () => {
      try {
        const response = await api(id, defaultParams);
        setData(response);
        setPageState("loaded");
      } catch (e) {
        setPageState("error");
        console.error(e);
      }
    };

    if (!id || !api) {
      return;
    }

    load();
  }, [id, api]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return { data, setData, pageState, setPageState, reload: fetch };
};

export default useFetch;
