import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { admin_contact as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import UilAlignJustify from "@iconscout/react-unicons/icons/uil-align-justify";
import { getContactNotesV2 } from "utils/adminApi";
import Note from "./Note";
import NoteForm from "./NoteForm";
import { getAdminUser } from "utils/localStorageService";
import DebouncedInput from "components/DebouncedInput";
import { usePageCache } from "contexts/pageCacheContext";
import { defer } from "rxjs";
import useOnScreen from "hooks/useOnScreen";

const PAGE_SIZE = 10;

const Notes = ({ data, defaultContactId, hubspotInputsDisabled, onDisabledClick }) => {
  const [notes, setNotes] = useState([]);
  const [hasMore, setHasMore] = useState();
  const [page, setPage] = useState();
  const [search, setSearch] = useState("");
  const lastFetch = useRef();
  const { id } = useParams();
  const adminUser = getAdminUser();
  const isFirst = useRef(true);
  const { set } = usePageCache();

  useEffect(() => {
    set("url_contact", `/admin/contacts/${id}/notes`);
  }, [set, id]);

  const fetch = useCallback(
    async (
      pageReq,
      concat = true,
      size,
      updatePage = true,
      isPagination = false
    ) => {

      if (!defaultContactId) {
        return;
      }
      // Ignore if pagination and there is another fetch going
      if (isPagination && lastFetch.current) {
        return;
      }
      if (lastFetch.current) {
        lastFetch.current.unsubscribe();
        lastFetch.current = null;
      }

      lastFetch.current = defer(() => {
        return getContactNotesV2({
          contact_id: defaultContactId,
          ordering: '-created_on',
          page_size: size || PAGE_SIZE,
          page: pageReq,
          search,
        });
      }).subscribe(
        (response) => {
          if (concat) {
            setNotes((d) => d.concat(response.results));
          } else {
            setNotes(response.results);
          }
          if (updatePage) {
            setPage(pageReq);
          }
          setHasMore(!!response.next);
          lastFetch.current = null;
        },
        (e) => {
          console.error(e);
        }
      );
    },
    [search, defaultContactId]
  );

  const { measureRef, isIntersecting, observer } = useOnScreen();

  useEffect(() => {
    if (isIntersecting && hasMore) {
      fetch(page + 1, true, null, true, true);
      observer.disconnect();
    }
  }, [isIntersecting, hasMore, fetch]);

  useEffect(() => {
    if (isFirst.current) {
      return;
    }
    fetch(1, false);
  }, [fetch]);

  useEffect(() => {
    if (isFirst.current) {
      const size = PAGE_SIZE;

      isFirst.current = false;

      setPage(1);
      fetch(1, false, size, false);
    }
  }, [fetch, notes]);

  const addNote = (note) => {
    setNotes([note, ...notes]);
  };

  const removeNote = (id) => {
    setNotes(notes.filter((n) => n.id !== id));
  };

  const updateNote = (id, note) => {
    setNotes(notes.map((n) => (n?.id === id ? { ...n, ...note } : n)));
  };

  const today = new Date();
  const weekAgo = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 7
  );
  const monthAgo = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    today.getDate()
  );

  return (
    <Section id="admin_contact_notes" className="flex mt-px h-full">
      <div className="bg-white flex-1 mr-2 h-full flex flex-col">
        <div className="flex items-center px-1 mb-1">
          <B2DB className="flex flex-1 items-center">
            <UilAlignJustify className="mr-2" size="18" /> {$.notes_title}
          </B2DB>
          <div className="w-70">
            <DebouncedInput
              className="w-full px-3 rounded h-8 flex items-center font-bold text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-bold focus:outline-none appearance-none border border-geyser focus:border-link focus:border-2"
              placeholder={$.notes_search_placeholder}
              value={search}
              onChange={(s) => setSearch(s)}
            />
          </div>
        </div>
        <div
          className="flex-1 min-h-0 overflow-y-auto px-1"
          id="scrollableDiv"
          style={{
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          {notes !== null && notes.map((n, i) => (
            <React.Fragment key={n.id}>
              {notes[i - 1] &&
              new Date(n.created_timestamp) < monthAgo &&
              (!notes[i - 1] ||
                (notes[i - 1] &&
                  new Date(notes[i - 1].created_timestamp) >= monthAgo)) ? (
                <Separator text="This Month" />
              ) : new Date(n.created_timestamp) < weekAgo &&
                (!notes[i - 1] ||
                  (notes[i - 1] &&
                    new Date(notes[i - 1].created_timestamp) >=
                      weekAgo)) ? (
                <Separator text="This Week" />
              ) : null}
              <Note
                className={i % 2 === 0 ? "bg-link-water bg-opacity-60" : "bg-white"}
                note={n}
                updateNote={updateNote}
                editable={n.created_by === `${adminUser.first_name} ${adminUser.last_name}` && !hubspotInputsDisabled}
                removeNote={removeNote}
                measureRef={measureRef}
              />
            </React.Fragment>
          ))}
        </div>
        <div className="border-t border-geyser p-2">
          {data && notes && defaultContactId && !hubspotInputsDisabled && (
            <NoteForm
              data={data}
              defaultContactId={defaultContactId}
              addNote={addNote}
              disabled={hubspotInputsDisabled}
              onDisabledClick={onDisabledClick}
            />
          )}
        </div>
      </div>
    </Section>
  )
}

const Separator = ({ text }) => (
  <div className="flex items-center">
    <div className="border-t border-geyser flex-1"></div>
    <div className="mx-2 text-sm font-bold text-geyser">{text}</div>
    <div className="border-t border-geyser flex-1 flex-no-shrink"></div>
  </div>
);

export default Notes;
