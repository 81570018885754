import React from "react";
import { Link } from "react-router-dom";

const B2BLink = ({ children, ...props }) => (
  <Link {...props}>
    <B2B className="inline-block hover:text-link-dark">{children}</B2B>
  </Link>
);

const B3BLink = ({ children, ...props }) => (
  <Link {...props}>
    <B3B className="inline-block hover:text-link-dark">{children}</B3B>
  </Link>
);

const B2DBLink = ({ children, ...props }) => (
  <Link {...props}>
    <B2DB className="inline-block">{children}</B2DB>
  </Link>
);

const H1B = ({ children, className = "" }) => (
  <h1
    className={"font-lato text-midnight text-3xl font-bold " + className}
  >
    {children}
  </h1>
);

const H2B = ({ children }) => (
  <h2 className="text-link text-xl font-bold">{children}</h2>
);

const H3W = ({ children }) => (
  <h3 className="text-white text-base font-bold">{children}</h3>
);

const B2DB = ({ children, className = "" }) => (
  <div className={"text-midnight text-sm font-bold " + className}>
    {children}
  </div>
);

const B2B = ({ children, className = "", ...props }) => (
  <div {...props} className={"text-link text-sm font-bold " + className}>
    {children}
  </div>
);

const B2G = ({ children }) => (
  <div className="text-kasmir text-sm font-bold">{children}</div>
);

const B2W = ({ children }) => (
  <div className="text-white text-sm font-bold">{children}</div>
);

const B3B = ({ children, className = "" }) => (
  <div className={"text-link text-sm font-bold " + className}>{children}</div>
);

const B3W = ({ children }) => (
  <div className="text-white text-sm font-bold">{children}</div>
);

const B1 = ({ children, className = "" }) => (
  <div className={"text-kasmir text-sm font-medium " + className}>
    {children}
  </div>
);

const D1 = ({ children, className = "" }) => (
  <div className={"text-kasmir text-xs font-medium " + className}>
    {children}
  </div>
);

const D2 = ({ children }) => (
  <div className="text-white text-xs font-medium">{children}</div>
);

const D3 = ({ children }) => (
  <div className="text-link text-xs font-bold">{children}</div>
);

const D4 = ({ children }) => (
  <div className="text-red text-xs font-medium">{children}</div>
);

export {
  H1B,
  H2B,
  H3W,
  B2DB,
  B2B,
  B2G,
  B2W,
  B3B,
  B3W,
  B1,
  D1,
  D2,
  D3,
  D4,
  B2BLink,
  B3BLink,
  B2DBLink,
};
