import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import { admin_project_attachments as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import { AnchorBtn, Button, DownloadBtn, RemoveBtn } from "components/Buttons";
import LoadPlaceholder from "components/LoadPlaceholder";
import UilFileAlt from "@iconscout/react-unicons/icons/uil-file-alt";
import useCachedFetch from "hooks/useCachedFetch";
import {
  deleteProjectFileV2,
  getProjectFilesV2,
  postProjectFile,
} from "utils/adminApi";
import { toFormat } from "utils/date";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { useDropzone } from "react-dropzone";
import Select from "components/Select";
import { getAdminUser } from "utils/localStorageService";
import { Input } from "components/Form";
import { withHttp } from "utils/str";

const fileTypeOptions = [
  { label: $.project_proposal_option, value: "Project Proposal" },
  { label: $.nda_option, value: "NDA" },
  { label: $.msa_option, value: "MSA" },
  { label: $.sow_option, value: "SOW" },
];

const Attachments = ({ project, setData, hubspotInputsDisabled, onDisabledClick }) => {
  const { id } = useParams();
  const { set } = usePageCache();
  const [saving, setSaving] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [fileType, setFileType] = useState();
  const [url, setUrl] = useState("");
  const alert = useAlert();
  const [form, setForm] = useState();
  const user = getAdminUser();
  const attachments = useCachedFetch(
    "admin_project_files_v2",
    getProjectFilesV2,
    project && project.id
  );

  useEffect(() => {
    set("url_project", `/admin/project/${id}/attachments`);
  }, [set, id]);

  const onAddSuccess = () => {
    setData({
      ...(project || []),
      attachments_count: project.attachments_count + 1
    });
  }

  const onDeleteSuccess = () => {
    setData({
      ...(project || []),
      attachments_count:  project.attachments_count - 1
    });
  }

  const onDrop = useCallback((acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [
      ".doc",
      ".docx",
      ".pdf",
      ".xls",
      ".xlsx",
      ".ppt",
      ".pptx",
      ".txt",
      ".png",
      ".jpeg",
      ".jpg",
    ],
    multiple: false,
  });

  const onDeleteFile = async (fileId) => {
    const call = async () => {
      try {
        const res = [...attachments.data.results].filter((a) => a.id !== fileId);
        attachments.setData((d) => ({ ...d, results: res }))
        await deleteProjectFileV2(fileId);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
    onDeleteSuccess();
  };

  const onSaveFile = async () => {
    setSaving(true);

    const reader = new FileReader();

    reader.onabort = () => {
      alert.error("file reading was aborted");
      console.error("file reading was aborted");
    };
    reader.onerror = () => {
      alert.error("file reading has failed");
      console.error("file reading has failed");
    };
    reader.onload = () => {
      const binaryStr = reader.result;
      upload(
        selectedFile,
        binaryStr,
        async (response) => {
          attachments.setData((d) => ({
            ...d,
            results: [
              ...d.results,
              {
                ...response,
                created_by: user.first_name + " " + user.last_name,
              },
            ],
          }));
          setSelectedFile(null);
          setFileType(null);
          setForm(null);
          setSaving(false);
        },
        () => {}
      );
    };

    reader.readAsDataURL(selectedFile);
  };

  const onSaveUrl = async () => {
    try {
      const response = await postProjectFile({
        project_id: project.id,
        filename: url,
        file_type: "URL",
        category: fileType.value,
        file: url,
      });

      attachments.setData((d) => ({
        ...d,
        results: [
          ...d.results,
          {
            ...response,
            created_by: user.first_name + " " + user.last_name,
          },
        ],
      }));

      setUrl("");
      setFileType(null);
      setForm(null);
      setSaving(false);
      onAddSuccess();
    } catch (e) {
      setSaving(false);
      console.error(e);
      alert.error(<AlertError error={e} />);
    }
  };

  const upload = async (file, binaryStr, onSuccess, onFailed) => {
    const idx = file.path.lastIndexOf(".");
    let fileType = "";
    if (idx > -1) {
      fileType = file.path.slice(idx+1).toUpperCase();
    }
    try {
      const response = await postProjectFile({
        project_id: project.id,
        filename: file.path,
        file_type: fileType,
        category: fileType.value,
        file: binaryStr.replace(/data:.*base64,/g, ""),
      });
      onSuccess(response);
      onAddSuccess();
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} />);
    }
  };

  if (!project || !attachments) {
    return (
      <div className="bg-white flex-1 flex flex-col px-2 py-4">
        <LoadPlaceholder className="w-60 h-4 mt-10 mb-10" />
        <LoadPlaceholder className="w-60 h-4 mb-2" />
        <LoadPlaceholder className="w-40 h-4 mb-2" />
        <LoadPlaceholder className="w-32 h-4 mb-2" />
      </div>
    );
  }

  return (
    <Section
      id="admin_project_attachments"
      className="flex mt-1 h-full flex-col w-2/3"
    >
      {!form && (
        <div className="flex mb-1 h-12 items-center">
          <Button noMinW onClick={() => hubspotInputsDisabled && onDisabledClick ? onDisabledClick() : setForm("file")}>
            {$.attach_file_btn}
          </Button>
          <Button noMinW className="ml-1" onClick={() => hubspotInputsDisabled && onDisabledClick ? onDisabledClick() : setForm("url")}>
            {$.attach_url_btn}
          </Button>
        </div>
      )}
      {form === "file" && (
        <div className="flex items-center h-12 mb-1">
          <Button
            disabled={!selectedFile || !fileType || saving}
            noMinW
            onClick={onSaveFile}
          >
            {saving ? $.saving_btn : $.save_btn}
          </Button>
          <div
            {...getRootProps()}
            className="bg-white text-sm rounded h-10 flex-1 mx-2 text-kasmir flex items-center px-2 border border-geyser"
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <div>{$.drop_files_message}</div>
            ) : selectedFile ? (
              <div className="text-midnight">{selectedFile.name}</div>
            ) : (
              <div>{$.drag_browse_message}</div>
            )}
          </div>
          <Select
            placeholder={$.file_placeholder}
            options={fileTypeOptions}
            onChange={(v) => setFileType(v)}
            value={fileType}
          />
        </div>
      )}
      {form === "url" && (
        <div className="flex items-center h-12 mb-1">
          <Button
            disabled={!url || !fileType || saving}
            noMinW
            onClick={onSaveUrl}
          >
            {saving ? $.saving_btn : $.save_btn}
          </Button>
          <div className="text-sm rounded h-10 flex-1 mx-2">
            <Input value={url} onChange={(v) => setUrl(v.target.value)} />
          </div>
          <Select
            placeholder={$.file_placeholder}
            options={fileTypeOptions}
            onChange={(v) => setFileType(v)}
            value={fileType}
          />
        </div>
      )}
      <div className="bg-white flex-1 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilFileAlt className="mr-2 text-link" size="18" /> {$.title}
          </B2DB>
        </div>
        <div className="px-2 py-2">
          <div className="flex items-center text-sm text-kasmir font-bold">
            <div className="flex items-center flex-1">
              <div className="w-1/4">{$.name_label}</div>
              <div className="w-1/4">{$.attached_by_label}</div>
              <div className="w-1/4">{$.attached_on_label}</div>
              <div className="w-1/4">{$.file_info_label}</div>
            </div>
            <div className="w-1/12" />
          </div>
          {attachments?.data?.results && Array.isArray(attachments.data.results) &&
            attachments.data.results.map((f) => (
              <div
                key={f.id}
                className="flex items-center text-sm text-midnight"
              >
                <div className="flex items-center flex-1">
                  <div className="w-1/4 font-bold">{f.category}</div>
                  <div className="w-1/4">{f.created_by}</div>
                  <div className="w-1/4">
                    {toFormat(new Date(f.created_at), "MM/dd/yyyy h:mm a")}
                  </div>
                  <div className="w-1/4 break-words">
                    {f.filename}
                    {!!f.size && <>, {f.size}</>}
                  </div>
                </div>
                <div className="w-1/12 flex items-center justify-end">
                  {f.file_type === "URL" ? (
                    <AnchorBtn
                      colored
                      href={withHttp(f.url)}
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  ) : (
                    <DownloadBtn
                      colored
                      download={f.filename}
                      href={withHttp(f.url)}
                    />
                  )}
                  <RemoveBtn colored onClick={() => onDeleteFile(f.id)} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </Section>
  );
};

export default Attachments;
