import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import { admin_company_list as $ } from "strings";
import { B2BLink } from "components/Typography";
import { Button } from "components/Buttons";
import { usePageCache } from "contexts/pageCacheContext";
import { getCompanies } from "utils/adminApi";
import NewCompanyForm from "./NewCompanyForm";
import HubspotToast from "components/HubspotToast";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus-circle";
import Section from "components/Section";
import { fetchOwners, fetchPrimaryClientEngagement } from "utils/fetch";
import { getAdminFlags } from "utils/localStorageService";
import useTable from "hooks/useTable";
import manager from "filters/company";

// const activeOptions = [
//   { label: $.active_option, value: "True" },
//   { label: $.inactive_option, value: "False" },
// ];

const CompanyList = () => {
  const alert = useAlert();
  const flags = getAdminFlags();
  const { set } = usePageCache();
  const [modal, setModal] = useState();
  const { Table, tableProps, Filters, filtersProps } = useTable({
    cacheKey: "cache_company_list",
    api: getCompanies,
    defaultParams: { ordering: "-updated_at" },
  });

  useEffect(() => {
    set("url_company", "/admin/company");
  }, [set]);

  useEffect(() => {
    document.title = "Pipeline - Company Search";
  }, []);

  const schema = {
    name: {
      show: true,
      renderRow: ({ r }) => (
        <B2BLink
          onClick={() => set(`admin_company_${r.id}_fetch`, r)}
          to={`/admin/company/${r.id}`}
        >
          {r.name}
        </B2BLink>
      ),
    },
    owner__user__id: {
      sortKey: "owner_name",
      show: true,
      type: "select",
      selectProps: { async: true, loadOptions: fetchOwners },
      renderRow: ({ r }) =>
        r.owner
          ? r.owner.first_name + " " + r.owner.last_name
          : "",
    },
    client_engagement__id: {
      sortKey: "client_engagement_name",
      show: true,
      type: "select",
      selectProps: { async: true, loadOptions: fetchPrimaryClientEngagement },
      renderRow: ({ r }) =>
        r.client_engagement
          ? r.client_engagement.first_name +
            " " +
            r.client_engagement.last_name
          : "",
    },
    projects_count: {
      needsIdSort: true,
      show: true,
      type: "int",
      renderRow: ({ v }) => v + " " + $.projects_count_label,
    },
    // employees: { type: "int" },
    // revenue: { type: "int", renderRow: ({ v }) => (v ? "$" + v : "") },
    description: { needsIdSort: true, type: "text" },
    crelate_id: {
      type: "text",
      renderRow: ({ r, v }) => (r.crelate_result ? r.crelate_result : v),
    },
    created_at: {
      show: true,
      type: "date",
      format: "yyyy-MM",
      rowFormat: "MM-dd-yyyy H:mm",
    },
    updated_at: {
      show: true,
      type: "date",
      format: "yyyy-MM",
      rowFormat: "MM-dd-yyyy H:mm",
    },
    // active: {
    //   show: true,
    //   type: "select",
    //   selectProps: { options: activeOptions },
    //   renderRow: ({ v }) => (v ? $.active_option : $.inactive_option),
    // },
  };

  const onAddClick = () => {
    alert.success(<HubspotToast type='add-company' />, {timeout: 10000});
  }

  return (
    <Section id="admin_company_list">
      <div className="flex">
        <Filters {...{ schema, $, ...filtersProps, manager }} />
        <div className="bg-white px-4 py-6 flex-1 min-w-0">
          <Table
            {...{
              schema,
              $,
              name: "companies",
              ...tableProps,
              showAll: false,
              manager,
            }}
          >
            <Button onClick={() => flags.hubspot_features_enabled ? onAddClick() : setModal("add_company")}>
              <UilPlus size="18" className="mr-1" /> {$.add_company_button}
            </Button>
          </Table>
        </div>
      </div>
      {modal === "add_company" && <NewCompanyForm onClose={setModal} />}
    </Section>
  );
};

export default CompanyList;
