import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {admin_contact as $} from "strings";
import {
  createTalentFromUser,
  patchAccount,
  postAccountContactV2
} from "utils/adminApi";
import Select from "components/Select";
import AlertError from "components/AlertError";
import {Button} from "components/Buttons";
import DatePicker from "components/DatePicker";
import {Input} from "components/Form";
import {useAlert} from "react-alert";
import {fetchCompanies} from "utils/fetch";
import {toFormat} from "utils/date";
import {getAdminUser} from "utils/localStorageService";
import {SHELL_COMPANY_IDS} from "../../../../../utils/misc";


const ROLE_TYPES = {
  talent: { label: 'Talent', value: "talent" },
  company: { label: 'Company Relationship', value: "company" },
};

const getRoleTypeFromURL = () => {
  let initialRoleType;
  initialRoleType = new URLSearchParams(window.location.search).get("type") || undefined;
  if (initialRoleType) {
    initialRoleType = ROLE_TYPES[initialRoleType];
  }
  return initialRoleType;
};

const boolOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

const fetchCompaniesNoShell = async (a, b, c) => {
  const response = await fetchCompanies(a, b, c);
  response.options = response.options.filter(c => !SHELL_COMPANY_IDS.includes(c.value));
  return response;
};

const RoleActions = ({
  data,
  talentRolesDisabled,
  onCompanyRoleSuccess,
  onAddTalentSuccess,
  userId,
  hubspotInputsDisabled,
  onDisabledClick
}) => {
  const { id } = useParams();
  const alert = useAlert();
  const adminUser = getAdminUser();
  const [selectedRoleType, setSelectedRoleType] = useState();
  const [companyRoleValues, setCompanyRoleValues] = useState({
    company_name: '',
    company_id: '',
    role_title: '',
    is_primary_role: '',
    is_active_role: '',
    start_date: '',
    end_date: '',
    staff_poc: adminUser?.id
  });
  const [roleOptions, setRoleOptions] = useState(Object.values(ROLE_TYPES));
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    let options = hubspotInputsDisabled ? [] : [ROLE_TYPES.company];
    if (!talentRolesDisabled) {
      options.push(ROLE_TYPES.talent);
    } else if (selectedRoleType === ROLE_TYPES.talent) {
      setSelectedRoleType(null);
    }
    setRoleOptions(options);
  }, [talentRolesDisabled]);

  useEffect(() => {
    const currentRoleType = getRoleTypeFromURL();
    if (currentRoleType) {
      setSelectedRoleType(currentRoleType);
    }
  }, []);

  const onAddCompanyRole = async () => {
    setIsSubmitting(true);
    try {
      const toSend = {
        ...companyRoleValues,
        start_date: companyRoleValues.start_date ? toFormat(companyRoleValues.start_date, "yyyy-MM-dd") : null,
        end_date: companyRoleValues.end_date ? toFormat(companyRoleValues.end_date, "yyyy-MM-dd") : null,
        email: data.contact_email ? data.contact_email : data.account_email,
        user_id: id
      };

      const response = await postAccountContactV2(toSend);
      onCompanyRoleSuccess(response);
      resetForm();
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onAddCompanyRole} />);
    }
    setIsSubmitting(false);
  };

  const onAddTalentRole = async () => {
    setIsSubmitting(true);
    try {
      await createTalentFromUser(userId);
      await patchAccount(id, {
        staff_poc: adminUser.id
      });
      onAddTalentSuccess(id);
      resetForm();
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onAddTalentRole} />);
    }
    setIsSubmitting(false);
  };

  const resetForm = () => {
    setCompanyRoleValues({
      company_name: '',
      company_id: '',
      role_title: '',
      is_primary_role: '',
      is_active_role: '',
      start_date: '',
      end_date: ''
    });
    setSelectedRoleType(null);
  };

  return (
    <div className="candidate_list_actions flex mb-1 items-start py-2">
      <div className="flex w-3/4">
        <div className="flex items-start justify-start mt-1 mr-1">
          {!selectedRoleType && <Button noMinW disabled>{$.save_btn}</Button>}
          {selectedRoleType === ROLE_TYPES.company &&
            <Button
              noMinW
              onClick={() => onAddCompanyRole()}
              disabled={
                isSubmitting ||
                !companyRoleValues.company_id ||
                !companyRoleValues.company_name ||
                !companyRoleValues.role_title ||
                !(typeof companyRoleValues.is_primary_role === 'boolean') ||
                !(typeof companyRoleValues.is_active_role === 'boolean') ||
                (companyRoleValues.is_active === false ? !companyRoleValues.end_date : false)
              }
              loading={isSubmitting}
            >
              {$.save_btn}
            </Button>
          }
          {selectedRoleType === ROLE_TYPES.talent &&
            <Button noMinW onClick={() => onAddTalentRole()}
                    disabled={isSubmitting} loading={isSubmitting}>
              {$.create_talent_record_btn}
            </Button>
          }
        </div>
        <div className="flex flex-col gap-1">
          <div
            className="flex flex-1 items-start justify-start gap-1"
            onClick={() => {
              if (hubspotInputsDisabled && talentRolesDisabled) {
                onDisabledClick();
              }
            }}
          >
            <Select
              className="w-56"
              placeholder={$.select_role_label}
              onChange={(v) => setSelectedRoleType(v)}
              value={selectedRoleType}
              options={roleOptions}
            />
            {selectedRoleType === ROLE_TYPES.company &&
              <>
                <Select
                  className="w-56"
                  onChange={(v) =>
                    setCompanyRoleValues({
                      ...companyRoleValues,
                      'company_name': v.label,
                      'company_id': v.value
                    })
                  }
                  value={
                    companyRoleValues?.company_id && companyRoleValues?.company_name
                      ? {
                        label: companyRoleValues.company_name,
                        value: companyRoleValues.company_id
                      }
                      : null
                  }
                  placeholder={$.company_name_placeholder}
                  async
                  loadOptions={fetchCompaniesNoShell}
                />
                <div className="w-56 my-1">
                  <Input
                    type="text"
                    placeholder={$.current_title_placeholder}
                    text={companyRoleValues.role_title}
                    defaultValue={companyRoleValues.role_title || ''}
                    onChange={(e) => setCompanyRoleValues({
                      ...companyRoleValues,
                      'role_title': e.target.value
                    })}
                  />
                </div>
              </>
            }
            {selectedRoleType === ROLE_TYPES.talent &&
              <div className="w-56 my-1">
                <Input value={`${data?.first_name} ${data?.last_name}`}
                       disabled/>
              </div>}
          </div>
          <div className="flex flex-1 items-start justify-start gap-1">
            {selectedRoleType === ROLE_TYPES.company &&
              <>
                <Select
                  className="w-48"
                  onChange={(v) =>
                    setCompanyRoleValues({
                      ...companyRoleValues,
                      'is_primary_role': v ? v.value : ''
                    })
                  }
                  value={boolOptions.find(o => o.value === companyRoleValues?.is_primary_role) || null}
                  placeholder={$.is_primary_placeholder}
                  options={boolOptions}
                  isClearable={true}
                />
                <Select
                  className="w-48"
                  onChange={(v) =>
                    setCompanyRoleValues({
                      ...companyRoleValues,
                      'is_active_role': v ? v.value : ''
                    })
                  }
                  value={boolOptions.find(o => o.value === companyRoleValues?.is_active_role) || null}
                  placeholder={$.is_active_placeholder}
                  options={boolOptions}
                  isClearable={true}
                />
                <div className="w-64">
                  <DatePicker
                    placeholderText={$.role_started_on_label}
                    value={companyRoleValues.start_date ? new Date(companyRoleValues.start_date) : null}
                    format="MMMM dd, yyyy"
                    monthly={false}
                    onChange={(v) => setCompanyRoleValues({
                      ...companyRoleValues,
                      "start_date": v
                    })}
                  />
                </div>
                <div className="w-64">
                  {companyRoleValues.is_active_role === true ? <></> : (
                    <DatePicker
                      placeholderText={$.role_ended_on_label}
                      value={companyRoleValues.end_date ? new Date(companyRoleValues.end_date) : null}
                      format="MMMM dd, yyyy"
                      monthly={false}
                      onChange={(v) => setCompanyRoleValues({
                        ...companyRoleValues,
                        "end_date": v
                      })}
                    />)}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleActions;
