import React, { useEffect } from "react";
import Section from "components/Section";
import Description from "./Description";
import TalentParagraph from "./TalentParagraph";
import Company from "./Company";
import ActiveTalent from "./ActiveTalent";
import LeadSource from "./LeadSource";
import { useParams } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext"
import ProjectContacts from "./ProjectContacts";
import { getAdminFlags } from "utils/localStorageService";

const Overview = ({ data, hubspotInputsDisabled, onDisabledClick }) => {
  const { id } = useParams();
  const { set } = usePageCache();
  const flags = getAdminFlags();

  useEffect(() => {
    set("url_project", `/admin/project/${id}`);
  }, [set, id]);

  return (
    <Section id="admin_project_overview" className="flex mt-px h-full">
        {data && (
          <div className="w-full flex flex-col md:flex-row">
            <div className="flex-1 flex flex-col h-full overflow-y-auto">
              <ActiveTalent data={data} />
              <TalentParagraph project={data} hubspotInputsDisabled={hubspotInputsDisabled} onDisabledClick={onDisabledClick} />
            </div>
            <div className="flex-1 flex flex-col h-full overflow-y-auto">
              <Description project={data} hubspotInputsDisabled={hubspotInputsDisabled} onDisabledClick={onDisabledClick} />
            </div>
            <div className="flex-1 flex flex-col h-full overflow-y-auto">
              <LeadSource project={data}hubspotInputsDisabled={hubspotInputsDisabled} onDisabledClick={onDisabledClick} />
              <Company project={data}hubspotInputsDisabled={hubspotInputsDisabled} onDisabledClick={onDisabledClick} />
              <ProjectContacts project={data} hubspotInputsDisabled={hubspotInputsDisabled} onDisabledClick={onDisabledClick} />
            </div>
          </div>
        )}
    </Section>
  );
};

export default Overview;
