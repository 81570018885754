import React, { useState } from "react";
import { admin_candidate_project_delete_form as $ } from "strings";
import { global as $$ } from "strings";
import { Header, Footer } from "components/Card";
import { Input } from "components/Form";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { deleteCandidate } from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { B2DB } from "components/Typography";
import AlertSuccess from "../../../../../components/AlertSuccess";

const DeleteCandidateForm = ({ onClose, onSuccess, talent, project }) => {
  const alert = useAlert();
  const [value, setValue] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const params = new URL(document.location).searchParams;
  const candidateId = params.get("cpid");

  const onDelete = async () => {
    try {
      setSubmitting(true);
      await deleteCandidate(candidateId);
      alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
      onSuccess();
      setSubmitting(false);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onDelete} />);
    }
  };

  return (
    <Modal isDirty={false} onClose={onClose} id="admin_candidate_delete_form">
      <div className="flex flex-col flex-nowrap flex-1 min-h-0" noValidate>
        <Header title={$.confirm_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <B2DB>{$.sure_message.replace("%", talent.fullname).replace("#", project.project_name)}</B2DB>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-4">
            <Input onChange={(e) => setValue(e.target.value)} />
          </div>
        </div>
        <Footer>
          <Button secondary onClick={onClose} className="mr-4">
            {$.cancel_button}
          </Button>
          {value === talent.fullname && !submitting ? (
            <Button danger onClick={onDelete} type="submit">
              {$.confirm_button}
            </Button>
          ) : (
            <Button disabled type="submit">
              {$.confirm_button}
            </Button>
          )}
        </Footer>
      </div>
    </Modal>
  );
};

export default DeleteCandidateForm;
