import React, { useMemo } from "react";
import Select from "./ListSearchSelect";
import FilterDeleteBtn from "./FilterDeleteBtn";
import RatingInput from "./ListRatingInput";
import useQualifications from "hooks/useQualifications";
import Rating from "./ListRating";
import { Checkbox } from "./Form";
import useDescriptors from "hooks/useDescriptors";
import useDescriptorOptions from "hooks/useDescriptorOptions";

const FilterQualification = ({
  filter,
  updateSearch,
  setIndex,
  index,
  isSelected,
  removeFilter,
}) => {
  const { qualifications } = useQualifications();
  const descriptors = useDescriptors();
  const descOptions = useDescriptorOptions();

  const qVal =
    filter.data && filter.data.qualification
      ? filter.data.qualification.value
      : "";

  const dVal =
    filter.data && filter.data.descriptor ? filter.data.descriptor.value : "";

  const descriptorOptions = useMemo(() => {
    const defOpt = [{ value: null, label: "No Descriptor" }];

    if (!descriptors || !descriptors[qVal]) {
      return defOpt;
    }

    return defOpt.concat(
      descriptors[qVal].map((d) => ({ label: d.name, value: d.id }))
    );
  }, [qVal, descriptors]);

  const descOptionsOptions = useMemo(() => {
    const defOpt = [{ value: null, label: "No Descriptor Value" }];

    if (!descOptions || !descOptions[dVal] || !dVal) {
      return defOpt;
    }

    return defOpt.concat(
      descOptions[dVal].map((o) => ({ label: o.value, value: o.id }))
    );
  }, [dVal, descOptions]);

  return !isSelected ? (
    <div
      onClick={() => setIndex(index)}
      className="bg-white border flex items-center border-link rounded h-8 px-1 text-link text-sm font-bold"
    >
      {filter.data && filter.data.qualification
        ? filter.data.qualification.label
        : "Select a qualification"}
      <Rating count={filter.data ? filter.data.rating : 0} />
      <FilterDeleteBtn onClick={() => removeFilter(index)} />
    </div>
  ) : (
    <div className="bg-white border-r border-l border-t items-center border-link rounded text-sm flex flex-col">
      <div className="h-8 flex items-center w-full border-b border-link">
        <div className="min-w-40 -mb-px">
          <Select
            autoFocus
            placeholder={filter.placeholder}
            onChange={(v) =>
              updateSearch(index, {
                ...filter.data,
                qualification: v,
                descriptor: null,
                descriptorValue: null,
              })
            }
            value={filter.data ? filter.data.qualification : null}
            options={qualifications.qualificationsOptions}
            {...filter.selectProps}
          />
        </div>
        <RatingInput
          value={filter.data ? filter.data.rating : 0}
          onChange={(v) => updateSearch(index, { ...filter.data, rating: v })}
        />
      </div>
      <div className="h-8 flex items-center w-full z-30 border-b border-link">
        <Select
          placeholder="Select Descriptor"
          onChange={(v) =>
            updateSearch(index, {
              ...filter.data,
              descriptor: v,
              descriptorValue: null,
            })
          }
          value={filter.data ? filter.data.descriptor : null}
          options={descriptorOptions}
        />
      </div>
      <div className="h-8 flex items-center w-full z-30 border-b border-link">
        <Select
          placeholder="Select Descriptor Value"
          onChange={(v) =>
            updateSearch(index, { ...filter.data, descriptorValue: v })
          }
          value={filter.data ? filter.data.descriptorValue : null}
          options={descOptionsOptions}
        />
      </div>
      <div className="h-8 px-1 flex items-center border-b rounded-b border-link w-full">
        <Checkbox
          onChange={(v) =>
            updateSearch(index, { ...(filter.data || {}), useSelf: v })
          }
          value={filter.data ? filter.data.useSelf : false}
        >
          <div className="flex items-center">Include Self Ratings</div>
        </Checkbox>
      </div>
    </div>
  );
};

export default FilterQualification;
