import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { admin_talent_delete_form as $ } from "strings";
import { global as $$ } from "strings";
import { Header, Footer } from "components/Card";
import { Input } from "components/Form";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { deleteAccountV2 } from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { B2DB } from "components/Typography";
import { useHistory } from "react-router-dom";
import AlertSuccess from "../../../components/AlertSuccess";

const DeleteForm = ({ onClose, data }) => {
  const alert = useAlert();
  const { id } = useParams();
  const [value, setValue] = useState("");
  const history = useHistory();
  const [submitting, setSubmitting] = useState(false);

  const onDelete = async () => {
    const call = async () => {
      try {
        setSubmitting(true);
        await deleteAccountV2(id);
        alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
        history.push(`/admin/talent/`);
        setSubmitting(false);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  return (
    <Modal isDirty={false} onClose={onClose} id="admin_talent_delete_form">
      <div className="flex flex-col flex-nowrap flex-1 min-h-0" noValidate>
        <Header title={$.confirm_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <B2DB>
            {$.sure_message.replace(
              "%",
              data.first_name + " " + data.last_name
            )}
          </B2DB>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-4">
            <Input onChange={(e) => setValue(e.target.value)} />
          </div>
        </div>
        <Footer>
          <Button secondary onClick={onClose} className="mr-4">
            {$.cancel_button}
          </Button>
          {value &&
          value.trim() ===
            (
              (data.first_name || "") +
              " " +
              (data.last_name || "")
            ).trim() &&
          !submitting ? (
            <Button danger onClick={onDelete} type="submit">
              {$.confirm_button}
            </Button>
          ) : (
            <Button disabled type="submit">
              {$.confirm_button}
            </Button>
          )}
        </Footer>
      </div>
    </Modal>
  );
};

export default DeleteForm;
