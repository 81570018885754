import axios from "axios";
import * as Sentry from "@sentry/react";
import {
  getAccessToken,
  getRefreshToken,
  setToken,
  clearToken,
} from "./localStorageService";
import history from "./history";

let setLogged;

const ax = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { "Content-Type": "application/json" },
});

ax.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    Sentry.captureException(error);
    Promise.reject(error);
  }
);

ax.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      (originalRequest._retry ||
        originalRequest.url.includes("auth/token/refresh/"))
    ) {
      clearToken();
      setLogged(false);
      history.push("/login");
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      const refreshToken = getRefreshToken();

      const res = await ax.post(
        "auth/token/refresh/",
        { refresh: refreshToken },
        { headers: { Authorization: "Basic Og==" } }
      );

      if (res.status === 200) {
        setToken(res.data);

        return ax(originalRequest);
      }
    }
    Sentry.captureException(error);
    return Promise.reject(error);
  }
);

export const configureLoggedSetter = (setter) => {
  setLogged = setter;
};

export const login = async (params) => {
  return (
    await ax.post("auth/token/", params, {
      headers: { Authorization: "Basic Og==" },
    })
  ).data;
};

export const oauth = async (token) => {
  return (await ax.post("oauth/", { token })).data;
};

export const oauthSignup = async (token) => {
  return (await ax.post("oauth/register/", { token })).data;
};

export const postUser = async (data) => {
  return (await ax.post(`accounts/`, data)).data;
};

export const getAccount = async (userId) => {
  return (await ax.get(`accounts/${userId}/`)).data;
};

export const patchAccount = async (userId, data) => {
  return (await ax.patch(`accounts/${userId}/`, data)).data;
};

export const getQualifications = async (params) => {
  return (await ax.get("/qualifications/", { params })).data;
};

export const postReferral = async (data) => {
  return (await ax.post(`referals/`, data)).data;
};

export const getTalentLinks = async (user__user_id) => {
  return (await ax.get(`accounts/talent_links/`, { params: { user__user_id } }))
    .data;
};

export const patchUser = async (userId, data) => {
  return (await ax.patch(`accounts/user/${userId}/`, data)).data;
};

export const postTalentLink = async (data) => {
  return (await ax.post(`accounts/talent_links/`, data)).data;
};

export const patchTalentLink = async (id, data) => {
  return (await ax.patch(`accounts/talent_links/${id}/`, data)).data;
};

export const deleteTalentLink = async (id) => {
  return (await ax.delete(`accounts/talent_links/${id}/`)).data;
};

export const deleteTalentProject = async (id) => {
  return (await ax.delete(`accounts/talent_projects/${id}/`)).data;
};

export const patchTalentProject = async (id, data) => {
  return (await ax.patch(`accounts/talent_projects/${id}/`, data)).data;
};

export const postTalentProject = async (data) => {
  return (await ax.post(`accounts/talent_projects/`, data)).data;
};

export const getTalentProjects = async (user__user_id, params) => {
  return (
    await ax.get(`accounts/talent_projects/`, {
      params: { user__user_id, ...params },
    })
  ).data;
};

export const getTalentQualifications = async (user__user_id, params) => {
  return (
    await ax.get(`accounts/talent_qualifications/`, {
      params: { user__user_id, ...params },
    })
  ).data;
};

export const postTalentQualification = async (data) => {
  return (await ax.post(`accounts/talent_qualifications/`, data)).data;
};

export const patchTalentQualification = async (id, data) => {
  return (await ax.patch(`accounts/talent_qualifications/${id}/`, data)).data;
};

export const deleteTalentQualification = async (id) => {
  return (await ax.delete(`accounts/talent_qualifications/${id}/`)).data;
};
