import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { admin_project_form as $ } from "strings";
import { global as $$ } from "strings";
import { Header, Footer } from "components/Card";
import { Checkbox, Label, Error, Input, Textarea } from "components/Form";
import Select from "components/Select";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import {
  getContacts,
  postProjectV2,
  postProjectNote
} from "utils/adminApi";
import { getAdminUser } from "utils/localStorageService";
import { usePageCache } from "contexts/pageCacheContext";
import {
  fetchCompanies,
  fetchTalentContacts,
  fetchCompanyContacts,
  fetchOwners,
  getFetch
} from "utils/fetch";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { useHistory } from "react-router-dom";
import AlertSuccess from "../../../components/AlertSuccess";

const sourceOptions = [
  { value: "Social", label: "Social" },
  { value: "Search", label: "Search" },
  { value: "Referral", label: "Referral" },
  { value: "Events", label: "Events" },
  { value: "Client Expansion", label: "Client Expansion" },
  { value: "Email", label: "Email" },
  { value: "Slack", label: "Slack" },
  { value: "Passive Lead Return", label: "Passive Lead Return" },
  { value: "Other", label: "Other" },
];

const referralOptions = [
  { value: "Talent", label: "Talent" },
  { value: "Client", label: "Client" },
  { value: "Internal", label: "Internal" },
  { value: "VC/PE", label: "VC/PE" },
  { value: "Other", label: "Other" },
];

const expansionOptions = [
  { value: "Existing", label: "Existing" },
  { value: "Past", label: "Past" },
];

const NewProjectForm = ({ onClose, setModal }) => {
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const alert = useAlert();
  const { set, cache } = usePageCache();
  const c = cache.admin_project_form || {};
  const user = getAdminUser();
  const { handleSubmit, register, errors, clearErrors, watch, formState, control, setValue } = useForm(
    { defaultValues: { name: c.name || "" } }
  );

  const v = watch([
    "name",
    "company",
    "new_lead_source",
    "referral_type",
    "referral_contact",
    "expansion_company",
    "expansion_contact",
    "incomplete_referral_info",
    "incomplete_referral_explanation"
  ]);

  useEffect(() => {
    if (v.incomplete_referral_info === true && v.incomplete_referral_explanation?.length) {
      const referrals = ['referral_contact', 'referral_talent', 'referral_staff']
      clearErrors(referrals);
    }
  }, [v.incomplete_referral_info, v.incomplete_referral_explanation?.length])

  useEffect(() => {
    if(v.expansion_company && v.expansion_contact) {
      setValue('expansion_contact', null);
    }
  }, [v.expansion_company]);

  const fetchExpansionContacts = getFetch(
    getContacts,
    (i) => ({
      contact: i,
      value: i.id,
      label: i.name
    }),
    { company_id: v.expansion_company?.value }
  );

  const onSubmit = async (values) => {
    setSending(true);

    const call = async () => {
      try {
        let leadSourceToSend = {};

        if (values.new_lead_source) {
            leadSourceToSend.source = values.new_lead_source.value;
            leadSourceToSend.incomplete_referral_info = values.incomplete_referral_info || false;

          if (values.new_lead_source.value === 'Referral') {
            leadSourceToSend.referral_type = values.referral_type.value;
            if (values.referral_type.value === 'Client' || values.referral_type.value === 'VC/PE') {
              leadSourceToSend.referral_contact = values.referral_contact?.value || null
            }
            if (values.referral_type.value === 'Talent') {
              leadSourceToSend.referral_talent = values.referral_talent?.value || null
            }
            if (values.referral_type.value === 'Internal') {
              leadSourceToSend.referral_staff = values.referral_staff?.value || null
            }
          }

          if (values.new_lead_source.value === 'Client Expansion') {
            leadSourceToSend.expansion_type = values.expansion_type?.value || null;
            leadSourceToSend.expansion_company = values.expansion_company?.value || null;
            leadSourceToSend.expansion_contact = values.expansion_contact?.value || null;
          }

          if (values.incomplete_referral_explanation?.length) {
            leadSourceToSend.incomplete_referral_explanation = values.incomplete_referral_explanation;
          }
        }
        const response = await postProjectV2({
          name: values.company.label + " - " + values.name,
          company: values.company && values.company.value,
          new_lead_source: leadSourceToSend,
          stage: "pitching",
          owner_primary_id: user.id
        });
        if (values.incomplete_referral_explanation?.length) {
          const toSendNote = {
            project: response.id,
            created_by_id: user.id,
            created_by: user.first_name + " " + user.last_name,
            body: values.incomplete_referral_explanation,
          }

          await postProjectNote(toSendNote)
        }

        set("admin_project_form", null);
        set(`admin_project_${response.id}_fetch`, response);
        alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
        history.push(`/admin/project/${response.id}`);
      } catch (e) {
        setSending(false);
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    };

    await call();
  };

  return (
    <Modal
      isDirty={!sending && formState.isDirty}
      onClose={() => {
        set("admin_project_form", null);
        onClose();
      }}
      id="admin_project_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Header title={$.new_project_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full pr-8 mb-3 mt-2">
            <Label>{$.project_name_input}</Label>
            <Input
              name="name"
              maxLength="150"
              ref={register({ required: $.validation_required })}
              error={errors.name}
            />
            {errors.name && <Error>{errors.name.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.company_input}</Label>
            <Controller
              control={control}
              rules={{ required: $.validation_required }}
              name="company"
              defaultValue={
                c.company
                  ? { value: c.company.id, label: c.company.name }
                  : null
              }
              as={<Select />}
              required
              error={errors.company}
              placeholder={$.select_option}
              async
              loadOptions={fetchCompanies}
              footerText={$.add_company_button}
              footerOnClick={() => {
                set("admin_project_form", {
                  name: v.name,
                  lead_source: v.new_lead_source
                    ? {
                        lead_source: v.new_lead_source.lead_source,
                        id: v.new_lead_source.value,
                        source: v.new_lead_source.label,
                      }
                    : null,
                });
                setModal("add_company");
              }}
            />
            {errors.company && <Error>{errors.company.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.project_source_input}</Label>
            <Controller
              control={control}
              name="new_lead_source"
              defaultValue={null}
              as={<Select />}
              error={errors.new_lead_source}
              placeholder={$.select_option}
              options={sourceOptions}
            />
            {errors.new_lead_source && (
              <Error>{errors.new_lead_source.message}</Error>
            )}
          </div>
          {v?.new_lead_source?.value === 'Referral' && (<>
            <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
              <Label>{$.referral_type_input}</Label>
              <Controller
                control={control}
                rules={{required:
                  (v.incomplete_referral_info === true && v.incomplete_referral_explanation?.length)
                  ? false : $.validation_required
                }}
                name="referral_type"
                defaultValue={null}
                as={<Select isClearable="true" />}
                error={errors.referral_type}
                placeholder={$.select_option}
                options={referralOptions}
                required={v.incomplete_referral_info !== true}
              />
              {errors.referral_type && (
                <Error>{errors.referral_type.message}</Error>
              )}
            </div>
            {(v?.referral_type?.value === 'Client' || v?.referral_type?.value === 'VC/PE') && (<>
              <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
                <Label>{$.referral_contact_input}</Label>
                <Controller
                  control={control}
                  rules={{required:
                    (v.incomplete_referral_info === true && v.incomplete_referral_explanation?.length)
                    ? false : $.validation_required
                  }}
                  name="referral_contact"
                  defaultValue={
                    v.referral_contact
                    ? {
                        label: v.referral_contact.name,
                        value: v.referral_contact.id,
                      }
                    : null
                  }
                  as={<Select isClearable="true" />}
                  required={v.incomplete_referral_info !== true}
                  error={errors.referral_contact}
                  placeholder={$.select_option}
                  async
                  loadOptions={fetchCompanyContacts}
                  footerText={$.add_contact_button}
                  footerOnClick={() => {
                    set("admin_project_form", {
                      name: v.name,
                      lead_source: v.new_lead_source
                        ? {
                            lead_source: v.new_lead_source.lead_source,
                            id: v.new_lead_source.value,
                            source: v.new_lead_source.label,
                          }
                        : null,
                    });
                    setModal("add_contact");
                  }}
                />
                {errors.referral_contact && <Error>{errors.referral_contact.message}</Error>}
              </div>
            </>)}
            {v?.referral_type?.value === 'Talent' && (<>
              <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
                <Label>{$.referral_contact_input}</Label>
                <Controller
                  control={control}
                  rules={{required:
                    (v.incomplete_referral_info === true && v.incomplete_referral_explanation?.length)
                    ? false : $.validation_required
                  }}
                  name="referral_talent"
                  defaultValue={
                    v.referral_talent
                    ? {
                        label: v.referral_talent.name,
                        value: v.referral_talent.id,
                      }
                    : null
                  }
                  as={<Select isClearable="true" />}
                  required={v?.incomplete_referral_info !== true}
                  error={errors.referral_talent}
                  placeholder={$.select_option}
                  async
                  loadOptions={fetchTalentContacts}
                  footerText={$.add_contact_button}
                  footerOnClick={() => {
                    set("admin_project_form", {
                      name: v.name,
                      lead_source: v.new_lead_source
                        ? {
                            lead_source: v.new_lead_source.lead_source,
                            id: v.new_lead_source.value,
                            source: v.new_lead_source.label,
                          }
                        : null,
                    });
                    setModal("add_contact");
                  }}
                />
                {errors.referral_talent && <Error>{errors.referral_talent.message}</Error>}
              </div>
            </>)}
            {v?.referral_type?.value === 'Internal' && (<>
              <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
                <Label>{$.referral_contact_input}</Label>
                <Controller
                  control={control}
                  rules={{required:
                    (v.incomplete_referral_info === true && v.incomplete_referral_explanation?.length)
                    ? false : $.validation_required
                  }}
                  name="referral_staff"
                  defaultValue={
                    v.referral_staff
                    ? {
                        label: v.referral_staff.name,
                        value: v.referral_staff.id,
                      }
                    : null
                  }
                  as={<Select isClearable="true" />}
                  required={v?.incomplete_referral_info !== true}
                  error={errors.referral_staff}
                  placeholder={$.select_option}
                  async
                  loadOptions={fetchOwners}
                  footerText={$.add_contact_button}
                  footerOnClick={() => {
                    set("admin_project_form", {
                      name: v.name,
                      lead_source: v.new_lead_source
                        ? {
                            lead_source: v.new_lead_source.lead_source,
                            id: v.new_lead_source.value,
                            source: v.new_lead_source.label,
                          }
                        : null,
                    });
                    setModal("add_contact");
                  }}
                />
                {errors.referral_staff && <Error>{errors.referral_staff.message}</Error>}
              </div>
            </>)}
            <div className="w-full pr-8">
              <Controller
                control={control}
                name="incomplete_referral_info"
                defaultValue={!!v.incomplete_referral_info}
                as={<Checkbox>{$.incomplete_referral_info}</Checkbox>}
              />
            </div>
            {(v.incomplete_referral_info === true || v.incomplete_referral_explanation?.length) && (
              <div className="w-full pr-8 mb-3 mt-2">
                <Label>{$.incomplete_referral_info_input}</Label>
                <Textarea
                  name="incomplete_referral_explanation"
                  placeholder={$.incomplete_referral_explanation_placeholder}
                  error={errors.incomplete_referral_explanation}
                  ref={register}
                />
                {errors.incomplete_referral_explanation && (
                  <Error>{errors.incomplete_referral_explanation.message}</Error>
                )}
              </div>)}
          </>)}
        {v?.new_lead_source?.value === 'Client Expansion' && (<>
          <div className="w-full md:w-1/3 pr-8 mb-3 mt-2">
            <Label>{$.expansion_type_input}</Label>
            <Controller
              control={control}
              rules={{required:
                (v.incomplete_referral_info === true && v.incomplete_referral_explanation?.length)
                ? false : $.validation_required
              }}
              name="expansion_type"
              defaultValue={null}
              as={<Select isClearable="true" />}
              error={errors.expansion_type}
              placeholder={$.select_option}
              options={expansionOptions}
              required={v.incomplete_referral_info !== true}
            />
            {errors.expansion_type && (
              <Error>{errors.expansion_type.message}</Error>
            )}
          </div>
          <div className="w-full md:w-1/3 pr-8 mb-3 mt-2">
            <Label>{$.expansion_company_input}</Label>
            <Controller
              control={control}
              rules={{required:
                (v.incomplete_referral_info === true && v.incomplete_referral_explanation?.length)
                ? false : $.validation_required
              }}
              name="expansion_company"
              defaultValue={
                v.expansion_company
                ? {
                    label: v.expansion_company.name,
                    value: v.expansion_company.id,
                  }
                : null
              }
              as={<Select isClearable="true" />}
              required={v?.incomplete_referral_info !== true}
              error={errors.expansion_company}
              placeholder={$.select_option}
              async
              loadOptions={fetchCompanies}
            />
            {errors.expansion_company && <Error>{errors.expansion_company.message}</Error>}
          </div>
          {v.expansion_company && (
          <div className="w-full md:w-1/3 pr-8 mb-3 mt-2">
            <Label>{$.expansion_contact_input}</Label>
            <Controller
              control={control}
              rules={{required:
                (v.incomplete_referral_info === true && v.incomplete_referral_explanation?.length)
                ? false : $.validation_required
              }}
              name="expansion_contact"
              defaultValue={
                v.expansion_contact
                ? {
                    label: v.expansion_contact.name,
                    value: v.expansion_contact.id,
                  }
                : null
              }
              as={<Select isClearable="true" />}
              required={v?.incomplete_referral_info !== true}
              error={errors.expansion_contact}
              placeholder={$.select_option}
              async
              loadOptions={fetchExpansionContacts}
            />
            {errors.expansion_contact && <Error>{errors.expansion_contact.message}</Error>}
          </div>
          )}
          <div className="w-full pr-8">
            <Controller
              control={control}
              name="incomplete_referral_info"
              defaultValue={!!v.incomplete_referral_info}
              as={<Checkbox>{$.incomplete_client_expansion_info}</Checkbox>}
            />
          </div>
          {(v.incomplete_referral_info === true || v.incomplete_referral_explanation?.length) && (
            <div className="w-full pr-8 mb-3 mt-2">
              <Textarea
                name="incomplete_referral_explanation"
                placeholder={$.incomplete_client_expansion_explanation_placeholder}
                error={errors.incomplete_referral_explanation}
                ref={register}
              />
              {errors.incomplete_referral_explanation && (
                <Error>{errors.incomplete_referral_explanation.message}</Error>
              )}
            </div>)}
        </>)}
        </div>
        <Footer>
          <Button
            secondary
            onClick={() => {
              set("admin_project_form", null);
              onClose();
            }}
            className="mr-4"
          >
            {$.cancel_button}
          </Button>
          <Button type="submit" loading={sending} disabled={sending}>
            {sending ? $.sending_button : $.create_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default NewProjectForm;
