import AlertError from "components/AlertError";
import { Button } from "components/Buttons";
import { Error, Input, Label } from "components/Form";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { admin_company_clockify as $ } from "strings";
import { createCompanyClockify } from "utils/adminApi";
import AlertSuccess from "../../../../components/AlertSuccess";

const CreateCompanyForm = ({ data, setData, setStatus }) => {
  const alert = useAlert();
  const [checking, setChecking] = useState(false);

  const { handleSubmit, register, errors, watch } = useForm();

  const allVal = watch();

  const onSubmit = async (values) => {
    setChecking(true);
    const toSend = {
      name: values.name,
    };

    const call = async () => {
      try {
        const response = await createCompanyClockify(data.id, toSend);
        setData({ ...data, clockify_id: response.clockify_id });
        setStatus(null);
        alert.success(<AlertSuccess message={$.create_success_message} />, {timeout: 2000});
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
      setChecking(false);
    };

    await call();
  };

  return (
    <div className="w-full">
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="flex flex-wrap flex-1 min-h-0 py-4">
          <div className="w-full">
            <div className="w-full md:w-1/2 mb-3 mt-2">
              <Label>{$.name_input}</Label>
              <Input
                name="name"
                maxLength="150"
                ref={register({ required: $.validation_required })}
                error={errors.name}
              />
              {errors.name && <Error>{errors.name.message}</Error>}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Button type="submit" loading={checking} disabled={!allVal.name || checking}>
            {checking ? $.checking_btn : $.save_btn}
          </Button>
          <Button secondary onClick={() => setStatus(null)} className="mr-4">
            {$.cancel_btn}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateCompanyForm;
