import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import TalentList from "./Talent/List/Page";
import TalentPage from "./Talent/Page/index";
import ProjectList from "./Project/List";
import ProjectPage from "./Project/Page";
import CompanyList from "./Company/List";
import CompanyPage from "./Company/Page";
import ContactTable from './Contact/Table';
import ContactPage from "./Contact/Page/index";
import SettingsPage from "./Settings/Page";
import { Topbar } from "components/Topbar";
import { Switch, Route, Redirect } from "react-router-dom";
import Section from "components/Section";
import useQualifications from "hooks/useQualifications";
import { setAdminFlags } from "utils/localStorageService";
import { getFlags } from "utils/adminApi";
import AlgoliaGlobalSearch from "./AlgoliaGlobalSearch";
import ListInitSearch from "./ListInitSearch";
import TalentMerge from "./Talent/Merge";

const Page = ({ setAdminLogged }) => {
  const [, setFlags] = useState({});
  const { qualifications } = useQualifications(true);

  useEffect(() => {
    const call = async () => {
      const response = await getFlags();
      setFlags(response);
      setAdminFlags(response);
    };

    call();
  }, []);

  useEffect(() => {
    const checkToken = (e) => {
      if (e.key === "admin_access_token" && e.oldValue && !e.newValue) {
        setAdminLogged(false);
      }
    };

    window.addEventListener("storage", checkToken);

    return () => {
      window.removeEventListener("storage", checkToken);
    };
  }, [setAdminLogged]);

  return (
    <Switch>
      <Route exact path="/admin/talent">
        <TalentList setAdminLogged={setAdminLogged} />
      </Route>
      <Route path="/admin/">
        <Section
          id="admin"
          className="font-lato bg-white h-screen flex flex-col"
        >
          <Topbar>
            <AlgoliaGlobalSearch />
          </Topbar>
          <div className="flex-1 flex min-h-0">
            <Nav setAdminLogged={setAdminLogged} />
            <div
              className="bg-link-water flex-1 h-full overflow-y-auto p-1"
              id="scrolltop"
            >
              <Switch>
                <Route exact path="/admin">
                  <Redirect to="/admin/talent" />
                </Route>
                <Route exact path="/admin/talent/search/:search">
                  <ListInitSearch
                    to="/admin/talent"
                    cacheKey="cache_talent_list_table"
                  />
                </Route>
                <Route exact path="/admin/talent">
                  <TalentList {...{ qualifications }} />
                </Route>
                <Route exact path="/admin/talent/:id/merge">
                  <TalentMerge />
                </Route>
                <Route path="/admin/talent/:id">
                  <TalentPage />
                </Route>
                <Route exact path="/admin/projects/search/:search">
                  <ListInitSearch
                    to="/admin/projects"
                    cacheKey="cache_project_list_table"
                  />
                </Route>
                <Route exact path="/admin/project">
                  <ProjectList {...{ qualifications }} />
                </Route>
                <Route exact path="/admin/projects">
                  <Redirect to="/admin/project" />
                </Route>
                <Redirect from="/admin/projects/:id/candidates" to="/admin/project/:id/candidates" />
                <Redirect from="/admin/projects/:id/roles" to="/admin/project/:id/stakeholders" />
                <Redirect from="/admin/projects/:id/dataimport" to="/admin/project/:id" />
                <Redirect from="/admin/projects/:id/contract" to="/admin/project/:id/finance" />
                <Redirect from="/admin/projects/:id" to="/admin/project/:id" />
                <Route path="/admin/project/:id">
                  <ProjectPage />
                </Route>
                <Route exact path="/admin/company/search/:search">
                  <ListInitSearch
                    to="/admin/company"
                    cacheKey="cache_company_list_table"
                  />
                </Route>
                <Route exact path="/admin/company">
                  <CompanyList />
                </Route>
                <Route path="/admin/company/:id">
                  <CompanyPage />
                </Route>
                <Route exact path="/admin/contacts">
                  <ContactTable />
                </Route>
                <Route exact path="/admin/contacts/search/:search">
                  <ListInitSearch
                    to="/admin/contacts"
                    cacheKey="cache_contact_list_table"
                  />
                </Route>
                <Route path="/admin/contacts/:id">
                  <ContactPage />
                </Route>
                <Route path="/admin/settings">
                  <SettingsPage />
                </Route>
              </Switch>
            </div>
          </div>
        </Section>
      </Route>
    </Switch>
  );
};

export default Page;
