import { admin_project_candidate_detail as candidate } from "strings";
import { admin_project_list as project } from "strings";

export const statusOptions = [
  { label: candidate.new_option, value: "new" },
  { label: candidate.maybe_option, value: "maybe" },
  { label: candidate.hold_option, value: "hold" },
  { label: candidate.short_list_option, value: "short_list" },
  { label: candidate.scheduled_option, value: "scheduled" },
  { label: candidate.submitted_option, value: "submitted", disabled: true },
  { label: candidate.intro_call_option, value: "intro_call", disabled: true },
  { label: candidate.placed_option, value: "placed", disabled: true },
  { label: candidate.pass_option, value: "pass" },
  { label: candidate.completed_option, value: "completed", disabled: true },
  { label: candidate.hired_option, value: "hired", disabled: true }
];

export const allowedStatusOptions = [
  { label: candidate.new_option, value: "new" },
  { label: candidate.maybe_option, value: "maybe" },
  { label: candidate.hold_option, value: "hold" },
  { label: candidate.short_list_option, value: "short_list" },
  { label: candidate.scheduled_option, value: "scheduled" },
  { label: candidate.pass_option, value: "pass" },
];

export const stageOptions = [
  { label: project.active_option, value: "active" },
  { label: project.pitching_option, value: "pitching" },
  { label: project.shadow_pitched_option, value: "shadow_pitched" },
  { label: project.matching_no_support_option, value: "matching_no_support" },
  { label: project.matching_support_option, value: "matching_support" },
  { label: project.sourcing_option, value: "sourcing" },
  { label: project.submitted_option, value: "submitted" },
  { label: project.contracting_option, value: "contreacting" },
  { label: project.pipelines_option, value: "pipelines" },
  { label: project.passive_option, value: "passive" },
  { label: project.filled_option, value: "filled" },
  { label: project.lost_option, value: "lost" },
  { label: project.completed_option, value: "completed" },
];

export const rejectionReasonOptions = [
  { label: candidate.misalignment_option, value: "1" },
  { label: candidate.budget_option, value: "2" },
  { label: candidate.not_interested_option, value: "3" },
  { label: candidate.not_available_option, value: "4" },
  { label: candidate.candidate_not_responsive_option, value: "5" },
  { label: candidate.filled_non_rsu_option, value: "6" },
  { label: candidate.alternate_selected_option, value: "7" },
  { label: candidate.client_not_responsive_option, value: "8" },
  { label: candidate.client_paused_option, value: "9" },
  { label: candidate.not_fit_option, value: "10" },
  { label: candidate.not_evaluated_option, value: "11" },
]
