import React from "react";
import { B2DB } from "./Typography";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";

/**
 * Renders a section inside the filters panel. It show a toogler title, that toggles the filters itself.
 * @param {component} children - The children to render, in this case the filters.
 * @param {boolean} active - Flag to indicate whether is opened or not.
 * @param {string} label - Section title.
 * @param {callback} onToggle - Callback to catch the toggle event.
 */
const FilterSection = ({ children, active, label, onToggle }) => (
  <div>
    <div onClick={onToggle}>
      <B2DB className="flex items-center mb-3 cursor-pointer">
        <span className="flex-1">{label}</span>
        {active && <UilUp size="20" />}
        {!active && <UilDown size="20" />}
      </B2DB>
    </div>
    {active && children}
  </div>
);

export default FilterSection;
