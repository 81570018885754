import React, { useState } from "react";
import { admin_project_overview as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import UilGlobe from "@iconscout/react-unicons/icons/uil-globe";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import UilUser from "@iconscout/react-unicons/icons/uil-user";
import Header from "components/ListHeader";
import useCachedFetch from "hooks/useCachedFetch";
import { getProjectContacts } from "utils/adminApi";
import AlertError from "components/AlertError";

const CONTACT_ROLE_PLACEHOLDER = "Select a Role";

const projectContactRolesMap = {
  null: 'Unknown',
  'engagement_poc': 'Engagement POC',
  'company_poc': 'Company POC',
  'invoicing': 'Invoicing',
  'decision_maker': 'Decision Maker',
  'point_of_contact': 'Point of Contact',
};

const ProjectContacts = ({ project }) => {
  const [open, setOpen] = useState(true);

  const {
    data: allProjectContacts,
    setData: setAllProjectContacts,
  } = useCachedFetch(
    "admin_project_contacts_many",
    getProjectContacts,
    project.id,
  );
  

  const refreshData = async () => {
    try {
      const res = await getProjectContacts(project.id);
      setAllProjectContacts(res);
    } catch (e) {
      alert.error(<AlertError error={e} onRetry={refreshData} />);
    }
  };

  const getContactRolesList = (role) => {
    if (!role) return;
    if (!role.includes('|')) {
      return projectContactRolesMap[role] || role || CONTACT_ROLE_PLACEHOLDER
    } else {
      const rolesArr = role.split('|');
      let rolesStr = '';
      rolesArr.forEach((role, i) => {
        rolesStr += projectContactRolesMap[role];
        if (i < rolesArr.length - 1) {
          rolesStr += ', '
        }
      })
      return rolesStr;
    }
  }


  const getProjectContactElement = (projectContact, key) => {
    return (
      <div className="px-2 pb-4 ring-1 ring-gray-300 mt-2 mx-1" key={key}>
        <div className="w-full flex">
          <div className="flex flex-row flex-grow">
            <div className="w-1/2">
              <Header Icon={UilUser} text={$.project_contact_name_label}/>
              <div className="flex pr-2">
                <B2DB>{projectContact.contact_name}</B2DB>
              </div>
            </div>
            {projectContact.contact != null && <div className="flex-grow">
              <Header Icon={UilUser} text={$.project_contact_role_label}/>
              <div>
                <B2DB>{
                  projectContact.contact_role ? getContactRolesList(projectContact.contact_role) : 'Unknown'
                }</B2DB>
              </div>
            </div>}
          </div>
        </div>
      </div>
    )
  }

  return (
    <Section id="admin_project_overview" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilGlobe className="mr-2" size="18" /> {$.project_contacts_title}
          </B2DB>
          <div className="cursor-pointer" onClick={() => setOpen(!open)}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && <div>
          {!!allProjectContacts && allProjectContacts.map((projectContact, index) => {
            return getProjectContactElement(projectContact, index);
          })}
        </div>
        }
      </div>
    </Section>
  );
};

export default ProjectContacts;
