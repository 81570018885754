import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { admin_project_contact_form as $ } from "strings";
import { global as $$ } from "strings";
import { Header, Footer } from "components/Card";
import { Input, Label, Error } from "components/Form";
import { Button, RemoveBtn } from "components/Buttons";
import Modal from "components/Modal";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus-circle";
import { postUser, postUserLink } from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { getAdminUserId } from "utils/localStorageService";
import { generateEmail } from "utils/str";
import AlertSuccess from "../../../../../components/AlertSuccess";

const ContactForm = ({ onClose, onContactChange, company }) => {
  const alert = useAlert();
  const [sending, setSending] = useState(false);
  const [links, setLinks] = useState([]);
  const adminUserId = getAdminUserId();

  const {
    handleSubmit,
    register,
    errors,
    formState,
    setValue,
  } = useForm();

  const onSubmit = async (values) => {
    setSending(true);

    const params = {
      password1: "",
      password2: "",
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email || generateEmail(),
      type: 'company',
      company_id: company.id
    };

    const call = async () => {
      try {
        const userResponse = await postUser(params);

        if (values.linkedin?.length) {
          await postUserLink({
            name: "linkedin",
            url: values.linkedin,
            updated_by: adminUserId,
            user: userResponse.id,
          });
        }

        if (values.website?.length) {
          await postUserLink({
            name: "website",
            url: values.website,
            updated_by: adminUserId,
            user: userResponse.id,
          });
        }
        alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
        onContactChange({ label: `${userResponse.first_name} ${userResponse.last_name}`, value: userResponse.default_contact_id })
        onClose();
      } catch (e) {
        const duplicateId = e.response?.data?.users[0]?.id;
        const contactPageUrl = `/admin/contacts/${duplicateId}/roles?type=company`;
        if (duplicateId) {
          alert.info(
            <AlertSuccess>
              <div>
                A Contact with this email already exists. <a href={contactPageUrl} className="underline">Click here to add a Company Role</a> from the Contact page in order to be able to assign them as a project contact.
              </div>
            </AlertSuccess>);
        } else {
          console.error(e);
          alert.error(<AlertError error={e} />);
        }
        setSending(false);
      }
    };

    await call();
  };

  return (
    <Modal
      isDirty={formState.isDirty}
      onClose={onClose}
      id="admin_company_contact_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Header title={$.create_contact_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.firstname_input}</Label>
            <Input
              autoFocus
              name="firstname"
              maxLength="150"
              ref={register({ required: $.validation_required })}
              error={errors.firstname}
            />
            {errors.firstname && <Error>{errors.firstname.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.lastname_input}</Label>
            <Input
              name="lastname"
              maxLength="150"
              ref={register({ required: $.validation_required })}
              error={errors.lastname}
            />
            {errors.lastname && <Error>{errors.lastname.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.email_input}</Label>
            <Input
              name="email"
              type="email"
              maxLength="254"
              ref={register({
                required: $.validation_required,
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                  message: $.validation_email,
                },
              })}
              error={errors.email}
            />
            {errors.email && <Error>{errors.email.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.phone_input}</Label>
            <Input
              type="tel"
              maxLength="15"
              name="phone_number"
              onPaste={(e) => {
                var newVal = e.clipboardData.getData("Text").replace(/\D/g, "");
                setTimeout(() => {
                  setValue("phone_number", newVal);
                }, 200);
              }}
              ref={register({
                pattern: {
                  value: /^[0-9]{10,15}$/i,
                  message: $.validation_phone,
                },
              })}
              error={errors.phone_number}
            />
            {errors.phone_number && (
              <Error>{errors.phone_number.message}</Error>
            )}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.linkedin_input}</Label>
            <Input
              name="linkedin"
              type="url"
              maxLength="250"
              ref={register({
                pattern: {
                  value: /^(https?:\/\/)?(www\.)?linkedin.com\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i,
                  message: $.validation_linkedin_url,
                },
              })}
              error={errors.linkedin}
            />
            {errors.linkedin && <Error>{errors.linkedin.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.website_input}</Label>
            <Input
              name="website"
              type="url"
              maxLength="250"
              ref={register({
                pattern: {
                  value: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i,
                  message: $.validation_url,
                },
              })}
              error={errors.website}
            />
            {errors.website && <Error>{errors.website.message}</Error>}
          </div>
          <div className="mt-4 w-full pr-8">
            <button
              type="button"
              className="text-link text-sm font-bold appearance-none focus:outline-none outline-none inline-flex items-center"
              onClick={() => setLinks([...links, true])}
            >
              <UilPlus size="18" className="mr-1" />
              {$.add_link_button}
            </button>
            {links.map((v, i) =>
              !v ? (
                false
              ) : (
                <div key={i} className="flex items-start my-3 w-full">
                  <div className="flex-1 pr-4">
                    <Label>{$.link_name_input}</Label>
                    <Input
                      name={`link_name_${i}`}
                      maxLength="200"
                      ref={register({ required: $.validation_required })}
                      error={errors[`link_name_${i}`]}
                    />
                    {errors[`link_name_${i}`] && (
                      <Error>{errors[`link_name_${i}`].message}</Error>
                    )}
                    {!errors[`link_name_${i}`] && errors[`link_url_${i}`] && (
                      <div className="h-2" />
                    )}
                  </div>
                  <div className="flex-1 pr-4">
                    <Label>{$.link_url_input}</Label>
                    <Input
                      name={`link_url_${i}`}
                      type="url"
                      maxLength="250"
                      ref={register({
                        required: $.validation_required,
                        pattern: {
                          value: /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,24}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i,
                          message: $.validation_url,
                        },
                      })}
                      error={errors[`link_url_${i}`]}
                    />
                    {errors[`link_url_${i}`] && (
                      <Error>{errors[`link_url_${i}`].message}</Error>
                    )}
                    {!errors[`link_url_${i}`] && errors[`link_name_${i}`] && (
                      <div className="h-2" />
                    )}
                  </div>
                  <div className="mt-6 pt-1">
                    <RemoveBtn
                      onClick={() => {
                        const newLinks = [...links];
                        newLinks[i] = false;
                        setLinks(newLinks);
                      }}
                    />
                  </div>
                </div>
              )
            )}
          </div>
          <div className="w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.company_input}</Label>
            <Input disabled value={company.name} />
          </div>
        </div>
        <Footer>
          <Button
            secondary
            onClick={onClose}
            className="mr-4"
          >
            {$.cancel_button}
          </Button>
          <Button type="submit" loading={sending} disabled={sending}>
            {sending ? $.sending_button : $.save_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default ContactForm;
