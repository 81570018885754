import React, { useState } from "react";
import { admin_table_edit_columns_form as $$ } from "strings";
import { Header, Footer } from "components/Card";
import { Button, AddBtn, SelectedBtn } from "components/Buttons";
import { B2DB } from "components/Typography";
import Modal from "components/Modal";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { getAdminUserId } from "utils/localStorageService";

const EditColumnsForm = ({
  onClose,
  patchApi,
  postApi,
  schema,
  $,
  columns,
}) => {
  let defaultCols = [];
  if (columns && columns.data) {
    try {
      defaultCols = JSON.parse(columns.data.snapshot);
    } catch (e) {
      console.error(e);
    }
  } else {
    defaultCols = Object.keys(schema).filter((k) => schema[k].show);
  }

  const user = getAdminUserId();
  const alert = useAlert();
  const [selected, setSelected] = useState(defaultCols);
  const [sending, setSending] = useState(false);

  const onSubmit = async () => {
    onClose();
    const snapshot = JSON.stringify(selected);
    columns.setData((d) => ({ ...d, snapshot }));

    const call = async () => {
      try {
        if (columns && columns.data) {
          patchApi(columns.data.id, { snapshot });
        } else {
          const response = postApi({ snapshot, user });
          columns.setData((d) => ({ ...d, ...response }));
        }
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };

    await call();
  };

  return (
    <Modal onClose={onClose} id="admin_table_edit_columns_form">
      <div className="flex flex-col flex-nowrap flex-1 min-h-0">
        <Header title={$$.title} />
        <div
          id="infinite-scroll-talent-project"
          className="flex flex-wrap flex-1 min-h-0 overflow-y-auto py-4"
        >
          {Object.entries(schema).map(([k, s], i) =>
            i > 0 ? (
              <div
                key={k}
                className="w-full py-3 flex items-center justify-between ounded px-2 cursor-pointer hover:bg-link-water"
                onClick={() =>
                  selected.includes(k)
                    ? setSelected((v) => v.filter((i) => i !== k))
                    : setSelected((v) => [...v, k])
                }
              >
                <div>
                  <B2DB>{$[`${k}_label`]}</B2DB>
                </div>
                <div>
                  {selected.includes(k) ? (
                    <SelectedBtn onClick={() => {}} />
                  ) : (
                    <AddBtn onClick={() => {}} />
                  )}
                </div>
              </div>
            ) : null
          )}
        </div>
        <Footer>
          <Button secondary onClick={onClose} className="mr-4">
            {$$.cancel_button}
          </Button>
          <Button type="submit" onClick={onSubmit} loading={sending} disabled={sending}>
            {$$.save_button}
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default EditColumnsForm;
