import React from "react";

/**
 * Renders a loading placeholder box/line.
 * @param {string} className - Classes to append to the default classes, used to set the size of the placeholder.
 */
const LoadPlaceholder = ({ className = "" }) => (
  <div className={"bg-animated " + className} />
);

/**
 * Renders a common title and paragraph load placeholders.
 */
export const LoadPlaceholderGroup = () => (
  <>
    <LoadPlaceholder className="w-60 h-4 mb-6" />
    <LoadPlaceholder className="w-60 h-4 mb-2" />
    <LoadPlaceholder className="w-40 h-4 mb-2" />
    <LoadPlaceholder className="w-32 h-4 mb-2" />
  </>
);

export default LoadPlaceholder;
