import React, { useState } from "react";
import { admin_contact as $ } from "strings";
import { deleteContactNoteV2 } from "utils/adminApi";
import { toFormat } from "utils/date";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import EditNoteForm from "./EditNoteForm";
import { urlify } from "utils/str";

const Note = ({ note, editable, updateNote, removeNote, measureRef, className }) => {
  const alert = useAlert();
  const [editing, setEditing] = useState();

  const onDelete = async () => {
    const call = async () => {
      removeNote(note.id);

      try {
        await deleteContactNoteV2(note.id);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
      }
    };

    await call();
  };

  return (
    <div ref={measureRef} className={"py-1 " + className}>
      {!editing && (
        <div>
          <div className="text-sm font-bold text-midnight flex items-center justify-between">
            <div className="flex-1">
              {`${note.created_by || $.unknown_author_label} ${$.added_by_label}`}
            </div>
          </div>
          <div className="relative text-sm font-normal text-midnight whitespace-pre-line rich-content min-h-0 ">
            <div dangerouslySetInnerHTML={{ __html: urlify(note.body) }} />
          </div>
          <div className="text-xs text-kasmir flex items-center justify-start">
            <div className="group relative flex items-center">
              {note.created_on && (
                <div className="">
                  {toFormat(
                    new Date(note.created_on),
                    "MMMM dd, yyyy 'at' h:mm a"
                  )}
                </div>
              )}
              {note.updated_at && (
                <div className="bottom-full w-content bg-cove text-white rounded px-1 py-1 text-center absolute z-30 font-bold text-xs hidden group-hover:inline">
                  {$.last_update_label}
                  <br />
                  {toFormat(
                    new Date(note.updated_at),
                    "MMMM dd, yyyy 'at' h:mm a"
                  )}
                </div>
              )}
              {note.created_on &&
                (editable ? <span className="mx-1">|</span> : "")}
            </div>
            {editable && (
              <>
                <button
                  className="focus:outline-none text-xs text-kasmir p-0 outline-none"
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  {$.edit_btn}
                </button>
                <span className="mx-1">|</span>
                <button
                  className="focus:outline-none text-xs text-kasmir p-0 outline-none"
                  onClick={onDelete}
                >
                  {$.delete_btn}
                </button>
              </>
            )}
          </div>
        </div>
      )}
      {editing && (
        <div>
          <div className="text-sm font-bold text-midnight">
            {note.created_by === "Crelate_Populate_Projects_Script"
              ? "Crelate Tags"
              : note.created_by || $.no_author_label}{" "}
            {$.added_by_label}
          </div>
          <EditNoteForm
            note={note}
            updateNote={updateNote}
            setEditing={setEditing}
          />
        </div>
      )}
    </div>
  );
};

export default Note;
