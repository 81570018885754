import { useState, useEffect } from "react";
import { getDescriptorOptions } from "utils/adminApi";

let cached = null;
const useDescriptorOptions = () => {
  const [options, setOptions] = useState(null);

  useEffect(() => {
    const load = async () => {
      try {
        const response = await getDescriptorOptions({ page_size: 9999 });
        const ordered = {};

        response.results.forEach((o) => {
          if (!ordered[o.descriptor]) {
            ordered[o.descriptor] = [];
          }

          ordered[o.descriptor].push(o);
        });

        cached = ordered;
        setOptions(cached);
      } catch (e) {
        console.error(e);
      }
    };

    if (!cached) {
      load();
    } else {
      setOptions(cached);
    }
  }, []);

  return options;
};

export default useDescriptorOptions;
