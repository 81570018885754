import React, { useCallback, useEffect, useState } from "react";
import isHotkey from "is-hotkey";
import { Editable, withReact, useSlate, Slate } from "slate-react";
import {
  Editor,
  Transforms,
  createEditor,
  Point,
  Range,
  Element as SlateElement,
} from "slate";
import { withHistory } from "slate-history";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fa1,
  fa2,
  faBold,
  faCode,
  faItalic,
  faList,
  faList12,
  faQuoteLeft,
  faUnderline,
} from "@fortawesome/free-solid-svg-icons";
import useForceUpdate from "hooks/useForcedUpdate";

const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};

const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const isBodyEmpty = (body) => {
  if (
    !body ||
    (body.length === 1 &&
      body[0].type === "paragraph" &&
      body[0].children.length === 1 &&
      body[0].children[0].text === "")
  ) {
    return true;
  }

  return false;
};

const RichTextEditor = ({
  placeholder,
  error,
  value,
  onChange,
  alwaysOpen,
  isStageChangeNote
}) => {
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const [editor] = useState(
    withTables(withHistory(withReact(createEditor())), [])
  );
  const [isFocus, setIsFocus] = useState(false);

  const forceUpdate = useForceUpdate();
  useEffect(() => {
    editor.children = value;
    if (isBodyEmpty(value)) {
      editor.selection = {
        anchor: { offset: 0, path: [0, 0] },
        focus: { offset: 0, path: [0, 0] },
      };
    }
    forceUpdate();
  }, [editor, value, forceUpdate]);

  return (
    <Slate editor={editor} value={value} onChange={onChange}>
      {(alwaysOpen || isFocus) && (
        <div className="flex items-center justify-start bg-link-water rounded-t border-l border-t border-r border-geyser">
          <MarkButton format="bold" icon={faBold} />
          <MarkButton format="italic" icon={faItalic} />
          <MarkButton format="underline" icon={faUnderline} />
          <MarkButton format="code" icon={faCode} />
          <BlockButton format="heading-one" icon={fa1} />
          <BlockButton format="heading-two" icon={fa2} />
          <BlockButton format="block-quote" icon={faQuoteLeft} />
          <BlockButton format="numbered-list" icon={faList12} />
          <BlockButton format="bulleted-list" icon={faList} />
        </div>
      )}
      <Editable
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        placeholder={placeholder}
        className={
          "rich-editor rich-content w-full px-2 pt-3 pb-2 text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-medium focus:outline-none appearance-none " +
          (error
            ? "border-red border-2 "
            : "border-l border-t border-r ") +
          (isStageChangeNote ? "stage-change-form-top border-link " : "border-geyser " ) +
          (alwaysOpen || isFocus ? "" : "rounded-t")
        }
        spellCheck
        onKeyDown={(event) => {
          for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
              event.preventDefault();
              const mark = HOTKEYS[hotkey];
              toggleMark(editor, mark);
            }
          }
        }}
      />
    </Slate>
  );
};

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type),
    split: true,
  });
  const newProperties = {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isBlockActive = (editor, format) => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    })
  );

  return !!match;
};

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = ({ attributes, children, element }) => {
  switch (element.type) {
    case "block-quote":
      return <blockquote {...attributes}>{children}</blockquote>;
    case "bulleted-list":
      return <ul {...attributes}>{children}</ul>;
    case "heading-one":
      return <h1 {...attributes}>{children}</h1>;
    case "heading-two":
      return <h2 {...attributes}>{children}</h2>;
    case "list-item":
      return <li {...attributes}>{children}</li>;
    case "numbered-list":
      return <ol {...attributes}>{children}</ol>;
    case "table":
      return (
        <table>
          <tbody {...attributes}>{children}</tbody>
        </table>
      );
    case "table-row":
      return <tr {...attributes}>{children}</tr>;
    case "table-cell":
      return <td {...attributes}>{children}</td>;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <i>{children}</i>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
};

const Button = ({ children, active, ...props }) => (
  <button
    type="button"
    {...props}
    className="group focus:outline-none w-8 h-8 "
  >
    <span
      className={
        "group-focus:ring focus:outline-none text-sm font-bold rounded w-8 h-8 inline-flex items-center justify-center " +
        (active
          ? "bg-link-water text-link hover:bg-link-lighter active:bg-link-light"
          : "")
      }
      tabIndex="-1"
    >
      {children}
    </span>
  </button>
);

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
};

const withTables = (editor) => {
  const { deleteBackward, deleteForward, insertBreak } = editor;

  editor.deleteBackward = (unit) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table-cell",
      });

      if (cell) {
        const [, cellPath] = cell;
        const start = Editor.start(editor, cellPath);

        if (Point.equals(selection.anchor, start)) {
          return;
        }
      }
    }

    deleteBackward(unit);
  };

  editor.deleteForward = (unit) => {
    const { selection } = editor;

    if (selection && Range.isCollapsed(selection)) {
      const [cell] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table-cell",
      });

      if (cell) {
        const [, cellPath] = cell;
        const end = Editor.end(editor, cellPath);

        if (Point.equals(selection.anchor, end)) {
          return;
        }
      }
    }

    deleteForward(unit);
  };

  editor.insertBreak = () => {
    const { selection } = editor;

    if (selection) {
      const [table] = Editor.nodes(editor, {
        match: (n) =>
          !Editor.isEditor(n) &&
          SlateElement.isElement(n) &&
          n.type === "table",
      });

      if (table) {
        return;
      }
    }

    insertBreak();
  };

  return editor;
};

export default RichTextEditor;
