import React, { useState, useEffect, useCallback } from "react";
import { admin_company_project_list as $ } from "strings";
import { B2B, B2DB } from "components/Typography";
import { Button } from "components/Buttons";
import { useParams } from "react-router-dom";
import UilFolder from "@iconscout/react-unicons/icons/uil-folder-open";
import { usePageCache } from "contexts/pageCacheContext";
import { getProjects } from "utils/adminApi";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus-circle";
import NewProjectForm from "./NewProjectForm";
import Section from "components/Section";
import { fetchContacts, fetchOwners, fetchLeadSources } from "utils/fetch";
import { toFormat } from "utils/date";
import useTable from "hooks/useTable";
import ProjectPanel from "./ProjectPanel";

const wwOrder = ["morning", "afternoon", "night", "weekend", "anytime"];

const stageOptions = [
  { label: $.active_option, value: "active" },
  { label: $.pitching_option, value: "pitching" },
  { label: $.shadow_pitched_option, value: "shadow_pitched" },
  { label: $.matching_no_support_option, value: "matching_no_support" },
  { label: $.matching_support_option, value: "matching_support" },
  { label: $.sourcing_option, value: "sourcing" },
  { label: $.submitted_option, value: "submitted" },
  { label: $.contracting_option, value: "contracting" },
  { label: $.pipelines_option, value: "pipelines" },
  { label: $.passive_option, value: "passive" },
  { label: $.filled_option, value: "filled" },
  { label: $.lost_option, value: "lost" },
  { label: $.completed_option, value: "completed" },
];

const yesNoOptions = [
  { label: $.yes_option, value: "True" },
  { label: $.no_option, value: "False" },
];

const workWindowsOptions = [
  { value: "morning", label: $.morning_option },
  { value: "afternoon", label: $.afternoon_option },
  { value: "night", label: $.night_option },
  { value: "weekend", label: $.weekend_option },
  { value: "anytime", label: $.anytime_option },
];

const Projects = ({ qualifications, company, hubspotEnabled, onEditClick }) => {
  const { id } = useParams();
  const cacheKey = "cache_company_project_" + id;
  const { set, cache } = usePageCache();
  const c = cache[cacheKey] || {};
  const [panel, setPanel] = useState(c.panel || false);
  const [modal, setModal] = useState();
  const onReset = useCallback(
    (data) => {
      if (panel && !data.find((c) => c.id === panel.id)) {
        setPanel();
      }
    },
    [setPanel, panel]
  );
  const { Table, tableProps, reset, Filters, filtersProps } = useTable({
    cacheKey,
    api: getProjects,
    onReset,
    defaultParams: { company__id: id },
  });

  useEffect(() => {
    return () => {
      set(cacheKey, { panel });
    };
  }, [panel, set, cacheKey]);

  useEffect(() => {
    set("url_company", `/admin/company/${id}/projects`);
    set("url_company_projects_" + id, `/admin/company/${id}/projects`);
  }, [set, id]);

  const schema = {
    name: {
      show: true,
      renderRow: ({ r }) => (
        <B2B className="cursor-pointer inline" onClick={() => setPanel(r)}>
          {r.name}
        </B2B>
      ),
    },
    contact__id: {
      sortKey: "contact__name",
      type: "select",
      selectProps: { async: true, loadOptions: fetchContacts },
      renderRow: ({ r }) =>
        r.contact ? r.contact.first_name + " " + r.contact.last_name : "",
    },
    project_owner_project: {
      show: true,
      type: "multiselect",
      sortKey: "owner_primary__first_name",
      selectProps: { async: true, loadOptions: fetchOwners },
      renderRow: ({ r }) => (
        <span>
          {r.owner_primary && <span className="font-bold">{r.owner_primary.first_name + " " + r.owner_primary.last_name}</span>}
          {(r.owner_primary && r.owner_secondary) && <>, </>}
          {r.owner_secondary && <span>{r.owner_secondary.first_name + " " + r.owner_secondary.last_name}</span>}
        </span>
      )
    },
    new_lead_source__id: {
      sortKey: "new_lead_source__name",
      type: "select",
      selectProps: { async: true, loadOptions: fetchLeadSources },
      renderRow: ({ r }) => (r.new_lead_source ? r.new_lead_source.name : ""),
    },
    availability_hours: {
      type: "int",
      renderRow: ({ v }) => (v ? v + " " + $.hours_per_week : ""),
    },
    ideal_rate: { type: "int", renderRow: ({ v }) => (v ? "$" + v : "") },
    maximum_rate: { type: "int", renderRow: ({ v }) => (v ? "$" + v : "") },
    work_windows: {
      type: "select",
      selectProps: { options: workWindowsOptions },
      renderRow: ({ v }) =>
        v
          ? v
              .sort(
                (a, b) =>
                  wwOrder.indexOf(a.work_window) -
                    wwOrder.indexOf(b.work_window) ||
                  a.work_window - b.work_window
              )
              .map((w) => $[w.work_window + "_option"])
              .join("/")
          : "",
    },
    moonlighter: {
      type: "select",
      selectProps: { options: yesNoOptions },
      renderRow: ({ v }) => (v ? $.yes_option : $.no_option),
    },
    project_qualifications: {
      type: "select",
      selectProps: { options: qualifications.qualificationsOptions },
      renderRow: ({ v }) => v.length + " qualifications",
    },
    candidates_count: { show: true, type: "int" },
    estimated_start_date: {
      type: "date",
      renderRow: ({ r }) => (
        r.start_date ? toFormat(new Date(r.start_date), "MM/dd/yyyy")
        : r.estimated_start_date ? `${toFormat(new Date(r.estimated_start_date), "MM/dd/yyyy")}*`
        : null
      ),
      disableFilter: true,
      disableSort: true
    },
    estimated_end_date: {
      type: "date",
      format: "yyyy-MM",
      rowFormat: "MM/dd/yyyy",
      disableFilter: true,
      disableSort: true
    },
    description: { type: "text" },
    crelate_id: {
      type: "text",
      renderRow: ({ r, v }) => (r.crelate_result ? r.crelate_result : v),
    },
    timestamp: {
      show: true,
      type: "date",
      format: "yyyy-MM",
      rowFormat: "MM/dd/yyyy",
    },
    last_updated: {
      show: true,
      type: "date",
      format: "yyyy-MM",
      rowFormat: "MM/dd/yyyy",
    },
    stage: {
      show: true,
      type: "select",
      selectProps: { options: stageOptions },
      renderRow: ({ v }) => (v ? $[`${v}_option`] : ""),
    },
  };

  return (
    <Section id="admin_company_project_list">
      <div className="flex">
        <Filters {...{ schema, $, ...filtersProps }} />
        <div className="bg-white px-4 py-6 mb-6 flex-1 min-w-0 overflow-x-auto">
          <div className="flex items-center">
            <B2DB className="flex flex-1 items-center">
              <UilFolder className="mr-2" size="18" /> {$.projects_title}
            </B2DB>
          </div>
          <div className="my-6 flex">
            <div className="flex-1 min-w-0">
              <Table
                {...{
                  schema,
                  $,
                  name: "projects",
                  ...tableProps,
                  showAll: false,
                }}
              >
                <Button onClick={() => hubspotEnabled ? onEditClick() : setModal("add_project")}>
                  <UilPlus size="18" className="mr-1" /> {$.add_project_button}
                </Button>
              </Table>
            </div>
          </div>
        </div>
        {panel && <ProjectPanel project={panel} setPanel={setPanel} />}
      </div>
      {modal === "add_project" && (
        <NewProjectForm
          id={id}
          onClose={setModal}
          onSuccess={() => reset()}
          company={company}
        />
      )}
    </Section>
  );
};

export const ProjectsPreload = () => {
  const { id } = useParams();
  const cacheKey = "cache_company_project_" + id;

  useTable({ cacheKey, api: getProjects, defaultParams: { company__id: id } });

  return null;
};

export default Projects;
