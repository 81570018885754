import React from "react";
import { B2DB, B1 } from "./Typography";
import UilDirection from "@iconscout/react-unicons/icons/uil-direction";
import UilUp from "@iconscout/react-unicons/icons/uil-angle-up";
import UilDown from "@iconscout/react-unicons/icons/uil-angle-down";

const Table = ({ children }) => (
  <table
    className="rounded-t table table-auto border-separate text-left min-w-full"
    style={{ borderSpacing: 0 }}
  >
    {children}
  </table>
);

const Head = ({ children }) => (
  <thead className="bg-link-water rounded-t">
    <tr className="">{children}</tr>
  </thead>
);

const H = ({
  children,
  sorted,
  direction,
  disableSort,
  onSort,
  dragOver,
  className = "",
  ...props
}) => (
  <th
    {...props}
    className={
      "uppercase bg-link-water sticky top-0 py-3 " +
      className +
      (dragOver ? " bg-geyser" : "")
    }
  >
    <div className="flex px-6 items-center w-full">
      <B2DB>{children}</B2DB>
      {onSort && !disableSort && (
        <button
          onClick={onSort}
          className="group focus:outline-none w-6 h-6 ml-1"
        >
          <span
            className={
              "group-focus:ring focus:outline-none appearance-none active:bg-geyser w-6 h-6 rounded flex items-center justify-center " +
              (sorted
                ? "text-link hover:text-link-dark"
                : "text-geyser hover:text-kasmir")
            }
            tabIndex="-1"
          >
            {!sorted && <UilDirection size="22" />}
            {sorted && direction === "-" && <UilUp size="22" />}
            {sorted && direction === "" && <UilDown size="22" />}
          </span>
        </button>
      )}
    </div>
  </th>
);

const Body = ({ children, className, ...props }) => (
  <tbody {...props}>{children}</tbody>
);

const Footer = ({ children }) => (
  <div className="rounded-b border border-geyser px-6 py-2">
    <B1>{children}</B1>
  </div>
);

const D = ({
  children,
  className = "",
  dragOver,
  hideLeftBorder = false,
  ...props
}) => (
  <td
    {...props}
    className={
      "border-b border-geyser last:border-r px-6 py-2 font-medium text-kasmir text-sm " +
      className +
      (dragOver ? " bg-geyser" : "") +
      (hideLeftBorder ? "" : " first:border-l")
    }
  >
    {children}
  </td>
);

const Row = ({ children, ...props }) => <tr {...props}>{children}</tr>;

export { Table, Head, Body, Footer, H, D, Row };
