import { useState, useEffect, useCallback, useRef } from "react";
import TableRenderer from "components/TableRenderer";
import FiltersRenderer from "components/FiltersRenderer";
import { usePageCache } from "contexts/pageCacheContext";
import { defer } from "rxjs";

const PAGE_SIZE = 30;

const useTable = ({
  cacheKey,
  api,
  onReset,
  defaultParams = {},
  pageSize = PAGE_SIZE,
  groupedBy = null,
  dragBy = false,
  onRowDrag = false,
}) => {
  const { set, cache } = usePageCache();
  const tableCache = cache[cacheKey + "_table"] || {};
  const isFirst = useRef(true);
  const [hasCache] = useState(!!cache[cacheKey + "_table"]);
  const [pageState, setPageState] = useState(hasCache ? "loading" : "loaded");
  const [data, setData] = useState(tableCache.data || null);
  const [page, setPage] = useState(tableCache.page || 1);
  const [hasMore, setHasMore] = useState(tableCache.hasMore || null);
  const [total, setTotal] = useState(tableCache.total || null);
  const [showColumns, setShowColumns] = useState(tableCache.showColumns || []);
  const [ordering, setOrdering] = useState(tableCache.ordering);
  const [direction, setDirection] = useState(tableCache.direction);
  const [needsIdSort, setNeedsIdSort] = useState(tableCache.needsIdSort);
  const [showFilters, setShowFilters] = useState(
    tableCache.showFilters || false
  );
  const [search, setSearch] = useState(tableCache.search || "");
  const [sections, setSections] = useState(tableCache.sections || {});
  const [filters, setFilters] = useState(tableCache.filters || {});
  const [params, setParams] = useState(tableCache.params || {});
  const [defaults] = useState(defaultParams);
  const lastFetch = useRef();
  const isBgUpdate = useRef(false);
  const pendingFetch = useRef();

  useEffect(() => {
    return () => {
      set(cacheKey + "_table", {
        params,
        filters,
        data,
        page,
        hasMore,
        total,
        showColumns,
        ordering,
        direction,
        showFilters,
        search,
        sections,
      });
    };
  }, [
    cacheKey,
    params,
    filters,
    data,
    page,
    hasMore,
    total,
    showColumns,
    ordering,
    direction,
    showFilters,
    search,
    sections,
    set,
  ]);

  const fetch = useCallback(
    async (
      pageReq,
      concat = true,
      size,
      updatePage = true,
      isPagination = false
    ) => {
      // Ignore if pagination and there is another fetch going
      if (isPagination && lastFetch.current) {
        if (isBgUpdate.current) {
          pendingFetch.current = {
            pageReq,
            concat,
            size,
            updatePage,
            isPagination,
          };
        }
        return;
      }
      if (lastFetch.current) {
        lastFetch.current.unsubscribe();
        lastFetch.current = null;
      }
      setPageState("fetching");

      const sendParams = {};
      Object.keys(params).forEach((k) => {
        if (null !== params[k]) {
          sendParams[k] = params[k];
        }
      });

      if (ordering) {
        sendParams.ordering = direction + ordering;
      }

      if (ordering && needsIdSort) {
        sendParams.ordering = direction + ordering + ",id";
      }

      if (search) {
        sendParams.search = search;
      }

      const showParams = { ...sendParams };

      lastFetch.current = defer(() => {
        return api({
          ...{ ...defaults, ...sendParams },
          page_size: size || pageSize,
          page: pageReq,
        });
      }).subscribe(
        (response) => {
          setShowColumns(
            Object.keys(showParams).map((p) =>
              p === "ordering"
                ? sendParams.ordering.replace("-", "")
                : p
                    .replace("__gte", "")
                    .replace("__lte", "")
                    .replace("__gt", "")
                    .replace("__lt", "")
            )
          );

          if (concat) {
            setData((d) => d.concat(response.results));
          } else {
            setData(response.results);
            if (onReset) {
              onReset(response.results);
            }
          }
          if (updatePage) {
            setPage(pageReq);
          } else if (isBgUpdate.current) {
            setPage(size / pageSize);
          }
          setHasMore(!!response.next);
          setTotal(response.count);
          setPageState("loaded");
          isBgUpdate.current = false;
          lastFetch.current = null;
          if (pendingFetch.current) {
            const pf = pendingFetch.current;
            pendingFetch.current = null;
            fetch(
              pf.pageReq,
              pf.concat,
              pf.size,
              pf.updatePage,
              pf.isPagination
            );
          }
        },
        (e) => {
          setPageState("error");
          console.error(e);
        }
      );
    },
    [params, search, ordering, direction, defaults, api, pageSize, onReset]
  );

  const reset = useCallback(() => fetch(1, false), [fetch]);

  useEffect(() => {
    if (isFirst.current) {
      return;
    }
    fetch(1, false);
  }, [fetch, hasCache]);

  useEffect(() => {
    if (isFirst.current) {
      const size =
        data && data.length > PAGE_SIZE
          ? data.length % PAGE_SIZE > 0
            ? (~~(data.length / PAGE_SIZE) + 1) * PAGE_SIZE
            : ~~(data.length / PAGE_SIZE) * PAGE_SIZE
          : PAGE_SIZE;

      isFirst.current = false;
      if (size > 30) {
        return;
      }
      isBgUpdate.current = true;

      setPage(size / PAGE_SIZE);
      fetch(1, false, size, false);
    }
  }, [fetch, hasCache, data]);

  return {
    reset,
    params,
    filters,
    data,
    setData,
    Table: TableRenderer,
    Filters: FiltersRenderer,
    filtersProps: {
      filters,
      params,
      setFilters,
      setParams,
      showFilters,
      sections,
      setSections,
      cacheKey,
    },
    tableProps: {
      data,
      fetch,
      hasMore,
      filters,
      params,
      setFilters,
      setParams,
      total,
      pageState,
      page,
      showColumns,
      ordering,
      direction,
      search,
      showFilters,
      setOrdering,
      setDirection,
      setShowFilters,
      setSearch,
      setNeedsIdSort,
      groupedBy,
      dragBy,
      onRowDrag,
    },
  };
};

export default useTable;
