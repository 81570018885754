import React from "react";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import UilWarning from "@iconscout/react-unicons/icons/uil-exclamation-triangle";
import UilError from "@iconscout/react-unicons/icons/uil-exclamation-octagon";
import UilSuccess from "@iconscout/react-unicons/icons/uil-check-circle";

// This constants objects maps the alert type to tailwindcss classes
const bgColors = {
  error: "bg-red-light",
  success: "bg-link-lighter",
  info: "bg-yellow-100",
};

const txtColors = {
  error: "text-red",
  success: "text-link",
  info: "text-yellow-800",
};

/**
 * Renders an alert. Is used as Template for the react-alert library (https://github.com/schiehll/react-alert)
 * @param {object} style - Styles to position the alert in the page given a configuration.
 * @param {object} options - It contains the alert type.
 * @param {string} message - Message to show in the alert.
 * @param {callback} close - A callback to close the alert.
 */
const AlertTemplate = ({ style, options, message, close }) => (
  <div
    className={
      "rounded pl-4 pr-2 py-2 flex items-center " +
      bgColors[options.type] +
      " " +
      txtColors[options.type]
    }
    style={style}
  >
    <span className="mr-2">
      {options.type === "success" && <UilSuccess size="24" />}
      {options.type === "error" && <UilError size="24" />}
      {options.type === "info" && <UilWarning size="24" />}
    </span>
    <span className="font-bold text-sm">{message}</span>
    <button onClick={close} className="ml-2 group focus:outline-none w-6 h-6">
      <span
        className="group-focus:ring focus:outline-none rounded text-lg w-6 h-6 inline-flex items-center justify-center"
        tabIndex="-1"
      >
        <UilTimes size="20" />
      </span>
    </button>
  </div>
);

export default AlertTemplate;
