import React, { useEffect, useRef } from "react";
import { admin_project_overview as $ } from "strings";
import Select from "components/CompactSelectNote";
import {
  getProject,
  patchProjectV2,
} from "utils/adminApi";
import useFetch from "hooks/useFetch";

const lossReasonOptions = [
  { value: "traditional_agency_reason", label: $.traditional_agency_reason },
  { value: "competitor_reason", label: $.competitor_reason },
  { value: "fte_hire_reason", label: $.fte_hire_reason },
  { value: "internal_resources_reason", label: $.internal_resources_reason },
  { value: "contractor_sourced_internally_reason", label: $.contractor_sourced_internally_reason },
  { value: "pausing_scope_reason", label: $.pausing_scope_reason },
  { value: "rejected_talent_recommendation_reason", label: $.rejected_talent_recommendation_reason },
  { value: "too_expensive_reason", label: $.too_expensive_reason },
  { value: "rsu_declined_opportunity_reason", label: $.rsu_declined_opportunity_reason },
  { value: "rsu_declined_availability_timing_reason", label: $.rsu_declined_availability_timing_reason },
  { value: "client_unresponsive_reason", label: $.client_unresponsive_reason },
  { value: "other_reason", label: $.other_reason },
];

export const onLostSave = async (
  { loss_reason, body, ...values },
  data,
  projectId
) => {
  const toSend = {
    loss_reason: loss_reason.value || null,
    stage: 'lost'
  };
  await patchProjectV2(projectId, toSend);
};

export const getLostNote = (values) => [
  {
    type: "table",
    children: [
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Loss Reason: ", bold: true },
              {
                text: values.loss_reason ? values.loss_reason.label : "Not Set",
              },
            ],
          },
        ]
      },
    ],
  },
];

const StageLostForm = ({
  values,
  updateVal,
  projectId,
  setValues,
  setContext,
}) => {
  const setupDone = useRef(false);

  const { data, pageState } = useFetch(getProject, projectId);

  useEffect(() => {
    if (!setupDone.current && pageState === "loaded") {
      setupDone.current = true;

      setContext(data);
      setValues({
        ...{
          loss_reason: data.loss_reason
        },
        ...values,
      });
    }
  }, [data, pageState]);

  if (pageState !== "loaded") {
    return null;
  }

  return (
    <div className="stage-change-form-middle flex flex-col border-l border-r border-link px-2">
      <div className="flex items-center">
        <span className="text-sm font-bold text-kasmir">
          {$.loss_reason_label}
        </span>
        <div className="flex-1 ml-1">
          <Select
            placeholder={$.reason_placeholder}
            value={values.loss_reason}
            onChange={(v) => updateVal("loss_reason", v)}
            options={lossReasonOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default StageLostForm;
