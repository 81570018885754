import { useState, useEffect } from "react";
import { getDescriptors } from "utils/adminApi";

let cached = null;
const useDescriptors = () => {
  const [descriptors, setDescriptors] = useState(null);

  useEffect(() => {
    const load = async () => {
      try {
        const response = await getDescriptors({ page_size: 9999 });
        const ordered = {};

        response.results.forEach((d) => {
          if (!ordered[d.qualification]) {
            ordered[d.qualification] = [];
          }

          ordered[d.qualification].push(d);
        });

        cached = ordered;
        setDescriptors(cached);
      } catch (e) {
        console.error(e);
      }
    };

    if (!cached) {
      load();
    } else {
      setDescriptors(cached);
    }
  }, []);

  return descriptors;
};

export default useDescriptors;
