import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { defer } from "rxjs";
import { admin_project_candidate_detail as $ } from "strings";
import { B2DB } from "components/Typography";
import UilPlusCircle from "@iconscout/react-unicons/icons/uil-plus-circle";
import CandidateActivityForm from "./CandidateActivityForm";
import CandidateActivityItem from "./CandidateActivityItem";
import DebouncedInput from "components/DebouncedInput";
import { getCandidateProjectNotes } from "utils/adminApi";
import { weekAgo, monthAgo } from "utils/date";
import useOnScreen from "hooks/useOnScreen";

const PAGE_SIZE = 30;

const Separator = ({ text }) => (
  <div className="flex items-center">
    <div className="border-t border-geyser flex-1"></div>
    <div className="mx-2 text-sm font-bold text-geyser">{text}</div>
    <div className="border-t border-geyser flex-1 flex-no-shrink"></div>
  </div>
);

const CandidateActivity = ({ candidate, refresh }) => {
  const lastFetch = useRef();
  const [notes, setNotes] = useState([]);
  const [hasMore, setHasMore] = useState();
  const [page, setPage] = useState();
  const [search, setSearch] = useState("");
  const isFirst = useRef(true);

  const { id } = useParams();

  const fetch = useCallback(
    async (
      pageReq,
      concat = true,
      size,
      updatePage = true,
      isPagination = false
    ) => {
      // Ignore if pagination and there is another fetch going
      if (isPagination && lastFetch.current) {
        return;
      }
      if (lastFetch.current) {
        lastFetch.current.unsubscribe();
        lastFetch.current = null;
      }

      lastFetch.current = defer(() => {
        return getCandidateProjectNotes({
          candidate_id: candidate.candidate_user_id,
          type: 'status_history',
          project_id: id,
          ordering: '-created_timestamp',
          page_size: size || PAGE_SIZE,
          page: pageReq,
          search
        });
      }).subscribe(
        (response) => {
          if (concat) {
            setNotes((d) => d.concat(response.results));
          } else {
            setNotes(response.results);
          }
          if (updatePage) {
            setPage(pageReq);
          }
          setHasMore(!!response.next);
          lastFetch.current = null;
        },
        (e) => {
          console.error(e);
        }
      );
    },
    [search, refresh, id]
  );

  const { measureRef, isIntersecting, observer } = useOnScreen();

  useEffect(() => {
    if (isIntersecting && hasMore) {
      fetch(page + 1, true, null, true, true);
      observer.disconnect();
    }
  }, [isIntersecting, hasMore, fetch]);

  useEffect(() => {
    if (isFirst.current) {
      return;
    }
    fetch(1, false);
  }, [fetch]);

  useEffect(() => {
    if (isFirst.current) {
      const size = PAGE_SIZE;

      isFirst.current = false;

      setPage(1);
      fetch(1, false, size, false);
    }
  }, [fetch, notes]);

  const onAddNote = (note) => {
    if (!note) return;
    setNotes([note, ...notes]);
  };

  return (
    <div className="bg-white flex-1 mr-2 h-full flex flex-col">
      <div className="flex items-center p-2">
        <B2DB className="flex flex-1 items-center">
          <UilPlusCircle className="mr-2" size="18" /> {$.candidate_activity_title}
        </B2DB>
        <div className="w-70">
          <DebouncedInput
            className="w-full px-3 rounded h-8 flex items-center font-bold text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-bold focus:outline-none appearance-none border border-geyser focus:border-link focus:border-2"
            placeholder={$.notes_search_placeholder}
            value={search}
            onChange={(s) => setSearch(s)}
          />
        </div>
      </div>
      <div
        className="flex-1 min-h-0 overflow-y-auto px-2"
        id="scrollableDiv"
        style={{
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {notes !== null && notes.map((n, i) => (
          <React.Fragment key={n.id}>
            {notes[i - 1] &&
            new Date(n.created_timestamp) < monthAgo &&
            (!notes[i - 1] ||
              (notes[i - 1] &&
                new Date(notes[i - 1].created_timestamp) >= monthAgo)) ? (
              <Separator text="This Month" />
            ) : new Date(n.created_timestamp) < weekAgo &&
              (!notes[i - 1] ||
                (notes[i - 1] &&
                  new Date(notes[i - 1].created_timestamp) >=
                    weekAgo)) ? (
              <Separator text="This Week" />
            ) : null}
            <CandidateActivityItem
              className={i % 2 === 0 ? "bg-link-water bg-opacity-60" : "bg-white"}
              note={n}
              editable={false}
              displayStatus={notes[i - 1]?.status !== notes[i].status}
              measureRef={measureRef}
            />
          </React.Fragment>
        ))}
      </div>
      <div className="border-t border-geyser p-2">
        {notes && (
          <CandidateActivityForm
            projectId={id}
            candidate={candidate}
            onAddNote={onAddNote}
          />
        )}
      </div>
    </div>
  );
};

export default CandidateActivity;
