import React from "react";
import UilLeft from "@iconscout/react-unicons/icons/uil-angle-left-b";
import { admin_project_candidate_detail as $ } from "strings";
import { Button, OutlinedButton, RemoveBtn } from "components/Buttons";

const CandidateDetailActions = ({ onDisabledClick, candidate, onBack, onChangeStatus, onDelete }) => {
  return (
    <div className="candidate_detail_actions flex mb-1 h-12 items-center">
      <OutlinedButton className="h-10" onClick={onBack}>
        <UilLeft size="18" />
      </OutlinedButton>
      <Button
        noMinW
        className="ml-1"
        onClick={() => {
          candidate.status === 'scheduled' ?
          onDisabledClick() :
          onChangeStatus()
        }}
      >
        {$.change_status_button}
      </Button>
      <RemoveBtn className="h-10 w-10" danger={true} onClick={onDelete} />
  </div>
  );
};

export default CandidateDetailActions;