import React, { useState } from "react";
import { admin_company_clockify as $ } from "strings";
import Section from "components/Section";
import { B2DB } from "components/Typography";
import UilBuilding from "@iconscout/react-unicons/icons/uil-building";
import { Button } from "components/Buttons";
import CreateCompanyForm from "./CreateCompanyForm";
import { LoadPlaceholderGroup } from "components/LoadPlaceholder";
import InputCompanyForm from "./InputCompanyForm";
import { Input, Label } from "components/Form";

const Clockify = ({ data, setData }) => {
  const [status, setStatus] = useState();

  if (!data) {
    return (
      <div className="bg-white px-4 py-6 mb-6">
        <LoadPlaceholderGroup />
      </div>
    );
  }

  return (
    <Section id="admin_company_clockify" className="bg-white px-4 py-6 mb-6">
      <B2DB className="flex items-center mb-4">
        <UilBuilding className="text-link mr-2" size="20" />
        {$.title}
      </B2DB>
      {!data.clockify_id && !status && (
        <div className="flex items-center space-x-4">
          <Button onClick={() => setStatus("input")}>{$.input_btn}</Button>
          <Button onClick={() => setStatus("create")}>{$.create_btn}</Button>
        </div>
      )}
      {status === "create" && (
        <CreateCompanyForm
          data={data}
          setData={setData}
          setStatus={setStatus}
        />
      )}
      {status === "input" && (
        <InputCompanyForm data={data} setData={setData} setStatus={setStatus} />
      )}
      {data.clockify_id && (
        <div className="w-full md:w-1/2">
          <Label>{$.clockify_id_input}</Label>
          <Input disabled value={data.clockify_id} />
        </div>
      )}
    </Section>
  );
};

export default Clockify;
