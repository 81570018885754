import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { admin_contact_list as $ } from "strings";
import { Button } from "components/Buttons";
import { B2B } from "components/Typography";
import { usePageCache } from "contexts/pageCacheContext";
import { getContactsV2 } from "utils/adminApi";
import AddContactForm from "./AddContactForm";
import HubspotToast from "components/HubspotToast";
import UilPlus from "@iconscout/react-unicons/icons/uil-plus-circle";
import Section from "components/Section";
import useTable from "hooks/useTable";
import manager from "filters/company";
import { getAdminFlags } from "utils/localStorageService";

const userTypeOptions = [
  { value: 'other', label: 'Talent / Staff' },
  { value: 'company', label: 'Company' },
];

const CompanyList = () => {
  const alert = useAlert();
  const { set } = usePageCache();
  const [modal, setModal] = useState();
  const history = useHistory();
  const flags = getAdminFlags();

  const { Table, tableProps, Filters, filtersProps } = useTable({
    cacheKey: "cache_contacts_table",
    api: getContactsV2,
    defaultParams: { ordering: "-updated_at" },
  });

  useEffect(() => {
    set("url_contact", "/admin/contacts");
  }, [set]);

  useEffect(() => {
    document.title = "Pipeline - Contact Search";
  }, []);

  const schema = {
    name: {
      sortKey: "fullname",
      renderRow: ({ r }) => (
        <B2B className="cursor-pointer inline" onClick={() => handleContactClick(r)}>
          {r.full_name ? r.full_name : r.email}
        </B2B>
      ),
    },
    primary_companies: {
      renderRow: ({ r }) => (r.primary_companies?.length ? r.primary_companies.map((c, i) => (
        <>
          <B2B className="inline cursor-pointer" onClick={() => handleCompanyClick(c.id)}>
            {c.name}
          </B2B>
          {i !== r.primary_companies.length - 1 && ', '}
        </>
      )) : 'Unknown'),
      disableSort: true,
      show: true
    },
    type: {
      type: "select",
      selectProps: { options: userTypeOptions },
      renderRow: ({ r }) => (userTypeOptions.find(o => o.value === r.type)?.label || 'Unknown')
    }
  };

  const handleContactClick = (contact) => {
    history.push(`/admin/contacts/${contact.id}/contact_info`);
  }

  const handleCompanyClick = (id) => {
    history.push(`/admin/company/${id}`);
  }

  const onAddClick = () => {
    alert.success(<HubspotToast type='add-contact' />, {timeout: 10000});
  }

  return (
    <Section id="admin_contact_list">
      <div className="flex">
      <Filters {...{ schema, $, ...filtersProps, manager }} /> 
        <div className="bg-white px-4 py-6 flex-1 min-w-0">
          <Table
            {...{
              schema,
              $,
              name: "contacts",
              ...tableProps,
              showAll: false,
              manager,
            }}
          >
            <Button onClick={() => flags.hubspot_features_enabled ? onAddClick() : setModal("add_contact")}>
              <UilPlus size="18" className="mr-1" /> {$.add_contact_button}
            </Button>
          </Table>
        </div>
      </div>
      {modal === "add_contact" && (
        <AddContactForm
          onClose={setModal}
          setModal={setModal}
          onSuccess={setModal}
        />
      )}
    </Section>
  );
};

export default CompanyList;
