import React, { useState, useEffect } from "react";
import { getCandidateTalentInfo, getCandidateProjectsV2 } from "utils/adminApi";
import Section from "components/Section";
import TalentInfo from './TalentInfo';
import CandidateDetailActions from './CandidateDetailActions';
import CandidateProjects from './CandidateProjects';
import CandidateActivity from './CandidateActivity';
import DeleteCandidateForm from './DeleteCandidateForm';
import CandidateStatusForm from './CandidateStatusForm';
import Candidate from './Candidate';
import { useAlert } from "react-alert";
import HubspotToast from "components/HubspotToast";

const CandidateDetail = ({ project, candidate, onBack, onUpdate, hubspotInputsDisabled }) => {
  const [modal, setModal] = useState(false);
  const [refreshActivity, setRefreshActivity] = useState(0);
  const [openPanel, setOpenPanel] = useState('talent');
  const [talentInfo, setTalentInfo] = useState({
    name: candidate.name || null,
    candidateId: candidate.candidate_user_id || null,
    currentTitle: candidate.current_title
  });
  const [activeProjects, setActiveProjects] = useState([]);
  const [candidateProjects, setCandidateProjects] = useState([]);
  const [completedProjects, setCompletedProjects] = useState([]);
  const alert = useAlert();

  const onTogglePanel = (panel) => {
    let currentPanel = openPanel;
    currentPanel === panel ? setOpenPanel(null) : setOpenPanel(panel);
  }

  const getTalentInfo = async () => {
    try {
      const response = await getCandidateTalentInfo(candidate.id); 
      setTalentInfo({
        ...talentInfo,
        ...response
      });
    } catch (e) {
      console.error(e);
    }
  };

  const getCandidateProjects = async () => {
    try {
      const params = {
        'candidate__user__id': candidate.candidate_user_id,
      };
      const response = await getCandidateProjectsV2(params);
      setActiveProjects(response.results.filter(r => r.status === 'active' && r.stage !== 'completed'));
      setCandidateProjects(response.results.filter(r => r.status !== 'pass' && r.status !== 'active' && r.stage !== 'completed' && r.stage !== 'filled'));
      setCompletedProjects(response.results.filter(r => r.status === 'completed'));
    } catch (e) {
      console.error(e);
    }
  };
    
  useEffect(() => {
    getTalentInfo();
    getCandidateProjects();
  }, []);

  const onDelete = () => {
    setModal('deleteCandidate');
  }

  const onDeleteSuccess = () => {
    setModal(false);
    onUpdate();
    onBack();
  }

  const onChangeStatus = () => {
    setModal('changeStatus');
  }

  const onChangeStatusSuccess = () => {
    setModal(false);
    setRefreshActivity(refreshActivity + 1);
    onUpdate();
  }

  const onDisabledClick = () => {
    alert.success(<HubspotToast type='candidate' objectId={project?.hubspot_deal_id} />, {timeout: 10000});
  }

  return (
    <div className="candidate_detail flex flex-col mt-1 h-full">
      <div className="flex mt-1 w-full">
        <CandidateDetailActions candidate={candidate} onDisabledClick={onDisabledClick} onBack={onBack} onChangeStatus={onChangeStatus} onDelete={onDelete} />
      </div>
      <div className="flex mt-1 w-full">
        <Candidate candidate={candidate} disabled={true} />
      </div>
      <Section className="flex mt-1 h-full overflow-y-auto">
        <CandidateActivity candidate={candidate} refresh={refreshActivity} />
        <div className="w-1/3">
          <TalentInfo talentInfo={talentInfo} open={openPanel === 'talent'} onTogglePanel={() => onTogglePanel('talent')} />
          <CandidateProjects projects={activeProjects} type={'active'} open={openPanel === 'active'} onTogglePanel={() => onTogglePanel('active')} />
          <CandidateProjects projects={candidateProjects} type={'candidate'} open={openPanel === 'candidate'} onTogglePanel={() => onTogglePanel('candidate')} />
          <CandidateProjects projects={completedProjects} type={'completed'} open={openPanel === 'completed'} onTogglePanel={() => onTogglePanel('completed')} />
        </div>
      </Section>
      {modal === "deleteCandidate" &&
        <DeleteCandidateForm
          selectedCandidates={[candidate]}
          onClose={setModal}
          onSuccess={onDeleteSuccess}
        />
      }
      {modal === "changeStatus" && (
        <CandidateStatusForm
          project={project}
          data={candidate}
          onClose={setModal}
          onSuccess={onChangeStatusSuccess}
          hubspotInputsDisabled={hubspotInputsDisabled}
        />
      )}
    </div>
  );
};

export default CandidateDetail;