import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import ContractDetails from "./ContractDetails";

const Finance = ({ data, setData }) => {
  const { id } = useParams();
  const { set } = usePageCache();

  useEffect(() => {
    set("url_project", `/admin/project/${id}/finance`);
  }, [set, id]);

  return (
    <div className="admin_finance">
      <ContractDetails projectData={data} setProjectData={setData} />
    </div>
  );
};

export default Finance;