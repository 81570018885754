import React, { useState } from "react";
import { UisStar } from "@iconscout/react-unicons-solid";
import UilStar from "@iconscout/react-unicons/icons/uil-star";

/**
 * Renders a rating input with stars. Used to rate qualifications.
 * @param {int} value - The value of the input.
 * @param {callback} onChange - Callback to handle value changes.
 */
const RatingInput = ({ value, onChange }) => {
  const [hover, setHover] = useState();

  const count = hover || value;

  return (
    <span className="text-base font-serif mx-1 flex items-center">
      <span className="flex items-center">
        {[...Array(5)].map((e, i) => (
          <div
            className="text-link cursor-pointer"
            onMouseEnter={() => setHover(i + 1)}
            onMouseLeave={() => setHover()}
            onClick={() => onChange(value === i + 1 ? 0 : i + 1)}
            key={i}
          >
            {count >= i + 1 ? <UisStar size="16" /> : <UilStar size="16" />}
          </div>
        ))}
      </span>
    </span>
  );
};

export default RatingInput;
