import React, { useState } from "react";
import { Input } from "./Form";
import { EditBtn, CancelBtn, SaveBtn } from "./Buttons";
import { H1B } from "./Typography";

const EditableTitle = ({ value, onSave, displayValue = "", disabled, onDisabledClick }) => {
  const [editable, setEditable] = useState(false);
  const [inputValue, setInputValue] = useState(value);

  return !editable ? (
    <div className="flex items-center">
      <H1B className="text-center md:text-left">{displayValue || value}</H1B>
      <EditBtn
        onClick={() => {
          if (disabled && onDisabledClick) {
            onDisabledClick();
            return;
          }
          setInputValue(value);
          setEditable(true);
        }}
      />
    </div>
  ) : (
    <div className="flex items-center flex-1">
      <Input
        autoFocus
        onChange={(e) => setInputValue(e.target.value)}
        value={inputValue}
      />
      <SaveBtn
        onClick={() => {
          setEditable(false);
          onSave(inputValue);
        }}
      />
      <CancelBtn onClick={() => setEditable(false)} />
    </div>
  );
};

export default EditableTitle;
