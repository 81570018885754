import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { admin_talent_roles_form as $ } from "strings";
import { global as $$ } from "strings";
import { Header, Footer } from "components/Card";
import { Label, Error } from "components/Form";
import Select from "components/Select";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import {
  getTalentOwners,
  postTalentOwner,
  deleteTalentOwner,
  patchTalentOnboardingV2
} from "utils/adminApi";
import { fetchOwners, fetchTalentLeadSources } from "utils/fetch";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { useParams } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import AlertSuccess from "components/AlertSuccess";

const rolesOptions = [
  { label: $.role_owner_option, value: "owners" },
  { label: $.role_lead_option, value: "lead_source" },
];

const RolesForm = ({
  setModal,
  onClose,
  setData,
  data = {},
  defaultRole = "owners",
  owners,
  onboardingData,
  setOnboardingData
}) => {
  const alert = useAlert();
  const { id } = useParams();
  const [role, setRole] = useState(defaultRole);
  const { set, cache } = usePageCache();
  const c = cache.admin_talent_form || {};

  const { handleSubmit, errors, formState, control } = useForm();

  const onSubmit = async (values) => {
    onClose();

    if (role === "lead_source") {
      set("admin_talent_form", null);
      setOnboardingData({...onboardingData, lead_source_name: values[role].label })

      const call = async () => {
        try {
          const response = await patchTalentOnboardingV2(data.id, {
            ...onboardingData,
            'lead_source_name': values[role] && values[role].label,
            'lead_source_id': values[role] && values[role].value,
          });
          alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
          setData({...data, lead_source: { name: response.lead_source_name, id: response.lead_source_id }});
        } catch (e) {
          console.error(e);
          alert.error(<AlertError error={e} onRetry={call} />);
        }
      };

      await call();
    } else if (role === 'owners') {
      let notFiltered = [];
      let toDelete;
      let toAdd;

      if (owners?.data?.results) {
        toDelete = owners.data.results.filter((o) => {
          const isDelete =
            !values.owners ||
            !values.owners.find((v) => v.value === o.owner.id);

          if (!isDelete) {
            notFiltered.push(o);
          }

          return isDelete;
        });
      }

      if (values.owners) {
        toAdd = values.owners
          .filter((v) => {
            const isAdd =
              !owners?.data?.results ||
              !owners?.data?.results.find((o) => v.value === o.owner.id);

            if (isAdd) {
              notFiltered.push({ talent: id, owner: v.owner });
            }

            return isAdd;
          })
          .map((v) => ({ talent: id, owner: v.value }));
      }

      owners.setData((d) => ({ ...d, results: notFiltered }));

      const call = async () => {
        try {
          if (Array.isArray(toAdd) && toAdd.length) {
            for (const o of toAdd) {
              await postTalentOwner(o);
            }
          }

          if (Array.isArray(toDelete) && toDelete.length) {
            for (const o of toDelete) {
              await deleteTalentOwner(o.id);
            }
          }

          const response = await getTalentOwners(id);
          alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
          owners.setData(response);
        } catch (e) {
          console.error(e);
          alert.error(<AlertError error={e} onRetry={call} />);
        }
      };

      await call();
    }

  };

  return (
    <Modal
      isDirty={formState.isDirty}
      onClose={() => {
        set("admin_talent_form", null);
        onClose();
      }}
      id="admin_talent_roles_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Header title={$.edit_role_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full pr-8 mb-3 mt-2">
            <Label>{$.role_input}</Label>
            <Select
              placeholder={$.select_option}
              options={rolesOptions}
              onChange={(v) => setRole(v.value)}
              defaultValue={rolesOptions.find((o) => o.value === defaultRole)}
            />
          </div>
          {role === "owners" && (
            <div className="w-full pr-8 mb-3 mt-2">
              <Label>{$.talent_owner_input}</Label>
              <Controller
                control={control}
                name="owners"
                defaultValue={
                  Array.isArray(owners?.data?.results)
                    ? owners.data.results.map((o) => ({
                        onwer: o.owner,
                        value: o.owner.id,
                        label:
                          o.owner.first_name +
                          " " +
                          o.owner.last_name,
                      }))
                    : []
                }
                as={<Select />}
                error={errors.owners}
                placeholder={$.select_option}
                async
                isMulti
                loadOptions={fetchOwners}
              />
              {errors.owners && <Error>{errors.owners.message}</Error>}
            </div>
          )}
          {role === "lead_source" && (
            <div className="w-full pr-8 mb-3 mt-2">
              <Label>{$.lead_source_input}</Label>
              <Controller
                control={control}
                name="lead_source"
                defaultValue={
                  c.lead_source
                    ? {
                        lead_source: c.lead_source,
                        value: c.lead_source.id,
                        label: c.lead_source.name,
                      }
                    : data.lead_source && onboardingData
                    ? {
                        lead_source: { label: onboardingData.lead_source_name, value: onboardingData.lead_source_id },
                        value: onboardingData.lead_source_id,
                        label: onboardingData.lead_source_name,
                      }
                    : null
                }
                as={<Select />}
                error={errors.lead_source}
                placeholder={$.select_option}
                async
                loadOptions={fetchTalentLeadSources}
                footerText={$.add_lead_source_button}
                footerOnClick={() => {
                  set("admin_talent_form", {
                    back_form: "roles.lead_source",
                  });
                  setModal("add_lead_source");
                }}
              />
              {errors.lead_source && (
                <Error>{errors.lead_source.message}</Error>
              )}
            </div>
          )}
        </div>
        <Footer>
          <Button
            secondary
            onClick={() => {
              onClose();
            }}
            className="mr-4"
          >
            {$.cancel_button}
          </Button>
          <Button type="submit" loading={formState.isSubmitting} disabled={formState.isSubmitting}>
            {$.save_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default RolesForm;