import { useState, useEffect } from "react";
import { getQualifications } from "utils/api";
import { getQualifications as adminGetQualifications } from "utils/adminApi";

let cached = null;
const useQualifications = (isAdmin = false) => {
  const [qualifications, setQualifications] = useState({});

  useEffect(() => {
    const load = async () => {
      try {
        const response = await (isAdmin
          ? adminGetQualifications
          : getQualifications)({ page_size: 9999 });
        const qualificationsArray = [...response.results];
        const qualificationsOrdered = {};
        const qualificationsByValue = {};

        response.results.forEach((v) => {
          let type = v.type ? v.type.toLowerCase() : "skill";
          if (type === "strategy") {
            type = "skill";
          }
          const grouping = v.grouping
            ? v.grouping.toLowerCase()
            : "miscellaneous";

          if (!qualificationsOrdered[type]) {
            qualificationsOrdered[type] = {};
          }

          if (!qualificationsOrdered[type][grouping]) {
            qualificationsOrdered[type][grouping] = [];
          }

          qualificationsOrdered[type][grouping].push(v);
          qualificationsByValue[v.id] = { ...v, type, grouping };
        });
        Object.values(qualificationsOrdered).forEach((type) => {
          Object.values(type).forEach((grouping) => {
            grouping.sort((a, b) => a.name.localeCompare(b.name));
          });
        });

        if (qualificationsOrdered.skill && qualificationsOrdered.strategy) {
          qualificationsOrdered.skill["strategy"] =
            qualificationsOrdered.strategy;

          delete qualificationsOrdered.strategy;
        }

        const qualificationsOptions = [];
        if (qualificationsOrdered) {
          ["skill", "tool", "industry", "leadership"].forEach((k) => {
            if (!qualificationsOrdered[k]) {
              return;
            }
            Object.entries(qualificationsOrdered[k]).forEach(([j, g]) => {
              const section = [];
              g.forEach((q) => section.push({ label: q.name, value: q.id }));

              const groupName = k + (j ? " - " + j.replace("_", " ") : "");
              qualificationsOptions.push({
                label: groupName,
                options: section.sort((a, b) => a.label.localeCompare(b.label)),
              });
            });
          });
        }

        cached = {
          qualificationsOrdered,
          qualificationsByValue,
          qualificationsOptions,
          qualificationsArray
        };
        setQualifications(cached);
      } catch (e) {
        console.error(e);
      }
    };

    if (!cached) {
      load();
    } else {
      setQualifications(cached);
    }
  }, [isAdmin]);

  return { qualifications };
};

export default useQualifications;
