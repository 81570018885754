import { autocomplete } from "@algolia/autocomplete-js";
import React, { createElement, Fragment, useEffect, useRef } from "react";
import { render } from "react-dom";

const Autocomplete = (props) => {
  const containerRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment },
      render({ children }, root) {
        render(children, root);
      },
      renderNoResults({ state }, root) {
        render(
          <div className="bottom-0 rounded-lg bg-white shadow py-2 px-4 text-base text-kasmir font-bold">
            No results have been found for "{state.query}".
          </div>,
          root
        );
      },
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return <div className={props.customStyle || "w-full"} ref={containerRef} />;
};

export default Autocomplete;
