import React, { useState } from "react";
import { admin_talent_overview as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import Indicator from "components/ListIndicator";
import UilSetting from "@iconscout/react-unicons/icons/uil-setting";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import UilPin from "@iconscout/react-unicons/icons/uil-map-pin";
import UilClock from "@iconscout/react-unicons/icons/uil-clock";
import UilStopwatch from "@iconscout/react-unicons/icons/uil-stopwatch";
import Header from "components/ListHeader";
import useCachedFetch from "hooks/useCachedFetch";
import {
  getTalentPreferencesV2,
  patchTalentPreferencesV2
} from "utils/adminApi";
import AvailabilityLastUpdate from "./AvailabilityLastUpdate";
import Select from "components/CompactSelect";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";

const workWindowsOptions = [
  { value: "morning", label: $.morning_option },
  { value: "afternoon", label: $.afternoon_option },
  { value: "night", label: $.night_option },
  { value: "weekend", label: $.weekend_option },
  { value: "anytime", label: $.anytime_option },
];

const boolOptions = [
  { value: true, label: $.yes_option },
  { value: false, label: $.no_option },
];

const customMultiSelectStyles = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.secondary
      ? "#f4f6f9"
      : state.selectProps.isDisabled
      ? "#f4f6f9"
      : "inherit",
    minHeight: "1.5rem",
    lineHeight: "1.5rem",
    width: "100%",
    marginTop: "0",
    marginBottom: "0",
    borderRadius: ".25rem",
    padding: "0",
    display: "flex",
    alignItems: "center",
    boxShadow: "0",
    border: state.selectProps.error
      ? "solid 1.5px #a10000"
      : state.isFocused
      ? "solid 1.5px #0070d2"
      : state.selectProps.secondary
      ? "0"
      : "solid 1px #fff",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#fff",
    borderRadius: ".25rem",
    height: "1.25rem",
    display: "flex",
    alignItems: "center",
    fontWeight: "700",
    fontSize: ".875rem",
    color: "#16325c",
    
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontWeight: "700",
    fontSize: ".875rem",
    color: "#16325c",
    padding: "0 10px 0 0",
  }),
}

const Preferences = ({ talentData }) => {
  const [open, setOpen] = useState(true);
  const [states, setStates] = useState({});
  const alert = useAlert();

  const { data, setData } = useCachedFetch(
    "admin_talent_preferences",
    getTalentPreferencesV2,
    talentData?.id
  );

  const onWorkWindowsChange = async (values) => {
    const dataValues = values ? values.map(v => v.value) : [];
    const origVal = dataValues;

    setData({ ...data, 'work_windows': dataValues });
    setStates({ ...states, 'work_windows': "saving" });

    const call = async () => {
      try {
        const response = await patchTalentPreferencesV2(talentData?.id, { ...data, 'work_windows': dataValues.join("|") });
        const { preferences_last_updated_at, preferences_last_updated_by } = response;
        setData({ ...data, 'work_windows': dataValues, preferences_last_updated_at, preferences_last_updated_by });
        setStates({ ...states, 'work_windows': "saved" });
      } catch (e) {
        setStates({ ...states, 'work_windows': "error" });
        setData({ ...data, 'work_windows': origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  }

  const onChange = async (key, value) => {
    const origVal = data[key];

    setData({ ...data, [key]: value });
    setStates({ ...states, [key]: "saving" });

    const call = async () => {
      try {
        const response = await patchTalentPreferencesV2(talentData?.id, { ...data, [key]: value });
        const { preferences_last_updated_at, preferences_last_updated_by } = response;
        setData({ ...data, [key]: value, preferences_last_updated_at, preferences_last_updated_by });
        setStates({ ...states, [key]: "saved" });
      } catch (e) {
        setStates({ ...states, [key]: "error" });
        setData({ ...data, [key]: origVal });
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  }

  return (
    <Section id="admin_talent_preferences" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilSetting className="mr-2" size="18" /> {$.preferences_title}
          </B2DB>
          <div className="cursor-pointer" onClick={() => setOpen(!open)}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && !!data && (<>
          <div className="px-2">
            <div className="w-full flex mb-2">
              <div className="w-1/2">
                <Header Icon={UilClock} text={$.ft_interest_title}>
                  <Indicator state={states.fulltime_interest} />
                </Header>
                <Select
                  className="w-full"
                  onChange={(v) => onChange("fulltime_interest", v.value)}
                  value={
                    boolOptions.find((i) => i.value === data.fulltime_interest) ||
                    null
                  }
                  placeholder={$.select_placeholder}
                  options={boolOptions}
                />
              </div>
              <div className="w-1/2">
                <Header Icon={UilPin} text={$.onsite_projects_title}>
                  <Indicator state={states.onsite_projects} />
                </Header>
                <Select
                  className="w-full"
                  onChange={(v) => onChange("onsite_projects", v.value)}
                  value={
                    boolOptions.find((i) => i.value === data.onsite_projects) ||
                    null
                  }
                  placeholder={$.select_placeholder}
                  options={boolOptions}
                />
              </div>
            </div>
            <div className="w-full flex mt-2">
              <div className="w-full">
                <Header Icon={UilStopwatch} text={$.work_windows_title}>
                  <Indicator state={states.work_windows} />
                </Header>
                <Select
                  className="w-full"
                  onChange={(v) => onWorkWindowsChange(v)}
                  value={
                    data.work_windows?.length ?
                      data.work_windows.map((w) => workWindowsOptions.find((o) => o.value === w))
                      : null
                  }
                  placeholder={$.select_placeholder}
                  isMulti
                  options={workWindowsOptions}
                  customStyles={customMultiSelectStyles}
                />
              </div>
            </div>
          </div>
          <div className="ml-2 py-1">
            <AvailabilityLastUpdate
              updatedBy={data.preferences_last_updated_by}
              updatedAt={data.preferences_last_updated_at}
            />
          </div> 
        </>)}
      </div>
    </Section>
  );
};

export default Preferences;
