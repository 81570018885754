const setToken = ({ access, refresh }) => {
  if (access) {
    localStorage.setItem("access_token", access);
  }
  if (refresh) {
    localStorage.setItem("refresh_token", refresh);
  }
};

const getAccessToken = () => localStorage.getItem("access_token");

const getRefreshToken = () => localStorage.getItem("refresh_token");

const clearToken = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("user_id");
};

const getUserId = () => localStorage.getItem("user_id");

const setUserId = (userId) => localStorage.setItem("user_id", userId);

const setAdminToken = ({ access, refresh }) => {
  if (access) {
    localStorage.setItem("admin_access_token", access);
  }
  if (refresh) {
    localStorage.setItem("admin_refresh_token", refresh);
  }
};

const getAdminAccessToken = () => localStorage.getItem("admin_access_token");

const getAdminRefreshToken = () => localStorage.getItem("admin_refresh_token");

const clearAdminToken = () => {
  localStorage.removeItem("admin_access_token");
  localStorage.removeItem("admin_refresh_token");
  localStorage.removeItem("admin_user_id");
  localStorage.removeItem("admin_user");
  localStorage.removeItem("admin_user");
};

const getAdminUserId = () => localStorage.getItem("admin_user_id");

const setAdminUserId = (userId) =>
  localStorage.setItem("admin_user_id", userId);

const getAdminUser = () => JSON.parse(localStorage.getItem("admin_user"));

const setAdminUser = (user) =>
  localStorage.setItem("admin_user", JSON.stringify(user));

const getAdminFlags = () =>
  JSON.parse(localStorage.getItem("admin_flags")) || {};

const setAdminFlags = (flags) =>
  localStorage.setItem("admin_flags", JSON.stringify(flags));

export {
  setToken,
  getAccessToken,
  getRefreshToken,
  clearToken,
  getUserId,
  setUserId,
  setAdminToken,
  getAdminAccessToken,
  getAdminRefreshToken,
  clearAdminToken,
  getAdminUserId,
  setAdminUserId,
  getAdminUser,
  setAdminUser,
  getAdminFlags,
  setAdminFlags,
};
