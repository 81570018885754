import React from "react";
import $ from "strings/terms";
import logo from "assets/logo.png";
import { H2B, B2G, B1 } from "components/Typography";
import { Wrapper } from "components/Layout";

const Terms = () => (
  <div className="bg-white w-screen py-16">
    <Wrapper>
      <div className="w-40">
        <img className="max-w-full max-h-full" src={logo} alt="Right Side Up" />
      </div>
      <div className="mt-20 pb-6 border-b border-link-water text-center">
        <H2B>{$.title}</H2B>
        <B2G>{$.subtitle}</B2G>
      </div>
      <div>
        {Object.entries($.sections).map(([k, t]) => (
          <div className="my-10" key={k}>
            <h3 className="text-midnight font-roboto font-bold">{k}</h3>
            <B1 className="whitespace-pre-line">{t}</B1>
          </div>
        ))}
      </div>
    </Wrapper>
  </div>
);

export default Terms;
