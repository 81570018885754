import React from "react";

/**
 * @param {string} message - The message title of the alert.
 */

const AlertSuccess = (props) => {
  return (
    <div className="w-70">
      <div className="flex items-center">
        {props.message ? <>{props.message}</> : <></>}
        {props.message && props.children ? <><br/></> : <></>}
        {props.children ? <>{props.children}</> : <></>}
      </div>
    </div>
  );
};

export default AlertSuccess;
