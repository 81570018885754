import React, { useEffect, useRef, useMemo } from "react";
import clsx from "clsx";
import { admin_project_overview as $ } from "strings";
import Input from "components/ListEditableNote";
import Select from "components/CompactSelectNote";
import { toFormat } from "utils/date";
import {
  getCandidates,
  getProject,
  patchCandidate,
  patchProjectV2,
  postCandidate,
  postPitched,
} from "utils/adminApi";
import { getFetchContractTalentsAndTeams } from "utils/fetch";
import { getAdminUser } from "utils/localStorageService";
import { Textarea } from "components/Form";
import useFetch from "hooks/useFetch";

export const onSubmittedSave = async (
  { team_person, pitched_rate, agreed_rate, body, ...values },
  data,
  projectId
) => {
  const toSendProject = {
    pitched_rate: pitched_rate || 0,
    stage: 'submitted'
  };
  const user = getAdminUser();

  if (team_person) {
    const type = team_person.type;
    const emptytype = team_person.type === "talent" ? "team" : "talent";
    values[type] = team_person.value;
    values[emptytype] = null;
  } else {
    values.talent = null;
    values.team = null;
  }

  await postPitched({
    ...values,
    project: projectId,
  });

  await patchProjectV2(projectId, toSendProject);

  if (values.talent) {
    const candRes = await getCandidates({
      candidate__user__id: values.talent,
      project__id: projectId,
    });

    const description = `Pitched on ${toFormat(new Date(), "MM/dd/yyyy")} by ${
      user.first_name
    } ${user.last_name}.${
      values.agreed_rate
        ? " Agreed Pay Rate of $" + values.agreed_rate + "."
        : ""
    } Pitch paragraph: ${values.talent_paragraph}`;

    if (candRes && Array.isArray(candRes.results) && candRes.results[0]) {
      const cand = candRes.results[0];

      await patchCandidate(cand.id, {
        status: "submitted",
        description,
      });
    } else {
      await postCandidate({
        status: "submitted",
        candidate: values.talent,
        project: projectId,
        applied: new Date(),
        description,
      });
    }
  }
};

export const getSubmittedNote = (values) => [
  {
    type: "paragraph",
    children: [
      { text: "Talent Profile: ", bold: true },
      { text: values.talent_paragraph || "Not Set" },
    ],
  },
  {
    type: "table",
    children: [
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Person: ", bold: true },
              {
                text: values.team_person ? values.team_person.label : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Pitched Rate: ", bold: true },
              {
                text: Number.isInteger(Number(values.pitched_rate))
                  ? "$" + Number(values.pitched_rate) + "/hr"
                  : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Agreed Rate: ", bold: true },
              {
                text: Number.isInteger(Number(values.agreed_rate))
                  ? "$" + Number(values.agreed_rate) + "/hr"
                  : "Not Set",
              },
            ],
          },
        ],
      },
    ],
  },
];

const StageSubmittedForm = ({
  values,
  updateVal,
  projectId,
  setValues,
  setContext,
  isNoteForm,
  onReady
}) => {
  const setupDone = useRef(false);
  const fetchTalents = useMemo(
    () => getFetchContractTalentsAndTeams(projectId),
    [projectId]
  );

  const { data, pageState } = useFetch(getProject, projectId);

  useEffect(() => {
    if (!setupDone.current && pageState === "loaded") {
      setupDone.current = true;
      if (onReady) onReady();

      setContext(data);
      setValues({
        ...{
          team_person: null,
          talent_paragraph: "",
          pitched_rate: data.pitched_rate || "",
          agreed_rate: "",
        },
        ...values,
      });
    }
  }, [data, pageState]);

  if (pageState !== "loaded") {
    return null;
  }

  return (
    <div className={clsx("flex flex-col px-2", isNoteForm && "border-l border-r border-link stage-change-form-middle")}>
      <div className={clsx("flex items-center w-full mb-2", isNoteForm && "border-link-waterborder-t border-b")}>
        <div className="w-full">
          <span className="text-sm font-bold text-kasmir">
            {$.talent_paragraph_label}
          </span>
          <div>
            <Textarea
              smallest
              borderless
              className="px-0 py-0 rounded font-bold text-kasmir text-sm bg-white placeholder-text-kasmir placeholder:font-bold focus:outline-none appearance-none border border-link-water w-full"
              placeholder={$.talent_paragraph_placeholder}
              value={values.talent_paragraph}
              onChange={(e) => updateVal("talent_paragraph", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center w-full">
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.team_person_label}
          </span>
          <div className="flex-1 ml-1">
            <Select
              menuPlacement="top"
              placeholder={$.team_person_placeholder}
              value={values.team_person}
              onChange={(v) => updateVal("team_person", v)}
              async
              loadOptions={fetchTalents}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.pitched_rate_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ".", " "].includes(evt.key) &&
                evt.preventDefault()
              }
              text={
                Number.isInteger(Number(values.pitched_rate))
                  ? "$" + values.pitched_rate + "/hr"
                  : $.pitched_rate_placeholder
              }
              beforeText="$"
              afterText="/hr"
              defaultValue={values.pitched_rate}
              onChange={(v) => updateVal("pitched_rate", Number(v))}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.agreed_rate_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ".", " "].includes(evt.key) &&
                evt.preventDefault()
              }
              text={
                Number.isInteger(Number(values.agreed_rate))
                  ? "$" + values.agreed_rate + "/hr"
                  : $.agreed_rate_placeholder
              }
              beforeText="$"
              afterText="/hr"
              defaultValue={values.agreed_rate}
              onChange={(v) => updateVal("agreed_rate", Number(v))}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StageSubmittedForm;
