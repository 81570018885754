import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { admin_delete_filter_form as $ } from "strings";
import { Header, Footer } from "components/Card";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";

const DeleteFilterForm = ({
  filter,
  deleteApi,
  onClose,
  setCustomFilter,
  reload,
}) => {
  const alert = useAlert();
  const [sending, setSending] = useState(false);

  const { handleSubmit, formState } = useForm();

  const onSubmit = async () => {
    setSending(true);

    const call = async () => {
      try {
        await deleteApi(filter.id);
        onClose();
        setCustomFilter(null);
        reload();
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
        setSending(false);
      }
    };

    await call();
  };

  return (
    <Modal
      isDirty={formState.isDirty}
      onClose={onClose}
      id="admin_delete_filter_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Header title={$.title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          {$.sure}
        </div>
        <Footer>
          <Button secondary onClick={onClose} className="mr-4">
            {$.cancel_button}
          </Button>
          <Button type="submit" loading={sending} disabled={sending}>
            {sending ? $.sending_button : $.delete_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default DeleteFilterForm;
