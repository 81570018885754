import React from "react";
import { admin_talent_account as $ } from "strings";
import { getDaysText, getTimestamp, toFormat } from "utils/date";

const AvailabilityLastUpdate = ({ updatedBy, updatedAt, format }) => {
  if (!updatedBy) {
    return null;
  }

  return (
    <span className="ml-2 text-xs">
      {$.updated_label} {updatedBy}
      {format === 'daysText' ? `, ${getDaysText(getTimestamp(updatedAt))}` : `at ${toFormat(new Date(updatedAt), "MM/dd/yyyy, hh:mm a")}`}
    </span>
  );
};

export default AvailabilityLastUpdate;
