import React from "react";

const ListFilters = ({ filters, addFilter, children }) => {
  return (
    <div className="w-full px-4 flex bg-link-water overflow-x-auto py-1">
      {children}
      {Object.entries(filters).map(([id, f]) => (
        <div
          key={id}
          className="bg-white border flex items-center border-link rounded h-8 px-1 cursor-pointer mx-1 text-link text-sm font-bold my-1 flex-shrink-0"
          onClick={() => addFilter({ id, data: f.data })}
        >
          {f.title}
        </div>
      ))}
    </div>
  );
};

export default ListFilters;
