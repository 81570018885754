import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { admin_contact as $ } from "strings";
import { getAccountV2 } from "utils/adminApi";
import { usePageCache } from "contexts/pageCacheContext";
import LoadPlaceholder from "components/LoadPlaceholder";
import CompanyRole from './CompanyRole';
import TalentRole from './TalentRole';
import StaffRole from './StaffRole';
import RoleActions from "./RoleActions";
import DeleteRoleForm from "./DeleteRoleForm";
import UilAngleDown from "@iconscout/react-unicons/icons/uil-angle-down";
import UilAngleUp from "@iconscout/react-unicons/icons/uil-angle-up";
import { orderBy } from 'lodash';
import { SHELL_COMPANY_IDS } from "../../../../../utils/misc";

const roleGroupings = [
  { label: 'Company Roles', value: "company" },
  { label: 'Talent Roles', value: "talent" },
  { label: 'Staff Roles', value: "staff" },
];


const Roles = ({
  data,
  setData,
  contacts,
  setContacts,
  staffRole,
  talentRole,
  setTalentRole,
  userId,
  hubspotInputsDisabled,
  onDisabledClick
}) => {
  const { id } = useParams();
  const { set } = usePageCache();
  const [modal, setModal] = useState();
  const [roleToDelete, setRoleToDelete] = useState();
  const [companyRoles, setCompanyRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    set("url_contact", `/admin/contacts/${id}/roles`);
  }, [set]);

  useEffect(() => {
    if (!contacts) return;
    groupCompanyRoles();
    updatePrimaryCompanies();
  }, [contacts]);

  const groupCompanyRoles = () => {
    let filtered = contacts.filter(c =>
      // do not display any company roles connected to test company
      // or any roles that do not have a company listed
      c.company?.id &&
      !SHELL_COMPANY_IDS.includes(c.company?.id)
    );
    let ordered = orderBy(filtered, item => item.company.name, ['asc']);
    setCompanyRoles(ordered);
    setLoading(false);
  }

  const onCompanyRoleSuccess = (role) => {
    const origVal = [...contacts];
    setContacts([...origVal, role]);
  }

  const onAddTalentSuccess = async (id) => {
    const talent = await getAccountV2(id);
    setTalentRole(talent);
  }

  const onDeleteClick = (role) => {
    setRoleToDelete(role);
    setModal('delete_role');
  }

  const onDeleteSuccess = (deleted) => {
    const origVal = [...contacts];
    let newVal = [...origVal.filter(role => role.id !== deleted.id)];
    setContacts(newVal);
    setModal();
  }

  const onUpdateRole = (updatedRole) => {
    const newArr = [...contacts.filter(c => c.id !== updatedRole.id)];
    newArr.push(updatedRole);
    setContacts([...newArr]);
  }

  const updatePrimaryCompanies = () => {
    const newPrimaryCompanies = contacts.filter(c => c.is_primary_role === true && c.company).map(pc => pc.company);
    setData({ ...data, primary_companies: [...newPrimaryCompanies] })
  }

  const RoleGroup = ({ roleType }) => {
    const [open, setOpen] = useState(true);
    return (
      <div id={`role_group_${roleType.value}`} className="m-1">
        <div className="flex items-center text-sm text-midnight font-bold font-bold">
          {roleType.label}
          {open ?
            <UilAngleDown className="cursor-pointer" onClick={() => setOpen(!open)} /> :
            <UilAngleUp className="cursor-pointer" onClick={() => setOpen(!open)} />
          }
        </div>
        {open && <div>
          {roleType.value === 'company' && <>
            {companyRoles?.length ?
              companyRoles.map((role) =>
                <CompanyRole
                  key={role.id}
                  roleData={role}
                  onDelete={() => onDeleteClick(role)}
                  onUpdateRole={onUpdateRole}
                  updatePrimaryCompanies={updatePrimaryCompanies}
                  hubspotInputsDisabled={hubspotInputsDisabled}
                  onDisabledClick={onDisabledClick}
                />
              ) :
              <NoRoles text={$.no_roles_label.replace('%', roleType.label)} />}
            </>
          }
          {roleType.value === 'talent' && <>
            {talentRole ?
              <TalentRole
                role={talentRole}
                clickable={true}
              /> :
              <NoRoles text={$.no_roles_label.replace('%', roleType.label)} />}
            </>
          }
          {roleType.value === 'staff' && <>
            {staffRole ?
              <StaffRole roleData={staffRole} /> :
              <NoRoles text={$.no_roles_label.replace('%', roleType.label)} />}
            </>
          }
        </div>}
      </div>
    )
  }

  const NoRoles = ({ text }) => (
    <div className="flex items-center">
      <div className="border-t border-geyser flex-1"></div>
      <div className="mx-2 text-sm font-normal italic text-midnight">{text}</div>
      <div className="border-t border-geyser flex-1 flex-no-shrink"></div>
    </div>
  );

  return (
    <div className="roles_list pb-10">
      {!companyRoles || loading ?
        <div className="flex-1 px-6 pt-6">
          <LoadPlaceholder className="w-60 h-4 my-4" />
          <LoadPlaceholder className="w-60 h-4 mb-4" />
          <LoadPlaceholder className="w-40 h-4 mb-2" />
        </div> :
        <>
          <RoleActions
            data={data}
            talentRolesDisabled={!!talentRole}
            onCompanyRoleSuccess={onCompanyRoleSuccess}
            onAddTalentSuccess={onAddTalentSuccess}
            userId={userId}
            hubspotInputsDisabled={hubspotInputsDisabled}
            onDisabledClick={onDisabledClick}
        />
          {roleGroupings.map((roleType, i) =>
            <RoleGroup roleType={roleType} key={i} />
          )}
        </>
      }
      {modal === "delete_role" &&
        <DeleteRoleForm
          onClose={setModal}
          onSuccess={onDeleteSuccess}
          role={roleToDelete}
        />}
    </div>
  );
};

export default Roles;
