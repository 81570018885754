import { useState, useEffect, useCallback, useRef } from "react";
import ListRenderer from "components/ListRenderer";
import SearchRenderer from "components/SearchRenderer";
import {defer} from "rxjs";
import algoliasearch from "algoliasearch/lite";
import ListFilters from "components/ListFilters";
import { usePageCache } from "contexts/pageCacheContext";
import AlgoliaGlobalSearch from "pages/admin/AlgoliaGlobalSearch";

const PAGE_SIZE = 10;

const toTS = (date) => Math.floor(date / 1000);

const selectHandle = (id, outputType, data, operator, useValue) =>
  data.searchText ? data.searchText :
  outputType === "boolean"
    ? `${id}:${data.value}`
    : outputType === "number"
    ? `${id}${operator || ":"}${data.value}`
    : `${id}:"${data[useValue ? "value" : "label"]}"`;

const handlers = {
  int: (f) =>
    f.data && f.data.useFresh
      ? `(${f.id}${f.operator}${f.data.value || 0} AND ${f.freshKey} >= ${toTS(
          new Date(new Date().setDate(new Date().getDate() - 60))
        )})`
      : `${f.id}${f.operator}${f.data ? f.data.value || 0 : 0}`,
  int_range: (f) =>
    f.data &&
    (f.data.min || f.data.max) &&
    !(parseInt(f.data.min) > parseInt(f.data.max))
      ? "(" +
        (f.data.min ? `${f.min_index}>=${f.data.min || 0} ` : "") +
        (f.data.min && f.data.max ? " AND " : "") +
        (f.data.max ? `${f.max_index}<=${f.data.max || 0} ` : "") +
        (f.data.useFresh
          ? ` AND ${f.freshKey} >= ${toTS(
              new Date(new Date().setDate(new Date().getDate() - 60))
            )}`
          : "") +
        ")"
      : "",
  boolean: (f) => `${f.id}=1`,
  select: (f) =>
    f.data
      ? f.isMulti
        ? Array.isArray(f.data) && f.data.length > 0
          ? "(" +
            f.data
              .map((d) =>
                selectHandle(f.id, d.outputType, d, f.operator, f.useValue)
              )
              .join(" OR ") +
            ")"
          : ""
        : selectHandle(f.id, f.outputType, f.data, f.operator, f.useValue)
      : "",
  date: (f) =>
    f.data
      ? `${f.id}${
          f.data.start && f.data.end
            ? ": " + toTS(f.data.start) + " TO " + toTS(f.data.end)
            : f.data.start
            ? " >= " + toTS(f.data.start)
            : " <= " + toTS(f.data.end)
        }`
      : "",
  qualification: (f) =>
    f.data && f.data.qualification
      ? `(qualifications.${f.data.qualification.value}.${
          f.data.useSelf ? "self" : "rsu"
        } >= ${f.data.rating || 0}` +
        (f.data.descriptor
          ? ` AND qualifications.${f.data.qualification.value}.descriptor: "${f.data.descriptor.label}"`
          : "") +
        (f.data.descriptorValue
          ? ` AND qualifications.${f.data.qualification.value}.descriptor_value: "${f.data.descriptorValue.label}"`
          : "") +
        ")"
      : "",
};

const getFiltersText = (search, filters) => {
  const searchObjToString = (s) => {
    if (typeof s !== "string") {
      if (s.id === "location") {
        return "";
      } else if (filters[s.id].searchText) {
        return filters[s.id].searchText(s.data);
      } else {
        return handlers[filters[s.id].type]({ ...filters[s.id], ...s });
      }
    }
  };

  const searchParts = search
    .map(searchObjToString)
    .flat()
    .filter(s => !!s);

  let searchStr = "";
  searchParts.forEach((sp, i) => {
    if (i === 0) {
      searchStr = sp;
    } else if (
      ["OR", "AND", "(", "NOT"].includes(searchParts[i - 1].toUpperCase()) ||
      ["OR", "AND", ")"].includes(sp.toUpperCase())
    ) {
      searchStr = searchStr + " " + sp;
    } else {
      searchStr = searchStr + " AND " + sp;
    }
  });

  return searchStr;
};

const getOtherSearchOptions = (search) => {
  return search.reduce((acc, s) => {
    if (s.id === "location" && s.data && s.data.latitude && s.data.longitude) {
      acc.aroundLatLng = `${s.data.latitude},${s.data.longitude}`;
      acc.aroundRadius = Math.max(
        Math.round(s.data.radius * 1609.34), // convert miles to meters
        1000 // minimum radius of 1km required by Algolia
      );
    }
    return acc;
  }, {});
};

const useList = (id, index, itemComponent, filters, placeholder) => {
  const { set, cache } = usePageCache();
  const listCache = cache[id + "_list"] || {};
  const [pageState, setPageState] = useState("loading");
  const [data, setData] = useState(null);
  const [total, setTotal] = useState(listCache.total || null);
  const [search, setSearch] = useState(listCache.search || [""]);
  const lastFetch = useRef();
  const [currentIndex, setCurrentIndex] = useState(null);
  const [lastIndex, setLastIndex] = useState(0);
  const [caretPos, setCaretPos] = useState(null);
  const [nextCaretPos, setNextCaretPos] = useState(null);
  const [useGlobal, setUseGlobal] = useState(false);

  useEffect(() => {
    set(id + "_list", { search, total });
  }, [id, search, total]);

  const fetch = useCallback(
    async (offset = 0, length = PAGE_SIZE, isPagination = false) => {
      if (lastFetch.current) {
        lastFetch.current.unsubscribe();
        lastFetch.current = null;
      }
      setPageState("fetching");

      const searchText = search
        .filter((s) => typeof s === "string")
        .map((s) => s.trim())
        .join(" ");

      let filtersText =
        search.length > 1
          ? getFiltersText(search, filters)
          : "";

      // clean up filtersText - remove redundant brackets
      while (filtersText.startsWith("((") && filtersText.endsWith("))")) {
        filtersText = filtersText.slice(1, -1);
      }
      let filtersText2 = "";
      let depth = 0;
      let i = 0;
      while (i < filtersText.length) {
        if (filtersText[i] === ")") {
          depth--;
        }
        if (depth === 0) {
          filtersText2 += filtersText[i];
        }
        if (filtersText[i] === "(") {
          depth++;
        }
        i++;
      }

      const otherSearchOptions =
        search.length > 1
        ? getOtherSearchOptions(search.slice(1))
        : {};

      const searchOptions = {
        offset,
        length,
        filters: filtersText,
        advancedSyntax: true,
        ...otherSearchOptions,
      };

      lastFetch.current = defer(async () => {
        const client = algoliasearch(
          process.env.REACT_APP_ALGOLIA_APP_ID,
          process.env.REACT_APP_ALGOLIA_SEARCH_KEY
        );
        const idx = client.initIndex(index);
        let alteredSearchText = searchText;
        const searchTextClean = searchText
          .replace(/"/g, "")
          .trim();
        if (
          searchText.includes(" OR ") ||
          searchText.includes(" or ") ||
          searchText.includes(" AND ") ||
          searchText.includes(" and ")
        ) {
          let optionalWords = searchText
            .replace(/"/g, "")
            .replace(/ OR$/g, "")
            .replace(/ or$/g, "")
            .replace(/ and$/g, "")
            .replace(/ AND$/g, "")
            .replaceAll(/ AND /g, "%20")
            .replaceAll(/ and /g, "%20")
            .split(/ OR | or /)
            .map(s => s.trim())
            .map(s => s.split(" ").filter(s => s !== "").join(""))
            .join(" ")
            .replaceAll("%20", " ");

          if (searchText.includes(" OR ") || searchText.includes(" or ")) {
            searchOptions.optionalWords = optionalWords;
          }
          alteredSearchText = optionalWords;
        } else if (searchTextClean !== "") {
          // first we check if there are exact email/name matches because we
          // want to exclude grade C UNLESS user is searching for a specific email/name
          let newFiltersText;
          if (filtersText) {
            newFiltersText = `${filtersText} AND (user_name:"${searchTextClean}" OR user_email:"${searchTextClean}")`;
          } else {
            newFiltersText = `user_name:"${searchTextClean}" OR user_email:"${searchTextClean}"`;
          }
          const initialSearch = idx.search(
            "",
            {
              ...searchOptions,
              filters: newFiltersText,
            },
          );
          const initialSearchResult = await initialSearch;
          if (initialSearchResult.nbHits === 0) {
            // if no exact email/name matches, exclude grade C (below) and do a regular search
          } else {
            // if there are exact email/name matches, don't exclude grade C
            return initialSearch;
          }
        }
        if (filtersText && !filtersText.includes("talent_grade")) {
          searchOptions.filters = `${filtersText}`;
        }
        // don't show excluded talent unless explicitly requested
        if (!filtersText.includes("excluded=1")) {
          filtersText.length ? 
            searchOptions.filters += ` AND NOT talent_grade:2 AND NOT excluded=1` :
            searchOptions.filters += ` NOT talent_grade:2 AND NOT excluded=1`
        }
        return idx.search(alteredSearchText, searchOptions);
      }).subscribe({
        next: (response) => {
          if (isPagination) {
            setData((d) => {
              const newData = [...d];
              response.hits.forEach((h, i) => (newData[offset + i] = h));
              return newData;
            });
          } else {
            setData(response.hits);
          }
          setTotal(response.nbHits);
          setPageState("loaded");
          lastFetch.current = null;
        },
        error: (e) => {
          setPageState("error");
          console.error(e);
        },
      });
    },
    [search, index]
  );

  useEffect(() => {
    fetch();
  }, [search]);

  const updateSearch = (i, value) => {
    if (typeof search[i] === "string") {
      setSearch((s) => s.map((v, j) => (j === i ? value : v)));
    } else {
      setSearch((s) => s.map((v, j) => (j === i ? { ...v, data: value } : v)));
    }
  };

  const setIndex = (index, updateLast = true) => {
    setCurrentIndex(index);

    if (updateLast) {
      setLastIndex(index);
    }
  };

  const removeFilter = (index) => {
    const newSearch = [...search];

    newSearch.splice(index, 1);

    const bfrTxt = newSearch[index - 1];
    const aftTxt = newSearch[index];

    const concatTxt =
      bfrTxt && aftTxt ? bfrTxt + " " + aftTxt : bfrTxt + aftTxt;

    newSearch.splice(index, 1);
    newSearch[index - 1] = concatTxt;

    setSearch(newSearch);
    setLastIndex(index - 1);
    if (currentIndex) {
      setCurrentIndex(index - 1);
      setNextCaretPos(bfrTxt && aftTxt ? bfrTxt.length + 1 : bfrTxt.length);
      setCaretPos(bfrTxt.length);
    } else {
      setCurrentIndex(null);
      setCaretPos(null);
    }
  };

  const addFilter = (filter) => {
    const newSearch = [...search];
    let newIndex = lastIndex;

    if (typeof search[lastIndex] === "string") {
      if (caretPos === 0) {
        newSearch.splice(lastIndex, 0, filter);
        newSearch.splice(lastIndex, 0, "");
      } else if (caretPos === search[lastIndex].length || caretPos === null) {
        newSearch.splice(lastIndex + 1, 0, "");
        newSearch.splice(lastIndex + 1, 0, filter);
      } else {
        const text = search[lastIndex];

        newSearch[lastIndex] = text.substring(0, caretPos);
        newSearch.splice(lastIndex + 1, 0, text.substring(caretPos));
        newSearch.splice(lastIndex + 1, 0, filter);
      }

      newIndex++;
    } else {
      newSearch.splice(lastIndex + 1, 0, filter);
      newSearch.splice(lastIndex + 1, 0, "");

      newIndex += 2;
    }

    setSearch(newSearch);
    setIndex(newIndex);
  };

  return {
    data,
    setData,
    List: ListRenderer,
    Search: useGlobal ? AlgoliaGlobalSearch : SearchRenderer,
    Filters: ListFilters,
    searchParams: {
      search,
      updateSearch,
      setIndex,
      setCaretPos,
      currentIndex,
      removeFilter,
      total,
      caretPos,
      nextCaretPos,
      setNextCaretPos,
      filters,
      setUseGlobal,
      onClose: setUseGlobal,
      placeholder
    },
    filtersParams: { filters, addFilter },
    listParams: {
      id,
      data,
      setData,
      fetch,
      total,
      pageState,
      itemComponent,
      search,
    },
  };
};

export default useList;
