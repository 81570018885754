import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { admin_contact_delete_form as $ } from "strings";
import { Header, Footer } from "components/Card";
import { Input } from "components/Form";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { deleteUser } from "utils/adminApi";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { useHistory } from "react-router-dom";
import AlertSuccess from "../../../components/AlertSuccess";

const DeleteForm = ({ onClose, data }) => {
  const alert = useAlert();
  const [value, setValue] = useState("");
  const history = useHistory();
  const [sending, setSending] = useState(false);
  const { id } = useParams()

  const onDelete = async () => {
    const call = async () => {
      try {
        await deleteUser(id);
        alert.success(<AlertSuccess message={$.success_message} />, {timeout: 2000});
        history.push(`/admin/contacts/`);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    setSending(true);
    await call();
    setSending(false);
  };

  return (
    <Modal isDirty={false} onClose={onClose} id="admin_contact_delete_form">
      <div className="flex flex-col flex-nowrap flex-1 min-h-0" noValidate>
        <Header title={$.confirm_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="text-midnight text-sm font-bold" dangerouslySetInnerHTML={{ __html:
            $.sure_message.replace(
              "%",
              `${data.name}`
            )}}
          />
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-4">
            <Input onChange={(e) => setValue(e.target.value)} />
          </div>
        </div>
        <Footer>
          <Button secondary onClick={onClose} className="mr-4">
            {$.cancel_button}
          </Button>
          {value === `${data.name}` && !sending ? (
            <Button danger onClick={onDelete} type="submit">
              {$.confirm_button}
            </Button>
          ) : (
            <Button disabled type="submit">
              {$.confirm_button}
            </Button>
          )}
        </Footer>
      </div>
    </Modal>
  );
};

export default DeleteForm;
