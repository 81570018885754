import React from "react";
import { admin_talent_account as $ } from "strings";
import { getDaysText, getTimestamp } from "utils/date";

const AvailabilityLastUpdate = ({ updatedBy, updatedAt }) => {
  return (
    <div className="min-h-4">
      {updatedBy && updatedAt && (
        <span className="text-xs">
          {$.updated_label} {updatedBy}, {getDaysText(getTimestamp(updatedAt))}
        </span>
      )}
    </div>
  );
};

export default AvailabilityLastUpdate;
