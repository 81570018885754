import React, { useState, useEffect } from "react";
import { admin_project_contract_details as $ } from "strings";
import { B2DB } from "components/Typography";
import { useParams } from "react-router-dom";
import Section from "components/Section";
import { getContractDetails, getAccount, getProject } from "utils/adminApi";
import useCachedFetch from "hooks/useCachedFetch";
import { LoadPlaceholderGroup } from "components/LoadPlaceholder";
import UilContract from "@iconscout/react-unicons/icons/uil-file-contract";
import ContractDetailsForm from "./ContractDetailsForm";

const ContractDetails = ({ projectData, setProjectData }) => {
  const { id } = useParams();
  const [companyDone, setCompanyDone] = useState(false);

  const { data, setData, pageState } = useCachedFetch(
    "admin_project_contract_details",
    getContractDetails,
    id
  );

  const getV1ProjectData = async () => {
    try {
      const v1ProjectData = await getProject(id);
      setProjectData({ ...projectData, 'company': v1ProjectData.company, 'estimated_end_date': v1ProjectData.estimated_end_date })
    } catch {
      return;
    }
    setCompanyDone(true);
  }

  useEffect(() => {
    getV1ProjectData();
  }, [])

  let candidateId = null;
  if (pageState === "loaded" && data && data.candidate) {
    candidateId = data.candidate;
  }

  const candidate = useCachedFetch(
    "admin_project_contract_details_candidate",
    getAccount,
    candidateId
  );

  if (
    pageState === "loading" ||
    (candidateId && candidate && candidate.pageState === "loading")
  ) {
    return (
      <div className="bg-white px-4 py-6 mb-6">
        <LoadPlaceholderGroup />
      </div>
    );
  }

  return (
    <Section id="admin_project_contract_details" className="w-full max-w-full">
      <div className="w-full flex flex-col xl:flex-row">
        <div className="w-full">
          <div className="bg-white px-4 py-6 mb-6">
            <div className="mb-4">
              <div className="flex items-center mb-2">
                <B2DB className="flex flex-1 items-center">
                  <UilContract size="18" className="mr-1" />
                  {$.contract_details_title}
                </B2DB>
              </div>
              <div className="flex items-center mb-2">
                {companyDone&&<ContractDetailsForm
                  projectData={projectData}
                  setProjectData={setProjectData}
                  data={data ? data : {}}
                  setData={setData}
                  candidate={candidate}
                />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export const ContractDetailsPreload = () => {
  const { id } = useParams();

  const { data, pageState } = useCachedFetch(
    "admin_project_contract_details",
    getContractDetails,
    id
  );

  let candidateId = null;
  if (pageState === "loaded" && data && data.candidate) {
    candidateId = data.candidate;
  }

  useCachedFetch(
    "admin_project_contract_details_candidate",
    getAccount,
    candidateId
  );

  return null;
};

export default ContractDetails;