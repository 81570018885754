import React, { useEffect } from "react";
import { admin_login as $ } from "strings";
import { GoogleLogin } from '@react-oauth/google';
import { oauth } from "utils/adminApi";
import { B3BLink, B1, B2DB, B3B } from "components/Typography";
import logoSm from "assets/logo_sm.svg";
import {
  setAdminToken,
  setAdminUserId,
  setAdminUser,
} from "utils/localStorageService";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { Link } from "react-router-dom";

const Login = ({ setAdminLogged }) => {
  useEffect(() => {
    document.title = "Pipeline - Staff Login";
  }, []);

  const alert = useAlert();
  const responseGoogle = async (response) => {
    try {
      const authResponse = await oauth(response.credential);
      if (!authResponse.user.is_staff) {
        throw new Error("Not an admin user");
      }
      setAdminToken(authResponse);
      setAdminUser(authResponse.user);
      setAdminUserId(authResponse.user.id);
      setAdminLogged(true);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} message={$.auth_error} />);
    }
  };

  const failedGoogle = (response) => {
    console.error(response);
    const e = { message: response.error };
    alert.error(<AlertError error={e} message={$.google_auth_error} />);
  };

  return (
    <div className="font-lato bg-link-water w-screen h-screen flex items-center justify-center text-center px-10">
      <div className="absolute top-0 left-0 h-16 w-16 ml-12 mt-10">
        <img
          className="max-w-full max-h-full"
          src={logoSm}
          alt="Right Side Up"
        />
      </div>
      <div className="rounded-lg bg-white relative w-78 h-78 py-24 px-12 flex items-center justify-center">
        <div className="w-full">
          <h1 className="text-link font-bold text-3xl">{$.welcome_header}</h1>
          <B2DB className="mb-8">{$.email_info}</B2DB>
          <div className="flex justify-center">
            <GoogleLogin
              onSuccess={responseGoogle}
              onError={failedGoogle}
              context="signin"
            />
          </div>
          <B1 className="mt-4">
            {$.another_account_text}{" "}
            <B3BLink to={"/login"}>{$.talent_login_link}</B3BLink>
          </B1>
        </div>
        <div className="absolute bottom-0 mb-4 flex items-center mt-4 justify-center">
          <Link to="/privacy">
            <B3B>{$.privacy_link}</B3B>
          </Link>
          <B1 className="mx-1">and</B1>
          <Link to="/terms">
            <B3B>{$.terms_link}</B3B>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
