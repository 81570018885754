import React from "react";
import { admin_candidate_projects as $ } from "strings";
import { Link } from "react-router-dom";
import { toFormat, secondsToHours } from "utils/date";

const CompletedProject = ({ project }) => {
  const {
    name,
    project_id,
    owner,
    pay_rate,
    start_date,
    end_date,
    clockify_hours,
    client_engagement
  } = project;

  return (
    <div id="completed_project">
      <div className="w-full flex">
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            <Link className="inline-block text-link" to={`/admin/project/${project_id}`}>
              {name}
            </Link>
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            {$.project_owner_title}: {owner.length === 0 && $.empty_value} {owner.map((o, i) => <span key={i}>{o}{i !== owner.length - 1 && ', '}</span>)}
          </div>
        </div>
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            {$.client_engagement_title}: {client_engagement || $.empty_value}
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            {$.pay_rate_title}: {pay_rate ? `$${pay_rate}` : $.empty_value}
          </div>
        </div>
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            {$.clockify_hours_title}: {clockify_hours ? `${secondsToHours(clockify_hours)} ${$.completed_hours}` : $.unknown_value}
          </div>
        </div>
      </div>
      <div className="w-full flex">
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            {$.start_date_title}: {start_date ? toFormat(new Date(start_date), "MM/dd/yyyy") : $.empty_value}
          </div>
        </div>
        <div className="w-1/2">
          <div className="text-sm text-midnight font-bold">
            {$.end_date_title}: {end_date ? toFormat(new Date(end_date), "MM/dd/yyyy") : $.ongoing_value}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletedProject;
