import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { admin_contact_form as $ } from "strings";
import { global as $$ } from "strings";
import { Header, Footer } from "components/Card";
import { Input, Label, Error } from "components/Form";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { postUser, postUserLink } from "utils/adminApi";
import { generateEmail } from "utils/str";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { useHistory } from "react-router-dom";
import { getAdminUserId } from "utils/localStorageService";
import { usePageCache } from "contexts/pageCacheContext";
import AlertSuccess from "../../../components/AlertSuccess";

const NewContactForm = ({ onClose, setModal }) => {
  const history = useHistory();
  const alert = useAlert();
  const { handleSubmit, register, errors, formState } = useForm();
  const [sending, setSending] = useState(false);
  const [duplicatedContact, setDuplicatedContact] = useState();
  const adminUserId = getAdminUserId();
  const { set, cache } = usePageCache();
  const c = cache.admin_contact_form || {};

  const onSubmit = async (values) => {
    setSending(true);
    const params = {
      first_name: values.firstname,
      last_name: values.lastname,
      email: values.email || generateEmail(),
      type: 'other'
    };

    const call = async () => {
      setDuplicatedContact(null);
      try {
        const response = await postUser(params);
        await postUserLink({
          name: "linkedin",
          url: values.linkedin,
          updated_by: adminUserId,
          user: response.id,
        });
        alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});

        set("admin_project_form", {
          ...c,
          lead_source: {
            ...c.new_lead_source,
            referral_contact: { value: response.default_contact_id, label: `${response.first_name} ${response.last_name}`}
          }
        });
        onClose();
        setModal("add_project");
      } catch (e) {
        setSending(false);
        const duplicatedId = e.response?.data?.users[0]?.id;
        if (duplicatedId) {
          setDuplicatedContact(duplicatedId);
        } else {
          console.log(e.response.data)
          console.error(e);
          alert.error(<AlertError error={e} />);
        }
      }
    };

    await call();
  };

  return (
    <Modal
      isDirty={!sending && formState.isDirty}
      onClose={onClose}
      id="admin_talent_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Header title={$.create_contact_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.firstname_input}</Label>
            <Input
              name="firstname"
              maxLength="254"
              ref={register({ required: $.validation_required })}
              error={errors.firstname}
            />
            {errors.firstname && <Error>{errors.firstname.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.lastname_input}</Label>
            <Input
              name="lastname"
              maxLength="254"
              ref={register({ required: $.validation_required })}
              error={errors.lastname}
            />
            {errors.lastname && <Error>{errors.lastname.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.email_input}</Label>
            <Input
              name="email"
              type="email"
              maxLength="254"
              ref={register({
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                  message: $.validation_email,
                },
              })}
              error={errors.email}
            />
            {errors.email && <Error>{errors.email.message}</Error>}
          </div>
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.linkedin_input}</Label>
            <Input
              name="linkedin"
              type="url"
              maxLength="250"
              ref={register({
                pattern: {
                  value: /^(https?:\/\/)?(www\.)?linkedin.com\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/i,
                  message: $.validation_linkedin_url,
                },
              })}
              error={errors.linkedin}
            />
            {errors.linkedin && <Error>{errors.linkedin.message}</Error>}
          </div>
        </div>
        <Footer>
          <Button secondary onClick={() => setModal("add_project")} className="mr-4">
            {$.cancel_button}
          </Button>
          <Button type="submit" loading={sending} disabled={sending}>
            {sending ? $.sending_button : $.create_button}
          </Button>
        </Footer>
        {duplicatedContact && (
          <div className="flex items-center justify-end">
            <Error>{$.duplicated_contact_error}</Error>
            <div
              onClick={() => {
                onClose();
                setTimeout(
                  () =>
                    history.push(`/admin/contacts/${duplicatedContact}/roles`),
                  100
                );
              }}
              className="ml-1 text-xs font-bold text-link hover:text-link-dark cursor-pointer"
            >
              {$.duplicated_contact_link}
            </div>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default NewContactForm;
