import React from "react";
import $ from "strings/privacy";
import logo from "assets/logo.png";
import { H2B, B1 } from "components/Typography";
import { Wrapper } from "components/Layout";

const Privacy = () => (
  <div className="bg-white w-screen py-16">
    <Wrapper>
      <div className="w-40">
        <img className="max-w-full max-h-full" src={logo} alt="Right Side Up" />
      </div>
      <div className="mt-20 pb-6 border-b border-link-water text-center mb-8">
        <H2B>{$.title}</H2B>
      </div>
      <div>
        {$.sections.map((t, i) => (
          <div className="my-4" key={i}>
            <B1 className="whitespace-pre-line">{t}</B1>
          </div>
        ))}
      </div>
    </Wrapper>
  </div>
);

export default Privacy;
