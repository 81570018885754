import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import { getTalentProjectsV2 } from "utils/adminApi";
import ProjectList from './ProjectList';
import ProjectDetail from './ProjectDetail';

const Projects = ({ data }) => {
  const { id } = useParams();
  const { set } = usePageCache();
  const history = useHistory();
  const [selectedProject, setSelectedProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = new URL(document.location).searchParams;
  const projectId = params.get("cpid");

  useEffect(() => {
    if (!projectId) {
      getProjects();
      setSelectedProject(null);
    }
  }, [projectId]);

  useEffect(() => {
    selectedProject ?
      set("url_talent", `/admin/talent/${id}/projects?cpid=${selectedProject.id}`) :
      set("url_talent", `/admin/talent/${id}/projects`);
  }, [set, id, selectedProject]);

  const onSelectProject = (project) => {
    history.push(`?cpid=${project.id}`);
    setSelectedProject(project);
  };

  const onDeselectProject = () => {
    history.replace({
      search: ''
    });
  }

  const getProjects = async (showLoader = true) => {
    if (!data) return;
    if (showLoader) setLoading(true);
    try {
      const params = { candidate__id: data.id, page_size: 999 };
      const response = await getTalentProjectsV2(params); 
      setLoading(false);
      setProjects(response.results);

      if (history.location.search) {
        const c = response.results.find((result) => result.id === history.location.search.split('=')[1]);
        setSelectedProject(c);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const onUpdate = () => {
    getProjects(false);
  }

  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div id="admin_project_candidates" className="h-full">
      {selectedProject ? 
        <ProjectDetail
          project={selectedProject}
          talent={data}
          onUpdate={onUpdate}
          onBack={onDeselectProject}
        /> :
        <ProjectList
          projects={projects}
          onSelectProject={onSelectProject}
          onUpdate={onUpdate}
          loading={loading}
        />
      }
    </div>
  )
};

export default Projects;