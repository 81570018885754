import { format, parse } from "date-fns";

const toFormat = (date, formatStr) => {
  if (date instanceof Date) {
    return format(date, formatStr);
  }

  return format(getDate(date), formatStr);
};

const getDate = (dateStr) => parse(dateStr, "yyyy-MM-dd", new Date());

const getDateDayless = (dateStr) => {
  const p = dateStr.split("-");

  return new Date(p[0], p[1] - 1);
};

const moveDays = (date, n) => {
  let d = new Date(date);
  d.setDate(d.getDate() + n);

  return d;
};

const lastDayInMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0);
};

const firstDayInMonth = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
};

const today = new Date();
const thisMonth = firstDayInMonth(today);

const getDaysText = (timestamp) => {
  const date = new Date(timestamp * 1000);

  const months =
    today.getMonth() -
    date.getMonth() +
    12 * (today.getFullYear() - date.getFullYear());

  if (months > 6) return "6+ Months Ago";
  if (months > 1) return months + " Months Ago";

  const days = Math.round((today - date) / (1000 * 60 * 60 * 24));

  if (days > 30) return months + " Months Ago";
  if (days === 1) return days + " Day Ago";
  if (days > 0) return days + " Days Ago";

  return "Today";
};

const getTimestamp = (date) => {
  return new Date(date).getTime() / 1000;
};

const secondsToHours = (s) => Math.floor(s / 3600);

const weekAgo = () => {
  const today = new Date();

  return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
};

const monthAgo = () => {
  const today = new Date();

  return new Date(today.getFullYear(), today.getMonth() - 1, today.getDate());
};

export const epochToHumanReadable = (timestamp) => {
  const d0 = new Date(0);
  const d = d0.setUTCSeconds(timestamp);

  return toFormat(new Date(d), "MM/dd/yyyy");
};

export {
  toFormat,
  getDate,
  getDateDayless,
  thisMonth,
  firstDayInMonth,
  lastDayInMonth,
  getDaysText,
  getTimestamp,
  secondsToHours,
  weekAgo,
  monthAgo,
  moveDays,
};
