import {
  getCompanyFilters,
  postCompanyFilter,
  patchCompanyFilter,
  deleteCompanyFilter,
  getCompanyColumns,
  postCompanyColumn,
  patchCompanyColumn,
} from "utils/adminApi";

const PAGE_SIZE = 30;

const fetch = async (search, loadedOptions, { page }) => {
  const params = { page_size: PAGE_SIZE, page };
  try {
    const response = await getCompanyFilters(
      search ? { ...params, search } : params
    );

    const options = response.results.map((c) => ({
      filter: c,
      value: c.id,
      label: c.name,
    }));

    return {
      options,
      hasMore: !!response.next,
      additional: { page: page + 1 },
    };
  } catch (e) {
    console.error(e);
  }
};

const versioning = (s) => {
  let needsUpdate = false;

  // Handle old filters format and update it
  // Set needsUpdate as true if needed

  return { ...s, needsUpdate };
};

const manager = {
  versioning,
  fetch,
  getApi: getCompanyFilters,
  postApi: postCompanyFilter,
  patchApi: patchCompanyFilter,
  deleteApi: deleteCompanyFilter,
  getColumnApi: getCompanyColumns,
  postColumnApi: postCompanyColumn,
  patchColumnApi: patchCompanyColumn,
};

export default manager;
