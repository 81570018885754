import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { admin_project_candidate_status_form as $ } from "strings";
import { Header, Footer } from "components/Card";
import { Label, Error, Textarea } from "components/Form";
import AlertSuccess from "../../../../../components/AlertSuccess";
import Select from "components/Select";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { patchCandidateV2 } from "utils/adminApi";
import { useAlert } from "react-alert";
import { rejectionReasonOptions } from "./constants";
import AlertError from "components/AlertError";

const ChangeStatusForm = ({
  onClose,
  onSuccess,
  data,
}) => {
  const params = new URL(document.location).searchParams;
  const [selectedStatus, setSelectedStatus] = useState(data?.status || null);
  const candidateId = params.get("cpid");
  const alert = useAlert();

  const { handleSubmit, register, errors, formState, control, watch } = useForm({
    defaultValues: { description: "" },
  });

  const watchStatus = watch('status');

  useEffect(() => {
    if (!watchStatus || !selectedStatus) return;
    if (watchStatus.value !== selectedStatus && setSelectedStatus) {
      setSelectedStatus(watchStatus.value);
    }
  }, [watchStatus, selectedStatus])

  const onSubmit = async (values) => {
    const toSend = {};
    toSend.status = values.status?.value;
    if (values.primary_rejection_reason?.value) {
      toSend.primary_rejection_reason = values.primary_rejection_reason.value;
      if (values.rejection_reason?.length) {
        toSend.rejection_reason = values.rejection_reason;
      };
      toSend.description = `Rejection Reason: ${values.primary_rejection_reason.label} `
      if (values.rejection_reason?.length) {
        toSend.description += `Note: ${values.rejection_reason}`;
      };
    }

    if (!values.primary_rejection_reason && values.description?.length) {
      toSend.description = values.description;
    };
  
    try {
      const msg = `Successfully moved talent to ${values.status.label}`;
      await patchCandidateV2(candidateId, toSend);
      alert.success(<AlertSuccess message={msg} />, {timeout: 2000});
      onSuccess();
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={() => onSubmit(values)} />);
    }
  };

  return (
    <Modal
      isDirty={formState.isDirty}
      onClose={onClose}
      id="admin_talent_status_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Header title={$.candidate_status_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full pr-8 mb-3 mt-2">
            <Label>{$.status_input}</Label>
            <Controller
              control={control}
              rules={{ required: $.validation_required }}
              name="status"
              defaultValue={
                data.status
                  ? { value: data.status, label: $[`${data.status}_option`] }
                  : null
              }
              as={<Select />}
              error={errors.status}
              placeholder={$.select_option}
              required
              options={[
                { label: $.new_option, value: "new" },
                { label: $.maybe_option, value: "maybe" },
                { label: $.hold_option, value: "hold" },
                { label: $.short_list_option, value: "short_list" },
                { label: $.scheduled_option, value: "scheduled" },
                { label: $.pass_option, value: "pass" },
              ]}
            />
            {errors.status && <Error>{errors.status.message}</Error>}
          </div>
          {(selectedStatus === 'pass' || watchStatus?.value === 'pass') && (
            <div className="w-full pr-8 mb-3 mt-2">
              <Label>{$.rejection_reason_input}</Label>
              <Controller
                control={control}
                rules={{ required: $.validation_required }}
                name="primary_rejection_reason"
                as={<Select />}
                error={errors.status}
                placeholder={$.select_option}
                required
                options={rejectionReasonOptions}
              />
              {errors.primary_rejection_reason && <Error>{errors.primary_rejection_reason.message}</Error>}
            </div>)
          }
          {(selectedStatus === 'pass' || watchStatus?.value === 'pass') ? 
            <div className="w-full pr-8 mb-3 mt-2">
              <Label>{$.rejection_note_input}</Label>
              <Textarea
                name="rejection_reason"
                ref={register()}
                error={errors.rejection_reason}
              />
              {errors.rejection_reason && <Error>{errors.rejection_reason.message}</Error>}
            </div> :
            <div className="w-full pr-8 mb-3 mt-2">
              <Label>{$.note_description_input}</Label>
              <Textarea
                name="description"
                ref={register()}
                error={errors.description}
              />
              {errors.description && <Error>{errors.description.message}</Error>}
            </div>
          }
        </div>
        <Footer>
          <Button
            secondary
            onClick={() => {
              onClose();
            }}
            className="mr-4"
          >
            {$.cancel_button}
          </Button>
          <Button type="submit">{$.save_button}</Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default ChangeStatusForm;