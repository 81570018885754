import React, { useEffect, useRef } from "react";
import { admin_project_overview as $ } from "strings";
import Input from "components/ListEditableNote";
import Select from "components/CompactSelectNote";
import {
  getProject,
  patchProjectV2,
  postProjectQualification,
  deleteProjectQualification,
  patchProjectTalentDescriptionV2
} from "utils/adminApi";
import { Textarea } from "components/Form";
import useFetch from "hooks/useFetch";
import useQualifications from "hooks/useQualifications";

const moonlighterOptions = [
  { value: 0, label: $.no_option },
  { value: 1, label: $.yes_option },
  { value: 2, label: $.maybe_option },
];

export const onMatchingSupportSave = async (
  {
    availability_hours,
    availability_hours_maximum,
    reflections,
    ideal_rate,
    ideal_rate_maximum,
    maximum_rate,
    minimum_rate,
    talent_paragraph,
    project_qualifications,
    ...values
  },
  data,
  projectId
) => {
  const toSendProject = {
    minimum_rate: minimum_rate || 0,
    maximum_rate: maximum_rate || 0,
    ideal_rate: ideal_rate || 0,
    ideal_rate_maximum: ideal_rate_maximum || 0,
    moonlighter: values.moonlighter && values.moonlighter.value,
    availability_hours: availability_hours || null,
    availability_hours_maximum: availability_hours_maximum || null,
    talent_paragraph: talent_paragraph || "",
    stage: 'matching_support'
  };

  if (talent_paragraph?.length) {
    const toSendDescription = { talent_description: talent_paragraph };
    await patchProjectTalentDescriptionV2(projectId, toSendDescription);
  }

  if (project_qualifications?.length) {
    const olds = data && Array.isArray(data.project_qualifications) ? data.project_qualifications : [];
    const news = project_qualifications ? project_qualifications.map((s) => s.value) : [];
    const oldsKeys = olds.map((q) => q.qualification);

    const toDelete = olds.filter((q) => !news.includes(q.qualification));

    const toCreate = news.filter((q) => !oldsKeys.includes(q));

    for (let q of toDelete) {
      await deleteProjectQualification(q.id);
    }

    for (let q of toCreate) {
      await postProjectQualification({
        project: projectId,
        qualification: q,
        rating: 0,
      });
    }
  }

  const updatedProjectData = await patchProjectV2(projectId, toSendProject);
  return updatedProjectData;
};

export const getMatchingSupportNote = (values) => [
  {
    type: "paragraph",
    children: [
      { text: "Talent Profile: ", bold: true },
      { text: values.talent_paragraph || "Not Set" },
    ],
  },
  {
    type: "table",
    children: [
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Availability Min: ", bold: true },
              {
                text: Number.isInteger(Number(values.availability_hours))
                  ? Number(values.availability_hours) + " hours per week"
                  : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Minimum Bill Rate: ", bold: true },
              {
                text: Number.isInteger(Number(values.minimum_rate))
                  ? "$" + Number(values.minimum_rate) + "/hr"
                  : "Not Set",
              },

            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Minimum Pay Rate: ", bold: true },
              {
                text: Number.isInteger(Number(values.ideal_rate))
                  ? "$" + Number(values.ideal_rate) + "/hr"
                  : "Not Set",
              },
            ],
          }
        ],
      },
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Availability Max: ", bold: true },
              {
                text: Number.isInteger(
                  Number(values.availability_hours_maximum)
                )
                  ? Number(values.availability_hours_maximum) +
                    " hours per week"
                  : "Not Set",
              },
            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Maximum Bill Rate: ", bold: true },
              {
                text: Number.isInteger(Number(values.maximum_rate))
                  ? "$" + Number(values.maximum_rate) + "/hr"
                  : "Not Set",
              },

            ],
          },
          {
            type: "table-cell",
            children: [
              { text: "Maximum Pay Rate: ", bold: true },
              {
                text: Number.isInteger(Number(values.ideal_rate_maximum))
                  ? "$" + Number(values.ideal_rate_maximum) + "/hr"
                  : "Not Set",
              },
            ],
          }
        ],
      },
      {
        type: "table-row",
        children: [
          { text: "Requirements: ", bold: true },
          {
            text: values.project_qualifications?.length
              ? values.project_qualifications.map((v) => v.label).join(", ")
              : "Not Set",
          },
        ]
      },
      {
        type: "table-row",
        children: [
          {
            type: "table-cell",
            children: [
              { text: "Moonlighter: ", bold: true },
              {
                text: values.moonlighter ? values.moonlighter.label : "Not Set",
              },
            ],
          },
          { type: "table-cell", children: [{ text: "" }] },
          { type: "table-cell", children: [{ text: "" }] },
        ],
      },
    ],
  },
];

const MatchingSupportForm = ({
  values,
  updateVal,
  projectId,
  setValues,
  setContext,
}) => {
  const { qualifications } = useQualifications(true);
  const setupDone = useRef(false);

  const { data, pageState } = useFetch(getProject, projectId);

  useEffect(() => {
    if (!setupDone.current && pageState === "loaded") {
      setupDone.current = true;

      setContext(data);
      setValues({
        ...{
          talent_paragraph: data.talent_paragraph,
          availability_hours: data.availability_hours,
          availability_hours_maximum: data.availability_hours_maximum,
          ideal_rate: data.ideal_rate,
          ideal_rate_maximum: data.ideal_rate_maximum,
          maximum_rate: data.maximum_rate,
          minimum_rate: data.minimum_rate,
          moonlighter:
            moonlighterOptions.find((i) => i.value === data.moonlighter) ||
            null,
          project_qualifications:
            Array.isArray(data.project_qualifications) &&
            qualifications.qualificationsByValue && data.project_qualifications
            ? data.project_qualifications.map((q) => ({
                qualification: q,
                value: q.qualification,
                label: qualifications.qualificationsByValue[q.qualification].name
              }))
            : []
        },
        ...values,
      });
    }
  }, [data, pageState]);

  if (pageState !== "loaded") {
    return null;
  }

  return (
    <div className="stage-change-form-middle flex flex-col border-l border-r border-link px-2">
      <div className="flex items-center w-full border-t border-b mb-2 border-link-water">
        <div className="w-full">
          <span className="text-sm font-bold text-kasmir">
            {$.talent_paragraph_label}
          </span>
          <div>
            <Textarea
              smallest
              borderless
              className="px-0 py-0 rounded font-normal text-kasmir text-sm bg-white placeholder-text-kasmir placeholder:font-normal focus:outline-none appearance-none border border-link-water w-full"
              placeholder={$.talent_paragraph_placeholder}
              value={values.talent_paragraph}
              onChange={(e) => updateVal("talent_paragraph", e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center w-full">
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.availability_hours_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              text={
                values.availability_hours
                  ? values.availability_hours + " hours per week"
                  : $.availability_hours_placeholder
              }
              afterText="per week"
              defaultValue={values.availability_hours}
              onChange={(v) => updateVal("availability_hours", Number(v))}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.ideal_rate_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ".", " "].includes(evt.key) &&
                evt.preventDefault()
              }
              text={
                Number.isInteger(Number(values.minimum_rate))
                  ? "$" + values.minimum_rate + "/hr"
                  : $.minimum_rate_placeholder
              }
              beforeText="$"
              afterText="/hr"
              defaultValue={values.minimum_rate}
              onChange={(v) => updateVal("minimum_rate", Number(v))}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.minimum_rate_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ".", " "].includes(evt.key) &&
                evt.preventDefault()
              }
              text={
                Number.isInteger(Number(values.ideal_rate))
                  ? "$" + values.ideal_rate + "/hr"
                  : $.ideal_rate_placeholder
              }
              beforeText="$"
              afterText="/hr"
              defaultValue={values.ideal_rate}
              onChange={(v) => updateVal("ideal_rate", Number(v))}
            />
          </div>
        </div>
      </div>
      <div className="flex items-start w-full">
        <div className="w-1/3 flex items-start">
          <span className="text-sm font-bold text-kasmir">
            {$.availability_hours_maximum_label}
          </span>
          <Input
            type="number"
            text={
              values.availability_hours_maximum
                ? values.availability_hours_maximum + " hours per week"
                : $.availability_hours_maximum_placeholder
            }
            afterText="per week"
            defaultValue={values.availability_hours_maximum}
            onChange={(v) => updateVal("availability_hours_maximum", Number(v))}
          />
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.ideal_rate_maximum_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ".", " "].includes(evt.key) &&
                evt.preventDefault()
              }
              text={
                Number.isInteger(Number(values.maximum_rate))
                  ? "$" + values.maximum_rate + "/hr"
                  : $.maximum_rate_placeholder
              }
              beforeText="$"
              afterText="/hr"
              defaultValue={values.maximum_rate}
              onChange={(v) => updateVal("maximum_rate", v)}
            />
          </div>
        </div>
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.maximum_rate_label}
          </span>
          <div className="flex-1 ml-1">
            <Input
              type="number"
              min="0"
              onKeyDown={(evt) =>
                ["e", "E", "+", "-", ".", " "].includes(evt.key) &&
                evt.preventDefault()
              }
              text={
                Number.isInteger(Number(values.ideal_rate_maximum))
                  ? "$" + values.ideal_rate_maximum + "/hr"
                  : $.ideal_rate_maximum_placeholder
              }
              beforeText="$"
              afterText="/hr"
              defaultValue={values.ideal_rate_maximum}
              onChange={(v) => updateVal("ideal_rate_maximum", Number(v))}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center w-full">
        <div className="w-1/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.moonlighter_label}
          </span>
          <div className="flex-1 ml-1">
            <Select
              placeholder={$.moonlighter_placeholder}
              value={values.moonlighter}
              onChange={(v) => updateVal("moonlighter", v)}
              options={moonlighterOptions}
            />
          </div>
        </div>
        <div className="w-2/3 flex items-center">
          <span className="text-sm font-bold text-kasmir">
            {$.requirements_label}
          </span>
          {qualifications && qualifications.qualificationsOptions && (
            <div className="flex-1 ml-1">
              <Select
                placeholder={$.requirements_placeholder}
                value={values.project_qualifications || []}
                isMulti
                onChange={(v) => updateVal("project_qualifications", v)}
                options={qualifications.qualificationsOptions}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MatchingSupportForm;
