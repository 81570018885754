import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import { admin_contact_stakeholders as $ } from "strings";
import { B2DB } from "components/Typography";
import Section from "components/Section";
import UilAward from "@iconscout/react-unicons/icons/uil-award";
import { Button, RemoveBtn } from "components/Buttons";
import { patchAccountContactV2, patchUser } from "utils/adminApi";
import LoadPlaceholder from "components/LoadPlaceholder";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { fetchOwners } from "utils/fetch";
import Select from "components/Select";


const STAKEHOLDER_TYPES = [
  { label: "Contact Owner", value: "owner" },
  { label: "Client Engagement", value: "client_engagement" },
];

const getStakeholderTypeFromURL = () => {
  // returns the stakeholder type from the URL (JSON {label, value}) or undefined
  // to ensure it's a proper value binding for the Select component
  let initialStakeholderType;
  initialStakeholderType = new URLSearchParams(window.location.search).get("type") || undefined;
  if (initialStakeholderType) {
    initialStakeholderType = STAKEHOLDER_TYPES.find(
      type => type.value === initialStakeholderType
    );
  }
  return initialStakeholderType;
};

const Stakeholders = ({
  data,
  setData,
  defaultContactId,
  hubspotInputsDisabled,
  onDisabledClick
}) => {
  const { id } = useParams();
  const { set } = usePageCache();
  const [stakeholder, setStakeholder] = useState(null);
  const [stakeholderType, setStakeholderType] = useState(getStakeholderTypeFromURL());
  const [prevStakeholderTypeFromURL, setPrevStakeholderTypeFromURL] = useState(getStakeholderTypeFromURL());
  const alert = useAlert();

  useEffect(() => {
    const currentStakeholderType = getStakeholderTypeFromURL();
    if (currentStakeholderType !== prevStakeholderTypeFromURL) {
      setStakeholderType(currentStakeholderType);
      setPrevStakeholderTypeFromURL(currentStakeholderType);
      setStakeholder(null);
    }
  });

  useEffect(() => {
    set("url_contact", `/admin/contacts/${id}/stakeholders`);
  }, [set]);

  const deleteOwner = async () => {
    let toSend = {
      email: data.contact_email,
      staff_poc: null,
      user_id: id
    };

    setData({ ...data, contact_owners: [] });

    const call = async () => {
      try {
        await patchAccountContactV2(defaultContactId, toSend);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };

    await call();
  };

  const deleteCE = async () => {
    let toSend = {
      client_engagement: null
    };

    setData({ ...data, client_engagement: null });

    const call = async () => {
      try {
        await patchUser(id, toSend);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };

    await call();
  }

  const handleSave = async () => {
    const call = async () => {
      try {
        if (stakeholderType.value === "owner") {
          await patchAccountContactV2(defaultContactId, {
            email: data.contact_email,
            staff_poc: stakeholder?.value,
            user_id: id
          });
        } else if (stakeholderType.value === "client_engagement") {
          await patchUser(id, {
            client_engagement: stakeholder?.value
          });
        } else {
          throw new Error(`Invalid stakeholder type: ${stakeholderType.value}`);
        }
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
      setStakeholder(null);
    };

    const sameStakeholder =
      stakeholderType.value === "owner" && data.contact_owners?.includes(stakeholder?.label) ||
      stakeholderType.value === "client_engagement" && data.client_engagement === stakeholder?.label;

    if (sameStakeholder) {
      setStakeholder(null);
      return;
    }

    if (stakeholderType.value === "owner") {
      if (data.contact_owners?.length) {
        try {
          await patchAccountContactV2(defaultContactId, {
            email: data.contact_email,
            staff_poc: null,
            user_id: id
          });
        } catch (e) {
          console.error(e);
          alert.error(<AlertError error={e} onRetry={call}/>);
        }
      }
      setData({...data, contact_owners: [stakeholder?.label]});
    } else if (stakeholderType.value === "client_engagement") {
      setData({...data, client_engagement: stakeholder?.label});
    }

    await call();
  };

  if (!data) {
    return (
      <div className="bg-white flex-1 flex flex-col">
        <LoadPlaceholder className="w-60 h-4 mt-10 mb-10" />
        <LoadPlaceholder className="w-60 h-4 mb-2" />
        <LoadPlaceholder className="w-40 h-4 mb-2" />
        <LoadPlaceholder className="w-32 h-4 mb-2" />
      </div>
    );
  }

  return (
    <Section
      id="admin_contact_stakeholders"
      className="flex mt-1 h-full flex-col w-2/3"
    >
      <div
        className="flex mb-1 items-center h-12 gap-1 w-full"
        onClick={() => {
          if (!hubspotInputsDisabled) return;
          onDisabledClick();
        }}
      >
        <Button
          noMinW
          disabled={!stakeholder || !defaultContactId}
          onClick={handleSave}
        >
          {$.save_btn}
        </Button>
        <Select
          className="grow w-3/4"
          placeholder={$.stakeholder_placeholder}
          onChange={(v) => setStakeholder(v)}
          value={stakeholder}
          async
          loadOptions={fetchOwners}
          autoFocus
          isDisabled={hubspotInputsDisabled}
        />
        <Select
          className="grow w-1/4"
          onChange={(v) => {
            setStakeholderType(v);
            setStakeholder(null);
          }}
          options={STAKEHOLDER_TYPES}
          value={stakeholderType}
          placeholder={$.type_placeholder}
          isDisabled={hubspotInputsDisabled}
        />
      </div>
      <div className="bg-white flex-1 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilAward className="mr-2 text-link" size="18" /> {$.title}
          </B2DB>
        </div>
        <div className="px-2 py-2">
          <div className="flex items-center text-sm text-kasmir font-bold">
            <div className="w-2/6">{$.name_label}</div>
            <div className="w-3/6">{$.role_label}</div>
            <div className="w-1/6" />
          </div>
          {data.contact_owners?.length ? (
            <div className="flex items-start my-2">
              <div className="w-2/6">
                <div className="text-midnight font-bold text-sm">
                  {data.contact_owners[0]}
                </div>
              </div>
              <div className="w-3/6 text-kasmir font-bold text-sm">
                {$.role_owner_label}
              </div>
              <div className="w-1/6 text-right pr-4">
                <RemoveBtn
                  colored
                  onClick={() => {
                    if (hubspotInputsDisabled) {
                      onDisabledClick();
                      return;
                    }
                    deleteOwner()
                  }}
                />
              </div>
            </div>
          ): <></>}
          {data.client_engagement ? (
            <div className="flex items-start my-2">
              <div className="w-2/6">
                <div className="text-midnight font-bold text-sm">
                  {data.client_engagement}
                </div>
              </div>
              <div className="w-3/6 text-kasmir font-bold text-sm">
                {$.role_ce_label}
              </div>
              <div className="w-1/6 text-right pr-4">
                <RemoveBtn
                  colored
                  onClick={() => {
                    if (hubspotInputsDisabled) {
                      onDisabledClick();
                      return;
                    }
                    deleteCE()
                  }}
                />
              </div>
            </div>
          ): <></>}
        </div>
      </div>
    </Section>
  );
};

export default Stakeholders;
