import React, {useEffect, useRef, useState} from "react";
import FilterDeleteBtn from "./FilterDeleteBtn";
import AddressSearch from "./AddressSearch";


const FilterLocation = ({
  filter,
  updateSearch,
  setIndex,
  index,
  isSelected,
  removeFilter,
}) => {
  const defaultRadius = 50;
  const [latitude, setLatitude] = useState(filter.data?.latitude);
  const [longitude, setLongitude] = useState(filter.data?.longitude);
  const [label, setLabel] = useState(filter.data?.label);
  const [radius, setRadius] = useState(filter.data?.radius || defaultRadius);

  useEffect(() => {
    updateSearch(index, {
      latitude: latitude,
      longitude: longitude,
      label: label,
      radius: radius,
    });
  }, [label, radius]);

  return !isSelected ? (
    <div
      onClick={() => setIndex(index)}
      className="bg-white border flex items-center border-link rounded h-8 px-1 text-link text-sm font-bold"
    >
      {filter.title}:{" "}
      {filter.data?.label || 'Not Set'} ({radius} mi)
      <FilterDeleteBtn onClick={() => removeFilter(index)} />
    </div>
  ) : (
    <div className="flex rounded text-sm font-bold h-8 w-full text-midnight bg-white outline-none appearance-none border-link border-2">
      <AddressSearch
        onChange={(v) => {
          if (v.latitude) setLatitude(v.latitude);
          if (v.longitude) setLongitude(v.longitude);
          if (v.name) setLabel(v.name);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setIndex(null, false);
          }
        }}
        className="rounded text-sm font-bold pl-1 h-full w-60 text-midnight bg-white outline-none appearance-none"
        defaultFullAddress={filter.data?.label || ''}
        autoFocus={true}
        placeholder="Enter country, state, city, or zip code"
      />
      <input
        type="number"
        className="border-l-2 border-link pl-1 text-sm font-bold h-full w-12 text-midnight bg-white outline-none appearance-none"
        value={radius}
        onChange={e => {
          if (e.target.value.length > 1 && e.target.value[0] === '0') {
            // remove leading zero
            e.target.value = e.target.value.slice(1);
          }
          try {
            const newRadius = parseInt(e.target.value) || 0;
            setRadius(newRadius);
          } catch (e) {
            setRadius(defaultRadius);
          }
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setIndex(null, false);
          }
        }}
        placeholder="Dist."
      />
      <div className="h-auto my-auto px-1 select-none">mi</div>
    </div>
  );
};

export default FilterLocation;
