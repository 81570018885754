import React from "react";
import { admin_talent_attachments as $ } from 'strings';
import { Header, Footer } from "components/Card";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { B2DB } from "components/Typography";

const DeleteAttachmentModal = ({ onClose, onDelete }) => {
return (
  <Modal isDirty={false} onClose={onClose} id="admin_talent_delete_form">
    <div className="flex flex-col flex-nowrap flex-1 min-h-0" noValidate>
      <Header title={$.delete_modal_title} />
      <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
        <B2DB>
          {$.delete_modal_msg}
        </B2DB>
      </div>
      <Footer>
        <div className="flex gap-4">
          <Button danger onClick={onDelete} type="submit">
            {$.delete_confirm_btn}
          </Button>
          <Button secondary onClick={onClose}>
            {$.delete_cancel_btn}
          </Button>
        </div>
      </Footer>
    </div>
  </Modal>
);
};

export default DeleteAttachmentModal;