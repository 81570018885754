import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { admin_talent_lead_source_form as $ } from "strings";
import { global as $$ } from "strings";
import { Header, Footer } from "components/Card";
import { Input, Label, Error } from "components/Form";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { postTalentLeadSource } from "utils/adminApi";
import { usePageCache } from "contexts/pageCacheContext";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import AlertSuccess from "components/AlertSuccess";

const LeadSourceForm = ({ onClose, setModal }) => {
  const alert = useAlert();
  const { set, cache } = usePageCache();
  const c = cache.admin_talent_form || {};
  const [sending, setSending] = useState(false);

  const { handleSubmit, register, errors, formState } = useForm();

  const onSubmit = async (values) => {
    setSending(true);

    const call = async () => {
      try {
        const response = await postTalentLeadSource(values);
        alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
        set("admin_talent_form", { ...c, lead_source: response });
        onClose();
        setModal(c.back_form);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} />);
        setSending(false);
      }
    };

    await call();
  };

  return (
    <Modal
      isDirty={formState.isDirty}
      onClose={() => {
        set("admin_talent_form", null);
        onClose();
      }}
      id="admin_talent_lead_source_form"
    >
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Header title={$.create_lead_source_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <div className="w-full md:w-1/2 pr-8 mb-3 mt-2">
            <Label>{$.name_input}</Label>
            <Input
              name="name"
              maxLength="150"
              ref={register({ required: $.validation_required })}
              error={errors.name}
            />
            {errors.name && <Error>{errors.name.message}</Error>}
          </div>
        </div>
        <Footer>
          <Button
            secondary
            onClick={() => {
              set("admin_talent_form", null);
              onClose();
            }}
            className="mr-4"
          >
            {$.cancel_button}
          </Button>
          <Button type="submit" loading={sending} disabled={sending}>
            {sending ? $.sending_button : $.save_button}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default LeadSourceForm;