import React from "react";

const ListHeader = ({ Icon, text, size, children }) => (
  <div className="mt-px mb-px flex items-center text-kasmir text-xs font-bold">
    <Icon size={size || 16} className="mr-1 text-link" />
    {text}
    {children}
  </div>
);

export default ListHeader;
