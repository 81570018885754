import React, { useState } from "react";
import AlertError from "components/AlertError";
import { Button } from "components/Buttons";
import { Error, Input, Label } from "components/Form";
import { useAlert } from "react-alert";
import { useForm } from "react-hook-form";
import { admin_talent_clockify as $ } from "strings";
import { global as $$ } from "strings";
import { createTalentClockify } from "utils/adminApi";
import AlertSuccess from "../../../../components/AlertSuccess";

const CreateTalentForm = ({ data, setData, setStatus }) => {
  const alert = useAlert();
  const [checking, setChecking] = useState(false);

  const { handleSubmit, register, errors, watch } = useForm({
    defaultValues: {
      name: data.first_name + " " + data.last_name,
    },
  });

  const allVal = watch();

  const onSubmit = async (values) => {
    setChecking(true);
    const toSend = {
      name: values.name,
      email: values.email,
    };

    const call = async () => {
      try {
        const response = await createTalentClockify(data?.id, toSend);
        alert.success(<AlertSuccess message={$$.successful_request_info} />, {timeout: 2000});
        setData({
          ...data,
          clockify_id: response.clockify_id,
          clockify_email: values.email,
        });
        setStatus(null);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
      setChecking(false);
    };

    await call();
  };

  return (
    <div className="w-full">
      <form
        className="flex flex-col flex-nowrap flex-1 min-h-0"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className="flex flex-wrap flex-1 min-h-0 py-4">
          <div className="w-full">
            <div className="w-full md:w-1/2 mb-3 mt-2">
              <Label>{$.name_input}</Label>
              <Input
                name="name"
                placeholder={$.name_placeholder}
                maxLength="150"
                ref={register({ required: $.validation_required })}
                error={errors.name}
              />
              {errors.name && <Error>{errors.name.message}</Error>}
            </div>
            <div className="w-full md:w-1/2 mb-3 mt-2">
              <Label>{$.email_input}</Label>
              <Input
                name="email"
                placeholder={$.email_placeholder}
                type="email"
                maxLength="254"
                ref={register({
                  required: $.validation_required,
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i,
                    message: $.validation_email,
                  },
                })}
                error={errors.email}
              />
              {errors.email && <Error>{errors.email.message}</Error>}
            </div>
          </div>
        </div>
        <div className="flex items-center space-x-4">
          <Button
            type="submit"
            loading={checking}
            disabled={!(allVal.name && allVal.email) || checking}
          >
            {checking ? $.checking_btn : $.save_btn}
          </Button>
          <Button secondary onClick={() => setStatus(null)} className="mr-4">
            {$.cancel_btn}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreateTalentForm;
