import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";
import { getCandidatesV2 } from "utils/adminApi";
import CandidateList from './CandidateList';
import CandidateDetail from './CandidateDetail';

const Candidates = ({ project, hubspotInputsDisabled }) => {
  const { id } = useParams();
  const { set } = usePageCache();
  const history = useHistory();
  const [selectedCandidateDetail, setSelectedCandidateDetail] = useState(null);
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = new URL(document.location).searchParams;
  const candidateId = params.get("c");

  useEffect(() => {
    if (!candidateId) {
      getCandidates();
      setSelectedCandidateDetail(null);
    }
  }, [candidateId]);

  useEffect(() => {
    selectedCandidateDetail ?
      set("url_project", `/admin/project/${id}/candidates?c=${selectedCandidateDetail.id}`) :
      set("url_project", `/admin/project/${id}/candidates`);
  }, [set, id, selectedCandidateDetail]);

  const onOpenCandidateDetail = (candidate) => {
    history.push(`?c=${candidate.id}`);
    setSelectedCandidateDetail(candidate);
  };

  const onExitCandidateDetail = () => {
    history.replace({
      search: ''
    });
  }

  const getCandidates = async (showLoader = true) => {
    if (showLoader) setLoading(true);
    try {
      const params = { project__id: id, ordering: "status", page_size: 999 };
      const response = await getCandidatesV2(params); 
      setLoading(false);
      setCandidates(response.results);

      if (history.location.search) {
        const c = response.results.find((result) => result.id === history.location.search.split('=')[1]);
        setSelectedCandidateDetail(c);
      }
    } catch (e) {
      console.error(e);
    }
  }

  const onUpdate = () => {
    getCandidates(false);
  }

  useEffect(() => {
    getCandidates();
  }, []);

  return (
    <div id="admin_project_candidates" className="h-full">
      {selectedCandidateDetail ? 
        <CandidateDetail
          project={project}
          candidate={selectedCandidateDetail}
          onUpdate={onUpdate}
          onBack={onExitCandidateDetail}
          hubspotInputsDisabled={hubspotInputsDisabled}
        /> :
        <CandidateList
          project={project}
          candidates={candidates}
          onOpenCandidateDetail={onOpenCandidateDetail}
          onUpdate={onUpdate}
          loading={loading}
          hubspotInputsDisabled={hubspotInputsDisabled}
        />
      }
    </div>
  )
};

export default Candidates;