import React from "react";
import { useParams, Redirect } from "react-router-dom";
import { usePageCache } from "contexts/pageCacheContext";

const ListInitSearch = ({ cacheKey, to }) => {
  const { search } = useParams();
  const { set } = usePageCache();

  set(cacheKey, { search });

  return <Redirect to={to} />;
};

export default ListInitSearch;
