import {
  getContacts,
  getCompanies,
  getStaffUsers,
  getLeadSources,
  getTalentLeadSources,
  getAccounts,
  getPaymentDeadlines,
  getInvoiceFrequencies,
  getCandidates,
  getClockifyClients,
  getClockifyTalents,
  getClockifyGroups,
  getAccountContactsV2,
  getProjectContacts,
  getProjectsV2
} from "utils/adminApi";

const PAGE_SIZE = 30;

const defaultMap = (i) => ({
  company: i,
  value: i.id,
  label: i.name,
});

const getFetch =
  (api, map = defaultMap, defaultParams = {}) =>
  async (search, loadedOptions, { page }) => {
    const params = { ...defaultParams, page_size: PAGE_SIZE, page };
    try {
      const response = await api(search ? { ...params, search } : params);
      const options = response.results.map(map);

      return {
        options,
        hasMore: !!response.next,
        additional: { page: page + 1 },
      };
    } catch (e) {
      console.error(e);
      return {
        options: [],
        hasMore: false,
        additional: { page: page + 1 },
      };
    }
  };

const fetchPrimaryClientEngagement = getFetch(getStaffUsers, (i) => ({
  owner: i,
  value: i.id,
  label: i.first_name + " " + i.last_name,
}));

const fetchOwners = getFetch(getStaffUsers, (i) => ({
  owner: i,
  value: i.id,
  label: i.first_name + " " + i.last_name,
  email: i.email,
}));

const fetchContacts = getFetch(getContacts, (i) => ({
  contact: i,
  value: i.id,
  label: i.name
}));

const fetchLeadSources = getFetch(getLeadSources, (i) => ({
  lead_source: i,
  value: i.id,
  label: i.name
}));

const fetchTalentLeadSources = getFetch(getTalentLeadSources);

const fetchCompanies = getFetch(getCompanies);

const fetchSupport = getFetch(getStaffUsers, (i) => ({
  owner: i,
  value: i.id,
  label: i.first_name + " " + i.last_name,
}));

const fetchSupportAssistant = getFetch(getStaffUsers, (i) => ({
  owner: i,
  value: i.id,
  label: i.first_name + " " + i.last_name,
}));

const fetchOpsOwners = getFetch(getStaffUsers, (i) => ({
  owner: i,
  value: i.id,
  label: i.first_name + " " + i.last_name,
}));

const getFetchContractTalents =
  (project__id) =>
  async (search, loadedOptions, { page }) => {
    let map;
    let defaultParams;
    let api;

    if (search) {
      map = (i) => ({
        talent: i,
        value: i.user.id,
        label: i.user.first_name + " " + i.user.last_name,
      });
      api = getAccounts;
      defaultParams = { user__is_staff: false };
    } else {
      map = (i) => ({
        talent: i.candidate,
        value: i.candidate.user.id,
        label: i.candidate.user.first_name + " " + i.candidate.user.last_name,
      });
      api = getCandidates;
      defaultParams = { ordering: "status", project__id };
    }

    const params = { ...defaultParams, page_size: PAGE_SIZE, page };
    try {
      const response = await api(search ? { ...params, search } : params);
      const options = response.results.map(map);

      return {
        options,
        hasMore: !!response.next,
        additional: { page: page + 1 },
      };
    } catch (e) {
      console.error(e);
      return {
        options: [],
        hasMore: false,
        additional: { page: page + 1 },
      };
    }
  };

const teamOptions = [
  { value: 0, label: "Offline", type: "team" },
  { value: 1, label: "Amazon", type: "team" },
  { value: 2, label: "Right Percent", type: "team" },
  { value: 3, label: "Affiliate", type: "team" },
  { value: 4, label: "Creative", type: "team" },
  { value: 5, label: "Other", type: "team" },
];

const getFetchContractTalentsAndTeams =
  (project__id) =>
  async (search, loadedOptions, { page }) => {
    let map;
    let defaultParams;
    let api;

    if (search) {
      map = (i) => ({
        talent: i,
        value: i.user.id,
        label: i.user.first_name + " " + i.user.last_name,
        type: "talent",
      });
      api = getAccounts;
      defaultParams = { user__is_staff: false };
    } else {
      map = (i) => ({
        talent: i.candidate,
        value: i.candidate.user.id,
        label: i.candidate.user.first_name + " " + i.candidate.user.last_name,
        type: "talent",
      });
      api = getCandidates;
      defaultParams = { ordering: "status", project__id };
    }

    const params = { ...defaultParams, page_size: PAGE_SIZE, page };
    try {
      const response = await api(search ? { ...params, search } : params);
      const options = [
        {
          label: "Team",
          options: search
            ? teamOptions.filter((t) =>
                t.label.toLowerCase().includes(search.toLowerCase())
              )
            : teamOptions,
        },
        { label: "Person", options: response.results.map(map) },
      ];
      return {
        options,
        hasMore: !!response.next,
        additional: { page: page + 1 },
      };
    } catch (e) {
      console.error(e);
      return {
        options: [],
        hasMore: false,
        additional: { page: page + 1 },
      };
    }
  };

const fetchPaymentDeadlines = getFetch(getPaymentDeadlines);

const fetchInvoiceFrequencies = getFetch(getInvoiceFrequencies);

const fetchClockifyClients = getFetch(getClockifyClients, (c) => ({
  client: c,
  value: c.clockify_id,
  label: c.name,
}));

const fetchClockifyTalents = getFetch(getClockifyTalents, (c) => ({
  client: c,
  value: c.id,
  label: c.name,
}));

const fetchClockifyGroups = getFetch(getClockifyGroups, (c) => ({
  group: c,
  value: c.id,
  label: c.name,
}));

const fetchAccounts = getFetch(getAccounts, (a) => ({
  account: a,
  value: a.user.id,
  label: a.user.first_name + " " + a.user.last_name,
}));

const fetchActiveProjects = async (search, loadedOptions, { page }) => {
  const params = {
    ordering: '-last_updated',
    stage: 'pitching,active,shadow_pitched,matching_support,matching_no_support,sourcing,submitted,contracting,pipelines',
    page_size: 10,
    page: page
  };
  try {
    const response = await getProjectsV2((search ? { ...params, search } : params));
    const options = response.results.map((i) => (
      {
        project: i,
        value: i.id,
        label: i.name
      }
    ));

    return {
      options,
      hasMore: !!response.next,
      additional: { page: page + 1 },
    };
  } catch (e) {
    console.error(e);
    return {
      options: [],
      hasMore: false,
      additional: { page: page + 1 },
    };
  }
};

const fetchTalentContacts = async (search, { page }) => {
  const params = { user__type: 'talent', page_size: PAGE_SIZE, page };
  try {
    const response = await getAccountContactsV2((search ? { ...params, search } : params));
    // TODO: determine if filter is needed
    const options = response.results.filter(c => c.is_default === true).map((i) => (
      {
        contact: i,
        value: i.user_id,
        label: i.name ? i.name : i.email
      }
    ));

    return {
      options,
      hasMore: !!response.next,
      additional: { page: page + 1 },
    };
  } catch (e) {
    console.error(e);
    return {
      options: [],
      hasMore: false,
      additional: { page: page + 1 },
    };
  }
};

const getFetchCompanyContacts =
  (company_id) =>
  async (search, loadedOptions, { page }) => {
    const api = getContacts;
    const map = (i) => ({
      contact: i,
      value: i.id,
      label: i.name
    });

    const params = { company_id, page_size: PAGE_SIZE, page };
    try {
      const response = await api(search ? { ...params, search } : params);
      const options = response.results.map(map);

      return {
        options,
        hasMore: !!response.next,
        additional: { page: page + 1 },
      };
    } catch (e) {
      console.error(e);
      return {
        options: [],
        hasMore: false,
        additional: { page: page + 1 },
      };
    }
  };

const fetchCompanyContacts = async (search, { page }) => {
  const params = { user__type: 'company', page_size: PAGE_SIZE, page };
  try {
    const response = await getAccountContactsV2(search ? { ...params, search } : params);
    const options = response.results.map((i) => (
      {
        contact: i,
        value: i.id,
        label: i.name
      }
    ));

    return {
      options,
      hasMore: !!response.next,
      additional: { page: page + 1 },
    };
  } catch (e) {
    console.error(e);
    return {
      options: [],
      hasMore: false,
      additional: { page: page + 1 },
    };
  }
};

const fetchProjectContacts = getFetch(getProjectContacts, (c) => ({
  contact: c,
  value: c.id,
  label: c.contact_name
}));

export {
  fetchContacts,
  fetchLeadSources,
  fetchCompanies,
  fetchOwners,
  fetchSupport,
  fetchTalentLeadSources,
  getFetchContractTalents,
  getFetchContractTalentsAndTeams,
  getFetchCompanyContacts,
  fetchPaymentDeadlines,
  fetchInvoiceFrequencies,
  fetchPrimaryClientEngagement,
  fetchSupportAssistant,
  fetchClockifyClients,
  fetchClockifyTalents,
  fetchClockifyGroups,
  fetchOpsOwners,
  fetchAccounts,
  fetchTalentContacts,
  fetchCompanyContacts,
  fetchProjectContacts,
  fetchActiveProjects,
  getFetch,
};
