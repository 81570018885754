import React from "react";
import FilterDeleteBtn from "./FilterDeleteBtn";

const FilterBoolean = ({ filter, setIndex, index, removeFilter }) => (
  <div
    onClick={() => setIndex(index)}
    className="bg-white border flex items-center border-link rounded h-8 px-1 text-link text-sm font-bold"
  >
    {filter.title}
    <FilterDeleteBtn onClick={() => removeFilter(index)} />
  </div>
);

export default FilterBoolean;
