import React, { useState, useEffect } from "react";

/**
 * Renders an editable text. When clicked it shows an input, and on unBlur shows the text back again.
 * It is used internally in the Range component.
 */
const Editable = ({
  text,
  defaultValue,
  error,
  onChange,
  textClass = "",
  inputClass = "",
  inputProps,
  type,
  afterText = "",
  beforeText = "",
  ...props
}) => {
  const [isEditing, setEditing] = useState(false);
  const [value, setValue] = useState();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleKeyDown = (e) => {
    const { key } = e;
    const keys = ["Escape", "Tab", "Enter"];

    if (keys.indexOf(key) > -1) {
      setEditing(false);
      onChange(value);
    }
  };

  return (
    <div {...props} className="h-5">
      {isEditing ? (
        <div
          onBlur={() => {
            onChange(value);
            setEditing(false);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
          className="flex items-center h-5 font-medium text-sm"
        >
          {beforeText}
          <input
            onChange={(e) => setValue(e.target.value)}
            value={value}
            autoFocus
            type={type}
            {...props}
            className={
              inputClass +
              " px-0 py-0 h-5 rounded text-center font-medium text-sm bg-white placeholder-text-kasmir placeholder:font-medium focus:outline-none appearance-none " +
              (type === "number" ? "w-8" : "w-28")
            }
          />
          {afterText}
        </div>
      ) : (
        <div
          onClick={() => {
            setEditing(true);
            setValue(defaultValue);
          }}
          className={
            textClass +
            " text-sm h-5 " +
            (!defaultValue && defaultValue !== 0
              ? "text-caded font-medium"
              : "text-midnight font-bold")
          }
        >
          {text}
        </div>
      )}
    </div>
  );
};

export default Editable;
