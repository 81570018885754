import React from "react";
import { admin_contact_role_delete_form as $ } from "strings";
import { deleteAccountContactV2 } from "utils/adminApi";
import { Header, Footer } from "components/Card";
import { Button } from "components/Buttons";
import Modal from "components/Modal";
import { useAlert } from "react-alert";
import AlertError from "components/AlertError";
import { B2DB } from "components/Typography";

const DeleteRoleForm = ({ onClose, onSuccess, role }) => {
  const alert = useAlert();
  const { id, role_title, company } = role;

  const onDelete = async () => {
    try {
      await deleteAccountContactV2(id);
      onSuccess(role);
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onDelete} />);
    }
  };

  return (
    <Modal isDirty={false} onClose={onClose} id="admin_contact_role_delete_form">
      <div className="flex flex-col flex-nowrap flex-1 min-h-0 px-2" noValidate>
        <Header title={$.confirm_title} />
        <div className="flex flex-wrap -mr-8 flex-1 min-h-0 overflow-y-auto py-4">
          <B2DB>{$.delete_role_message.replace("%", `${company?.name} - ${role_title}`)}</B2DB>
        </div>
        <Footer>
          <Button secondary onClick={onClose} className="mr-4">
            {$.cancel_button}
          </Button>
          <Button danger onClick={onDelete} type="submit">
            {$.confirm_button}
          </Button>
        </Footer>
      </div>
    </Modal>
  );
};

export default DeleteRoleForm;