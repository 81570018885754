import React, { useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { admin_talent_project as $ } from "strings";
import RichTextEditor from "components/RichTextEditorNew";
import UilArrow from "@iconscout/react-unicons/icons/uil-arrow-circle-right";
import { isBodyEmpty } from "components/RichTextEditor";
import AlertError from "components/AlertError";
import { useAlert } from "react-alert";
import { serialize } from "utils/htmlSerializer";
import { patchCandidateV2, getTalentNotesV2 } from "utils/adminApi";

const CandidateActivityForm = ({ project, onAddNote }) => {
  const params = new URL(document.location).searchParams;
  const candidateId = params.get("cpid");
  const alert = useAlert();
  const [saving, setSaving] = useState();
  const [values, setValues] = useState({
    description: [{ type: "paragraph", children: [{ text: "" }] }],
  });

  const { id } = useParams();

  const updateVal = (k, v) => {
    setValues((vs) => ({ ...vs, [k]: v }));
  };

  const isEmpty = isBodyEmpty(values.description);

  const onSubmit = async () => {
    setSaving(true);

    let description = values.description;

    const toSend = {
      description: serialize({ children: description }),
      project: project.project_id,
      status: project.status,
    };

    const call = async () => {
      try {
        await patchCandidateV2(candidateId, toSend);
        setValues({ description: [{ type: "paragraph", children: [{ text: "" }] }] });
        const updatedNotes = await getTalentNotesV2({
          talent_id: id,
          project_id: project.project_id,
          ordering: '-created_timestamp',
          page_size: 1
        });
        if (!updatedNotes?.results?.length) return;
        const newNote = updatedNotes.results[0];
        onAddNote(newNote);
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }

      setSaving(false);
    };

    await call();
  };

  return (
    <>
      <RichTextEditor
        placeholder={$.add_candidate_note}
        value={values.description}
        onChange={(v) => updateVal("description", v)}
      />
      <div className="flex items-center justify-start border-b border-r border-l border-geyser rounded-b pb-1 px-2">
        <div className="flex items-center flex-1 justify-end">
          {!isEmpty && (
            <button
              onClick={() => {
                updateVal("description", [
                  { type: "paragraph", children: [{ text: "" }] },
                ]);
              }}
              type="button"
              className="text-kasmir flex items-center font-bold text-sm"
            >
              {$.cancel_button}
            </button>
          )}
          <button
            onClick={onSubmit}
            type="button"
            className={
              "flex items-center font-bold text-sm ml-4 " +
              (saving || (isEmpty)
                ? "cursor-not-allowed text-caded"
                : "text-kasmir")
            }
            disabled={saving || (isEmpty)}
          >
            {saving ? $.saving_btn : $.submit_btn}
            <UilArrow size="20" className="ml-1" />
          </button>
        </div>
      </div>
    </>
  );
};

export default CandidateActivityForm;