import React from "react";
import { admin_talent_project as $ } from "strings";
import { B2DB } from "components/Typography";
import { Link } from "react-router-dom";
import Section from "components/Section";
import Header from "components/ListHeader";
import UilArrow from "@iconscout/react-unicons/icons/uil-compress-alt";
import UilUserCircle from "@iconscout/react-unicons/icons/uil-user-circle";
import UilSuitcase from "@iconscout/react-unicons/icons/uil-suitcase";
import UilPin from "@iconscout/react-unicons/icons/uil-map-pin";
import LoadPlaceholder from "components/LoadPlaceholder";
import UilInfoCircle from "@iconscout/react-unicons/icons/uil-info-circle";
import { getProjectCompanyV2 } from "utils/adminApi";
import useFetch from "hooks/useFetch";
import { getAdminFlags } from "utils/localStorageService";

const CompanyInfo = ({ projectId, open, onTogglePanel }) => {
  const { data, setData } = useFetch(getProjectCompanyV2, projectId);
  const flags = getAdminFlags();
  
  return (
    <Section id="project_company_info" className="flex mb-1">
      <div className="bg-white flex-1 mr-2 flex flex-col">
        <div className="flex items-center px-2 py-4">
          <B2DB className="flex flex-1 items-center">
            <UilInfoCircle className="mr-2" size="18" />{$.company_info_title}
          </B2DB>
          <div className="cursor-pointer" onClick={onTogglePanel}>
            <UilArrow size="18" />
          </div>
        </div>
        {open && (
          <div className="px-2 pb-4">
            {!data && (
              <div className="flex-1 border border-link-water rounded px-6 pt-6">
                <LoadPlaceholder className="w-40 h-4 my-4" />
                <LoadPlaceholder className="w-40 h-4 mb-4" />
                <LoadPlaceholder className="w-20 h-4 mb-2" />
              </div>
            )}
            {data && (
              <>
                <div className="w-full flex">
                  <div className="w-1/2">
                    <Header Icon={UilSuitcase} text={$.company_name_title} />
                    <div className="text-sm text-midnight font-bold text-ellipsis">
                      <Link
                        className="inline-block"
                        to={`/admin/company/${data.id}`}
                      >
                        <B2DB>
                          {data.name}
                        </B2DB>
                      </Link>
                    </div>
                  </div>
                  <div className="w-1/2">
                    <Header Icon={UilUserCircle} text={$.primary_ce_title} />
                    <div className="text-sm text-midnight font-bold truncate">
                      {data.contact_name || $.unknown_value}
                    </div>
                  </div>
                </div>
                <div className="w-full flex mt-2">
                  <div className="w-1/2">
                    <Header Icon={UilPin} text={$.location_title} />
                    <div className="text-sm text-midnight font-bold truncate">
                      {data.address|| $.unknown_value}
                    </div>
                  </div>
                </div>
              </>
            )}
            </div>
          )}
      </div>
    </Section>
  )
};

export default CompanyInfo;