import React from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { admin_contact as $ } from "strings";
import { toFormat } from "utils/date";
import Header from "components/ListHeader";
import userImg from "assets/user.svg";
import UilUserCircle from "@iconscout/react-unicons/icons/uil-user-circle";
import UilCalendar from "@iconscout/react-unicons/icons/uil-calender";
import UilUsersAlt from "@iconscout/react-unicons/icons/uil-users-alt";

const boolOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const TalentRole = ({ role }) => {
  const { id } = useParams();
  const {
    profile_photo,
    fullname,
    current_title,
    owners,
    community,
    created_at
  } = role;

  return (
    <div 
      id={`talent_role--${id}`}
      className="bg-white flex-1 mt-2 mb-2 flex flex-col"
    >
      <div className="w-full flex space-between px-2">
        <div className="flex w-1/4 items-center">
          <img
            src={profile_photo || userImg}
            className="rounded w-12 h-12 -ml-2 mr-1"
            alt={fullname}
          />
          <div className="truncate">
            <div className="text-sm text-midnight font-bold text-ellipsis font-bold mt-1">
              <Link
                id={`talentLink--${id}`}
                className="inline-block"
                to={`/admin/talent/${id}/overview`}
              >{fullname}
              </Link>
            </div>
            <div className="text-sm text-midnight font-bold truncate">
              {current_title || $.no_current_title}
            </div>
          </div>
        </div>
        <div className="mt-1 w-1/6">
          <Header Icon={UilUserCircle} text={$.talent_owner_label} />
          <div className="text-sm text-midnight font-bold text-ellipsis">
            {owners?.length ? owners.join(', ') : $.unknown_value}
          </div>
        </div>
        <div className="mt-1 w-1/6">
          <Header Icon={UilUsersAlt} text={$.community_label} />
          <div className="text-sm text-midnight font-bold text-ellipsis">
            {boolOptions.find(o => o.value ===  community)?.label || $.unknown_value}
          </div>
        </div>
        <div className="mt-1 w-1/6">
          <Header Icon={UilCalendar} text={$.talent_created_on_label} />
          <div className="text-sm text-midnight font-bold text-ellipsis">
            {created_at ? toFormat(new Date(created_at), "MMMM dd, yyyy") : $.unknown_value}
          </div>
        </div>
      </div>
    </div>
  )
};

export default TalentRole;