import React from "react";
import ReactSelect, { components } from "react-select";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import { AsyncPaginate, wrapMenuList } from "react-select-async-paginate";

const baseStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#dce9f6"
      : state.isFocused
      ? "#f4f6f9"
      : "#fff",
    fontFamily: "Lato",
    fontWeight: state.isSelected ? "500" : "400",
    fontSize: ".75rem",
    color: state.isSelected ? "#0070d2" : "#54698d",
    "&:hover": {
      backgroundColor: state.isSelected ? "#dce9f6" : "#f4f6f9",
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    color: "#16325c",
    backgroundColor: "#f4f6f9",
    borderRadius: ".25rem",
    height: "1.5rem",
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
    display: "flex",
    alignItems: "center",
    flexShrink: "0",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontWeight: "400",
    fontSize: ".75rem",
    color: "#16325c",
    padding: "0",
    paddingLeft: "0",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: "#16325c",
    padding: "0",
    marginLeft: ".25rem",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#16325c",
    },
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    color: "#16325c",
    "&:hover": {
      color: "#16325c",
    },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "#16325c",
    padding: "0",
    "&:hover": {
      color: "#16325c",
    },
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0",
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: "0",
    border: "solid 1px #0070d2",
    borderRadius: "0 0 .25rem .25rem",
    marginTop: "1px",
    marginLeft: "-1px",
    width: "calc(100% + 2px)",
    zIndex: "60",
    overflow: "hidden",
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontWeight: "400",
    fontSize: ".75rem",
    color: "#54698d",
  }),
  valueContainer: (provided) => ({
    ...provided,
    fontWeight: "700",
    fontSize: ".875rem",
    color: "#16325c",
    padding: "0",
    width: "100%",
    flexWrap: "nowrap",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    width: "100%",
    fontWeight: "700",
    fontSize: ".875rem",
    color: state.selectProps.secondary ? "#0070d2" : "16325c",
  }),
  input: (provided) => ({
    ...provided,
    fontWeight: "700",
    fontSize: ".875rem",
    color: "#16325c",
    width: "100%",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: "400",
    color: "#54698d",
    fontSize: ".875rem",
    flexShrink: "0",
  }),
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  indicatorSeparator: () => ({ display: "none" }),
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.selectProps.secondary
      ? "#f4f6f9"
      : state.selectProps.isDisabled
      ? "#f4f6f9"
      : "inherit",
    minHeight: "1.5rem",
    width: "100%",
    marginTop: "0",
    marginBottom: "0",
    borderRadius: ".25rem",
    padding: "0",
    paddingLeft: ".25rem",
    paddingRight: ".25rem",
    display: "flex",
    alignItems: "start",
    boxShadow: "0",
    border: "0",
  }),
};

const ClearIndicator = (props) => (
  <components.ClearIndicator {...props}>
    <UilTimes size="20" />
  </components.ClearIndicator>
);

const MultiValueRemove = (props) => (
  <components.MultiValueRemove {...props}>
    <UilTimes size="13" />
  </components.MultiValueRemove>
);

const MenuList = ({ children, selectProps, ...rest }) => {
  const Footer = selectProps.footer;

  return (
    <components.MenuList {...rest}>
      {children}
      {selectProps.footer && (
        <div className="border-t rounded-b border-link py-2 px-1 sticky bg-white bottom-0">
          <Footer />
        </div>
      )}
    </components.MenuList>
  );
};

const PaginateMenuList = wrapMenuList(MenuList);

const Select = ({
  async,
  onChange,
  components = {},
  customStyles = {},
  ...props
}) => {
  const BaseSelect = async ? AsyncPaginate : ReactSelect;

  return (
    <BaseSelect
      className="w-full"
      debounceTimeout={250}
      cacheOptions={async}
      defaultOptions={async}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      menuPlacement="auto"
      {...props}
      onChange={(v) => {
        let value = v;
        if (props.isMulti && v && v.length) {
          if (v.find((o) => o.value === "anytime")) {
            value = props.options;
          }
        } else if (props.isMulti) {
          value = "";
        } else if (!props.isMulti && v && v.value === null) {
          value = null;
        }

        onChange(value);
      }}
      additional={{ page: 1 }}
      styles={{ ...baseStyles, ...customStyles }}
      components={{
        ...{
          DropdownIndicator: () => null,
          ClearIndicator,
          MultiValueRemove,
          MenuList: async ? PaginateMenuList : MenuList,
        },
        ...components,
      }}
    />
  );
};

export default Select;
