import React from "react";
import logoSm from "assets/logo_sm.svg";

/**
 * Renders the brand logo, it uses a small and normal size ones.
 */
const Logo = () => (
  <div className="mr-2 left-0 top-0 h-full w-12 inline-flex items-center">
    <img className="max-w-full max-h-full" src={logoSm} alt="Right Side Up" />
  </div>
);

export default Logo;
