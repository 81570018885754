import React, { useMemo } from "react";
import { admin_company_project_list as $ } from "strings";
import { H2B, B2DB, B1, B3B, B3BLink } from "components/Typography";
import { CloseBtn } from "components/Buttons";
import { Link } from "react-router-dom";
import UilStopwatch from "@iconscout/react-unicons/icons/uil-stopwatch";
import UilBill from "@iconscout/react-unicons/icons/uil-bill";
import UilBuilding from "@iconscout/react-unicons/icons/uil-building";
import UilSuitcase from "@iconscout/react-unicons/icons/uil-suitcase";
import UilAward from "@iconscout/react-unicons/icons/uil-award";
import UilMarker from "@iconscout/react-unicons/icons/uil-map-marker";
import { usePageCache } from "contexts/pageCacheContext";
import { getProjectQualifications } from "utils/adminApi";
import useCachedFetch from "hooks/useCachedFetch";

const wwOrder = ["morning", "afternoon", "night", "weekend", "anytime"];

const ProjectPanel = ({ project, setPanel }) => {
  const { set } = usePageCache();

  const projectQualifications = useCachedFetch(
    "admin_project_qualifications",
    getProjectQualifications,
    project.id
  );

  const required = useMemo(() => {
    let required = 0;

    if (
      project &&
      projectQualifications.data &&
      Array.isArray(projectQualifications.data.results)
    ) {
      projectQualifications.data.results.forEach((q) => {
        if (q.required) {
          required++;
        }
      });
    }

    return required;
  }, [project, projectQualifications]);

  return (
    <div className="bg-white px-4 py-6 mb-6 w-2/5 ml-6">
      <div className="flex items-center justify-between">
        <Link to={`/admin/project/${project.id}`}>
          <H2B>{project.name}</H2B>
        </Link>
        <CloseBtn onClick={() => setPanel()} />
      </div>
      <div className="mt-8">
        <B2DB className="flex items-center">
          <UilBuilding size="18" className="mr-2" />
          {$.company_title}
        </B2DB>
        <B3BLink to={`/admin/company/${project.company.id}`}>
          {project.company.name}
        </B3BLink>
      </div>
      <div className="mt-8">
        <B2DB className="flex items-center">
          <UilSuitcase size="18" className="mr-2" />
          {$.contact_title}
        </B2DB>
        {project.contact && (
          <B3BLink to={`/admin/contacts/${project.contact.id}`}>
            {project.contact.name}
          </B3BLink>
        )}
      </div>
      <div className="mt-8">
        <B2DB className="flex items-center">
          <UilMarker size="18" className="mr-2" />
          {$.location_title}
        </B2DB>
        <B3B>{project.full_address}</B3B>
      </div>
      <div className="mt-8">
        <B2DB className="flex items-center">
          <UilStopwatch size="18" className="mr-2" />
          {$.work_availability_title}
        </B2DB>
        <B1>
          {project.availability_hours && (
            <>
              {project.availability_hours} {$.hours_per_week}
              <br />
            </>
          )}
          {project.work_windows
            .sort(
              (a, b) =>
                wwOrder.indexOf(a.work_window) -
                  wwOrder.indexOf(b.work_window) ||
                a.work_window - b.work_window
            )
            .map((w) => $[w.work_window + "_option"])
            .join("/")}
        </B1>
      </div>
      <div className="mt-8">
        <B2DB className="flex items-center">
          <UilBill size="18" className="mr-2" />
          {$.rate_title}
        </B2DB>
        <B1>
          {project.ideal_rate >= 0 && project.maximum_rate && (
            <>
              ${project.ideal_rate} - ${project.maximum_rate}
            </>
          )}
        </B1>
      </div>
      <div className="mt-8 mb-8">
        <B2DB className="flex items-center">
          <UilAward size="18" className="mr-2" />
          {$.qualifications_title}
        </B2DB>
        <B1>{required} qualifications required.</B1>
      </div>
      <Link
        onClick={() => set(`admin_project_${project.id}_fetch`, project)}
        to={`/admin/project/${project.id}/`}
        className="text-sm font-bold rounded w-full h-10 inline-flex items-center justify-center bg-link text-white hover:bg-link-dark active:bg-link-darker"
      >
        {$.view_full_button}
      </Link>
    </div>
  );
};

export default ProjectPanel;
