import React from "react";
import DebouncedInput from "./DebouncedInput";
import UilSearch from "@iconscout/react-unicons/icons/uil-search";
import UilCheck from "@iconscout/react-unicons/icons/uil-check";
import { D4 } from "./Typography";
import { global as $ } from "strings";
import InputMask from "comigo-tech-react-input-mask";

/**
 * Renders a search input. It can be debounced if needed, to avoid many requests to the api.
 * @param {string} type - Type of the inner input, by default text.
 * @param {boolean} error - Flag to indicate if the input has an error.
 * @param {boolean} debounced - Flag to indicate if use a debounced input or not.
 * @param {object} props - Extra props to attach to the inner input.
 */
const Search = React.forwardRef(
  ({ type = "text", error, debounced, ...props }, ref) => (
    <div className="relative">
      <div className="z-10 absolute left-0 top-0 bottom-0 flex items-center text-kasmir ml-2">
        <UilSearch size="16" />
      </div>
      {debounced ? (
        <DebouncedInput
          ref={ref}
          {...props}
          type={type}
          className={
            "w-full rounded text-sm font-normal h-10 w-66 pl-8 pr-3 text-kasmir bg-link-water focus:outline-none appearance-none border border-link-water focus:border-link focus:border-2 focus:bg-white " +
            (error ? "border-2 border-red" : "border-0")
          }
        />
      ) : (
        <input
          ref={ref}
          {...props}
          type={type}
          className={
            "w-full rounded text-sm font-normal h-10 w-66 pl-8 pr-3 text-kasmir bg-link-water focus:outline-none appearance-none border border-link-water focus:border-link focus:border-2 focus:bg-white " +
            (error ? "border-2 border-red" : "border-0")
          }
        />
      )}
    </div>
  )
);

/**
 * Renders an input.
 * @param {string} type - Type of the inner input, by default text.
 * @param {boolean} error - Flag to indicate if the input has an error.
 * @param {object} props - Extra props to attach to the inner input.
 */
const Input = React.forwardRef(({ type = "text", error, ...props }, ref) => (
  <input
    ref={ref}
    {...props}
    onWheel={(e) => e.target.blur()}
    type={type}
    className={
      "w-full px-3 rounded h-10 w-60 flex items-center font-bold text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-normal focus:outline-none appearance-none " +
      (error
        ? "border-red border-2"
        : props.disabled
        ? "border border-geyser bg-link-water"
        : "border border-geyser focus:border-link focus:border-2")
    }
  />
));

const PhoneInput = React.forwardRef(({ value, onChange, type = "tel", error, ...props }, ref) => {
  return (
    <InputMask
      ref={ref}
      {...props}
      onWheel={(e) => e.target.blur()}
      type={type}
      className={
        "w-full px-3 rounded h-10 w-60 flex items-center font-bold text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-normal focus:outline-none appearance-none " +
        (error
          ? "border-red border-2"
          : props.disabled
          ? "border border-geyser bg-link-water"
          : "border border-geyser focus:border-link focus:border-2")
      }
      mask="(999)-999-9999"
      placeholder="(###) ###-####"
      onChange={(e) => {
        onChange(e)
      }}
      value={value}
    />
  )
});

/**
 * Renders a textarea.
 * @param {boolean} error - Flag to indicate if the input has an error.
 * @param {boolean} small - Flag to indicate if the input is small.
 * @param {object} props - Extra props to attach to the inner input.
 */
const Textarea = React.forwardRef(
  ({ error, small, unbolded, borderless, smallest, ...props }, ref) => (
    <textarea
      ref={ref}
      {...props}
      className={
        "w-full rounded text-sm text-midnight bg-white placeholder-text-kasmir placeholder:font-normal focus:outline-none appearance-none " +
        (error && !borderless
          ? "border-red border-2 "
          : !borderless
          ? "border border-geyser focus:border-link focus:border-2 "
          : "") +
        (borderless ? "p-0 " : "p-3 ") +
        (smallest ? "h-8 " : small ? "h-12 " : "h-20 ") +
        (unbolded ? "font-normal" : "font-bold")
      }
    />
  )
);

/**
 * Renders label to be used with a form field.
 * @param {component} children - The children to render, in most cases a clear button.
 * @param {string} className - Classes to append to the label default classes.
 */
const Label = ({ children, className = "" }) => (
  <label
    className={
      "text-kasmir font-normal text-xs mb-1 flex items-center " + className
    }
  >
    {children}
  </label>
);

/**
 * Renders clear button to be used inside form fields labels. Used to clear filters.
 * @param {string} text - Text to show in the button, it has a default value.
 * @param {callback} onClick - Callback to trigger on click.
 */
const Clear = ({ text = $.clear_button, onClick }) => (
  <div className="flex-1 flex justify-end">
    <button
      className="focus:outline-none appearance-none inline float-right font-xs text-link font-bold"
      onClick={onClick}
    >
      {text}
    </button>
  </div>
);

/**
 * Renders a form field error.
 * @param {component} children - The children to render, the error message.
 */
const Error = ({ children }) => <D4>{children}</D4>;

/**
 * Renders a checkbox field.
 * @param {component} children - The children to render, the text of the checkbox.
 * @param {boolean} value - Value of the checkbox.
 * @param {callback} onChange - Callback to catch value changes.
 * @param {object} props - Extra props to attach to the inner checkbox input.
 */
const Checkbox = ({ children, value, onChange, disabled, ...props }) => (
  <label className="flex items-center font-helvetica text-kasmir font-normal text-sm">
    <input
      type="checkbox"
      className="hidden"
      checked={value}
      onChange={(e) => {
        if (disabled) return;
        onChange(e.target.checked)
      }}
      {...props}
    />
    <div
      className={
        "w-4 h-4 mr-1 text-xs rounded cursor-pointer text-white flex items-center justify-center flex-shrink-0 " +
        (value ? "bg-link " : "bg-geyser ") + 
        (disabled ? "cursor-not-allowed" : "cursor-pointer")
      }
    >
      {value && <UilCheck />}
    </div>
    {children}
  </label>
);

export { Input, PhoneInput, Textarea, Checkbox, Search, Label, Error, Clear };
