import React, { useState, useEffect } from "react";
import { admin_contact as $ } from "strings";
import { useParams } from "react-router-dom";
import {
  postUserLink,
  patchUserLink,
  postAccountContactV2,
  patchContactOverviewV2
 } from "utils/adminApi";
import Select from "components/Select";
import AlertError from "components/AlertError";
import { Button, OutlinedButton } from "components/Buttons";
import { Input, PhoneInput } from "components/Form";
import { formatPhone } from "utils/str";
import { useAlert } from "react-alert";
import { getAdminUser } from "utils/localStorageService";

const contactInfoTypes = [
  { label: 'Phone Number', value: "phone" },
  { label: 'Email Address', value: "email" },
  { label: 'Contact Link', value: "link" }
];

const linkTypes = [
  { label: 'LinkedIn', value: "linkedin" },
  { label: 'Personal Website', value: "website" },
]

// this ID points to a test company;
// a company email must belong to a company to be honored as a company email
const SHELL_COMPANY_ID = process.env.NODE_ENV === 'production' ?
'1f3aa606-c307-4559-acb8-beddad86d893' : // prod
'cebd973d-deb1-480a-a7ae-f05abe6fbc61'; // staging

const AddContactInfo = ({
  data,
  contactLinks,
  onAddEmailSuccess,
  onAddPhoneSuccess,
  onAddLinkSuccess,
  onUpdateLinkSuccess,
  isTalent,
  disabled,
  onDisabledClick
}) => {
  const { id } = useParams();
  const adminUser = getAdminUser();
  const alert = useAlert();
  const [formOpen, setFormOpen] = useState(false);
  const [selectedContactType, setSelectedContactType] = useState();
  const [sending, setSending] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [emailTypes, setEmailTypes] = useState([
    { label: 'Personal Email Address', value: "personal" },
    { label: 'Company Email Address', value: "company" },
  ])
  const [phoneValues, setPhoneValues] = useState({
    phone: ''
  });
  const [emailValues, setEmailValues] = useState({
    email: '',
    email_type: ''
  });
  const [linkValues, setLinkValues] = useState({
    url: '',
    name: ''
  });

  const {
    contact_email,
    finance_email,
    phone_number
  } = data;

  useEffect(() => {
    if (isTalent === true) {
      setEmailTypes([...emailTypes, { label: 'Finance Email Address', value: "finance" }]);
    }
  }, [isTalent])

  useEffect(() => {
    if (!emailValues.email_type) return;
    if (emailValues.email_type === 'company') {
      setEmailValues({...emailValues, 'email': ''});
      setIsUpdate(false);
      return;
    }
    let existingVal;
    if (emailValues.email_type === 'finance') {
      existingVal = finance_email;
    } else if (emailValues.email_type === 'personal') {
      existingVal = contact_email;
    }
    if (existingVal) {
      setEmailValues({...emailValues, 'email': existingVal});
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, [emailValues.email_type])

  useEffect(() => {
    // if link type is LinkedIn and one exists, set existing LI url as input val
    if (linkValues.name === 'linkedin') {
      let existingVal = contactLinks.find(l => l.name.toLowerCase() === 'linkedin')?.url;
      if (existingVal) {
        setLinkValues({...linkValues, 'url': existingVal})
        setIsUpdate(true);
      } else {
        setIsUpdate(false);
      }
    } else if (linkValues.name.toLowerCase() === 'website') {
      let existingVal = contactLinks.find(l => l.name.toLowerCase() === 'website')?.url;
      if (existingVal) {
        setLinkValues({...linkValues, 'url': existingVal})
        setIsUpdate(true);
      } else {
        setIsUpdate(false);
      }
    } else {
      if (linkValues.url.includes('linkedin')) {
        setLinkValues({...linkValues, 'url': ''})
      }
      setIsUpdate(false);
    }
  }, [linkValues.name])

  useEffect(() => {
    if (!selectedContactType || selectedContactType?.value !== 'phone') return;
    let existingVal = phone_number;
    if (existingVal) {
      setPhoneValues({...phoneValues, 'phone': phone_number});
      setIsUpdate(true);
    } else {
      setPhoneValues({...phoneValues, 'phone': ''})
      setIsUpdate(false);
    }
  }, [selectedContactType])

  const onAddEmailAddress = async () => {
    try {
      if (emailValues.email_type === 'company') {
        const toSend = {
          email: emailValues.email,
          user_id: id,
          company_id: SHELL_COMPANY_ID,
          is_primary_role: false
        }
        await postAccountContactV2(toSend);
        onAddEmailSuccess('company', toSend.email);
      }
      else if (emailValues.email_type === 'login') {
        const toSend = {
          contact_email: emailValues.email
        }
        await patchContactOverviewV2(id, toSend);
        onAddEmailSuccess('login', toSend.contact_email);
      }
      else if (emailValues.email_type === 'finance') {
        const toSend = {
          finance_email: emailValues.email
        }
        await patchContactOverviewV2(id, toSend);
        onAddEmailSuccess('finance', toSend.finance_email);
      }
      setEmailValues({email: '', email_type: ''});
      resetFormValues();
    } catch (e) {
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onAddEmailAddress} />);
    }
  };

  const onAddPhone = async () => {
    setSending(true);
    const formattedPhone = phoneValues.phone.replace(/[^\d]/g, '');

    const toSend = {
      phone_number: formattedPhone,
    }

    try {
      const response = await patchContactOverviewV2(id, toSend);
      onAddPhoneSuccess(response.phone_number);
      setPhoneValues({phone: ''});
      resetFormValues();
    }catch (e) {
      setSending(false);
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onAddPhone} />);
    }
  }

  const onUpdateLink = async (linkType) => {
    setSending(true);
    let existingLinkId = contactLinks.find(l => l.name.toLowerCase() === linkType.toLowerCase())?.id;

    const toSend = {
      url: linkValues.url,
      updated_by: adminUser.id,
      user: id
    }

    try {
      const response = await patchUserLink(existingLinkId, toSend);
      onUpdateLinkSuccess(response);
      setLinkValues({url: '', name: ''});
      resetFormValues();
    } catch (e) {
      setSending(false);
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onAddLink} />);
    }

  }

  const onAddLink = async () => {
    setSending(true);

    const toSend = {
      ...linkValues,
      updated_by: adminUser.id,
      user: id
    }

    try {
      const response = await postUserLink(toSend);
      setLinkValues({url: '', name: ''});
      onAddLinkSuccess(response);
      resetFormValues();
    } catch (e) {
      setSending(false);
      console.error(e);
      alert.error(<AlertError error={e} onRetry={onAddLink} />);
    }
  }

  const resetFormValues = () => {
    setSending(false);
    setFormOpen(false);
    setSelectedContactType(null);
  }


  return (
    <div className="candidate_list_actions flex mb-1 items-start py-2">
      <div className="flex w-3/4">
        {formOpen ? <div className="flex items-start justify-start mt-1 mr-1">
          {!selectedContactType && <Button noMinW disabled>{$.save_btn}</Button>}
          {selectedContactType?.value === 'email' &&
            <Button noMinW
              onClick={() => onAddEmailAddress()}
              disabled={
                !emailValues.email ||
                !emailValues.email_type
              }
            >
              {isUpdate ? $.update_button : $.save_btn}
            </Button>
          }
          {selectedContactType?.value === 'phone' &&
            <Button noMinW
              onClick={() => onAddPhone()}
              disabled={
                !phoneValues.phone ||
                sending
              }
            >
              {isUpdate ? $.update_button : $.save_btn}
            </Button>
          }
          {selectedContactType?.value === 'link' &&
            <Button noMinW
              onClick={() => isUpdate ? onUpdateLink(linkValues.name) : onAddLink()}
              disabled={
                !linkValues.url ||
                !linkValues.name ||
                sending
              }
            >
              {isUpdate ? $.update_button : $.save_btn}
            </Button>
          }
          <OutlinedButton noMinW className="ml-1 h-10" onClick={resetFormValues}>
            {$.cancel_btn}
          </OutlinedButton>
        </div> : <div className="flex items-start justify-start mt-1 mr-1">
          <Button
            noMinW
            onClick={() => {
              if (disabled && onDisabledClick) {
                onDisabledClick();
                return;
              }
              setFormOpen(true)
            }}
          >
            {$.add_contact_info_button}
          </Button>
        </div>}
        <div className="flex flex-col gap-1">
          <div className="flex flex-1 items-start justify-start gap-1">
            {formOpen &&
              <Select
                className="w-56"
                placeholder={$.select_contact_type_label}
                onChange={(v) => setSelectedContactType(v)}
                value={selectedContactType}
                options={contactInfoTypes}
              />
            }
            {formOpen && selectedContactType?.value === 'email' &&
              <>
                <div className="w-56 my-1">
                  <Input
                    type="text"
                    placeholder={$.email_address_label}
                    value={emailValues.email || ''}
                    onChange={(e) => setEmailValues({...emailValues, 'email': e.target.value})}
                  />
                </div>
                <Select
                  className="w-56"
                  onChange={(v) =>
                    setEmailValues({...emailValues, 'email_type': v.value})
                  }
                  // defaultValue={emailAddresses.find(e => e.email_type === emailValues.emailType)?.email || ''}
                  value={emailTypes.find(o => o.value === emailValues.email_type)}
                  placeholder={$.email_type_label}
                  options={emailTypes}
                />
              </>
            }
            {formOpen && selectedContactType?.value === 'phone' &&
              <>
                <div className="w-56 my-1">
                  <PhoneInput
                    type="tel"
                    text={formatPhone(phoneValues.phone)}
                    value={formatPhone(phoneValues.phone)}
                    onChange={(e) => setPhoneValues({...phoneValues, 'phone': e.target.value})}
                  />
                </div>
              </>
            }
            {formOpen && selectedContactType?.value === 'link' &&
              <>
                <div className="w-56 my-1">
                  <Input
                    type="text"
                    placeholder={$.link_placeholder}
                    value={linkValues.url}
                    onChange={(e) => setLinkValues({...linkValues, 'url': e.target.value})}
                  />
                </div>
                <Select
                  className="w-56"
                  onChange={(v) =>
                    setLinkValues({...linkValues, 'name': v.value})
                  }
                  value={linkTypes.find(o => o.value === linkValues.name)}
                  placeholder={$.link_type_label}
                  options={linkTypes}
                />
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddContactInfo;
