import React, { useState, useEffect } from "react";
import { admin_company as $ } from "strings";
import { B2DB, B1 } from "components/Typography";
import { NavLink, Switch, Route, useParams } from "react-router-dom";
import UilSync from "@iconscout/react-unicons/icons/uil-sync";
import Information from "./Information";
import Projects, { ProjectsPreload } from "./Projects";
import Contacts, { ContactsPreload } from "./Contacts";
import DataImport, { DataImportPreload } from "./DataImport";
import ContactForm from "./ContactForm";
import { usePageCache } from "contexts/pageCacheContext";
import { BackLinkBtn, RemoveBtn } from "components/Buttons";
import { getCompany, patchCompany } from "utils/adminApi";
import useQualifications from "hooks/useQualifications";
import useCachedFetch from "hooks/useCachedFetch";
import Section from "components/Section";
import { LoadPlaceholderGroup } from "components/LoadPlaceholder";
import UilCalendar from "@iconscout/react-unicons/icons/uil-calender";
import { toFormat } from "utils/date";
import EditableTitle from "components/EditableTitle";
import AlertError from "components/AlertError";
import HubspotToast from "components/HubspotToast";
import { useAlert } from "react-alert";
import DeleteForm from "./DeleteForm";
import Clockify from "./Clockify";
import { getAdminFlags } from "utils/localStorageService";

const CompanyPage = () => {
  const alert = useAlert();
  const [modal, setModal] = useState();
  const { cache } = usePageCache();
  const { id } = useParams();
  const { qualifications } = useQualifications(true);
  const flags = getAdminFlags();
  const { data, setData, pageState } = useCachedFetch(
    "admin_company",
    getCompany,
    id
  );

  const name = data ? data.name : "";
  useEffect(() => {
    document.title = "Pipeline - " + name;
  }, [name]);

  const onSaveName = async (name) => {
    setData({ ...data, name });

    const call = async () => {
      try {
        await patchCompany(data.id, { name });
      } catch (e) {
        console.error(e);
        alert.error(<AlertError error={e} onRetry={call} />);
      }
    };
    await call();
  };

  const onEditClick = () => {
    alert.success(<HubspotToast type='company' objectId={data.hubspot_company_id} />, {timeout: 10000});
  }

  return (
    <Section id="admin_company">
      <BackLinkBtn to="/admin/company" text={$.back_company} />
      <div className="bg-white pt-6 pb-6 px-4 mb-6 mt-4">
        {pageState === "loading" || !data ? (
          <div className="p-2">
            <LoadPlaceholderGroup />
          </div>
        ) : (
          <>
            <div className="w-full lg:w-1/2 flex items-center flex-col md:flex-row">
              <div className="flex-1 flex items-center">
                  <EditableTitle
                    value={data.name}
                    onSave={onSaveName}
                    disabled={flags.hubspot_features_enabled}
                    onDisabledClick={() => onEditClick()}
                  />
                  <RemoveBtn onClick={() => flags.hubspot_features_enabled ? onEditClick() : setModal("delete")} />
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center md:justify-start mt-2 flex-wrap gap-y-2">
              <div className="flex pr-4 md:border-r border-kasmir">
                <B2DB className="flex mr-2">
                  <UilCalendar className="mr-2" size="18" />
                  {$.created_label}:
                </B2DB>
                <B1>{toFormat(new Date(data.created_at), "MM/dd/yyyy")}</B1>
              </div>
              <div className="flex px-4">
                <B2DB className="flex mr-2">
                  <UilSync className="mr-2" size="18" />
                  {$.updated_label}:
                </B2DB>
                <B1>{toFormat(new Date(data.updated_at), "MM/dd/yyyy")}</B1>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="flex flex-col lg:flex-row w-full mt-4">
        <div className="bg-white p-2 lg:mr-6 mb-6 flex flex-row lg:flex-col overflow-x-auto flex-shrink-0">
          <NavLink
            exact
            to={`/admin/company/${id}`}
            className="-mb-px rounded text-sm px-2 font-bold h-10 min-w-32 flex items-center justify-start text-midnight border-b-2 border-transparent"
            activeClassName="bg-link-lighter text-link"
          >
            {$.company_information_link}
          </NavLink>
          <NavLink
            to={
              cache["url_company_projects"] || `/admin/company/${id}/projects`
            }
            className="-mb-px rounded text-sm px-2 font-bold h-10 min-w-32 flex items-center justify-start text-midnight border-b-2 border-transparent"
            activeClassName="bg-link-lighter text-link"
          >
            {$.projects_link}
          </NavLink>
          <NavLink
            to={`/admin/company/${id}/contacts`}
            className="-mb-px rounded text-sm px-2 font-bold h-10 min-w-32 flex items-center justify-start text-midnight border-b-2 border-transparent"
            activeClassName="bg-link-lighter text-link"
          >
            {$.contacts_link}
          </NavLink>
          {/*<NavLink
          to={`/admin/company/${id}/intel`}
          className="-mb-px text-sm font-bold h-10 w-48 flex items-center justify-start text-midnight border-b-2 border-transparent"
          activeClassName="text-link border-link"
        >
          {$.intel_link}
        </NavLink>*/}
          <NavLink
            to={`/admin/company/${id}/dataimport`}
            className="-mb-px rounded text-sm px-2 font-bold h-10 min-w-32 flex items-center justify-start text-midnight border-b-2 border-transparent"
            activeClassName="bg-link-lighter text-link"
          >
            {$.data_import_link}
          </NavLink>
          {flags.clockify_project_enabled && (
            <NavLink
              to={`/admin/company/${id}/clockify`}
              className="-mb-px rounded text-sm px-2 font-bold h-10 min-w-32 flex items-center justify-start text-midnight border-b-2 border-transparent"
              activeClassName="bg-link-lighter text-link"
            >
              {$.clockify_link}
            </NavLink>
          )}
        </div>
        <div className="flex-1 overflow-x-auto">
          <Switch>
            <Route exact path="/admin/company/:id">
              <Information {...{ data, setData }} hubspotEnabled={flags.hubspot_features_enabled} onEditClick={onEditClick} />
            </Route>
            <Route exact path="/admin/company/:id/projects">
              <Projects {...{ qualifications, company: data }} hubspotEnabled={flags.hubspot_features_enabled} onEditClick={onEditClick} />
            </Route>
            <Route path="/admin/company/:id/contacts">
              <Contacts />
            </Route>
            <Route path="/admin/company/:id/dataimport">
              <DataImport data={data} hubspotEnabled={flags.hubspot_features_enabled} onEditClick={onEditClick} />
            </Route>
            <Route path="/admin/company/:id/clockify">
              <Clockify data={data} setData={setData} />
            </Route>
          </Switch>
        </div>
      </div>
      {modal === "delete" && <DeleteForm data={data} onClose={setModal} />}
      {modal === "add_contact" && (
        <ContactForm
          {...{ companyId: id, setModal, onClose: () => setModal() }}
        />
      )}
      <ProjectsPreload />
      <ContactsPreload />
      <DataImportPreload />
    </Section>
  );
};

export default CompanyPage;
